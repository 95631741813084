import { Divider, Stack } from '@mui/material';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { useFileApi } from '@/modules/api/hooks';
import { PropertiesPopup } from '@/modules/common/components/PropertiesPopup';
import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { downloadFile } from '@/modules/common/helpers/browser';
import { useFileTypeValidator } from '@/modules/common/hooks/useFileValidator';

import { FLOORPLAN_ASSET_FILE_CONTAINER } from '../constants';
import { useUvtAssetFileSyncing, useVehicleDependencyManager } from '../hooks';
import { ValidAssetType } from '../types';
import { allVehicleAssetsSelector } from '../store';
import FileActions from './FileActions';

export const AssetPanel = ({
  anchor,
  vehicleId,
  close,
}: {
  anchor: MutableRefObject<HTMLDivElement>;
  vehicleId: string;
  close: () => void;
}) => {
  const { t } = useTranslation('interface');
  const { getFile } = useFileApi();
  const uvtFileInput = useRef<HTMLInputElement>(null);
  const kmMdbFileInput = useRef<HTMLInputElement>(null);
  const { uploadAndSyncFileToFloorplan } = useUvtAssetFileSyncing();
  const { validateFileType } = useFileTypeValidator();
  const { getLatestFileRef } = useVehicleDependencyManager();
  const allVehicleAssets = useRecoilValue(allVehicleAssetsSelector);
  const vehicleAsset = allVehicleAssets.find((assets) => assets.vehicleVariantId === vehicleId);

  const download = useCallback(
    async (downloadUrl: string, assetType: ValidAssetType, name: string) => {
      try {
        const response = await getFile(
          downloadUrl,
          FLOORPLAN_ASSET_FILE_CONTAINER,
          'arraybuffer',
          // @ts-expect-error strictNullChecks. Pls fix me
          null,
        );

        if (response.status !== 200) {
          console.error(`Failed to download file. Status code: ${response.status}`);
          return;
        }

        if (assetType === 'uvt') {
          downloadFile(response.data, 'application/json', `${name}.json`);
        } else if (assetType === 'kmMdb') {
          downloadFile(response.data, 'application/vnd.ms-access', `${name}.mdb`);
        } else {
          console.warn(`Unsupported asset type: ${assetType}`);
        }
      } catch (error) {
        console.error('Error downloading file', error);
      }
    },
    [getFile],
  );

  const onFileSelect = useCallback(
    (assetType: ValidAssetType, event: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-expect-error strictNullChecks. Pls fix me
      if (validateFileType(event.target.files?.[0], [assetType === 'uvt' ? 'json' : 'mdb'])) {
        uploadAndSyncFileToFloorplan(
          // @ts-expect-error strictNullChecks. Pls fix me
          event.target.files?.[0],
          assetType,
          // @ts-expect-error strictNullChecks. Pls fix me
          vehicleAsset.vehicleVariantId,
        );
      }
    },
    // @ts-expect-error strictNullChecks. Pls fix me
    [uploadAndSyncFileToFloorplan, validateFileType, vehicleAsset.vehicleVariantId],
  );

  const handleUploadClick = useCallback((assetType: ValidAssetType) => {
    if (assetType === 'uvt') {
      uvtFileInput.current?.click();
    } else if (assetType === 'kmMdb') {
      kmMdbFileInput.current?.click();
    }
  }, []);

  return (
    <PropertiesPopup
      anchor={anchor}
      open
      onClose={close}
      header={
        <PropertiesTitle
          fontSize={14}
          fontWeight={400}
          value={t('setup.floorPlan.vehicle_types.asset_panel.title')}
        />
      }
      sx={{
        width: '320px',
        height: 'auto',
      }}
    >
      <Stack spacing='10px'>
        <Stack
          divider={<Divider />}
          gap={1}
          sx={{
            maxHeight: '200px',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {
            // @ts-expect-error strictNullChecks. Pls fix me
            vehicleAsset.uvtReference && (
              <>
                <FileActions
                  fileName={
                    // @ts-expect-error strictNullChecks. Pls fix me
                    vehicleAsset.uvtReferenceOriginal
                      ? // @ts-expect-error strictNullChecks. Pls fix me
                        vehicleAsset.vehicleVariantId
                      : // @ts-expect-error strictNullChecks. Pls fix me
                        `custom-${vehicleAsset.vehicleVariantId}`
                  }
                  fileType='json'
                  onUpload={() => handleUploadClick('uvt')}
                  onDownload={() =>
                    // @ts-expect-error strictNullChecks. Pls fix me
                    download(vehicleAsset.uvtReference, 'uvt', vehicleAsset.vehicleVariantId)
                  }
                  // @ts-expect-error strictNullChecks. Pls fix me
                  onReset={() => getLatestFileRef('uvt', vehicleAsset.vehicleVariantId)}
                />
                <input
                  type='file'
                  accept='.json'
                  ref={uvtFileInput}
                  onChange={(e) => onFileSelect('uvt', e)}
                  hidden
                />
              </>
            )
          }
          {
            // @ts-expect-error strictNullChecks. Pls fix me
            vehicleAsset.kmMdbReference && (
              <>
                <FileActions
                  fileName={
                    // @ts-expect-error strictNullChecks. Pls fix me
                    vehicleAsset.kmMdbReferenceOriginal
                      ? // @ts-expect-error strictNullChecks. Pls fix me
                        vehicleAsset.vehicleVariantId
                      : // @ts-expect-error strictNullChecks. Pls fix me
                        `custom-${vehicleAsset.vehicleVariantId}`
                  }
                  fileType='mdb'
                  onUpload={() => handleUploadClick('kmMdb')}
                  onDownload={() =>
                    // @ts-expect-error strictNullChecks. Pls fix me
                    download(vehicleAsset.kmMdbReference, 'kmMdb', vehicleAsset.vehicleVariantId)
                  }
                  // @ts-expect-error strictNullChecks. Pls fix me
                  onReset={() => getLatestFileRef('kmMdb', vehicleAsset.vehicleVariantId)}
                />
                <input
                  type='file'
                  accept='.mdb'
                  ref={kmMdbFileInput}
                  onChange={(e) => onFileSelect('kmMdb', e)}
                  hidden
                />
              </>
            )
          }
        </Stack>
      </Stack>
    </PropertiesPopup>
  );
};
