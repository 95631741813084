import { Divider, MenuItem } from '@mui/material';
import { HistoryManager } from '@recoil/history';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useFloorPlanState } from '@modules/floorplan';
import { useContextMenu } from '@modules/workspace/hooks';
import { showPivotOptionSelector } from '@/store/recoil/workspace/contextMenu/selectors/showPivotOptionSelector';
import { relatedComponentIdsState } from '@recoil/workspace/contextMenu/state/relatedComponentIdsState';
import { connectionState, allConnectionIds } from '@/modules/connections/connections';
import { CheckIcon } from '@/assets/icons';
import { distcon } from '@/modules/connections/distant';
import { relatedComponentsSelector } from '@/store/recoil/workspace/contextMenu/selectors/relatedComponentsSelector';

export const PivotOption = () => {
  const show = useRecoilValue(showPivotOptionSelector);

  if (!show) return null;

  return <PivotOptionComponent />;
};

const PivotOptionComponent = () => {
  const { t } = useTranslation();
  const { saveFloorPlan } = useFloorPlanState();
  const componentIds = useRecoilValue(relatedComponentIdsState);
  const components = useRecoilValue(relatedComponentsSelector);
  const { hide } = useContextMenu();
  const setConnection = useSetRecoilState(connectionState(componentIds[0]));
  const setDistantConnection = useSetRecoilState(distcon(componentIds[0]));
  const connectionIds = useRecoilValue(allConnectionIds);
  const connection = components[0]

  const onClick = useCallback(async (value: boolean) => {
    hide();

    const oldState = connection;
    const newState = {
      // @ts-expect-error strictNullChecks. Pls fix me
      ...connection,
      usePivots: value,
    };

    HistoryManager.track(`Connection updated`, newState, oldState, (state) =>
      // @ts-expect-error strictNullChecks. Pls fix me
      connectionIds.includes(connection.id) ? setConnection(state) : setDistantConnection(state),
    );

    // @ts-expect-error strictNullChecks. Pls fix me
    if (connectionIds.includes(connection.id)) setConnection(newState);
    else setDistantConnection(newState);

    saveFloorPlan();
  }, [connection, connectionIds, hide, saveFloorPlan, setConnection, setDistantConnection]);

  return (
    <>
      <Divider />
      <MenuItem onClick={() => onClick(false)} component='button'>
        {t('interface:context_menu.workspace.curve_mode', 'Curve mode')}
        {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
        {!connection.usePivots && <CheckIcon sx={{ marginLeft: '70px' }} />}
      </MenuItem>
      <MenuItem onClick={() => onClick(true)} component='button'>
        {t('interface:context_menu.workspace.pivot_mode', 'Pivot mode')}
        {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
        {connection.usePivots && <CheckIcon sx={{ marginLeft: '70px' }} />}
      </MenuItem>
      <Divider />
    </>
  );
};
