import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { useAutodeskDebug } from '@modules/debug';

import { useLayers, useMouseMovement } from '../../AutodeskViewer/hooks';
import { AutodeskViewer } from './AutodeskViewer';
import { useViewerWrapper } from './useViewerWrapper';

type Props = { children: ReactNode };

export const AutodeskViewerWrapper = ({ children }: Props) => {
  const { opacity, urn, x, y, offsetY, offsetX, scale, viewerRef, width, height, onViewerLoaded } =
    useViewerWrapper();

  const { visible, locked } = useLayers();
  // @ts-expect-error strictNullChecks. Pls fix me
  const { onMouseDown, onMouseMove, onMouseUp } = useMouseMovement(viewerRef.current, locked);
  const debug = useAutodeskDebug();

  return (
    <Box
      position='relative'
      height='100%'
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      {!!urn && visible && (
        <AutodeskViewer
          urn={urn}
          x={x}
          y={y}
          offsetX={offsetX}
          offsetY={offsetY}
          scale={scale}
          width={width}
          height={height}
          // @ts-expect-error strictNullChecks. Pls fix me
          ref={viewerRef}
          sx={{ opacity: debug ? 100 : opacity / 100 }}
          gui={debug}
          onViewerLoaded={onViewerLoaded}
        />
      )}
      {!debug && (
        <Box position='absolute' left='0' right='0' top='0' bottom='0' zIndex='3'>
          {children}
        </Box>
      )}
    </Box>
  );
};
