import { useErrorNotification } from '@/modules/Notifications/hooks/useErrorNotification';
import { useAxios, UseAxiosOptions } from './useAxios';
import { useEffect } from 'react';
import { AxiosError } from 'axios';

type UseAxiosErrorOptions = {
  serviceName?: string;
  errorCode: (response: any) => string;
  errorCondition: (response: any) => boolean;
  errorArgs?: (response: any) => string[];
};

export const useAxiosWithErrorNotifications = (
  { baseURL, scopes = [] }: UseAxiosOptions,
  { serviceName, errorCode, errorArgs = () => [], errorCondition }: UseAxiosErrorOptions,
) => {
  const { addErrorNotification } = useErrorNotification();
  const axiosInstance = useAxios({ baseURL, scopes });

  useEffect(() => {
    const responseInterceptor = axiosInstance.interceptors.response.use(
      async (response) => {
        if (errorCondition(response)) {
          console.error(`Fault when executing ${serviceName} call:`, response);

          const args = errorArgs(response);
          const code = errorCode(response);
          await addErrorNotification(code, args);
        }
        return response;
      },
      (error: AxiosError) => {
        // @ts-expect-error strictNullChecks. Pls fix me
        addErrorNotification(error.response.status.toString());
        return error;
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [axiosInstance, errorCondition, serviceName, errorArgs, errorCode, addErrorNotification]);

  return axiosInstance;
};
