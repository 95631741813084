import { MathUtils, Vector2, Vector3 } from 'three';
import { DEG2RAD } from 'three/src/math/MathUtils';

import { checkForBoundingBoxCollision } from '@/helpers/utils';
import { WideLineSegment } from '@/modules/common/types/general';
import { getVectorRotatedAroundPoint } from '@/modules/common/helpers/math';
import { centerPoint } from '@/modules/workspace/helpers/shape';
import { BoundingBox, BoundingBoxMap, OrientedBoundingBox } from '@helpers/types';
// import { calculatePointsShapeBoundingBox } from '@modules/angledHighways';
import { SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import { isPointsShape } from '../types/guards';
import { DTShape } from '../types/shapes';

type MinMaxBox = {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
};
function findBoundingBoxMinAndMax(boundingBox: MinMaxBox, currentBoundingBox: BoundingBox): void {
  const { x, y, height, width } = currentBoundingBox;

  if (x < boundingBox.minX) {
    boundingBox.minX = x;
  }

  if (y < boundingBox.minY) {
    boundingBox.minY = y;
  }

  if (x + width > boundingBox.maxX) {
    boundingBox.maxX = x + width;
  }

  if (y + height > boundingBox.maxY) {
    boundingBox.maxY = y + height;
  }
}

function getBoundingBoxMinMax(boundingBoxes: BoundingBox[]): MinMaxBox {
  const boundingBox: MinMaxBox = {
    minX: Number.POSITIVE_INFINITY,
    minY: Number.POSITIVE_INFINITY,
    maxX: Number.NEGATIVE_INFINITY,
    maxY: Number.NEGATIVE_INFINITY,
  };

  boundingBoxes.forEach((currentBoundingBox) => {
    findBoundingBoxMinAndMax(boundingBox, currentBoundingBox);
  });

  return boundingBox;
}

const zeroBoundingBox = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

const calculateBoundingBoxByModel = (
  boundingBoxes: BoundingBox[],
  getBoundgBoxModel: (minMax: MinMaxBox) => BoundingBox,
): BoundingBox => {
  if (!boundingBoxes.length) return { ...zeroBoundingBox };

  const minMaxBox: MinMaxBox = getBoundingBoxMinMax(boundingBoxes);

  return getBoundgBoxModel(minMaxBox);
};

export const calculateBoundingBoxMM = (boundingBoxes: BoundingBox[]): BoundingBox =>
  calculateBoundingBoxByModel(boundingBoxes, (minMax: MinMaxBox) => ({
    x: Math.round(minMax.minX * 10) / 10,
    y: Math.round(minMax.minY * 10) / 10,
    width: Math.round((minMax.maxX - minMax.minX) * 10) / 10,
    height: Math.round((minMax.maxY - minMax.minY) * 10) / 10,
  }));

export const calculateOrientedBoundingBoxMM = (orientedBoundingBoxes: OrientedBoundingBox[]): BoundingBox => {
  const boundingBoxes = orientedBoundingBoxes.map((box) => calculateOrientedBoundingBoxExtent(box));
  return calculateBoundingBoxByModel(boundingBoxes, (minMax: MinMaxBox) => ({
    x: Math.round(minMax.minX * 10) / 10,
    y: Math.round(minMax.minY * 10) / 10,
    width: Math.round((minMax.maxX - minMax.minX) * 10) / 10,
    height: Math.round((minMax.maxY - minMax.minY) * 10) / 10,
  }))
}

export const calculateBoundingBox = (boundingBoxes: BoundingBox[]): BoundingBox =>
  calculateBoundingBoxByModel(boundingBoxes, (minMax: MinMaxBox) => ({
    x: Math.floor(minMax.minX),
    y: Math.floor(minMax.minY),
    width: Math.floor(minMax.maxX - minMax.minX),
    height: Math.floor(minMax.maxY - minMax.minY),
  }));

export const calculateOrientedBoundingBox = (
  orientedBoundingBoxes: OrientedBoundingBox[],
): BoundingBox => {
  const boundingBoxes = orientedBoundingBoxes.map((box) => calculateOrientedBoundingBoxExtent(box));
  return calculateBoundingBoxByModel(boundingBoxes, (minMax: MinMaxBox) => ({
    x: Math.floor(minMax.minX),
    y: Math.floor(minMax.minY),
    width: Math.floor(minMax.maxX - minMax.minX),
    height: Math.floor(minMax.maxY - minMax.minY),
  }));
};

export const calculateOrientedBoundingBoxCorners = (
  orientedBoundingBox: OrientedBoundingBox,
): Vector2[] => {
  const corners: Vector2[] = [];
  const center = centerPoint(orientedBoundingBox, orientedBoundingBox.r);

  for (let i = 0; i < 4; i++) {
    const angle = Math.PI / 4 + (Math.PI / 2) * i;
    const xSign = Math.sign(Math.cos(-angle));
    const ySign = Math.sign(Math.sin(-angle));
    const cornerOffset = new Vector3(
      (xSign * orientedBoundingBox.width) / 2,
      (ySign * orientedBoundingBox.height) / 2 + 0,
      0,
    );
    cornerOffset.applyAxisAngle(new Vector3(0, 0, 1), -orientedBoundingBox.r * DEG2RAD);
    corners.push(new Vector2(center.x + cornerOffset.x, center.y + cornerOffset.y));
  }

  return corners;
};

export const calculateOrientedBoundingBoxExtent = (
  boundingBox: OrientedBoundingBox,
): BoundingBox => {
  const corners = calculateOrientedBoundingBoxCorners(boundingBox);
  const minX = Math.min(...corners.map((corner) => corner.x));
  const maxX = Math.max(...corners.map((corner) => corner.x));
  const minY = Math.min(...corners.map((corner) => corner.y));
  const maxY = Math.max(...corners.map((corner) => corner.y));

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

export const calculateShapeExtent = (
  shape: DTShape,
): BoundingBox => {
  if (isPointsShape(shape)) {
    return {
      ...calculatePointsShapeBoundingBox(
        shape.properties.controlPoints.map((cp) => cp.position),
        shape.parameters.width,
      ),
    };
  }

  return calculateOrientedBoundingBoxExtent(shape.properties)
};

/**
 * Changes the vertical origin of the bounding box
 */
export const flipBoundingBoxVertically = (
  boundingBox: BoundingBox,
  workspaceBoundingBox: BoundingBox,
): BoundingBox => {
  const { x, y, height, width } = boundingBox;

  return {
    width,
    height,
    x,
    y: Math.floor(workspaceBoundingBox.height - y - height),
  };
};

export const makeShapeBoundingBoxCmMap = (shapes: DTShape[]): BoundingBoxMap =>
  new Map(
    shapes.map((shape) => {
      let shapeBoundingBoxCm: BoundingBox;

      const shapeId = shape.id;

      if (isPointsShape(shape)) {
        const { x, y, width, height } = calculatePointsShapeBoundingBox(
          shape.properties.controlPoints.map((cp) => cp.position),
          shape.parameters.width,
        );

        shapeBoundingBoxCm = {
          x: x * SHAPE_TO_CANVAS_SCALE,
          y: y * SHAPE_TO_CANVAS_SCALE,
          width: width * SHAPE_TO_CANVAS_SCALE,
          height: height * SHAPE_TO_CANVAS_SCALE,
        };

        return [shapeId, shapeBoundingBoxCm];
      }

      const { x, y, width, height } = shape.properties;
      shapeBoundingBoxCm = {
        x: x * SHAPE_TO_CANVAS_SCALE,
        y: y * SHAPE_TO_CANVAS_SCALE,
        width: width * SHAPE_TO_CANVAS_SCALE,
        height: height * SHAPE_TO_CANVAS_SCALE,
      };

      return [shapeId, shapeBoundingBoxCm];
    }),
  );

export const checkForShapeCollisions = (colliders: DTShape[], shapes: DTShape[]): DTShape[] => {
  const collidingShapes: DTShape[] = []

  colliders.forEach(collider => {
    shapes.forEach(shape => {
      if (
        checkForBoundingBoxCollision(
          calculateShapeExtent(collider),
          calculateShapeExtent(shape),
        )
      )
        collidingShapes.push(shape)
    });
  });

  return collidingShapes
}

export const boundingBoxToLine = (boundingBox: OrientedBoundingBox): WideLineSegment => {
  const horizontal = boundingBox.width > boundingBox.height;
  const x = horizontal ? boundingBox.x : boundingBox.x + boundingBox.width / 2;
  const y = horizontal ? boundingBox.y + boundingBox.height / 2 : boundingBox.y;
  const width = horizontal ? boundingBox.width : 0;
  const height = horizontal ? 0 : boundingBox.height;

  const start = new Vector2(x, y);
  const end = new Vector2(x + width, y + height);

  const centerOfRotation = new Vector2(boundingBox.x, boundingBox.y);

  return {
    points: {
      start: getVectorRotatedAroundPoint(start, centerOfRotation, -boundingBox.r),
      end: getVectorRotatedAroundPoint(end, centerOfRotation, -boundingBox.r),
    },
    width: horizontal ? boundingBox.height : boundingBox.width,
  };
};

export const wideLineSegmentToOrientedBoundingBox = (line: WideLineSegment): OrientedBoundingBox => {
  const {
    points: { start, end },
    width,
  } = line;
  const startV = new Vector2(start.x, start.y);
  const endV = new Vector2(end.x, end.y);

  const boxWidth = startV.distanceTo(endV);
  const boxHeightHalf = width / 2;
  const middleLineInterpolatedTowardsEndByHalfWidth = startV
    .clone()
    .lerp(endV, boxHeightHalf / boxWidth);
  const origin = getVectorRotatedAroundPoint(
    middleLineInterpolatedTowardsEndByHalfWidth,
    startV,
    -90,
  );

  const rotation = MathUtils.radToDeg(Math.atan2(endV.y - startV.y, endV.x - startV.x));

  return {
    x: Math.round(origin.x),
    y: Math.round(origin.y),
    width: Math.round(boxWidth),
    height: Math.round(width),
    r: Math.round(rotation * -1),
  };
};

export const calculatePointsShapeBoundingBox = (
  points: Vector2[],
  highwayWidth: number,
): BoundingBox => {
  const xValues = [];
  const yValues = [];
  points.forEach((cp) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    xValues.push(cp.x);
    // @ts-expect-error strictNullChecks. Pls fix me
    yValues.push(cp.y);
  });

  const minX = Math.min(...xValues);
  const minY = Math.min(...yValues);
  const maxX = Math.max(...xValues);
  const maxY = Math.max(...yValues);

  return {
    x: minX - highwayWidth / 2,
    y: minY - highwayWidth / 2,
    width: maxX - minX + highwayWidth,
    height: maxY - minY + highwayWidth,
  };
};
