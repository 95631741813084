import { CircleElement, Color, Element, RectElement } from '@thive/canvas';
import { v4 as uuid } from 'uuid';
import { LayoutPoints, UnitShapes } from '../helpers/types';
import { Vector3 } from 'three';

export const POINT_RADIUS = 50;
export const POINT_RADIUS_SELECTED = 200;
export const UNIT_RADIUS = 100 * 2;
export const POINT_COLOR = '#638cab';
export const POINT_COLOR_SELECTED = '#ff551c';
export const UNIT_COLOR = '#1556ed80';

export const drawLayoutPoints = (layoutPoints: LayoutPoints) => {
  const elements: Element[] = [];
  const shapes: UnitShapes = new Map();

  layoutPoints.forEach((unit) => {
    const pointShapeIds = new Set<string>();

    // render all points on each unit
    unit.positions.forEach((point) => {
      const id = uuid();
      const element: CircleElement = {
        id,
        name: id,
        type: 'Circle',
        radius: POINT_RADIUS,
        center: new Vector3(point.x, point.y),
        fill: Color.fromHex(POINT_COLOR),
      };
      elements.push(element);
      pointShapeIds.add(id);
    });

    // render unit centroid
    const unitId = unit.id.toString();
    const element: RectElement = {
      id: unitId,
      name: unitId,
      type: 'Rect',
      // @ts-expect-error strictNullChecks. Pls fix me
      position: new Vector3(unit.center.x, unit.center.y),
      size: new Vector3(UNIT_RADIUS, UNIT_RADIUS),
      fill: Color.fromHex(UNIT_COLOR),
    };
    elements.push(element);
    shapes.set(unitId, pointShapeIds);

    // render unit text
    const text = {
      type: 'Text',
      id: `${unit.id.toString()}-text`,
      name: `${unit.id.toString()}-text`,
      // @ts-expect-error strictNullChecks. Pls fix me
      position: new Vector3(unit.center.x - UNIT_RADIUS, unit.center.y - UNIT_RADIUS * 2),
      text: `Unit ${unit.id.toString()}`,
      fontSize: 200,
      color: Color.fromHex('#1556ed'),
    };
    elements.push(text as Element);
  });

  return { elements, shapes };
};
