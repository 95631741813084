import { Button } from '@mui/material';
import { useCallback, useRef } from 'react';

type Props = {
  accept: string;
  onUploaded: (file: File) => void;
  label: string;
  disabled?: boolean;
};

export const FileUploadButton = ({ accept, label, onUploaded, disabled = false }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>();

  const changeHandler = useCallback(
    // @ts-expect-error strictNullChecks. Pls fix me
    (e: React.ChangeEvent<HTMLInputElement>) => onUploaded(e.currentTarget.files[0]),
    [onUploaded],
  );

  return (
    <>
      <input
        onChange={changeHandler}
        accept={accept}
        // @ts-expect-error strictNullChecks. Pls fix me
        ref={fileInputRef}
        type='file'
        style={{ display: 'none' }}
      />
      <Button
        disabled={disabled}
        onClick={() => {
          if (fileInputRef.current) fileInputRef.current.click();
        }}
      >
        {label}
      </Button>
    </>
  );
};
