import { useRecoilCallback } from 'recoil';

import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { useArtefacts } from '@/modules/artefacts';
import { getOrderedControlPoints } from '@/modules/common/helpers/shapes';
import { ControlPoint, ControlPointsObj } from '@/modules/common/types/shapes';
import { useConnections } from '@/modules/connections';
import { useAutoSave } from '@/modules/floorplan';
import { useDeleteShape } from '@modules/workspace/hooks';
import shapeAtom from '@recoil/shape/atom';
import { useControlPointHistoryTracking } from './useControlPointHistoryTracking';

export const useDeleteControlPoint = () => {
  const { deleteMultipleShapes } = useDeleteShape();
  const { trackControlPointHistory } = useControlPointHistoryTracking();
  const { updateConnections } = useConnections();
  const { update: updateArtefacts } = useArtefacts();
  const { save } = useAutoSave();

  const deleteControlPoint = useRecoilCallback(
    ({ snapshot, set }) =>
      async (deleteId: string, shapeId: string) => {
        const highway = (await snapshot.getPromise(shapeAtom(shapeId))) as AngledHighwayShape;

        if (highway.properties.controlPoints.length === 2) {
          deleteMultipleShapes([shapeId]);

          // TODO
          // angled highway connection support
          // runPreValidation();
        } else {
          // delete single controlPoint

          let previousControlPoints: ControlPoint[];
          let newControlPoints: ControlPoint[];

          set(shapeAtom(shapeId), (current: AngledHighwayShape): AngledHighwayShape => {
            previousControlPoints = current.properties.controlPoints;

            const tempControlPoints: ControlPointsObj = current.properties.controlPoints.reduce(
              (acc: ControlPointsObj, item: ControlPoint) => {
                acc[item.id] = item;

                return acc;
              },
              {},
            );
            const controlPointToDelete = tempControlPoints[deleteId];
            const prevControlPointId = controlPointToDelete.prev;
            const nextControlPointId = controlPointToDelete.next;

            // update previous cp
            if (prevControlPointId) {
              tempControlPoints[prevControlPointId] = {
                ...tempControlPoints[prevControlPointId],
                next: nextControlPointId || null,
              };
            }

            // update next cp
            if (nextControlPointId) {
              tempControlPoints[nextControlPointId] = {
                ...tempControlPoints[nextControlPointId],
                prev: prevControlPointId || null,
              };
            }

            delete tempControlPoints[deleteId];

            newControlPoints = getOrderedControlPoints(Object.values(tempControlPoints));

            return {
              ...current,
              properties: {
                ...current.properties,
                controlPoints: newControlPoints,
              },
            };
          });

          trackControlPointHistory(
            // @ts-expect-error strictNullChecks. Pls fix me
            { shapeId, value: newControlPoints },
            {
              shapeId,
              // @ts-expect-error strictNullChecks. Pls fix me
              value: previousControlPoints,
            },
          );
        }

        updateConnections([shapeId]);
        updateArtefacts([shapeId]);
        save();
      },
    [deleteMultipleShapes, save, trackControlPointHistory, updateConnections, updateArtefacts],
  );

  return {
    deleteControlPoint,
  };
};
