import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import shapeAtom from '../../../store/recoil/shape/atom';
import { getShapeColor, LANE_COLOR } from '../helpers/colors';

export type Colors = {
  shapeColor: string;
  shapeStrokeColor: string;
  laneColor: string;
  loadBoxColor: string;
};

export const useColors = (shapeId: string) => {
  const { type, disabled } = useRecoilValue(shapeAtom(shapeId));

  return useMemo(() => {
    const transparency = disabled ? '22' : undefined;

    return {
      // @ts-expect-error strictNullChecks. Pls fix me
      shapeColor: getShapeColor(type, true, transparency),
      // @ts-expect-error strictNullChecks. Pls fix me
      shapeStrokeColor: getShapeColor(type, false, transparency),
      laneColor: LANE_COLOR,
      // @ts-expect-error strictNullChecks. Pls fix me
      loadBoxColor: getShapeColor(type, true, transparency ?? '66'),
    };
  }, [type, disabled]);
};
