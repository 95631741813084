import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';

import dwgAtom from './atom';

const referenceApsUrn = selector<string>({
  key: 'reference/urn',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: ({ get }) => get(dwgAtom).apsUrn,
  set: ({ get, set }, newValue) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    set(dwgAtom, { ...get(dwgAtom), apsUrn: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default referenceApsUrn;
