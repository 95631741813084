import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Accordion } from '@/modules/common/components/Accordion';
import selectedShapesIdsState from '@/store/recoil/shapes/selected/selectedShapesIdsState';
import { InfoText } from '@common/components/InfoText';
import {
  PreValidationAspect,
  PrevalidationDebounceMs,
  useRunPreValidation,
} from '@modules/floorplanValidation/clientSide';
import { Flow, FlowInfo } from '@modules/flows/components';
import { useFlow } from '@modules/flows/hooks/useFlow';
import { flowInfoIdSelector } from '@modules/flows/store/flowInfoId';
import { FlowScope } from '@modules/flows/types';
import { Stack } from '@mui/material';

export const RelatedFlows = () => {
  const { t } = useTranslation('interface');
  const [flowInfoId, setFlowInfoId] = useRecoilState(flowInfoIdSelector);
  const containerRef = useRef<HTMLDivElement>();
  const { getRelatedFlowIdsOfShapes } = useFlow();
  const [flowIds, setFlowIds] = useState<string[]>([]);
  const selectedShapeIds = useRecoilValue(selectedShapesIdsState);
  const { runPreValidation } = useRunPreValidation(PrevalidationDebounceMs.INPUT);

  const updateFlowIds = useCallback(
    async (shapeIds: string[]) => {
      if (shapeIds.length === 0) setFlowIds([]);
      const relatedFlowIds = await getRelatedFlowIdsOfShapes(shapeIds);
      setFlowIds(relatedFlowIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onDelete = useCallback(() => {
    updateFlowIds(selectedShapeIds);
    runPreValidation([
      PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
      PreValidationAspect.DISCONNECTED_AREA_IDS,
      PreValidationAspect.CONNECTION_LACKING_ROADS,
    ]);
  }, [runPreValidation, updateFlowIds, selectedShapeIds]);

  const closeFlowInfo = useCallback(() => {
    setFlowInfoId(null);
  }, [setFlowInfoId]);

  const onAccordionToggle = useCallback(
    (expanded) => !expanded && setFlowInfoId(null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    updateFlowIds(selectedShapeIds);
  }, [selectedShapeIds, updateFlowIds]);

  useEffect(
    () => {
      closeFlowInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Accordion
        title={t('properties.flows.header', { count: flowIds.length })}
        onChange={onAccordionToggle}
        unmountOnExit
        sx={{ px: 2 }}
      >
        {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
        <Stack ref={containerRef}>
          {flowIds.length ? (
            flowIds.map((id) => (
              <Flow
                flowScope={FlowScope.LAYOUT}
                tooltipTitle={t('properties.flows.delete.tooltip')}
                key={id}
                id={id}
                onDelete={onDelete}
              />
            ))
          ) : (
            <InfoText
              header={t('properties.flows.related_flows.no_flows.title')}
              subHeader={t('properties.flows.related_flows.no_flows.subtitle')}
              sx={{ width: '90%', margin: '1rem auto' }}
            />
          )}
        </Stack>
      </Accordion>

      {flowInfoId && (
        <FlowInfo
          propertiesLabeli18nkey='interface:properties.flows.flow_info.total_loads.label'
          // @ts-expect-error strictNullChecks. Pls fix me
          anchor={containerRef}
          id={flowInfoId}
          onClose={closeFlowInfo}
          flowScope={FlowScope.LAYOUT}
        />
      )}
    </>
  );
};
