import { create } from 'zustand';
import { parseLayoutPointFile, parseUnitDataFile } from '../helpers/parseFile';
import {
  ConnectionShapes,
  JunctionShapesIds,
  LayoutPoints,
  ShapeId,
  UnitData,
  UnitId,
  UnitShapes,
} from '../helpers/types';
import { unitIdToJunctionShapeIds } from '../helpers/unitIdToJunctionShapeIds';

type LayoutState = {
  layoutPoints: LayoutPoints;
  unitData: UnitData;
  unitShapes: UnitShapes;
  connectionShapes: ConnectionShapes;
  deleteConnectionIds: Set<ShapeId>;
};

type LayoutActions = {
  // import
  setLayoutPoint(file: File): void;
  setUnitData(file: File): void;

  // operation
  getJunctionShapeIds(unitId: UnitId): JunctionShapesIds;
  setUnitShapes(unitShapes: UnitShapes): void;
  setConnectionShapes(shapes: ConnectionShapes): void;
  deleteConnections(ids: ShapeId[]): void;
  keepConnections(ids: ShapeId[]): void;
  reset(): void;
};

const INITIAL_STATE: LayoutState = {
  // @ts-expect-error strictNullChecks. Pls fix me
  layoutPoints: undefined,
  // @ts-expect-error strictNullChecks. Pls fix me
  unitData: undefined,
  // @ts-expect-error strictNullChecks. Pls fix me
  unitShapes: undefined,
  // @ts-expect-error strictNullChecks. Pls fix me
  connectionShapes: undefined,
  // @ts-expect-error strictNullChecks. Pls fix me
  deleteConnectionIds: undefined,
};

export const use3TE25LayoutStore = create<LayoutState & LayoutActions>((set, get) => ({
  ...INITIAL_STATE,
  setLayoutPoint: async (file?: File) => {
    if (file) {
      set({ layoutPoints: parseLayoutPointFile(await file.text()) });
    }
  },
  // @ts-expect-error strictNullChecks. Pls fix me
  getJunctionShapeIds: (unitId: UnitId) => {
    const junctions = unitIdToJunctionShapeIds(get().connectionShapes);
    return junctions.get(unitId);
  },
  setUnitData: async (file?: File) => {
    if (file) {
      set({ unitData: parseUnitDataFile(await file.text()) });
    }
  },
  setUnitShapes: (unitShapes: UnitShapes) => {
    set({ unitShapes });
  },
  setConnectionShapes: (connectionShapes: ConnectionShapes) => {
    set({ connectionShapes, deleteConnectionIds: new Set() });
  },
  deleteConnections: (ids: ShapeId[]) =>
    set((state) => {
      ids.forEach((id) => state.deleteConnectionIds.add(id));
      return state;
    }),
  keepConnections: (ids: ShapeId[]) =>
    set((state) => {
      ids.forEach((id) => state.deleteConnectionIds.delete(id));
      return state;
    }),
  reset: () => {
    set(INITIAL_STATE);
  },
}));
