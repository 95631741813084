import { selector } from 'recoil';
import { MAX_ZOOM_SCALE, MAX_ZOOM_SCALE_CLAMPED, stageRefSelector, stageSelector } from '.';
import workspaceAtom from './atom';
import { referencePan } from '@modules/referenceImage/store';
import { Position } from '@helpers/types';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { clampZoomScale } from '../../../modules/workspace/helpers/clampZoomScale';
import { autoScaleAllNodes } from '@/modules/workspace/hooks/useAutoScale';

export type ZoomProps = {
  newScale: number;
  centerIn?: Position;
  forceCenter?: Position;
  limitZoomIn?: boolean;
};

export const zoomSelector = selector<ZoomProps>({
  key: 'zoomSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: () => null,
  set: ({ get, set }, newValue: ZoomProps) => {
    const { centerIn, forceCenter, limitZoomIn } = newValue;
    let { newScale } = newValue;

    if (limitZoomIn) {
      newScale = clampZoomScale({
        scale: newScale,
        clampMaxScale: limitZoomIn,
        clampMinScale: true,
        maxScale: limitZoomIn ? MAX_ZOOM_SCALE_CLAMPED : MAX_ZOOM_SCALE,
      });
    }

    let center = centerIn;
    const oldStage = get(stageSelector);
    if (!center) {
      const { viewport } = get(workspaceAtom);
      center = {
        x: viewport.w / 2,
        y: viewport.h / 2,
      };
    }

    const absCenter = {
      x: (center.x - oldStage.x) / oldStage.scale,
      y: (center.y - oldStage.y) / oldStage.scale,
    };

    const newOrigin = {
      x: -newScale * absCenter.x + center.x,
      y: -newScale * absCenter.y + center.y,
    };

    if (forceCenter) {
      newOrigin.x = forceCenter.x;
      newOrigin.y = forceCenter.y;
    }

    set(referencePan, {
      x: newOrigin.x,
      y: newOrigin.y,
    });

    set(stageSelector, {
      scale: newScale,
      x: newOrigin.x,
      y: newOrigin.y,
    });

    const stageRef = get(stageRefSelector);
    autoScaleAllNodes(stageRef, newScale);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
