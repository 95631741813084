import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { useArtefacts } from '@/modules/artefacts';
import { TemplateGroupGenerationParams } from '@/modules/common/types/shapeGroup';
import { useShapeGroup, shapeGroupState } from '@/modules/shapeGroups';
import { useTemplatingService } from '@/modules/templating/hooks/useTemplatingService';
import { TemplateType } from '@/modules/common/types/templating';
import { useDeleteShape } from '@/modules/workspace/hooks';
import { allShapesSelector } from '@/store/recoil/shapes';
import {
  prepareToLoad as prepareToLoadConnections,
  saveLoadConnectionSelector,
} from '@modules/connections/connections';
import {
  prepareToLoad as prepareToLoadDistantConnections,
  saveLoadDistantConnectionsSelector,
} from '@modules/connections/distant';
import {
  prepareToLoad as prepareToLoadCrossings,
  saveLoadCrossingSelector,
} from '@modules/connections/crossings';
import { prepareToLoad as prepareToLoadShapes } from '@modules/shapes';
import { useRunPreValidation } from '@/modules/floorplanValidation/clientSide';
import { customIdGeneratorsState } from '@/modules/floorplanService';
import { useLoading } from '@/modules/common/hooks';
import { LoadingType } from '@/modules/common/types/general';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

export const useTemplateGenerationCallbacks = () => {
  const { t } = useTranslation();
  const { generateTemplate } = useTemplatingService();
  const { createTemplateGroupFromShapeIds, deleteGroups } = useShapeGroup();
  const { showSnackbar } = useSnackbarStore();
  const { update: updateArtefacts } = useArtefacts();
  const { deleteMultipleShapes } = useDeleteShape();
  const { runPreValidation } = useRunPreValidation();
  const { showLoader, hideLoader } = useLoading();

  const createTemplate = useRecoilCallback(
    ({ snapshot, set }) =>
      async <T extends TemplateType>(
        type: T,
        input: TemplateGroupGenerationParams[T],
        templateToReplaceId?: string,
      ) => {
        showLoader(LoadingType.TRANSPARENT);
        const result = await generateTemplate(input);

        if (!result.success) {
          hideLoader();
          showSnackbar(
            t('errors:template.failed_to_generate', 'Failed to generate template.')
          );

          return null;
        }

        // TODO: ensure that shapes fall within stage boundaries, edit them as needed. handle case when not fittable within stage

        if (templateToReplaceId) {
          const templateToReplace = await snapshot.getPromise(shapeGroupState(templateToReplaceId));
          await deleteGroups([templateToReplaceId]);
          await deleteMultipleShapes(templateToReplace.children);
        }

        const { shapes, connections, distcons, idGenerators, unit } = result.data;
        const shapeIdsToGroup = [];

        // TODO: remains to be seen if vehicle types and load types will be provided by template generation response.
        // For now, manually add this so the shape loading functionality can be reused for loading shapes from the template service
        const tempProvidedVehicleTypes = [];
        const tempProvidedLoadTypes = [];
        const shapesPrepared = prepareToLoadShapes(shapes, tempProvidedVehicleTypes, tempProvidedLoadTypes, unit)

        if (shapes && shapes.length > 0) {
          set(allShapesSelector, (currentShapes) => [
            ...currentShapes,
            ...shapesPrepared,
          ]);
          // @ts-expect-error strictNullChecks. Pls fix me
          shapeIdsToGroup.push(...shapes.map((item) => item.id));
        }
        if (connections && connections.length > 0) {
          set(saveLoadConnectionSelector, (currentConnections) => [
            ...currentConnections,
            ...prepareToLoadConnections(connections, shapesPrepared),
          ]);
        }
        if (distcons && distcons.length > 0) {
          set(saveLoadDistantConnectionsSelector, (currentDistcons) => [
            ...currentDistcons,
            ...prepareToLoadDistantConnections(distcons, shapes),
          ]);
        }
        set(saveLoadCrossingSelector, (currentCrossings) => [
          ...currentCrossings,
          ...prepareToLoadCrossings(shapes),
        ]);

        const group = await createTemplateGroupFromShapeIds(type, shapeIdsToGroup, input);
        set(customIdGeneratorsState, idGenerators);
        await updateArtefacts(group.children);
        hideLoader();
        runPreValidation();

        return group;
      },
    [
      createTemplateGroupFromShapeIds,
      deleteGroups,
      deleteMultipleShapes,
      generateTemplate,
      runPreValidation,
      showSnackbar,
      t,
      updateArtefacts,
      showLoader,
      hideLoader,
    ],
  );

  return { createTemplate };
};
