import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import shapeAtom from '../../../shape/atom';
import { relatedShapeIdsState } from '../state';

export const relatedShapesSelector = selector({
  key: 'contextMenu/workspace/context/shapes', // TODO: give appropriate name
  get: ({ get }) => {
    const shapeIds = get(relatedShapeIdsState);

    const shapes = [];
    shapeIds.forEach((id) => {
      const shape = get(shapeAtom(id));
      // @ts-expect-error strictNullChecks. Pls fix me
      shapes.push(shape);
    });

    return shapes;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
