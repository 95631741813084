import { Module } from '@/modules/common/types/navigation';
import { usePrevious } from '@modules/common/hooks';
import navAtom from '@recoil/nav';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Visualization, VisualizationRef } from './visualization';

export const VisualizationLifecycleWrapper = () => {
  const [nav, setNav] = useRecoilState(navAtom);
  const prevNav = usePrevious(nav);
  const ref = useRef<VisualizationRef>();
  const [isUnloaded, setIsUnloaded] = useState(true);

  useEffect(() => {
    if (nav === Module.VISUALISATION || nav === Module.VIEWER) {
      setIsUnloaded(false);
    } else if (prevNav === Module.VISUALISATION || prevNav === Module.VIEWER) {
      ref.current?.requestUnload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nav]);

  const handleUnloaded = useCallback(() => {
    setIsUnloaded(true);
    //  navigate the other module if visualization unloaded
    if (nav === Module.VISUALISATION) {
      setNav(Module.SIMULATION);
    }
    if (nav === Module.VIEWER) {
      setNav(Module.LAYOUT);
    }
  }, [nav, setNav]);

  // @ts-expect-error strictNullChecks. Pls fix me
  return <>{!isUnloaded && <Visualization ref={ref} onUnloaded={handleUnloaded} />}</>;
};
