/* eslint-disable import/no-named-as-default */
import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { unitSelector } from './unitSelector';
import { convertToMMFromUnit } from '@/modules/common/helpers/unit';

export const unitConverterSelector = selectorFamily({
  key: 'unitConverterSelector',
  get:
    (value: number) =>
    ({ get }) => {
      const unit = get(unitSelector);
      return convertToMMFromUnit(unit, value);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
