import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useCanvasStore } from '@/modules/canvas';
import { ToolState as DTTools } from '@modules/common/types/tools';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';

type ToolState = {
  tool: DTTools;
};

type ToolActions = {
  setTool(tool: DTTools): void;
  reset(): void;
};

const INITIAL_STATE: ToolState = {
  tool: DTTools.SELECT,
};

export const useToolStore = create<ToolState & ToolActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      setTool: (tool: DTTools) => {
        if (tool === get().tool) return;

        const canvas = useCanvasStore.getState().instance;

        switch (tool) {
          case DTTools.PAN:
            // @ts-expect-error strictNullChecks. Pls fix me
            canvas.activateTool('Pan');
            break;
          case DTTools.DRAW:
            // @ts-expect-error strictNullChecks. Pls fix me
            canvas.activateTool('Draw');
            break;
          default:
            // @ts-expect-error strictNullChecks. Pls fix me
            canvas.activateTool('Select');
            break;
        }

        set(
          {
            tool,
          },
          undefined,
          {
            type: 'setTool',
          },
        );
      },

      reset: () => {
        set(INITIAL_STATE, undefined, {
          type: 'reset',
        });
      },
    }),
    { store: 'toolStore', ...DEVTOOLS_OPTIONS },
  ),
);
