import { stageRefSelector } from '@/store/recoil/workspace';
import { Stage } from 'konva/lib/Stage';
import { useRecoilCallback } from 'recoil';
import {
  AUTOSCALE_NAME,
  AUTOSCALE_MIN,
  AUTOSCALE_MAX,
  AUTOSCALE_DEFAULT,
  AUTOSCALE_STROKE_NAME,
  AUTOSCALE_STROKE_MIN,
  AUTOSCALE_STROKE_MAX,
  AUTOSCALE_STROKE_DEFAULT,
} from '../helpers/konva';
import { Shape } from 'konva/lib/Shape';
import { useEffect } from 'react';

const scaleWithMinMax = (
  minValue: number,
  maxValue: number,
  defaultValue: number,
  newScale: number,
): number => {
  const minScale = minValue ?? 1;
  const maxScale = maxValue ?? 1;
  const defaultScale = defaultValue ?? 1;
  let scale = defaultScale / newScale;
  scale = scale > maxScale ? maxScale : scale;
  scale = scale < minScale ? minScale : scale;
  return scale;
};

export const autoScaleAllNodes = (stageRef: Stage, newScale: number) => {
  if (!stageRef) return;

  // scale the node
  const scalableNodes = stageRef.find(`.${AUTOSCALE_NAME}`);
  scalableNodes.forEach((node) => {
    const shape = node as Shape;
    const scale = scaleWithMinMax(
      shape.getAttr(AUTOSCALE_MIN),
      shape.getAttr(AUTOSCALE_MAX),
      shape.getAttr(AUTOSCALE_DEFAULT),
      newScale,
    );
    shape.scale({ x: scale, y: scale });
  });

  // scale the stroke
  const scalableStrokeNodes = stageRef.find(`.${AUTOSCALE_STROKE_NAME}`);
  scalableStrokeNodes.forEach((node) => {
    const shape = node as Shape;
    const scale = scaleWithMinMax(
      shape.getAttr(AUTOSCALE_STROKE_MIN),
      shape.getAttr(AUTOSCALE_STROKE_MAX),
      shape.getAttr(AUTOSCALE_STROKE_DEFAULT),
      newScale,
    );
    shape.strokeWidth(scale);
    if (shape.dash) {
      shape.dash([scale * 2, scale * 2]);
    }
  });
};

export const useAutoScale = () => {
  const update = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const stageRef = await snapshot.getPromise(stageRefSelector);
        // @ts-expect-error strictNullChecks. Pls fix me
        const scale = stageRef.scale().x;
        autoScaleAllNodes(stageRef, scale);
      },
    [],
  );

  useEffect(() => {
    update();
  }, [update]);

  return { update };
};
