import { produce } from 'immer';
import { Draft } from 'immer/src/types/types-external';
import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { durationFromHours } from '@/modules/common/helpers/date';
import { isProcessAreaOneEp } from '@/modules/common/types/guards';
import { Module } from '@/modules/common/types/navigation';
import {
  floorPlanIdSelector,
  groupIdSelector,
  isLatestSelector,
  projectIdSelector,
  useFloorPlanService,
} from '@/modules/floorplan';
import { useSimulationFlows } from '@/modules/flows/simulation/hooks';
import { layoutFlowsSelector } from '@/modules/flows/store/layout';
import { useOrderProfile } from '@/modules/orderProfile';
import { allProcessTwoEndPointSelector } from '@/modules/processTwoEndPoint/store';
import { enabledVehiclesSelector, vehicleCapacitySelector } from '@/modules/vehicles';
import navAtom from '@/store/recoil/nav/atom';
import { allAreasSelector } from '@/store/recoil/shapes/area';
import { useDebouncedCallback, useNavigation } from '@modules/common/hooks';
import { displayVersionSelector } from '@modules/floorplan/store/floorPlan';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';
import { createDependentFlows, mergeVersions } from '../helpers/simulation';
import { Simulation, SimulationDraft, SimulationStatus } from '../helpers/types';
import { currentSimulationIdState, currentSimulationState } from '../store/draft';
import {
  currentGroupIdSelector,
  currentGroupSelector,
  currentGroupSimulationIdsSelector,
  currentGroupSimulationSelector,
} from '../store/group';
import {
  errorMessageSelector,
  isFirstLoadedSelector,
  isLoadingSelector,
  moduleSelector,
  floorPlanIdSelector as simulationFloorPlanIdSelector,
} from '../store/module';
import {
  floorPlanVersionsSelector,
  nextSimulationNameSelector,
  simulationIdsSelector,
  simulationSelector,
} from '../store/simulations';
import { useSimulationApi } from './useSimulationApi';
import { useErrorNotification } from '@/modules/Notifications/hooks/useErrorNotification';

/**
 * Simulation callbacks for simulation and simulation groups
 */
export const useSimulationCallbacks = () => {
  const {
    fetchAllGroups,
    fetchGroupConfiguration,
    abortGroup: abortRemote,
    removeGroup: removeRemote,
    rerunGroup: rerunRemote,
    renameGroup,
  } = useSimulationApi();
  const { fetchAllVersions } = useFloorPlanService();
  const { goToTracker } = useNavigation();
  const renameGroupDebounced = useDebouncedCallback(renameGroup, 300);
  const { copyAllLayoutFlowsToSimulationFlows, updateSimulationFlowsWithSimulationDraft } =
    useSimulationFlows();
  const { prepareForSimulation } = useOrderProfile();
  const { addErrorNotification } = useErrorNotification();

  const clearState = useRecoilCallback(
    ({ snapshot, reset }) =>
      async () => {
        // NOTE: workaround for issue: resizing window which activates mobile drawer unmounts sim panel which clears state.
        const nav = await snapshot.getPromise(navAtom);
        if (nav === Module.SIMULATION) return;

        reset(currentSimulationState);
        reset(currentSimulationIdState);
        const ids = await snapshot.getPromise(simulationIdsSelector);
        ids.forEach((id) => reset(simulationSelector(id)));
        reset(simulationIdsSelector);
        reset(moduleSelector);
        reset(floorPlanVersionsSelector);
        reset(currentGroupIdSelector);
        reset(currentGroupSelector);
      },
    [],
  );

  const updateSimulation = useRecoilCallback(
    ({ set }) =>
      async (id: string, func: (draft: Draft<Simulation>) => void) => {
        set(simulationSelector(id), (state) => produce(state, func));
      },
    [],
  );

  const abort = useCallback(
    async (simulation: Simulation) => {
      try {
        await updateSimulation(simulation.id, (simulation) => {
          simulation.status = SimulationStatus.ABORTING;
        });
        await abortRemote(simulation.floorPlanId, simulation.id);
        await updateSimulation(simulation.id, (simulation) => {
          simulation.status = SimulationStatus.ABORTED;
        });
      } catch (error) {
        addErrorNotification(error.code);
      }
    },
    [updateSimulation, abortRemote, addErrorNotification],
  );

  const createNewDraft = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const enabledVehicles = await snapshot.getPromise(enabledVehiclesSelector);
        const durationHour = getUserPreference(UserPreferenceName.SIMULATION_DURATION);
        const processAreas = (await snapshot.getPromise(allAreasSelector)).filter(
          isProcessAreaOneEp,
        );
        const processTwoEndPointShapes = await snapshot.getPromise(allProcessTwoEndPointSelector);
        const flows = await snapshot.getPromise(layoutFlowsSelector);
        const simDuration = durationFromHours(durationHour);
        const draft: SimulationDraft = {
          name: await snapshot.getPromise(nextSimulationNameSelector),
          // @ts-expect-error strictNullChecks. Pls fix me
          floorPlanId: await snapshot.getPromise(simulationFloorPlanIdSelector),
          version: await snapshot.getPromise(displayVersionSelector),
          duration: simDuration,
          transportWindow: simDuration,
          vehicleTypes: await Promise.all(
            enabledVehicles.map(async (vehicle) => ({
              range: [await snapshot.getPromise(vehicleCapacitySelector(vehicle.id))],
              name: vehicle.name,
              loadTime: getUserPreference(UserPreferenceName.SIMULATION_LOAD_TIME),
              unloadTime: getUserPreference(UserPreferenceName.SIMULATION_UNLOAD_TIME),
              hue: vehicle.hue,
            })),
          ),
          flows: [],
          dependentFlows: createDependentFlows(processAreas, processTwoEndPointShapes, flows),
          checkpointSets: [],
          chargingParkingPriority: [],
          obstructionTimeOutInSeconds: getUserPreference(
            UserPreferenceName.SIMULATION_OBSTRUCTION_TIMEOUT,
          ),
          trafficManagementDisabled: getUserPreference(
            UserPreferenceName.SIMULATION_TRAFFIC_MANAGEMENT_DISABLED,
          ),
          failOnNoRouteFound: getUserPreference(
            UserPreferenceName.SIMULATION_FAIL_ON_NO_ROUTE_FOUND,
          ),
          manualIntervention: getUserPreference(UserPreferenceName.SIMULATION_MANUAL_INTERVENTION),
          orderDistributionStrategy: getUserPreference(
            UserPreferenceName.SIMULATION_ORDER_DISTRIBUTION_STRATEGY,
          ),
        };

        copyAllLayoutFlowsToSimulationFlows();
        prepareForSimulation();

        set(currentSimulationState, draft);
        set(currentSimulationIdState, uuid());
      },
    [copyAllLayoutFlowsToSimulationFlows, prepareForSimulation],
  );

  const loadDetails = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        if (await snapshot.getPromise(currentSimulationState)) {
          return;
        }

        set(errorMessageSelector, null);

        try {
          set(isLoadingSelector, true);
          const simulationId = await snapshot.getPromise(currentSimulationIdState);
          const simulation = await snapshot.getPromise(simulationSelector(simulationId));
          const details = await fetchGroupConfiguration(simulation.floorPlanId, simulation.id);

          const draft: SimulationDraft = {
            floorPlanId: simulation.floorPlanId,
            // @ts-expect-error strictNullChecks. Pls fix me
            version: simulation.details.floorPlanVersion,
            name: await snapshot.getPromise(nextSimulationNameSelector),
            generatedFloorPlanId: simulation.generatedFloorPlanId,
            // @ts-expect-error strictNullChecks. Pls fix me
            trafficManagementDisabled: simulation.trafficManagementDisabled,
            // @ts-expect-error strictNullChecks. Pls fix me
            failOnNoRouteFound: simulation.failOnNoRouteFound,
            // @ts-expect-error strictNullChecks. Pls fix me
            manualIntervention: simulation.manualIntervention,
            obstructionTimeOutInSeconds: simulation.obstructionTimeOutInSeconds,
            orderDistributionStrategy: simulation.orderDistributionStrategy,
            ...details,
          };
          updateSimulationFlowsWithSimulationDraft(draft);

          set(currentSimulationState, draft);
        } catch (error) {
          set(errorMessageSelector, 'errors:simulation.list.fetch');
          console.error(error);
        } finally {
          set(isLoadingSelector, false);
        }
      },
    [fetchGroupConfiguration, updateSimulationFlowsWithSimulationDraft],
  );

  const loadAll = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const isFirstLoaded = await snapshot.getPromise(isFirstLoadedSelector);

        set(errorMessageSelector, null);

        if (isFirstLoaded) {
          set(isLoadingSelector, false);
          return;
        }

        const groupId = await snapshot.getPromise(groupIdSelector);
        const projectId = await snapshot.getPromise(projectIdSelector);
        const isLatest = await snapshot.getPromise(isLatestSelector);

        set(isLoadingSelector, true);

        try {
          const [versions, simulations] = await Promise.all([
            fetchAllVersions(projectId, groupId),
            fetchAllGroups(groupId),
          ]);

          set(floorPlanVersionsSelector, versions);

          if (isLatest) {
            const version = (await snapshot.getPromise(displayVersionSelector)) || 1;
            const floorPlan = versions.find((item) => item.version === version);
            // @ts-expect-error strictNullChecks. Pls fix me
            set(simulationFloorPlanIdSelector, floorPlan.id);
          } else {
            set(simulationFloorPlanIdSelector, await snapshot.getPromise(floorPlanIdSelector));
          }

          mergeVersions(simulations, versions).forEach((item) =>
            set(simulationSelector(item.id), item),
          );
          set(
            simulationIdsSelector,
            simulations.map((item) => item.id),
          );
          set(isFirstLoadedSelector, true);
        } catch (error) {
          set(simulationIdsSelector, []);
          set(errorMessageSelector, 'errors:simulation.list.fetch');
          console.error(error);
        } finally {
          set(isLoadingSelector, false);
        }
      },
    [fetchAllVersions],
  );

  const openSimulationEditPanel = useRecoilCallback(
    ({ set }) =>
      async (simulation: Simulation) => {
        set(currentSimulationIdState, simulation.id);
      },
    [],
  );

  const closeSimulationGroupPanel = useRecoilCallback(
    ({ snapshot, reset }) =>
      async () => {
        reset(currentGroupIdSelector);
        const ids = await snapshot.getPromise(currentGroupSimulationIdsSelector);
        ids.forEach((id) => reset(currentGroupSimulationSelector(id)));
        reset(currentGroupSimulationIdsSelector);
      },
    [],
  );

  const openFleetTracker = useCallback(
    async (simulation: Simulation) => {
      // @ts-expect-error strictNullChecks. Pls fix me
      goToTracker(simulation.simulationRunId, simulation.floorPlanId);
    },
    [goToTracker],
  );

  const openSimulationGroupPanel = useRecoilCallback(
    ({ set, snapshot }) =>
      async (id: string) => {
        set(currentGroupSelector, await snapshot.getPromise(simulationSelector(id)));
        set(currentGroupIdSelector, id);
      },
    [],
  );

  const remove = useRecoilCallback(
    ({ set, reset }) =>
      async (simulation: Simulation) => {
        try {
          await updateSimulation(simulation.id, (simulation) => {
            simulation.status = SimulationStatus.DELETING;
          });
          await removeRemote(simulation.floorPlanId, simulation.id);
          set(simulationIdsSelector, (state) => state.filter((item) => item !== simulation.id));
          reset(simulationSelector(simulation.id));
        } catch (error) {
          // TODO common error ocmponent
          console.error(error);
        }
      },
    [updateSimulation, removeRemote],
  );

  const rename = useCallback(
    async (simulation: Simulation, name: string) => {
      try {
        await updateSimulation(simulation.id, (simulation) => {
          simulation.name = name;
        });
        await renameGroupDebounced(simulation.floorPlanId, simulation.id, name);
      } catch (error) {
        // TODO common error ocmponent
        console.error(error);
      }
    },
    [updateSimulation, renameGroupDebounced],
  );

  const rerun = useRecoilCallback(
    ({ set }) =>
      async (simulation: Simulation) => {
        try {
          set(isLoadingSelector, true);
          const rerunSimulation = await rerunRemote(simulation.floorPlanId, simulation.id);

          if (rerunSimulation.errors) {
            addErrorNotification(rerunSimulation.errors[0].code);
          }

          set(simulationSelector(rerunSimulation.id), {
            ...rerunSimulation,
            details: {
              ...rerunSimulation.details,
              floorPlanVersion: simulation.details.floorPlanVersion,
            },
          });
          set(simulationIdsSelector, (state) => [rerunSimulation.id, ...state]);
        } catch (e) {
          addErrorNotification(e.code);
        } finally {
          set(isLoadingSelector, false);
        }
      },
    [rerunRemote, addErrorNotification],
  );

  return {
    abort,
    closeSimulationGroupPanel,
    clearState,
    createNewDraft,
    loadAll,
    loadDetails,
    openFleetTracker,
    openSimulationEditPanel,
    openSimulationGroupPanel,
    remove,
    rename,
    rerun,
    updateSimulation,
  };
};
