import { TextField } from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { StyledTooltip } from '../StyledTooltip';
import { useEnterBlur } from './hooks';
import { isNegativeAllowed, getRegex, parseStringValue, isInteger, parseValue } from './helpers';

type Props = {
  ariaLabel?: string;
  disabled?: boolean;
  error?: string;
  errorType?: 'normal' | 'floating';
  inputProps?: Partial<InputProps>;
  onChange?: (value: number) => void;
  onBlur?: (value: number) => void;
  onFocus?: () => void;
  prefix?: string | JSX.Element;
  mode?: 'integer' | 'positive-integer' | 'negative-integer';
  tooltip?: string;
  unit?: string;
  value?: number;
};

export const Input = ({
  ariaLabel,
  disabled = false,
  error,
  errorType = 'normal',
  inputProps,
  onChange,
  onBlur,
  onFocus,
  prefix,
  mode = 'integer',
  tooltip,
  unit,
  value = 0,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const regex = getRegex(mode);
  const allowNegative = isNegativeAllowed(mode);
  const [validValue, setValidValue] = useState<string>(parseValue(mode, value));
  // @ts-expect-error strictNullChecks. Pls fix me
  const { onKeyUp } = useEnterBlur(ref);

  const onBlurInner = useCallback(() => {
    // @ts-expect-error strictNullChecks. Pls fix me
    if (!isInteger(regex, ref.current.value)) {
      onChange?.(0);
    }

    // @ts-expect-error strictNullChecks. Pls fix me
    ref.current.value = parseStringValue(regex, validValue);
    // @ts-expect-error strictNullChecks. Pls fix me
    setValidValue(ref.current.value);
    // @ts-expect-error strictNullChecks. Pls fix me
    onBlur?.(Number(ref.current.value));
  }, [regex, onBlur, validValue, onChange]);

  const onFocusInner = useCallback(
    (evt) => {
      evt.target.select();
      onFocus?.();
    },
    [onFocus],
  );

  const onChangeInner = useCallback(
    (e) => {
      let { value } = e.target;
      if (regex.test(value)) {
        // @ts-expect-error strictNullChecks. Pls fix me
        ref.current.value = value;
        // @ts-expect-error strictNullChecks. Pls fix me
        setValidValue(ref.current.value);
        // @ts-expect-error strictNullChecks. Pls fix me
        onChange?.(Number(ref.current.value));
      } else if (value === '') {
        // @ts-expect-error strictNullChecks. Pls fix me
        ref.current.value = '';
        setValidValue('');
      } else if (allowNegative && value === '-') {
        // @ts-expect-error strictNullChecks. Pls fix me
        ref.current.value = '-';
        setValidValue('-');
      } else {
        // @ts-expect-error strictNullChecks. Pls fix me
        ref.current.value = validValue;
      }
      regex.lastIndex = 0;
    },
    [allowNegative, regex, onChange, validValue],
  );

  useEffect(() => {
    // Test
    if (Number(validValue) === value) {
      return;
    }

    // @ts-expect-error strictNullChecks. Pls fix me
    ref.current.value = parseValue(mode, value);
    // @ts-expect-error strictNullChecks. Pls fix me
    setValidValue(ref.current.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <StyledTooltip title={tooltip}>
      <TextField
        name={uuid()}
        inputRef={ref}
        disabled={disabled}
        error={!!error}
        helperText={error}
        onChange={onChangeInner}
        onBlur={onBlurInner}
        onFocus={onFocusInner}
        onKeyUp={onKeyUp}
        defaultValue={value}
        variant='filled'
        FormHelperTextProps={{
          className: errorType === 'floating' ? 'Mui-error-floating' : '',
        }}
        InputProps={{
          ...inputProps,
          inputProps: {
            'aria-label': ariaLabel,
          },
          disableUnderline: true,
          startAdornment:
            prefix &&
            (typeof prefix === 'string' ? (
              <InputAdornment position='end'>{prefix}</InputAdornment>
            ) : (
              <InputAdornment sx={{ marginBottom: 2 }} position='start'>
                {prefix}
              </InputAdornment>
            )),
          endAdornment: unit && <InputAdornment position='end'>{unit}</InputAdornment>,
        }}
      />
    </StyledTooltip>
  );
};
