import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Vector2 } from 'three';

import { useArtefacts } from '@/modules/artefacts';
import { Input } from '@/modules/common/components/Input';
import { convertToUnitFromMM } from '@/modules/common/helpers/unit';
import { useCopyPaste } from '@/modules/workspace/hooks/useCopyPaste';
import { HistoryManager } from '@/store/recoil/history';
import { KEYCODE } from '@/store/recoil/input';
import ignoreKeyboardInputState from '@/store/recoil/input/ignoreKeyboardInput';
import shapeAtom from '@/store/recoil/shape/atom';
import { allShapesSelector } from '@/store/recoil/shapes';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { unitSelector } from '@/store/recoil/workspace';
import { isPointsShape, isProcessAreaTwoEp } from '@modules/common/types/guards';
import { useConnections, useUndoRedoConnections } from '@modules/connections';
import { useAutoSave } from '@modules/floorplan';
import { PreValidationAspect, useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { Button, Grid, Stack, Typography } from '@mui/material';

export function MultiCopyModal({ closeModal }) {
  const [horizontalCopies, setHorizontalCopies] = useState(0);
  const [verticalCopies, setVerticalCopies] = useState(0);
  const [horizontalSpacing, setHorizontalSpacing] = useState(0);
  const [verticalSpacing, setVerticalSpacing] = useState(0);
  const { t } = useTranslation(['interface', 'common']);
  const unit = useRecoilValue(unitSelector);
  const { duplicateShape } = useCopyPaste();
  const ignoreKeys = useSetRecoilState(ignoreKeyboardInputState);
  const [selectedShapesIds, setSelectedShapesIds] = useRecoilState(selectedShapesIdsState);
  const { save } = useAutoSave();
  const { runPreValidation } = useRunPreValidation();
  const { updateConnections } = useConnections();
  const { getUndoRedoState, restoreUndoRedoState } = useUndoRedoConnections();
  const { update: updateArtefacts } = useArtefacts();

  const makeCopies = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        shapeId: string,
        horizontalCopiesIn: number,
        verticalCopiesIn: number,
        horizontalSpacingIn: number,
        verticalSpacingIn: number,
      ) => {
        const selectedShape = await snapshot.getPromise(shapeAtom(shapeId));

        // TODO: angled highways support. different properties need to be taken into account
        if (isPointsShape(selectedShape)) return;
        // TODO: process two ep
        if (isProcessAreaTwoEp(selectedShape)) return;

        const newShapeIds = [];
        const newShapes = [];

        for (let x = 0; x <= horizontalCopiesIn; x++) {
          for (let y = 0; y <= verticalCopiesIn; y++) {
            if (x === 0 && y === 0) continue;

            const offset = new Vector2(
              (selectedShape.properties.width + Math.abs(horizontalSpacingIn)) *
                x *
                Math.sign(horizontalSpacingIn),
              (selectedShape.properties.height + Math.abs(verticalSpacingIn)) *
                y *
                Math.sign(verticalSpacingIn),
            );
            const newShape = duplicateShape(selectedShape, offset);
            // @ts-expect-error strictNullChecks. Pls fix me
            newShapeIds.push(newShape.id);
            // @ts-expect-error strictNullChecks. Pls fix me
            newShapes.push(newShape);
          }
        }

        const shapes = await snapshot.getPromise(allShapesSelector);
        set(allShapesSelector, (shapesIn) => [...shapesIn, ...newShapes]);
        setSelectedShapesIds([shapeId, ...newShapeIds]);
        await updateConnections(newShapeIds);
        await updateArtefacts(newShapeIds);
        await save();

        const { oldConnectionState, newConnectionState } = await getUndoRedoState(newShapeIds);

        HistoryManager.track(
          `Duplicated shape`,
          { shapes: [...shapes, ...newShapes], connections: oldConnectionState },
          {
            shapes,
            connections: newConnectionState,
          },
          ({ shapes, connections }) => {
            set(allShapesSelector, shapes);
            set(selectedShapesIdsState, []);
            restoreUndoRedoState(connections);
          },
        );

        runPreValidation([
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.DISCONNECTED_FLOW_STOPS,
        ]);
      },
    [
      duplicateShape,
      save,
      setSelectedShapesIds,
      updateConnections,
      getUndoRedoState,
      restoreUndoRedoState,
      updateArtefacts,
    ],
  );

  const getUnitValue = useCallback((value) => convertToUnitFromMM(unit, value), [unit]);

  const handleCopy = useCallback(() => {
    makeCopies(
      selectedShapesIds[0],
      horizontalCopies,
      verticalCopies,
      horizontalSpacing,
      verticalSpacing,
    );

    closeModal();
  }, [
    closeModal,
    horizontalCopies,
    horizontalSpacing,
    makeCopies,
    selectedShapesIds,
    verticalCopies,
    verticalSpacing,
  ]);

  useEffect(() => {
    ignoreKeys([KEYCODE.BACKSPACE, KEYCODE.DELETE]);

    return () => {
      console.log('enable backspace again');
      ignoreKeys([]);
    };
  }, [ignoreKeys]);

  const notNegative = useCallback((input) => Math.max(input, 0), []);

  return (
    <Stack direction='column' spacing={2}>
      <Typography sx={{ fontSize: '14px' }}>
        {t('interface:multi_copy.create_copies', 'Create copies')}
      </Typography>

      <>
        <Grid
          container
          spacing={2}
          columns={12}
          alignItems='center'
          sx={{ width: 320, marginRight: 4 }}
        >
          <Grid item xs={1}>
            <Typography fontSize='10px'>X:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Input
              value={horizontalCopies}
              onChange={(value) => setHorizontalCopies(notNegative(value))}
              mode='positive-integer'
            />
          </Grid>
          <Grid item xs={4}>
            <Typography fontSize='10px'>
              {t('interface:multi_copy.x_offset', 'X offset')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Input
              value={getUnitValue(horizontalSpacing)}
              unit={t(`interface:settings.units.${unit}`, unit)}
              onChange={(value) => setHorizontalSpacing(value)}
              mode='integer'
            />
          </Grid>

          <Grid item xs={1}>
            <Typography fontSize='10px'>Y:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Input
              value={verticalCopies}
              onChange={(value) => setVerticalCopies(notNegative(value))}
              mode='positive-integer'
            />
          </Grid>
          <Grid item xs={4}>
            <Typography fontSize='10px'>
              {t('interface:multi_copy.y_offset', 'Y offset')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Input
              value={getUnitValue(verticalSpacing)}
              unit={t(`interface:settings.units.${unit}`, unit)}
              onChange={(value) => setVerticalSpacing(value)}
              mode='integer'
            />
          </Grid>
        </Grid>

        <Stack direction='row' justifyContent='space-between'>
          <Button onClick={handleCopy} disabled={horizontalCopies === 0 && verticalCopies === 0}>
            {t('interface:multi_copy.create_button', 'Create copies')}
          </Button>
          <Button onClick={closeModal}>{t('common:cancel', 'Cancel')}</Button>
        </Stack>
      </>
    </Stack>
  );
}
