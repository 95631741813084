import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference, useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, IconButton, Stack } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHome } from './useHome';

export const LogoMenu = () => {
  const [viewOpen, setViewOpen] = useState(false);
  const anchorView = useRef(null);
  const [rulerOpen, setRulerOpen] = useState(false);
  const anchorRuler = useRef(null);
  const showRuler = useUserPreference(UserPreferenceName.SHOW_RULER);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { t } = useTranslation(['interface', 'common']);
  const { goHome } = useHome();

  const viewOpenClick = useCallback(() => {
    setViewOpen((prevOpen) => !prevOpen);
  }, []);

  const handleViewClose = useCallback((event: Event) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    if (anchorView.current && anchorView.current.contains(event.target as HTMLElement)) {
      return;
    }
    setViewOpen(false);
  }, []);

  const openViewSubmenu = useCallback(() => {
    setRulerOpen(true);
  }, []);

  const handleRulerClose = useCallback((event: Event) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    if (anchorRuler.current && anchorRuler.current.contains(event.target as HTMLElement)) {
      return;
    }
    setRulerOpen(false);
    setViewOpen(false);
  }, []);

  const handleRulerClick = useCallback(() => {
    updateUserPreference(UserPreferenceName.SHOW_RULER, !showRuler);
    setRulerOpen(false);
    setViewOpen(false);
  }, [showRuler, updateUserPreference]);

  return (
    <>
      <IconButton
        ref={anchorView}
        onClick={viewOpenClick}
        sx={{
          width: '40px',
          height: '40px',
        }}
      >
        <ExpandMoreIcon sx={{ fontSize: '24px' }} />
      </IconButton>
      <Popper
        sx={{
          paddingLeft: 2.8,
          zIndex: 1,
        }}
        open={viewOpen}
        anchorEl={anchorView.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleViewClose}>
                <Stack>
                  <MenuList id='split-button-menu' autoFocusItem>
                    <MenuItem onClick={goHome}>
                      {t('interface:tabs.dashboard.label', 'Go to Dashboard')}
                    </MenuItem>
                    <Divider />
                    <MenuItem ref={anchorRuler} onMouseEnter={openViewSubmenu}>
                      {t('common:view', 'View')}
                      <ArrowRightIcon sx={{ marginLeft: '70px' }} />
                    </MenuItem>
                  </MenuList>
                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={rulerOpen}
                    anchorEl={anchorRuler.current}
                    role={undefined}
                    placement='right'
                    transition
                    disablePortal
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: 'right',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleRulerClose}>
                            <Stack>
                              <MenuList id='split-button-menu' autoFocusItem>
                                <MenuItem onClick={handleRulerClick}>
                                  {t('common:ruler', 'Ruler')}
                                </MenuItem>
                              </MenuList>
                            </Stack>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
