import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { vehicleDetailsState } from './vehicleDetailsState';

export const vehicleLengthSelector = selectorFamily<number, string>({
  key: 'vehicle/byId/length',
  // @ts-expect-error strictNullChecks. Pls fix me
  get:
    (id: string) =>
    ({ get }) => {
      const details = get(vehicleDetailsState(id));
      if (!details) return null;

      return details.length / 10;
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
