import { GateArtifacts, parseGateId } from '@/modules/artefacts';
import {
  markCellModifiable,
  parseGuidCell,
  parseNumberCell,
  parseTextCell,
  writeHeaderRow,
  writeInfoSection,
} from '@/modules/common/helpers/excel';
import { Result } from '@/modules/common/types/general';
import { DTShape } from '@/store/recoil/shape';
import ExcelJS from 'exceljs';
import { t } from 'i18next';
import { Vector3 } from 'three';

const EXCEL_SHEET_NAME = 'Stations';

enum Column {
  AreaName = 'AreaName',
  EndPoint = 'EndPoint',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  A = 'A',
  dX = 'dX',
  dY = 'dY',
  dZ = 'dZ',
  dA = 'dA',
  uid = 'uid',
}

type Definition = {
  type: Column;
  column: number;
  title: string;
  editable?: boolean;
};

const columnDefinitions = new Map<Column, Definition>();

columnDefinitions.set(Column.AreaName, {
  type: Column.AreaName,
  title: 'Area name',
  column: 1,
});

columnDefinitions.set(Column.EndPoint, {
  type: Column.EndPoint,
  title: 'End point',
  column: 2,
});

columnDefinitions.set(Column.X, {
  type: Column.X,
  title: 'X',
  column: 3,
  editable: true,
});

columnDefinitions.set(Column.Y, {
  type: Column.Y,
  title: 'Y',
  column: 4,
  editable: true,
});

columnDefinitions.set(Column.Z, {
  type: Column.Z,
  title: 'Z',
  column: 5,
  editable: true,
});

columnDefinitions.set(Column.A, {
  type: Column.A,
  title: 'A',
  column: 6,
  editable: true,
});

columnDefinitions.set(Column.dX, {
  type: Column.dX,
  title: 'dX',
  column: 7,
  editable: true,
});

columnDefinitions.set(Column.dY, {
  type: Column.dY,
  title: 'dY',
  column: 8,
  editable: true,
});

columnDefinitions.set(Column.dZ, {
  type: Column.dZ,
  title: 'dZ',
  column: 9,
  editable: true,
});

columnDefinitions.set(Column.dA, {
  type: Column.dA,
  title: 'dA',
  column: 10,
  editable: true,
});

columnDefinitions.set(Column.uid, {
  type: Column.uid,
  title: 'uid',
  column: 11,
});

export const createExcelTemplate = async (
  projectName: string,
  floorPlanName: string,
  floorPlanVersion: number,
  gates: readonly GateArtifacts[],
  adjustments: Map<string, Vector3>,
  shapes: readonly DTShape[],
  layoutDelta: Vector3,
) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(EXCEL_SHEET_NAME);
  const nextRowIndex = writeInfoSection(sheet, projectName, floorPlanName, floorPlanVersion);

  const headerColumns = Array.from(columnDefinitions.values())
    .sort((item1, item2) => item1.column - item2.column)
    .map((item) => item.title);

  writeHeaderRow(sheet, nextRowIndex + 1, headerColumns);
  writeContent(sheet, nextRowIndex + 2, gates, adjustments, shapes, layoutDelta);

  const excelBuffer = await workbook.xlsx.writeBuffer();
  return new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
};

const writeContent = (
  sheet: ExcelJS.Worksheet,
  rowIndex: number,
  gates: readonly GateArtifacts[],
  adjustments: Map<string, Vector3>,
  shapes: readonly DTShape[],
  layoutDelta: Vector3,
) => {
  const shapeDict = new Map<string, DTShape>(shapes.map((item) => [item.id, item]));

  gates.forEach((gate, index) => {
    const shape = shapeDict.get(gate.shapeId);
    if (!shape) {
      return;
    }

    const adjustment = adjustments.get(gate.station.locationName) ?? new Vector3();

    const values = [
      {
        type: Column.AreaName,
        value: shape.name,
      },
      {
        type: Column.EndPoint,
        value: parseGateId(gate.station.locationName).endpointName,
      },
      {
        type: Column.X,
        value: gate.point.x + layoutDelta.x,
      },
      {
        type: Column.Y,
        value: gate.point.y + layoutDelta.y,
      },
      {
        type: Column.Z,
        value: 0,
      },
      {
        type: Column.A,
        value: gate.station.orientation,
      },
      {
        type: Column.dX,
        value: adjustment.x,
      },
      {
        type: Column.dY,
        value: adjustment.y,
      },
      {
        type: Column.dZ,
        value: 0,
      },
      {
        type: Column.dA,
        value: 0,
      },
      {
        type: Column.uid,
        value: shape.id,
      },
    ];

    const row = sheet.getRow(rowIndex + index);

    values.forEach((item) => {
      const definition = columnDefinitions.get(item.type);
      // @ts-expect-error strictNullChecks. Pls fix me
      const cell = row.getCell(definition.column);

      cell.value = item.value;
      // @ts-expect-error strictNullChecks. Pls fix me
      if (definition.editable) {
        markCellModifiable(cell);
      }
    });
  });
};

export type ExcelRow = {
  shapeId: string;
  endPoint: string;
  x: number;
  y: number;
  z: number;
  a: number;
  dX: number;
  dY: number;
  dZ: number;
  dA: number;
};

export const readExcelFile = async (file: File): Promise<Result<ExcelRow[]>> => {
  const workbook = new ExcelJS.Workbook();
  workbook.calcProperties.fullCalcOnLoad = true;
  await workbook.xlsx.load(await file.arrayBuffer());

  const sheet = workbook.getWorksheet(EXCEL_SHEET_NAME);
  const rowIndex = 7;

  const data: ExcelRow[] = [];

  try {
    // @ts-expect-error strictNullChecks. Pls fix me
    for (let index = rowIndex; index <= sheet.rowCount; index++) {
      // @ts-expect-error strictNullChecks. Pls fix me
      const row = sheet.getRow(index);

      if (!row.hasValues) {
        continue;
      }

      data.push({
        shapeId: parseGuidCell(getCell(row, Column.uid)),
        endPoint: parseTextCell(getCell(row, Column.EndPoint)),
        x: parseNumberCell(getCell(row, Column.X)),
        y: parseNumberCell(getCell(row, Column.Y)),
        z: parseNumberCell(getCell(row, Column.Z)),
        a: parseNumberCell(getCell(row, Column.A)),
        dX: parseNumberCell(getCell(row, Column.dX)),
        dY: parseNumberCell(getCell(row, Column.dY)),
        dZ: parseNumberCell(getCell(row, Column.dZ)),
        dA: parseNumberCell(getCell(row, Column.dA)),
      });
    }
  } catch (e) {
    return {
      status: 'error',
      error: `${t('errors:commissioning.gate.import_fail')} ${e.message}`,
    };
  }

  return {
    status: 'ok',
    value: data,
  };
};

const getCell = (row: ExcelJS.Row, column: Column) =>
  // @ts-expect-error strictNullChecks. Pls fix me
  row.getCell(columnDefinitions.get(column).column);
