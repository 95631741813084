import { IconButton, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { StraightenIcon } from '@/assets/icons';
import { valueCheck } from '@helpers/input';
import { Position } from '@helpers/types';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import { NumberInput } from '@modules/common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useFloorPlanState } from '@modules/floorplan';
import { useReferenceImage } from '@modules/referenceImage/hooks';
import { referenceMode, referenceScale, referenceType } from '@modules/referenceImage/store';
import { Mode, REFERENCE_TYPE } from '@modules/referenceImage/types';
import { useRequiredReferenceScaling } from '@modules/setup/hooks/useRequiredReferenceScaling';
import { useZoomButton } from '@modules/workspace/components/ZoomButton/useZoomButton';
import { KEYCODE } from '@recoil/input';
import ignoreKeyboardInputState from '@recoil/input/ignoreKeyboardInput';
import { unitConverterSelector, unitSelector, unitValueSelector } from '@recoil/workspace';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

export const ScalePanel = () => {
  const { t } = useTranslation(['interface', 'common']);
  const [mode, setMode] = useRecoilState(referenceMode);
  const { zoomFitReference } = useZoomButton();
  const workspaceMode = useRecoilValue(modeSelector);
  const disabled = workspaceMode !== WorkspaceMode.EDITABLE;
  const [scale, setScale] = useRecoilState(referenceScale);
  const { showSnackbar } = useSnackbarStore();
  const { saveFloorPlan } = useFloorPlanState();
  const { saveReferenceSettings } = useReferenceImage();
  const { onClose } = useRequiredReferenceScaling();
  const { referenceExistsAndIsNotScaled } = useRequiredReferenceScaling();
  const floorplanReferenceType = useRecoilValue(referenceType);
  const unit = useRecoilValue(unitSelector);

  // @ts-expect-error strictNullChecks. Pls fix me
  const dist = useRecoilValue(useMemo(() => unitValueSelector(scale.dist), [scale.dist]));
  // @ts-expect-error strictNullChecks. Pls fix me
  const setDist = useRecoilValue(useMemo(() => unitValueSelector(scale.setDist), [scale.setDist]));

  const ignoreKeys = useSetRecoilState(ignoreKeyboardInputState);
  useEffect(() => {
    setScale({
      dist: 0,
      set: false,
      free: true,
      pointA: null,
      pointB: null,
    });
    // @ts-expect-error strictNullChecks. Pls fix me
    setMode(undefined);

    ignoreKeys([KEYCODE.BACKSPACE, KEYCODE.DELETE]);

    return () => {
      ignoreKeys([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const distChange = useRecoilCallback(
    ({ snapshot, set }) =>
      async (value: number) => {
        const valueInMillimeter = await snapshot.getPromise(unitConverterSelector(value));
        set(referenceScale, (current) => ({
          ...current,
          dist: valueCheck(valueInMillimeter),
        }));
      },
    [],
  );

  const handleSetScale = useCallback(
    async (pointA: Position, pointB: Position, dist: number) => {
      setScale({
        ...scale,
        pointA,
        pointB,
        dist,
        set: true,
      });

      showSnackbar(
        t('interface:notifications.reference.scaled.success', 'Successfully scaled reference.'),
      );

      await zoomFitReference();
      await saveFloorPlan();
      await saveReferenceSettings();
    },
    [setScale, scale, showSnackbar, t, zoomFitReference, saveFloorPlan, saveReferenceSettings],
  );

  const handleConfirm = useCallback(() => {
    if (mode !== Mode.SCALE) {
      // @ts-expect-error strictNullChecks. Pls fix me
      if (scale.setPointA && scale.setPointB && scale.dist > 0) {
        // @ts-expect-error strictNullChecks. Pls fix me
        handleSetScale(scale.setPointA, scale.setPointB, scale.dist);
        return;
      }
    }
    if (!scale.pointA || !scale.pointB) {
      showSnackbar(t('errors:reference.points_not_set', 'Two points not set'));
      return;
    }

    // @ts-expect-error strictNullChecks. Pls fix me
    if (scale.dist <= 0) {
      showSnackbar(t('errors:reference.invalid_distance', 'Zero or invalid distance set'));
      return;
    }

    // @ts-expect-error strictNullChecks. Pls fix me
    handleSetScale(scale.pointA, scale.pointB, scale.dist);
  }, [
    mode,
    scale.pointA,
    scale.pointB,
    scale.dist,
    scale.setPointA,
    scale.setPointB,
    handleSetScale,
    showSnackbar,
    t,
  ]);

  const onScaleClick = useCallback(() => {
    if (mode === Mode.SCALE && (scale.dist === 0 || !scale.pointA || !scale.pointB)) {
      // @ts-expect-error strictNullChecks. Pls fix me
      setMode(undefined);
      onClose();
    } else if (mode !== Mode.SCALE) {
      setMode(Mode.SCALE);
      setScale({
        dist: 0,
        set: false,
        free: true,
        pointA: null,
        pointB: null,
      });
    }
  }, [mode, onClose, scale, setMode, setScale]);

  const onScaleChange = useCallback((newValue: number) => distChange(newValue), [distChange]);

  return (
    <Stack sx={{ px: 2 }}>
      <Typography
        sx={{
          fontSize: '14px',
          height: '32px',
        }}
      >
        Scale
      </Typography>
      <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', mb: 1 }}>
        <Typography
          sx={{
            flex: `1 1 100%`,
            mr: 1,
            fontSize: '10px',
          }}
        >
          {referenceExistsAndIsNotScaled
            ? t('interface:reference.properties.no_scale_set', 'No scale set!')
            : ''}
        </Typography>
        <StyledTooltip
          title='Scale'
          disabled={disabled || floorplanReferenceType === REFERENCE_TYPE.NONE}
        >
          <IconButton
            disabled={disabled || floorplanReferenceType === REFERENCE_TYPE.NONE}
            onMouseDown={onScaleClick}
            sx={{ p: 0 }}
          >
            <StraightenIcon />
          </IconButton>
        </StyledTooltip>
      </Stack>
      <PropertiesLabel
        i18nkey='interface:reference.properties.dimension'
        defaultLabel='Real dimension'
        labelRatio={50}
      >
        <NumberInput
          value={mode === Mode.SCALE ? dist ?? 0 : setDist ?? 0}
          unit={t(`interface:settings.units.${unit}`, unit)}
          name='Dimension'
          disabled={disabled || floorplanReferenceType === REFERENCE_TYPE.NONE}
          onChange={onScaleChange}
          onBlur={handleConfirm}
        />
      </PropertiesLabel>
    </Stack>
  );
};
