import { IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { StyledTooltip } from '@common/components/StyledTooltip';
import RemoveIcon from '@assets/icons/RemoveIcon';
import { OverflowTypography } from '../OverflowTypography/OverflowTypography';

type Props = {
  deleteDisabled?: boolean;
  title?: string;
  titleKey?: string;
  onDeleteClick?: () => void;
};

export const MultiListItem = ({ deleteDisabled, titleKey, title, onDeleteClick }: Props) => {
  const { t } = useTranslation([]);

  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, py: '4px' }}>
      <CropOriginalIcon sx={{ width: '21px', height: '21px', flex: '0 0 auto' }} />
      <OverflowTypography
        align='center'
        sx={{
          fontSize: '12px',
          fontWeight: 400,
          flex: '1 1 auto',
          textAlign: 'left',
        }}
      >
        {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
        {titleKey ? t(titleKey) : title}
      </OverflowTypography>
      <StyledTooltip titleKey='common:delete' disabled={deleteDisabled}>
        <IconButton
          onClick={onDeleteClick}
          disabled={deleteDisabled}
          sx={{ padding: 0, flex: '0 0 auto' }}
        >
          <RemoveIcon />
        </IconButton>
      </StyledTooltip>
    </Stack>
  );
};
