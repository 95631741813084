import { ClickAwayListener, Divider, IconButton, Popper, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useSaveData } from '@/modules/commissioning';
import { isCommissioningSubModule } from '@/modules/common/helpers/navigation';
import navAtom from '@/store/recoil/nav/atom';
import { StatusChip } from '@common/components/StatusChip';
import { useConfig, useLoading, useNavigation } from '@modules/common/hooks';
import { LoadingType } from '@modules/common/types/general';
import { Module } from '@modules/common/types/navigation';
import {
  hasUnsavedChangesSelector,
  isLatestSelector,
  useFloorPlanFile,
  useVersioning,
} from '@modules/floorplan';
import { FloorplanServiceOption } from '@modules/floorplanService/enum';
import { useAutomaticValidation } from '@modules/floorplanService/hooks';
import ChevronDownIcon from '@/assets/icons/ChevronDownIcon';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { KEYCODE, keyboardState } from '@recoil/input';

export const CenterMenu = () => {
  const { bumpVersion, restoreVersion } = useVersioning();
  const keyboard = useRecoilValue(keyboardState);
  const [saveDropdownOpen, setSaveDropdownOpen] = useState(false);
  const anchorRef = useRef(null);
  const { validate } = useAutomaticValidation();
  const { goToModule } = useNavigation();
  const { showLoader, hideLoader } = useLoading();
  const isLatest = useRecoilValue(isLatestSelector);
  const hasUnsavedChanges = useRecoilValue(hasUnsavedChangesSelector);
  const nav = useRecoilValue(navAtom);
  const { save: saveCommissioning } = useSaveData();
  const { t } = useTranslation(['interface', 'common']);
  const { downloadFloorplan } = useFloorPlanFile();
  const { app } = useConfig();
  const inCommissioningMode = isCommissioningSubModule(nav);

  const inDev = useMemo(
    () =>
      !app.version || app.version.toLowerCase() === 'local dev' || app.version.includes('alpha'),
    [app.version],
  );

  const saveDropDownClick = useCallback(() => {
    setSaveDropdownOpen((prevOpen) => !prevOpen);
  }, []);

  const onCommissionClick = useCallback(async () => {
    setSaveDropdownOpen(false);

    if (hasUnsavedChanges) {
      await bumpVersion();
    }

    goToModule(Module.COMMISSIONING_SETUP);
  }, [bumpVersion, goToModule, hasUnsavedChanges]);

  const onSalesClick = useCallback(async () => {
    setSaveDropdownOpen(false);

    if (hasUnsavedChanges) {
      await bumpVersion();
    }

    goToModule(Module.SALES_CANVAS);
  }, [bumpVersion, goToModule, hasUnsavedChanges]);

  const handleSaveDropDownClose = useCallback((event: Event) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setSaveDropdownOpen(false);
  }, []);

  const handleDownloadFloorplan = useCallback(async () => {
    showLoader();
    await downloadFloorplan();
    hideLoader();
    setSaveDropdownOpen(false);
  }, [downloadFloorplan, showLoader, hideLoader]);

  const save = useCallback(async () => {
    showLoader(LoadingType.TRANSPARENT);

    if (inCommissioningMode) {
      await saveCommissioning();
      hideLoader();
      return;
    }

    if (isLatest) {
      await bumpVersion();
    } else {
      await restoreVersion();
    }
    hideLoader();
  }, [
    showLoader,
    inCommissioningMode,
    isLatest,
    hideLoader,
    saveCommissioning,
    bumpVersion,
    restoreVersion,
  ]);

  const onSaveClick = useCallback(() => {
    setSaveDropdownOpen(false);
    save();
  }, [save, setSaveDropdownOpen]);

  const handleClickExport = useCallback(
    (option: FloorplanServiceOption) => {
      setSaveDropdownOpen(false);
      validate(option);
    },
    [validate],
  );

  const onVersionHistoryClick = useCallback(() => {
    setSaveDropdownOpen(false);
    goToModule(Module.VERSIONING);
  }, [goToModule]);

  // TODO find a better place for this
  useEffect(() => {
    if (keyboard === KEYCODE.SAVE && (hasUnsavedChanges || inCommissioningMode)) {
      save();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboard]);

  return (
    <>
      <IconButton
        aria-controls={saveDropdownOpen ? 'split-button-menu' : undefined}
        aria-expanded={saveDropdownOpen ? 'true' : undefined}
        aria-label='Save'
        aria-haspopup='menu'
        onClick={saveDropDownClick}
        ref={anchorRef}
      >
        <ChevronDownIcon />
      </IconButton>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={saveDropdownOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleSaveDropDownClose}>
                <Stack>
                  <MenuList id='split-button-menu' autoFocusItem>
                    <MenuItem
                      onClick={onSaveClick}
                      disabled={!inCommissioningMode && isLatest && !hasUnsavedChanges}
                    >
                      {t(isLatest ? 'interface:versioning.save' : 'interface:versioning.restore')}
                    </MenuItem>
                    <MenuItem onClick={handleDownloadFloorplan}>
                      {t('common:download', 'download')}
                    </MenuItem>
                    <MenuItem onClick={onVersionHistoryClick}>
                      {t('interface:versioning.version_history', 'version history')}
                    </MenuItem>
                    <MenuItem onClick={onCommissionClick} sx={{ gap: 1 }}>
                      {t('interface:commissioning.commissioning')}
                      <StatusChip status={t(`common:beta`)} color='info.main' />
                    </MenuItem>
                    {inDev && (
                      <MenuItem onClick={onSalesClick} sx={{ gap: 1 }}>
                        Sales canvas
                        <StatusChip status={t(`common:alpha`)} color='info.main' />
                      </MenuItem>
                    )}
                    <Divider />
                    <Typography
                      align='left'
                      fontSize={12}
                      sx={{ paddingLeft: '1.4em', paddingBottom: '0.5em' }}
                    >
                      {`${t('interface:export.export', 'export')}:`}
                    </Typography>
                    <MenuItem onClick={() => handleClickExport(FloorplanServiceOption.All)}>
                      {t('interface:export.collision', 'collision')}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickExport(FloorplanServiceOption.Splines)}>
                      {t('interface:export.splines', 'splines')}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickExport(FloorplanServiceOption.Validate)}>
                      {t('common:validation', 'validation')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleClickExport(FloorplanServiceOption.TStackExport)}
                    >
                      {t('interface:export.tStackExport', 'Kollmorgen')}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickExport(FloorplanServiceOption.Maestro)}>
                      {t('interface:export.maestro', 'Maestro')}
                    </MenuItem>
                  </MenuList>
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
