import { Vector2 } from 'three';

import { BoundingBox, Rectangle } from '@/helpers/types';
import { AreaAlignment, AreaDistribution, AreaLoadPlacement } from '@/modules/common/types/shapes';
import { addEndPointGenerationSettings } from '@/modules/floorplanService/helpers/mapping/addEndPointGenerationSettings';
import { addDefaultIdGenerator } from '@/modules/floorplanService/helpers/mapping/addIdGenerators';
import { addVehicleArea } from '@/modules/floorplanService/helpers/mapping/addVehicleArea';
import { LoadHandlingType } from '@/modules/floorplanService/helpers/mapping/types';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { pointAlongVector } from '@/modules/workspace/helpers/shape';
import { AreaShape } from '@/store/recoil/shape';
import { AREA_OVERFLOW } from '@/store/recoil/workspace';
import { encodeIdWithVehicleId } from './idEncoder';
import { areaStackingMode, calcFpsLoadPositionAngle, fpsEndPointAngle, getFpsLoadCarriersBoundingBox, lineToFpsRectangle } from './utils';
import { WideLineSegment } from '@/modules/common/types/general';
import { ProcessTwoEPEndParameters } from '@/modules/processTwoEndPoint/types';

export const createProcessShape = (
  vehicleSpec,
  idGenerators,
  shape: ProcessTwoEPShape,
  vehicleSize,
  loadTypes,
  workspaceBoundingBox,
) => {
  const { controlPoints } = shape.properties
  const { width, supportedLoadCarriersIds, operationTime, deliveryParameters, intakeParameters } = shape.parameters
  const height = vehicleSize.length + 500
  const horizontal = width > height

  if (deliveryParameters.supportedVehicleIds.includes(vehicleSpec.vehicleSpecId)) {
    createProcessEnd(
      `${shape.id}.0`,
      `delivery`,
      `${shape.name}-delivery`,
      controlPoints[0].position,
      controlPoints[1].position,
      height,
      width,
      horizontal,
      workspaceBoundingBox,
      supportedLoadCarriersIds,
      // @ts-expect-error strictNullChecks. Pls fix me
      operationTime,
      deliveryParameters,
      LoadHandlingType.DELIVERY,
      vehicleSpec,
      idGenerators,
      vehicleSize,
      loadTypes,
    );
  }

  if (intakeParameters.supportedVehicleIds.includes(vehicleSpec.vehicleSpecId)) {
    createProcessEnd(
      `${shape.id}.1`,
      `intake`,
      `${shape.name}-intake`,
      controlPoints[controlPoints.length - 1].position,
      controlPoints[controlPoints.length - 2].position,
      height,
      width,
      horizontal,
      workspaceBoundingBox,
      supportedLoadCarriersIds,
      // @ts-expect-error strictNullChecks. Pls fix me
      operationTime,
      intakeParameters,
      LoadHandlingType.INTAKE,
      vehicleSpec,
      idGenerators,
      vehicleSize,
      loadTypes,
    );
  }
};

export const createProcessEnd = (
  id: string,
  type: 'delivery' | 'intake',
  name: string,
  pointA: Vector2,
  pointB: Vector2,
  height: number,
  width: number,
  horizontal: boolean,
  workspaceBoundingBox: BoundingBox,
  supportedLoadCarriersIds: string[],
  operationTime: number,
  endParameters: ProcessTwoEPEndParameters,
  loadHandlingType: LoadHandlingType,
  vehicleSpec,
  idGenerators,
  vehicleSize,
  loadTypes,
) => {
  const rectangle = calculateEndRectangle(
    pointA,
    pointB,
    height,
    width,
    horizontal,
    workspaceBoundingBox
  )
  const shape = createShape(
    id, 
    type, 
    name, 
    width, 
    height,
    supportedLoadCarriersIds,
    operationTime,
    endParameters,
  )
  createFpsElemets(  
    vehicleSpec,
    idGenerators,
    shape,
    vehicleSize,
    loadTypes,
    loadHandlingType,
    rectangle,
    horizontal,
  )
};

const calculateEndRectangle = (
  startPoint: Vector2, 
  referencePoint: Vector2, 
  height: number, 
  width: number,
  horizontal: boolean,
  workspaceBoundingBox: BoundingBox
) => {
  const endPoint = pointAlongVector(startPoint, referencePoint, height)
  const line: WideLineSegment = { 
    points: {
      start: startPoint,
      end: endPoint
    },
    width,
  };
  return lineToFpsRectangle(line, workspaceBoundingBox, horizontal ? -90 : 0);
}

const createShape = (
  id: string, 
  type: 'delivery' | 'intake', 
  name: string, 
  width: number, 
  height: number,
  supportedLoadCarriersIds: string[], 
  operationTime: number,
  endParameters: ProcessTwoEPEndParameters
) => ({
    id,
    type,
    name,
    parameters: {
      alignment: AreaAlignment.CENTER,
      positionOverflow: AREA_OVERFLOW.CONTAIN,
      distribution: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
      gap: 0,
      margin: width,
      gapHorizontal: 0,
      gapVertical: 0,
      multiDeep: false,
      supportedLoadCarriersIds,
      operationTime,
      loadPlacement: AreaLoadPlacement.OFF,
      ...endParameters,
    },
    
    properties: {
      width,
      height,
      r: 0,
    },
  } as AreaShape)

export const createFpsElemets = (
  vehicleSpec,
  idGenerators,
  shape,
  vehicleSize,
  loadTypes,
  loadHandlingType: LoadHandlingType,
  rectangle: Rectangle,
  horizontal: boolean,
) => {
  const shapeName = encodeIdWithVehicleId(shape.name, vehicleSpec.databaseId);
  const lpIdGenerator = `${shapeName}-LpIdGen`;
  const epIdGenerator = `${shapeName}-EpIdGen`;
  const shapeId = encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId);
  const { supportedLoadCarriersIds, loadCarrierOrientation } = shape.parameters;
  const { direction } = shape.parameters;
  const supportedLoadCarrierTypes = loadTypes.filter(({ id }) =>
    supportedLoadCarriersIds?.includes(id),
  );
  const { width: largestLoadCarrierWidth, length: largestLoadCarrierLength } =
    getFpsLoadCarriersBoundingBox(supportedLoadCarrierTypes);

  const endpointDirection =
    shape.parameters.storageProperty === undefined || shape.parameters.storageProperty === null
      ? null
      : shape.parameters.storageProperty.endpointDirection;

  const stackingMode = areaStackingMode(
    shape.type, 
    shape.properties, 
    direction, 
    endpointDirection,
    shape.parameters.storageType
  );

  // @ts-expect-error strictNullChecks. Pls fix me
  addVehicleArea(vehicleSpec, shapeId, shape.type, rectangle, stackingMode);

  const loadLengthIsLongestSide = largestLoadCarrierLength > largestLoadCarrierWidth;
  let loadPositionAngleRelativeToEndPoint = calcFpsLoadPositionAngle(
    loadCarrierOrientation,
    loadLengthIsLongestSide,
  );

  const endPointAngleRelativeToArea = fpsEndPointAngle(direction, horizontal);

  addEndPointGenerationSettings(
    vehicleSpec,
    shapeId,
    shape,
    epIdGenerator,
    lpIdGenerator,
    null,
    loadHandlingType,
    'TRANSFER_EP_GENERATION_SETTINGS',
    vehicleSize,
    supportedLoadCarrierTypes,
    largestLoadCarrierWidth,
    largestLoadCarrierLength,
    loadPositionAngleRelativeToEndPoint,
    direction,
    endPointAngleRelativeToArea,
  );

  addDefaultIdGenerator(idGenerators, epIdGenerator, shapeName);
  addDefaultIdGenerator(idGenerators, lpIdGenerator, shapeName);
};