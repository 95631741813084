import { useRecoilCallback } from 'recoil';
import { unitSelector } from '@recoil/workspace';
import { convertToMMFromUnit } from '@/modules/common/helpers/unit';

export const useUnit = () => {
  const toBaseUnit = useRecoilCallback(
    ({ snapshot }) =>
      async (value: number) => {
        const unit = await snapshot.getPromise(unitSelector);
        return convertToMMFromUnit(unit, value);
      },
    [],
  );

  return {
    toBaseUnit,
  };
};
