import { floorPlanIdSelector, isLatestSelector, useFloorPlanService } from '@/modules/floorplan';
import { useRecoilCallback } from 'recoil';

import { useNavigation, useWorkspaceMode } from '@modules/common/hooks';
import { WorkspaceMode } from '@modules/common/types/general';
import { useLoadReferenceImage } from '@modules/referenceImage';

import { toolButtonState } from '@/store/recoil/tool';
import { drawingIdSelector } from '@/store/recoil/workspace';
import { idSelector as groupIdSelector, groupSelector } from '@modules/floorplan/store/group';
import { idSelector as projectIdSelector } from '@modules/floorplan/store/project';
import { useFloorPlanState } from './useFloorPlanState';

export const useVersioning = () => {
  const { loadFloorPlan, saveFloorPlan } = useFloorPlanState();
  const { load: loadReferenceImage } = useLoadReferenceImage();
  const { updateUrl } = useNavigation();
  const {
    fetchVersion,
    bumpVersion: bumpVersionRemote,
    fetchLatestVersion,
    restoreVersion: restoreVersionRemote,
  } = useFloorPlanService();
  const { setMode } = useWorkspaceMode();

  /**
   * Loads the latest floor plan version
   */
  const loadLatestVersion = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const projectId = await snapshot.getPromise(projectIdSelector);
        const groupId = await snapshot.getPromise(groupIdSelector);
        const floorPlan = await fetchLatestVersion(projectId, groupId);

        setMode(WorkspaceMode.EDITABLE);
        await loadReferenceImage(projectId, floorPlan.id);
        await loadFloorPlan(floorPlan, floorPlan.id);
        updateUrl(projectId, groupId, floorPlan.id);
      },
    [updateUrl, loadFloorPlan, loadReferenceImage, fetchVersion, setMode],
  );

  /**
   * Loads the given floor plan version
   */
  const loadVersion = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const projectId = await snapshot.getPromise(projectIdSelector);
        const groupId = await snapshot.getPromise(groupIdSelector);
        const floorPlan = await fetchVersion(projectId, groupId, id);

        setMode(WorkspaceMode.READONLY_SELECTION);
        await loadReferenceImage(projectId, id);
        await loadFloorPlan(floorPlan, floorPlan.id);
        updateUrl(projectId, groupId, id);
      },
    [updateUrl, loadFloorPlan, loadReferenceImage, fetchVersion, setMode],
  );

  /**
   * Saves the current latest version and creates a new latest version
   */
  const bumpVersion = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        await saveFloorPlan(true);
        const groupId = await snapshot.getPromise(groupIdSelector);
        const projectId = await snapshot.getPromise(projectIdSelector);
        const floorPlan = await bumpVersionRemote(projectId, groupId);

        setMode(WorkspaceMode.EDITABLE);
        set(groupSelector, (state) => ({
          ...state,
          id: floorPlan.groupId,
          latestFloorPlanId: floorPlan.id,
        }));
        updateUrl(projectId, floorPlan.groupId, floorPlan.id);
        await loadFloorPlan(floorPlan, floorPlan.id, false);

        set(toolButtonState, null);
        set(drawingIdSelector, null);
      },
    [updateUrl, loadFloorPlan, bumpVersionRemote, setMode, saveFloorPlan],
  );

  /**
   * Restores a readonly version creating a new latest version base on it
   */
  const restoreVersion = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const isLatest = await snapshot.getPromise(isLatestSelector);
        const groupId = await snapshot.getPromise(groupIdSelector);
        const projectId = await snapshot.getPromise(projectIdSelector);
        const sourceFloorPlanId = isLatest ? null : await snapshot.getPromise(floorPlanIdSelector);
        // @ts-expect-error strictNullChecks. Pls fix me
        const floorPlan = await restoreVersionRemote(projectId, groupId, sourceFloorPlanId);

        setMode(WorkspaceMode.EDITABLE);
        set(groupSelector, (state) => ({
          ...state,
          id: floorPlan.groupId,
          latestFloorPlanId: floorPlan.id,
        }));
        updateUrl(projectId, floorPlan.groupId, floorPlan.id);
        await loadFloorPlan(floorPlan, floorPlan.id);
      },
    [updateUrl, loadFloorPlan, restoreVersionRemote, setMode],
  );

  return {
    loadLatestVersion,
    loadVersion,
    bumpVersion,
    restoreVersion,
  };
};
