import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { formControlClasses, inputClasses, selectClasses } from '@mui/material';
import { Stack } from '@mui/system';

import { SelectInput } from '@/modules/common/components/inputs';
import { unique } from '@modules/common/helpers/array';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useAutoSave } from '@modules/floorplan';
import { PreValidationAspect, useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { useFlow } from '@modules/flows';
import { useShapeGroup } from '@modules/shapeGroups';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { enabledVehiclesSelector } from '@/modules/vehicles';
import { areaVehicleIds } from '../store/area';
import { useArtefacts } from '@/modules/artefacts';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { useGetAllFlowNodesRelatedToFlowId } from '@/modules/flows/hooks/useGetAllVehicleIdsRelatedToFlowId';
import { AreaShapeParameters, shapeParameter } from '@/store/recoil/shape';
import useConfirm from '@modules/common/components/useConfirm';
import { FlowNode } from '@/modules/flows/types';
import { ProcessTwoEPShapeParameters } from '@/modules/processTwoEndPoint';
import { isAreaShapeParameters } from '@/modules/common/types/guards';

const containerSx = {
  // Wrap value-string to new line, don't truncate.
  [`& .${selectClasses.multiple}`]: {
    whiteSpace: 'normal!important',
  },

  // because value-string is wrapped, correct the height and padding of the options container.
  [`& .${formControlClasses.root} .${inputClasses.root}`]: {
    height: 'auto',
    padding: '4px 12px!important',
  },
};

export const VehicleSelect = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarStore();
  const enabledVehicles = useRecoilValue(enabledVehiclesSelector);
  const selectedVehicleIds = useRecoilValue(areaVehicleIds);
  const mode = useRecoilValue(modeSelector);
  const { getRelatedFlowIdsOfShapes } = useFlow();
  const { findShapeGroupId, getAllShapeIds } = useShapeGroup();
  const { runPreValidation } = useRunPreValidation();
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const { save } = useAutoSave();
  const getAllFlowNodesRelatedToFlowId = useGetAllFlowNodesRelatedToFlowId();
  const { confirm, Dialog } = useConfirm()
  const ref = useRef<HTMLDivElement>();

  const setVehicleIds = useRecoilCallback(
    ({ set }) =>
      async (nodes: FlowNode[], vehicleId: string) => {
        nodes.forEach(async (node) => {
          const { id, directionToNode} = node
          set(
            shapeParameter(id),
            (
              current: AreaShapeParameters | ProcessTwoEPShapeParameters
            ): AreaShapeParameters | ProcessTwoEPShapeParameters => {
              if (isAreaShapeParameters(current)) {
                return {
                  ...current,
                  supportedVehicleIds: [vehicleId],
                }
              }
          
              if (directionToNode === 'forward') {
                return {
                  ...current,
                  deliveryParameters: {
                    ...current.deliveryParameters,
                    supportedVehicleIds: [vehicleId],
                  },
                }
              }
              return {
                ...current,
                intakeParameters: {
                  ...current.intakeParameters,
                  supportedVehicleIds: [vehicleId],
                }
              }
            },
          );
        });
      },
    []
  )

  const handleChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newSelected: string) => {
        const selectedShapeIds = await snapshot.getPromise(selectedShapesIdsState);

        const groupIds = await Promise.all(selectedShapeIds.map((item) => findShapeGroupId(item)));
        const uniqueGroupIds = unique(groupIds.filter(Boolean));
        const shapeIdsInGroups = await getAllShapeIds(uniqueGroupIds);

        if (
          uniqueGroupIds.length > 1 ||
          (uniqueGroupIds.length === 1 && shapeIdsInGroups.size !== selectedShapeIds.length)
        ) {
          showSnackbar(
            t(
              'errors:grouping.change_vehicle_type',
              'Please ungroup shapes before changing vehicle types',
            )
          );
          return;
        }

        const flows = await getRelatedFlowIdsOfShapes(selectedShapeIds);
        if (flows.length > 0) {
          const changeVehicleIds = await confirm(
            t(
              'interface:properties.area.supported_vehicle.change_vehicle_popup.label',
              'Change vehicle?',
            ),
            t(
              'interface:properties.area.supported_vehicle.change_vehicle_popup.message',
              'Do you want to change vehicle type for areas linked by flow?',
            )
          )
          if (!changeVehicleIds) return;

          const flowNodes = await getAllFlowNodesRelatedToFlowId(selectedShapeIds[0]);
          await setVehicleIds(flowNodes, newSelected)
          await save();
          runPreValidation([
            PreValidationAspect.DISCONNECTED_AREA_IDS,
            PreValidationAspect.CONNECTION_LACKING_ROADS,
            PreValidationAspect.FLOWLESS_AREAS,
            PreValidationAspect.DISCONNECTED_FLOW_STOPS,
            PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
          ]);
          updateArtefacts(flowNodes.map((node) => node.id));
          return;
        }

        selectedShapeIds.forEach(async (id) => {
          set(
            shapeParameter(id),
            (
              current: AreaShapeParameters
            ): AreaShapeParameters => ({
              ...current,
              supportedVehicleIds: [newSelected],
            }),
          );
        });
        
        await save();
        runPreValidation([
          PreValidationAspect.DISCONNECTED_AREA_IDS,
          PreValidationAspect.CONNECTION_LACKING_ROADS,
          PreValidationAspect.FLOWLESS_AREAS,
          PreValidationAspect.DISCONNECTED_FLOW_STOPS,
          PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
        ]);
        updateArtefacts(selectedShapeIds);
      },
    [
      getAllShapeIds, 
      getRelatedFlowIdsOfShapes, 
      save, 
      runPreValidation, 
      updateArtefacts, 
      findShapeGroupId,  
      t, 
      confirm, 
      getAllFlowNodesRelatedToFlowId
    ],
  );

  return (
    <>
      {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
      <Dialog anchor={ref} offset={[0, 100]} />
      {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
      <Stack sx={containerSx} ref={ref}>
        <SelectInput
          disabled={mode !== WorkspaceMode.EDITABLE}
          options={enabledVehicles?.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
          onChange={handleChange}
          value={selectedVehicleIds}
        />
      </Stack>
    </>
  );
};
