import { atom } from 'recoil';

import { SimulationDraft } from '../../helpers/types';

/**
 * Id of the currently editable simulation
 */
export const simulationIdAtom = atom<string>({
  key: 'simulations/draft/idAtom',
  // @ts-expect-error strictNullChecks. Pls fix me
  default: null,
});

/**
 * The currently editable simulation
 */
export const simulationAtom = atom<SimulationDraft>({
  key: 'simulations/draft/atom',
  // @ts-expect-error strictNullChecks. Pls fix me
  default: null,
});
