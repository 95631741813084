import { StyledTooltip } from '@common/components/StyledTooltip';
import { Box, MenuItem } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openLink } from '@modules/common/helpers/browser';
import { isAfter, timeAgo } from '@modules/common/helpers/date';
import { InsightsIcon } from '@/assets/icons';

import { Simulation } from '../helpers/types';

type Props = {
  simulation: Simulation;
};

export const DashboardMenuItem = ({ simulation }: Props) => {
  const dashboardUrl = simulation.results?.dashboard?.url;
  const dashboardUrlExpirationDate = simulation.results?.dashboard?.urlExpirationDate;
  const { t } = useTranslation('interface');

  const expired = useMemo(
    () => dashboardUrlExpirationDate && isAfter(dashboardUrlExpirationDate),
    [dashboardUrlExpirationDate],
  );

  const tooltip = useMemo(() => {
    if (!dashboardUrlExpirationDate) {
      return;
    }

    if (isAfter(dashboardUrlExpirationDate)) {
      return t('simulation.run_card.dashboard_button.expired_tooltip');
    }

    return t('simulation.run_card.dashboard_button.expire_tooltip', {
      date: timeAgo(dashboardUrlExpirationDate),
    });
  }, [dashboardUrlExpirationDate, t]);

  const onClick = useCallback(async () => {
    // @ts-expect-error strictNullChecks. Pls fix me
    openLink(dashboardUrl);
  }, [dashboardUrl]);

  let content = (
    <MenuItem onClick={onClick} disabled={!dashboardUrl || expired}>
      <InsightsIcon />
      {t('simulation.run_card.dashboard_button.label')}
    </MenuItem>
  );

  if (tooltip) {
    return (
      <StyledTooltip title={tooltip} placement='top'>
        <Box>{content}</Box>
      </StyledTooltip>
    );
  }

  return content;
};
