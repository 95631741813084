import { selectorFamily } from 'recoil';

import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { allVehiclesState } from './allVehiclesState';

export const vehicleNameSelector = selectorFamily<string, string>({
  key: 'vehicle/byId/name',
  // @ts-expect-error strictNullChecks. Pls fix me
  get:
    (id: string) =>
    ({ get }) => {
      const vs = get(allVehiclesState).find((item) => item.id === id);
      if (!vs) return null;

      return vs.name;
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
