import { useRef } from 'react';
import { Box } from '@mui/material';

import { useCanvasViewportSetup, useCanvasEventSetup, useCanvasSetup } from '../setup';

export const Canvas = () => {
  const elementRef = useRef<HTMLDivElement>(null);

  // @ts-expect-error strictNullChecks. Pls fix me
  useCanvasSetup(elementRef);
  useCanvasEventSetup();
  // TODO this should be somewhere else
  useCanvasViewportSetup();

  return <Box sx={{ height: '100%' }} ref={elementRef} />;
};
