import { Group, Rect, Text } from 'react-konva';
import { useRecoilValue } from 'recoil';

import { stageSelector } from '@/store/recoil/workspace';
import useImage from 'use-image';

// TODO: hacky way to calculate the width of the text at first render. See if we can make this dynamic
const FONT = {
  type: 'Azeret Mono',
  charWidth: 0.6756,
};

type Props = {
  width: number;
  height: number;

  message?: string;
};

export function InvalidShape({ width, height, message }: Props) {
  const stageProps = useRecoilValue(stageSelector);
  const [hashImage] = useImage('/red_hashing.png');
  const labelFontSize = width < 50 ? 20 : Math.max(Math.min((2.5 - stageProps.scale) * 40, 40), 20);
  // @ts-expect-error strictNullChecks. Pls fix me
  const labelWidth = message.length * FONT.charWidth * labelFontSize;

  return (
    <Group listening={false}>
      <Rect
        width={width}
        height={height}
        fillPatternImage={hashImage}
        fillPatternScale={{ x: 0.01, y: 0.01 }}
        strokeEnabled={false}
      />
      <Rect
        fill='rgba(255,255,255,.8)'
        x={width * 0.5 - labelWidth * 0.5}
        y={height * 0.5 - (labelFontSize + 10) * 0.5}
        height={labelFontSize + 10}
        width={labelWidth}
        strokeEnabled={false}
      />
      <Text
        fontFamily={FONT.type}
        x={width * 0.5 - labelWidth * 0.5 + 5}
        y={height * 0.5 - (labelFontSize + 10) * 0.5}
        height={labelFontSize + 10}
        width={labelWidth}
        strokeScaleEnabled
        fontSize={labelFontSize}
        text={message}
        wrap='none'
        verticalAlign='middle'
        strokeEnabled={false}
      />
    </Group>
  );
}
