import { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';

import { useDeleteControlPoint } from '@/modules/workspace/hooks/points';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { DeleteIcon } from '@assets/icons';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { useContextMenu } from '@modules/workspace/hooks';
import { Button, MenuItem, Typography } from '@mui/material';
import objectUnderMouseIdSelector from '@recoil/input/objectUnderMouseIdSelector';
import shapeAtom from '@recoil/shape/atom';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { relatedControlPointIdState } from '@recoil/workspace/contextMenu';
import useConfirm from '@/modules/common/components/useConfirm';

const DeleteControlPointOptionComponent = () => {
  const contextRelatedControlPointId = useRecoilValue(relatedControlPointIdState);

  if (!contextRelatedControlPointId) return null;

  return <DeleteControlPointOptionContent controlPointId={contextRelatedControlPointId} />;
};

export const DeleteControlPointOption = memo(DeleteControlPointOptionComponent);

type DeleteControlPointOptionContentProps = {
  controlPointId: string;
};

const DeleteControlPointOptionContent = ({
  controlPointId,
}: DeleteControlPointOptionContentProps) => {
  // hooks
  const { t } = useTranslation();
  const { deleteControlPoint } = useDeleteControlPoint();
  const { hide } = useContextMenu();
  const { confirm, Dialog } = useConfirm();
  const ref = useRef<HTMLDivElement>();

  // recoil
  const highwayInEditModeId = useWorkspaceStore((state) => state.shapeInEditModeIdState);
  const resetRelatedControlPointId = useResetRecoilState(relatedControlPointIdState);

  // const disabled = !highwayInEditMode || highwayInEditMode.properties.controlPoints.length < 2 ;
  const disabled = false;

  const clickHandler = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        const highwayInEditMode = (await snapshot.getPromise(
          shapeAtom(highwayInEditModeId),
        )) as AngledHighwayShape;

        if (highwayInEditMode.properties.controlPoints.length === 2) {
          hide();

          const deleteHighway = await confirm(
            t('errors:angled_highway.confirm_dialog.title'),
            t('errors:angled_highway.confirm_dialog.message'),
          );
          if (!deleteHighway) return;
          deleteControlPoint(controlPointId, highwayInEditModeId);

          return;
        }

        hide();
        deleteControlPoint(controlPointId, highwayInEditModeId);
      },
    [hide, deleteControlPoint, controlPointId, highwayInEditModeId],
  );

  useEffect(() => () => resetRelatedControlPointId(), [resetRelatedControlPointId]);

  return (
    <>
      <Dialog
        // @ts-expect-error strictNullChecks. Pls fix me
        anchor={ref}
        offset={[0, 100]}
      />
      <MenuItem
        disabled={disabled}
        onClick={clickHandler}
        component='button'
        sx={{
          display: 'flex',
          gap: '14px',
          justifyContent: 'space-between',
        }}
      >
        {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
        <Typography ref={ref}>
          {t('interface:context_menu.workspace.control_point.delete')}
        </Typography>
        <DeleteIcon />
      </MenuItem>
    </>
  );
};
