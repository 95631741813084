import { useRecoilCallback } from 'recoil';

import { useArtefacts } from '@/modules/artefacts';
import { useShapeCreationHistory } from '@/modules/common/hooks';
import { useUndoRedoConnections } from '@/modules/connections';
import { useUpdateConnection } from '@/modules/connections/connections';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { allShapesSelector } from '@/store/recoil/shapes';
import { drawingIdSelector } from '@/store/recoil/workspace';
import shapeAtom from '@recoil/shape/atom';

export const useStopPointsDrawing = () => {
  const { updateConnections } = useUpdateConnection();
  const { trackShapeCreation } = useShapeCreationHistory();
  const { getUndoRedoState } = useUndoRedoConnections();
  const { update: updateArtefacts } = useArtefacts();

  const stopPointsDrawing = useRecoilCallback(
    ({ snapshot, set }) =>
      async (activateEditModeAfterStop = false) => {
        const drawingState = useWorkspaceStore.getState().activePointsDrawingState;
        const newShapeId = drawingState.id;
        // put shape in edit mode after stop drawing
        if (activateEditModeAfterStop) {
          const isEditable = drawingState.controlPoints.length > 1;

          // @ts-expect-error strictNullChecks. Pls fix me
          if (isEditable) useWorkspaceStore.getState().shapeInEditModeIdState = newShapeId;
        }

        // @ts-expect-error strictNullChecks. Pls fix me
        set(shapeAtom(newShapeId), (current) => ({
          ...current,
          isDrawing: false,
        }));
        useWorkspaceStore.getState().reset();
        set(drawingIdSelector, null);
        // @ts-expect-error strictNullChecks. Pls fix me
        await updateConnections([newShapeId]);
        // @ts-expect-error strictNullChecks. Pls fix me
        updateArtefacts([newShapeId]);

        // @ts-expect-error strictNullChecks. Pls fix me
        const { oldConnectionState, newConnectionState } = await getUndoRedoState([newShapeId]);
        const allCurrentShapes = await snapshot.getPromise(allShapesSelector);
        trackShapeCreation(
          { shapes: allCurrentShapes, connections: oldConnectionState },
          {
            shapes: allCurrentShapes.filter((shape) => shape.id !== newShapeId),
            connections: newConnectionState,
          },
        );
      },
    [updateConnections, updateArtefacts],
  );

  return {
    stopPointsDrawing,
  };
};
