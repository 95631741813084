import { useMemo } from 'react';
import { Line, Star } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { Group } from 'konva/lib/Group';

import { theme } from '@/modules/common/components/theme';
import { Module } from '@modules/common/types/navigation';
import { referenceDimensions, referenceMode } from '@modules/referenceImage/store';
import { Mode, ReferenceImageSettings } from '@modules/referenceImage/types';
import navAtom from '@recoil/nav/atom';
import ScaleLabel from './ScaleLabel';
import { useScalePoints } from './useScalePoints';
import { SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';

type Props = {
  referenceImage: ReferenceImageSettings;
  group: Group;
};

export const ScalePoints = ({ referenceImage, group }: Props) => {
  const { x: refX, y: refY } = useRecoilValue(referenceDimensions);
  const { scale, zoom } = useScalePoints(referenceImage, group);
  const nav = useRecoilValue(navAtom);
  const mode = useRecoilValue(referenceMode);

  // get label points relative to stage origin
  const setPointA = useMemo(() => {
    if (!scale.setPointA) return null;

    return {
      x: scale.setPointA.x + refX * SHAPE_TO_CANVAS_SCALE,
      y: scale.setPointA.y + refY * SHAPE_TO_CANVAS_SCALE,
    };
  }, [scale.setPointA, refX, refY]);
  const setPointB = useMemo(() => {
    if (!scale.setPointB) return null;

    return {
      x: scale.setPointB.x + refX * SHAPE_TO_CANVAS_SCALE,
      y: scale.setPointB.y + refY * SHAPE_TO_CANVAS_SCALE,
    };
  }, [scale.setPointB, refX, refY]);

  const interimPointA = useMemo(() => {
    if (!scale.pointA) return null;

    return {
      x: scale.pointA.x + refX * SHAPE_TO_CANVAS_SCALE,
      y: scale.pointA.y + refY * SHAPE_TO_CANVAS_SCALE,
    };
  }, [scale.pointA, refX, refY]);
  const interimPointB = useMemo(() => {
    if (!scale.pointB) return null;

    return {
      x: scale.pointB.x + refX * SHAPE_TO_CANVAS_SCALE,
      y: scale.pointB.y + refY * SHAPE_TO_CANVAS_SCALE,
    };
  }, [scale.pointB, refX, refY]);

  const endCaps = useMemo(() => {
    if (!setPointA || !setPointB) return null;

    const offset = 7.5 / zoom;
    const horizontal = setPointA.y === setPointB.y;

    return {
      a: [
        setPointA.x + (horizontal ? 0 : -offset),
        setPointA.y + (horizontal ? -offset : 0),
        setPointA.x + (horizontal ? 0 : offset),
        setPointA.y + (horizontal ? offset : 0),
      ],
      b: [
        setPointB.x + (horizontal ? 0 : -offset),
        setPointB.y + (horizontal ? -offset : 0),
        setPointB.x + (horizontal ? 0 : offset),
        setPointB.y + (horizontal ? offset : 0),
      ],
    };
  }, [setPointA, setPointB, zoom]);

  if (nav !== Module.SETUP) return null;

  return (
    <>
      {interimPointA !== null && interimPointB !== null && (
        <Line
          points={[interimPointA.x, interimPointA.y, interimPointB.x, interimPointB.y]}
          strokeWidth={2}
          strokeScaleEnabled={false}
          stroke={theme.palette.warning.main}
        />
      )}
      {interimPointA !== null && (
        <Star
          x={interimPointA.x}
          y={interimPointA.y}
          numPoints={4}
          innerRadius={10 / zoom}
          outerRadius={0}
          stroke={theme.palette.warning.main}
          strokeWidth={1}
          strokeScaleEnabled={false}
        />
      )}
      {interimPointB !== null && (
        <Star
          x={interimPointB.x}
          y={interimPointB.y}
          numPoints={4}
          innerRadius={10 / zoom}
          outerRadius={0}
          stroke={theme.palette.warning.main}
          strokeWidth={1}
          strokeScaleEnabled={false}
        />
      )}
      {mode !== Mode.SCALE &&
        setPointA &&
        setPointB &&
        // @ts-expect-error strictNullChecks. Pls fix me
        scale.setDist > 0 && (
          <>
            <Line
              points={[setPointA.x, setPointA.y, setPointB.x, setPointB.y]}
              strokeWidth={2}
              strokeScaleEnabled={false}
              stroke={theme.palette.error.main}
            />
            <Line
              // @ts-expect-error strictNullChecks. Pls fix me
              points={endCaps.a}
              strokeWidth={2}
              strokeScaleEnabled={false}
              stroke={theme.palette.warning.main}
            />
            <Line
              // @ts-expect-error strictNullChecks. Pls fix me
              points={endCaps.b}
              strokeWidth={2}
              strokeScaleEnabled={false}
              stroke={theme.palette.warning.main}
            />
            <ScaleLabel
              pointA={setPointA}
              pointB={setPointB}
              // @ts-expect-error strictNullChecks. Pls fix me
              value={scale.setDist}
            />
          </>
        )}
    </>
  );
};
