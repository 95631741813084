/* eslint-disable import/no-named-as-default */
import { selectorFamily } from 'recoil';
import { convertToUnitFromMM } from '@/modules/common/helpers/unit';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { unitSelector } from './unitSelector';

export const unitValueSelector = selectorFamily<number, number>({
  key: 'unitValueSelector',
  get:
    (value: number) =>
    ({ get }) => {
      const unit = get(unitSelector);
      return convertToUnitFromMM(unit, value);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
