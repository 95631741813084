import { boundingBoxToLine } from '@/modules/common/helpers/boundingBox';
import { areLinesParallel } from '@/modules/workspace/helpers/shape';
import {
  isAngledHighwayShape,
  isAreaShape,
  isHighwayShape,
  isPositionShape,
  isProcessAreaTwoEp,
  isWallShape,
} from '@modules/common/types/guards';
import { AreaParkingDirection, DTShape, LaneDirection } from '@modules/common/types/shapes';
import { AddAreaMappingFromAreaToHighway } from './addAreaMappingFromAreaToHighway';
import { addAreaMappingFromHighwayToOtherShapes } from './addAreaMappingFromHighwayToOtherShapes';
import { decodeShapeId, encodeIdWithVehicleId, getSegmentIndex } from './idEncoder';
import { AreaMapperTypeEnum, ConnectionDirection, Mapping } from './types';
import {
  allowUTurnBetween2Roads,
  areHighwaysNextToEachOther,
  getAngledHighwaySegment,
  isGetawayConnection,
} from './utils';
import { StorageType } from '@/modules/common/types/storage';
import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { HighwayShape } from '@/store/recoil/shape';
import { LineSegment } from '@/modules/common/types/general';

export const DistantConnectionMapping = (
  vehicleSpec,
  highwayId: string,
  highwayConnections,
  highwayLine: LineSegment,
  laneDirection: LaneDirection,
  connections,
  road: HighwayShape | AngledHighwayShape,
  shapes: DTShape[],
) => {
  const mappings = [];

  highwayConnections.forEach((connection) => {
    const otherShape = shapes.filter(
      (shape) => decodeShapeId(shape.id) === decodeShapeId(connection.targetName),
    );
    const targetId = encodeIdWithVehicleId(connection.targetName, vehicleSpec.databaseId);

    if (vehicleSpec.areas.some((area) => area.name === targetId) && !isWallShape(otherShape[0])) {
      if (isProcessAreaTwoEp(otherShape[0])) {
        const parkingDirection = connection.targetName.split('.')[1] === '0' 
          ? otherShape[0].parameters.deliveryParameters.parkingDirection 
          : otherShape[0].parameters.intakeParameters.parkingDirection 

        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          // @ts-expect-error strictNullChecks. Pls fix me
          mappings.push({
            discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_END_POINTS,
            areaReferencesToMap: [{ name: targetId }],
            epBackwardParking: parkingDirection === AreaParkingDirection.BACKWARD,
            usePivots: connection.usePivots,
          });
        }
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromSource
        ) {
          AddAreaMappingFromAreaToHighway(
            vehicleSpec.gateMappingSettings.areaMappings,
            targetId,
            highwayId,
            false,
            connection.usePivots,
          );
        }
      }

      const otherHighwayLine = isAngledHighwayShape(otherShape[0])
        ? getAngledHighwaySegment(otherShape[0], getSegmentIndex(connection.targetName).toString())
        : !isProcessAreaTwoEp(otherShape[0]) ? boundingBoxToLine(otherShape[0].properties) : null;

      let connectionRotation = connection.rot;
      if (connectionRotation > 180) connectionRotation = 360 - connectionRotation;
      if (connectionRotation === 0) connectionRotation = 180;

      if (isAreaShape(otherShape[0]) || isPositionShape(otherShape[0])) {
        const getaway = isGetawayConnection(
          otherShape[0].parameters.direction,
          highwayLine,
          otherShape[0].id,
          connections,
        );
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          if (
            isAreaShape(otherShape[0]) &&
            otherShape[0].parameters.storageType === StorageType.TWOSIDEDRACK
          ) {
            // @ts-expect-error strictNullChecks. Pls fix me
            mappings.push({
              discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_NARROW_AISLE_END_POINTS,
              areaReferencesToMap: [{ name: targetId }],
              usePivots: connection.usePivots,
            });
          } else if (isAreaShape(otherShape[0])) {
            // @ts-expect-error strictNullChecks. Pls fix me
            mappings.push({
              discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_END_POINTS,
              areaReferencesToMap: [{ name: targetId }],
              epBackwardParking:
                otherShape[0].parameters.parkingDirection === AreaParkingDirection.BACKWARD,
              usePivots: connection.usePivots,
            });
          }
        }
        if (
          isAreaShape(otherShape[0]) &&
          otherShape[0].parameters.storageType !== StorageType.TWOSIDEDRACK &&
          (connection.direction === ConnectionDirection.BothWays ||
            connection.direction === ConnectionDirection.FromSource)
        ) {
          AddAreaMappingFromAreaToHighway(
            vehicleSpec.gateMappingSettings.areaMappings,
            targetId,
            highwayId,
            getaway,
            connection.usePivots,
          );
        }
      } else if (
        (isHighwayShape(otherShape[0]) || isAngledHighwayShape(otherShape[0])) &&
        // @ts-expect-error strictNullChecks. Pls fix me
        areHighwaysNextToEachOther(highwayLine, otherHighwayLine.points, connectionRotation)
      ) {
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          // @ts-expect-error strictNullChecks. Pls fix me
          mappings.push({
            discType: AreaMapperTypeEnum.MAP_ALL_NEAREST_CHECK_POINTS,
            areaReferencesToMap: [{ name: targetId }],
            usePivots: connection.usePivots,
            isUTurn: allowUTurnBetween2Roads(
              {
                line: highwayLine,
                laneDir: laneDirection,
                isHorizontal: isHighwayShape(road)
                  ? road.properties.width > road.properties.height
                  : undefined,
              },
              {
                // @ts-expect-error strictNullChecks. Pls fix me
                line: otherHighwayLine.points,
                laneDir: otherShape[0].parameters.laneDirection,
                isHorizontal: isHighwayShape(road)
                  ? road.properties.width > road.properties.height
                  : undefined,
              },
            ),
          });
        }
      } else if (
        (isHighwayShape(otherShape[0]) || isAngledHighwayShape(otherShape[0])) &&
        // @ts-expect-error strictNullChecks. Pls fix me
        areLinesParallel(highwayLine, otherHighwayLine.points)
      ) {
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          // @ts-expect-error strictNullChecks. Pls fix me
          mappings.push({
            discType: AreaMapperTypeEnum.MAP_TWO_NEAREST_CHECK_POINTS,
            areaReferencesToMap: [{ name: targetId }],
            isUTurn: allowUTurnBetween2Roads(
              {
                line: highwayLine,
                laneDir: laneDirection,
                isHorizontal: isHighwayShape(road)
                  ? road.properties.width > road.properties.height
                  : undefined,
              },
              {
                // @ts-expect-error strictNullChecks. Pls fix me
                line: otherHighwayLine.points,
                laneDir: otherShape[0].parameters.laneDirection,
                isHorizontal: isHighwayShape(road)
                  ? road.properties.width > road.properties.height
                  : undefined,
              },
            ),
            usePivots: connection.usePivots,
          });
        }
      } else if (isHighwayShape(otherShape[0]) || isAngledHighwayShape(otherShape[0])) {
        // @ts-expect-error strictNullChecks. Pls fix me
        mappings.push({
          discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING,
          areaReferencesToMap: [{ name: targetId }],
        });
      }
    }
  });

  const groupedMappings = Object.values(
    mappings.reduce<Record<string, Mapping>>((r, e) => {
      // @ts-expect-error strictNullChecks. Pls fix me
      const key = `${e.discType}|${e.epBackwardParking}|${e.isUTurn}|${e.usePivots}`;
      if (!r[key]) {
        r[key] = e;
      } else {
        // @ts-expect-error strictNullChecks. Pls fix me
        r[key].areaReferencesToMap.push(e.areaReferencesToMap[0]);
      }
      return r;
    }, {}),
  );

  groupedMappings.forEach((group) => {
    addAreaMappingFromHighwayToOtherShapes(
      vehicleSpec.gateMappingSettings.areaMappings,
      highwayId,
      group.discType === AreaMapperTypeEnum.MAP_ALL_NEAREST_CHECK_POINTS ? group : null,
      group.discType === AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING ? group : null,
      group.discType === AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_END_POINTS ? group : null,
      group.discType === AreaMapperTypeEnum.MAP_TWO_NEAREST_CHECK_POINTS ? group : null,
      null,
      group.discType === AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_NARROW_AISLE_END_POINTS
        ? group
        : null,
    );
  });
};
