import { Vector2 } from 'three';
import { parseXMLDocument } from '@/modules/common/helpers/browser';
import { t } from 'i18next';

export const deserialize = (text: string): Vector2[] => createPoints(parse(text));

const parse = (text: string) => {
  try {
    return parseXMLDocument(text, 'application/xml');
  } catch (e) {
    throw new Error(t('errors:commissioning.navitech.unable_to_parse', { cause: e.message }));
  }
};

const createPoints = (xml: Document) => {
  const environment = xml.querySelector('Environment');

  if (!environment) {
    throw new Error(t('errors:commissioning.navitech.missing_environment_tag'));
  }

  const exportedPoints = environment.querySelector('ExportedPoints');

  if (!exportedPoints) {
    throw new Error(t('errors:commissioning.navitech.missing_exported_points_tag'));
  }

  const pts = exportedPoints.querySelectorAll('pt');

  return Array.from(pts).map(parseElement);
};

const parseElement = (element: Element) => {
  const [xElement, yElement] = element.querySelectorAll('X, Y');
  // @ts-expect-error strictNullChecks. Pls fix me
  const x = parseNumber(xElement.textContent);
  // @ts-expect-error strictNullChecks. Pls fix me
  const y = parseNumber(yElement.textContent);

  if (Number.isNaN(x) || Number.isNaN(y)) {
    throw new Error(t('errors:commissioning.navitech.invalid_number_value'));
  }

  return new Vector2(x, y).multiplyScalar(1000);
};

const parseNumber = (value: string) => (value === '' ? NaN : Number(value));
