import { BoundingBox } from '@/helpers/types';
import { boundingBoxToFpsBoundingBox } from '@/modules/floorplanService/helpers/mapping/utils';
import { REFERENCE_TAG, SOURCE_NAME } from '../consts';
import { Type, Variant } from '../enum';
import { VisualizationObject } from '../types';

const REFERENCE_HEIGHT = -40;

export function createReferenceImage(workspaceBox: BoundingBox, referenceBox: BoundingBox, file: string): VisualizationObject {
  const fpBox = boundingBoxToFpsBoundingBox(referenceBox, workspaceBox);
  return {
    Name: 'myimage',
    Source: SOURCE_NAME,
    Type: Type.Primitive,
    Variant: Variant.Plane,
    // @ts-expect-error strictNullChecks. Pls fix me
    Position: { X: fpBox.minX, Y: fpBox.minY, Z: REFERENCE_HEIGHT },
    Scale: { X: referenceBox.width, Y: referenceBox.height, Z: 1 },
    Tags: [REFERENCE_TAG],
    Data: {
      Base64Png: file,
    },
  };
}
