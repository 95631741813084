import { Stage } from 'konva/lib/Stage';
import { useRecoilCallback } from 'recoil';

import { OrientedBoundingBox } from '@/helpers/types';
import { useArtefacts } from '@/modules/artefacts';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import {
  scaleSelector,
  stageRefSelector,
  stageSelector,
  viewportSelector,
} from '@/store/recoil/workspace';
import { calculateOrientedBoundingBox, calculatePointsShapeBoundingBox } from '@modules/common/helpers/boundingBox';
import { AdditionalData, FloorPlan } from '@modules/common/types/floorPlan';
import { isPointsShape } from '@modules/common/types/guards';
import { Module } from '@modules/common/types/navigation';
import { ToolState } from '@modules/common/types/tools';
import {
  hasUnsavedChangesSelector,
  isLatestSelector,
  useFloorPlanService,
} from '@modules/floorplan';
import { fpsFilesSelector, fpsWarningsSelector } from '@modules/floorplanService/store';
import { useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import {
  REFERENCE_TYPE,
  referenceCache,
  referenceType,
  useGetReferenceProperties,
} from '@modules/referenceImage';
import { CANVAS_TO_SHAPE_SCALE, ContainerId } from '@modules/workspace/helpers/konva';
import { floorplanElementSelector } from '@recoil/floorplan';
import floorplanInfoAtom from '@recoil/floorplan/atom';
import { HistoryManager } from '@recoil/history';
import navAtom from '@recoil/nav';
import { allShapesSelector } from '@recoil/shapes';
import { toolButtonState } from '@recoil/tool';

let debounce = null;
const debounceDelay = 0;

const THUMB_PX_RATIO_DECREASE = 1 / 4;

export function useFloorPlanState() {
  const { updateLatest } = useFloorPlanService();
  const { showSnackbar } = useSnackbarStore();
  const { runPreValidation } = useRunPreValidation();
  const { getReferenceProperties } = useGetReferenceProperties();
  const { initialize: initializeArtefacts } = useArtefacts();

  const loadFloorPlanState = useRecoilCallback(
    ({ set }) =>
      async (additionalData: AdditionalData) => {
        if (!additionalData.empty) {
          set(floorplanElementSelector, additionalData);
        }
      },
    [],
  );

  /**
   * Loads floor plan from saved state
   */
  const loadFloorPlan = useRecoilCallback(
    ({ set }) =>
      async (floorPlan: FloorPlan, floorPlanId: string, clearSelection = true) => {
        const { additionalData } = floorPlan;

        set(floorplanInfoAtom, floorPlan);
        set(referenceCache, floorPlanId);
        if (clearSelection) set(selectedShapesIdsState, []);

        // load from save
        if (!additionalData.empty) {
          await loadFloorPlanState(additionalData);
          set(toolButtonState, ToolState.SELECT);

          // populate initial prevalidationState
          runPreValidation();

          // blank project
        } else {
          set(navAtom, Module.SETUP);
        }

        // Start tracking state only after initial load of floorplan.
        HistoryManager.startTracking();

        await initializeArtefacts();
      },
    [loadFloorPlanState, runPreValidation, initializeArtefacts],
  );

  const getFloorPlan = useRecoilCallback(
    ({ snapshot }) =>
      async (): Promise<FloorPlan> => {
        const floorPlanElements = await snapshot.getPromise(floorplanElementSelector);
        const floorPlanInfo = await snapshot.getPromise(floorplanInfoAtom);

        return {
          // @ts-expect-error strictNullChecks. Pls fix me
          ...floorPlanInfo,
          additionalData: { ...floorPlanElements },
        };
      },
    [],
  );

  const generateThumbnail = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const stageRef = (await snapshot.getPromise(stageRefSelector)) as Stage;
        if (!stageRef) return;

        const [viewport, stageProps, currentScale, shapes, refType, referenceProperties] =
          await Promise.all([
            snapshot.getPromise(viewportSelector),
            snapshot.getPromise(stageSelector),
            snapshot.getPromise(scaleSelector),
            snapshot.getPromise(allShapesSelector),
            snapshot.getPromise(referenceType),
            getReferenceProperties(),
          ]);

        const allShapeOBBs: OrientedBoundingBox[] = shapes.map((item) => {
          if (isPointsShape(item)) {
            const bb = calculatePointsShapeBoundingBox(
              item.properties.controlPoints.map((cp) => cp.position),
              item.parameters.width,
            );

            return {
              ...bb,
              r: 0,
            };
          }

          return item.properties;
        });

        if (
          referenceProperties &&
          // add ref properties if it's a konva element and thus capturable for the thumbnail
          refType === REFERENCE_TYPE.IMAGE
        ) {
          allShapeOBBs.push({
            x: referenceProperties.x,
            y: referenceProperties.y,
            width: referenceProperties.width,
            height: referenceProperties.height,
            r: 0,
          });
        }

        const { x, y, width, height } = allShapeOBBs.length
          ? calculateOrientedBoundingBox(allShapeOBBs)
          : { width: viewport.w * 10, height: viewport.h * 10, x: 0, y: 0 };

        let scaleToFitBbToViewport = Math.max(
          viewport.w / (width / CANVAS_TO_SHAPE_SCALE),
          viewport.h / (height / CANVAS_TO_SHAPE_SCALE),
        );
        const pixelRatio = scaleToFitBbToViewport / currentScale;

        const config = {
          x: stageProps.x + (x / CANVAS_TO_SHAPE_SCALE) * currentScale,
          y: stageProps.y + (y / CANVAS_TO_SHAPE_SCALE) * currentScale,
          width: (width / CANVAS_TO_SHAPE_SCALE) * currentScale,
          height: (height / CANVAS_TO_SHAPE_SCALE) * currentScale,
          pixelRatio: pixelRatio * THUMB_PX_RATIO_DECREASE,
        };

        // @ts-expect-error strictNullChecks. Pls fix me
        return stageRef
          .getLayers()
          .find((layer) => layer.id() === ContainerId.SHAPE_RENDERING_LAYER)
          .toDataURL(config);
      },
    [getReferenceProperties],
  );

  const save = useRecoilCallback(
    ({ set }) =>
      async () => {
        const thumbnail = await generateThumbnail();
        const payload = await getFloorPlan();

        // @ts-expect-error strictNullChecks. Pls fix me
        payload.additionalData.thumbnail = thumbnail;

        if (!payload.id) {
          return;
        }

        try {
          const { hasUnsavedChanges } = await updateLatest(
            payload.project,
            payload.groupId,
            payload,
          );

          set(hasUnsavedChangesSelector, hasUnsavedChanges);
        } catch (e) {
          showSnackbar(`Failed to save`);
          console.log(e);
        }
      },
    [showSnackbar, updateLatest, getFloorPlan, generateThumbnail],
  );

  /**
   * Saves current floor plan state in the current version
   * @deprecated Use the useAutoSave hook
   * TODO move to useAutoSave
   */
  const saveFloorPlan = useRecoilCallback(
    ({ snapshot, set }) =>
      async (skipDebounce = false) => {
        if (!(await snapshot.getPromise(isLatestSelector))) {
          console.error('Trying to save a readonly floor plan');
          return;
        }

        set(fpsFilesSelector, null);
        set(fpsWarningsSelector, []);

        if (skipDebounce) {
          await save();
        } else {
          if (debounce) clearTimeout(debounce);
          // @ts-expect-error strictNullChecks. Pls fix me
          debounce = setTimeout(async () => await save(), debounceDelay);
        }
      },
    [save],
  );

  return {
    saveFloorPlan,
    getFloorPlan,
    loadFloorPlanState,
    loadFloorPlan,
  };
}
