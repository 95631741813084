import { IconButton, Stack, Typography } from '@mui/material';
import { StyledTooltip } from '@common/components/StyledTooltip';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import AddIcon from '@assets/icons/AddIcon';

type Props = {
  children?: ReactNode;
  onAddClick?: () => void;
  addDisabled?: boolean;
  title?: string;
  titleKey?: string;
};

export const MultiList = ({ addDisabled, children, title, titleKey, onAddClick }: Props) => {
  const { t } = useTranslation([]);

  return (
    <Stack spacing={1}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography
          align='center'
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            py: 1,
          }}
        >
          {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
          {titleKey ? t(titleKey) : title}
        </Typography>
        <StyledTooltip titleKey='common:add' disabled={addDisabled}>
          <IconButton
            disabled={addDisabled}
            onClick={onAddClick}
            sx={{ padding: 0, border: 'none' }}
          >
            <AddIcon />
          </IconButton>
        </StyledTooltip>
      </Stack>
      {children}
    </Stack>
  );
};
