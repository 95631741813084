import { AreaShape, HighwayShape, ObstacleShape } from '@recoil/shape';
import { ArrowElement, Color, Element, RectElement } from '@thive/canvas';
import { v4 as uuid } from 'uuid';

import { convertV2ToV3, getVectorRotatedAroundPoint } from '@/modules/common/helpers/math';
import { AreaDirection } from '@/modules/common/types/shapes';
import { Vector2, Vector3 } from 'three';
import { COLORS, STROKE_COLOR } from '../../commissioning/mappers/areas/colors';

export const createShapeElement = (
  shape: AreaShape | HighwayShape | ObstacleShape,
  disabled = false,
): RectElement => ({
  type: 'Rect',
  // @ts-expect-error strictNullChecks. Pls fix me
  name: shape.name,
  id: shape.id,
  position: new Vector3(shape.properties.x, shape.properties.y),
  rotation: new Vector3(0, 0, shape.properties.r),
  size: new Vector3(shape.properties.width, shape.properties.height),
  fill: Color.fromHex(COLORS[shape.type]),
  strokeWidth: 2,
  stroke: Color.fromHex(STROKE_COLOR),
  interactivity: {
    selectable: !disabled,
  },
});

export const createDirectionElement = (
  shape: AreaShape,
): Element[] => {
  const center = new Vector2(shape.properties.x, shape.properties.y)
  const angle = areaDirectionToAngle(shape.parameters.direction) + shape.properties.r
  const scale = 5

  const background: RectElement = {
    type: 'Rect',
    name: `${shape.name}-background`,
    id: uuid(),
    parentId: shape.id,
    position: convertV2ToV3(center),
    rotation: new Vector3(0, 0, shape.properties.r),
    size: new Vector3(400, 400).multiplyScalar(scale),
    fill: Color.fromHex("#FFFFFF"),
    interactivity: {
      selectable: false,
      rotatable: false,
      draggable: false,
    },
  };

  const body1: RectElement = {
    type: 'Rect',
    name: `${shape.name}-body`,
    id: uuid(),
    parentId: shape.id,
    position: convertV2ToV3(center, 2),
    rotation: new Vector3(0, 0, shape.properties.r),
    size: new Vector3(140, 170).multiplyScalar(scale),
    fill: Color.fromHex("#FFFFFF"),
    strokeWidth: 2 * scale,
    stroke: Color.fromHex("#000000"),
    interactivity: {
      selectable: false,
      rotatable: false,
      draggable: false,
    },
  };

  const body2: RectElement = {
    type: 'Rect',
    name: `${shape.name}-body2`,
    id: uuid(),
    parentId: shape.id,
    position: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(0, -95).multiplyScalar(scale).add(center), new Vector2(center.x, center.y), angle), 1),
    rotation: new Vector3(0, 0, shape.properties.r),
    size: new Vector3(180, 90).multiplyScalar(scale),
    fill: Color.fromHex("#FFFFFF"),
    strokeWidth: 2 * scale,
    stroke: Color.fromHex("#000000"),
    interactivity: {
      selectable: false,
      rotatable: false,
      draggable: false,
    },
  };

  const fork1: RectElement = {
    type: 'Rect',
    name: `${shape.name}-fork1`,
    id: uuid(),
    parentId: shape.id,
    position: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(-25, 110).multiplyScalar(scale).add(center), new Vector2(center.x, center.y), angle), 1),
    rotation: new Vector3(0, 0, shape.properties.r),
    size: new Vector3(30, 100).multiplyScalar(scale),
    fill: Color.fromHex("#FFFFFF"),
    strokeWidth: 2 * scale,
    stroke: Color.fromHex("#000000"),
    interactivity: {
      selectable: false,
      rotatable: false,
      draggable: false,
    },
  };

  const fork2: RectElement = {
    type: 'Rect',
    name: `${shape.name}-fork2`,
    id: uuid(),
    parentId: shape.id,
    position: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(25, 110).multiplyScalar(scale).add(center), new Vector2(center.x, center.y), angle), 1),
    rotation: new Vector3(0, 0, shape.properties.r),
    size: new Vector3(30, 100).multiplyScalar(scale),
    fill: Color.fromHex("#FFFFFF"),
    strokeWidth: 2 * scale,
    stroke: Color.fromHex("#000000"),
    interactivity: {
      selectable: false,
      rotatable: false,
      draggable: false,
    },
  };

  const arrow: ArrowElement = {
    type: 'Arrow',
    name: `${shape.name}-arrow`,
    id: uuid(),
    parentId: shape.id,
    pointA: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(0, 45).multiplyScalar(scale).add(center), center, angle)),
    pointB: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(0, -45).multiplyScalar(scale).add(center), center, angle)),
    thickness: 8 * scale,
    stroke: Color.fromHex("#000000"),
    interactivity: {
      selectable: false,
      rotatable: false,
      draggable: false,
    },
  };

  return [background, body2, fork1, fork2, body1, arrow]
};

const areaDirectionToAngle = (direction: AreaDirection): number => {
  switch (direction) {
    case AreaDirection.DOWN:
      return 0;
    case AreaDirection.RIGHT:
      return 90;
    case AreaDirection.UP:
      return 180;
    case AreaDirection.LEFT:
      return 270;
    default:
      break;
  }
  return 0;
};
