import { selector } from 'recoil';
import { ToolState } from '../../../modules/common/types/tools';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { keyboardState, KEYCODE, mouseKeysState } from '../input';
import { KeyCodeType } from '../input/constants';
import workspaceAtom, { WorkspaceAtom } from './atom';

const toolStateSelector = selector<ToolState>({
  key: 'toolState',
  get: ({ get }) => {
    const keyboardInput = get<KeyCodeType>(keyboardState);
    // @ts-expect-error strictNullChecks. Pls fix me
    const mouseInput = get<number>(mouseKeysState);
    if (keyboardInput === KEYCODE.SPACE || mouseInput === 1) return ToolState.PAN;

    return get<WorkspaceAtom>(workspaceAtom).toolState;
  },
  set: ({ get, set }, newValue) => {
    set(workspaceAtom, {
      ...get(workspaceAtom),
      previousToolState: get<WorkspaceAtom>(workspaceAtom).toolState,
    });
    set(workspaceAtom, { ...get(workspaceAtom), toolState: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default toolStateSelector;
