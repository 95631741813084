import { Module } from '@modules/common/types/navigation';
import navAtom from '@recoil/nav';
import toolAtom from '@recoil/tool/atom';
import { stageRefSelector, stageSelector } from '@recoil/workspace';
import { MouseEvent, useCallback, useRef } from 'react';
import { useRecoilCallback } from 'recoil';

import { useWorkspaceClamp } from '../../common/hooks';
import { useReferenceImage } from '../../hooks';
import { referenceDimensions, referenceSelected } from '../../store';
import { ViewerRef } from '../types';
import { KEYCODE, keyboardState } from '../../../../store/recoil/input';

export const useMouseMovement = (viewerRef: ViewerRef, locked: boolean) => {
  const { saveReferenceSettings } = useReferenceImage();
  const pressed = useRef<boolean>();
  const initialPosition = useRef(null);
  const { clamp } = useWorkspaceClamp();

  const onMouseDown = useRecoilCallback(
    ({ set, snapshot }) =>
      async (e: MouseEvent<HTMLDivElement>) => {
        const nav = await snapshot.getPromise(navAtom);
        const tool = await snapshot.getPromise(toolAtom);
        const isSelected = await snapshot.getPromise(referenceSelected);

        if (!viewerRef || nav !== Module.SETUP || locked) {
          return;
        }

        if (e.button === 0 && (tool === 'cursor' || tool === 'select') && isSelected) {
          pressed.current = true;
        }

        const rect = (e.target as HTMLDivElement).getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        set(referenceSelected, viewerRef.isViewportPositionOverModel({ x, y }));
      },
    [viewerRef, locked],
  );

  const limitPositionToWorkspace = useRecoilCallback(
    ({ snapshot }) =>
      async (dx: number, dy: number) => {
        const { x, y } = await snapshot.getPromise(referenceDimensions);

        return clamp(x + dx, y + dy);
      },
    [clamp],
  );

  const onMouseMove = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        if (!pressed.current) {
          return;
        }

        const spaceIsPressed = (await snapshot.getPromise(keyboardState)) === KEYCODE.SPACE;
        const isSelected = await snapshot.getPromise(referenceSelected);

        if (isSelected && spaceIsPressed) {
          set(referenceSelected, false);
          return;
        }

        if (!isSelected) return;

        const stageRef = await snapshot.getPromise(stageRefSelector);
        const stageProps = await snapshot.getPromise(stageSelector);

        // @ts-expect-error strictNullChecks. Pls fix me
        let { x, y } = stageRef.getPointerPosition();

        x -= stageProps.x;
        y -= stageProps.y;
        x /= stageProps.scale;
        y /= stageProps.scale;

        if (!initialPosition.current) {
          // @ts-expect-error strictNullChecks. Pls fix me
          initialPosition.current = {
            x,
            y,
          };
        }

        // @ts-expect-error strictNullChecks. Pls fix me
        const dx = (x - initialPosition.current.x) * 10;
        // @ts-expect-error strictNullChecks. Pls fix me
        const dy = (y - initialPosition.current.y) * 10;
        const newPosition = await limitPositionToWorkspace(dx, dy);

        set(referenceDimensions, (state) => ({
          ...state,
          ...newPosition,
        }));

        // @ts-expect-error strictNullChecks. Pls fix me
        initialPosition.current = {
          x,
          y,
        };

        saveReferenceSettings();
      },
    [limitPositionToWorkspace, saveReferenceSettings],
  );

  const onMouseUp = useCallback(() => {
    pressed.current = false;
    initialPosition.current = null;
  }, []);

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
  };
};
