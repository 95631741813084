import { AreaReference, VehicleSpec } from '../types';
import { CheckPointAtCrossing } from './mapHighwayCrossings';

export const addCrossingCpCutout = (
  vehicleSpec: VehicleSpec,
  cpAtCrossings: CheckPointAtCrossing[],
) => {
  // build map for checkpoint at crossing <fromCpId, toCpIds[]>
  const cpAtCrossingMap = new Map<string, string[]>();
  cpAtCrossings.forEach((cp) => { 
    if (!cpAtCrossingMap.has(cp.fromCheckPointId)) {
      cpAtCrossingMap.set(cp.fromCheckPointId, cp.toCheckPointIds);
    } else {
      // @ts-expect-error strictNullChecks. Pls fix me
      cpAtCrossingMap.get(cp.fromCheckPointId).push(...cp.toCheckPointIds);
    }
  });
  const reverseCrossingMap = getReverseCrossingMap(cpAtCrossings);
  reverseCrossingMap.forEach((toCps, fromCp) => {
    if (!cpAtCrossingMap.has(fromCp)) {
      cpAtCrossingMap.set(fromCp, toCps);
    } else {
      // @ts-expect-error strictNullChecks. Pls fix me
      cpAtCrossingMap.get(fromCp).push(...toCps);
    }
  });
  // eslint-disable-next-line no-restricted-syntax
  for (const cp of vehicleSpec.checkPointGenerationSettings) {
    const referenceName = cp.areaReference.name;
    if (!cpAtCrossingMap.has(referenceName)) continue;
    const toCpIds = cpAtCrossingMap.get(referenceName);
    // @ts-expect-error strictNullChecks. Pls fix me
    const cpCutout: AreaReference[] = toCpIds.map((cpName) => ({
      name: cpName,
    }));
    cp.cpCutOutReferences.push(...cpCutout);
  }
};

const getReverseCrossingMap = (cpAtCrossings: CheckPointAtCrossing[]): Map<string, string[]> => {
  const reverseCrossing = new Map<string, string[]>();
  cpAtCrossings.forEach((crossing) => {
    crossing.toCheckPointIds.forEach((toCp) => {
      if (!reverseCrossing.has(toCp)) {
        reverseCrossing.set(toCp, []);
      }
      const cps = reverseCrossing.get(toCp);
      // @ts-expect-error strictNullChecks. Pls fix me
      cps.push(crossing.fromCheckPointId);
    });
  });
  return reverseCrossing;
};
