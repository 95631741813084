import { BoundingBox } from '@/helpers/types';
import { boundingBoxToFpsBoundingBox } from '@/modules/floorplanService/helpers/mapping/utils';
import { boxToWidthHeight } from '@/modules/wifisimulation/helpers';
import { AccessPoint } from '@/modules/wifisimulation/types';
import { SOURCE_NAME } from '../consts';
import { TextureVariant, Type, Variant } from '../enum';
import { MaterialData, VisualizationObject } from '../types';

export const createAccessPoint = (
  accessPoint: AccessPoint,
  workspaceBox: BoundingBox,
): VisualizationObject => {
  const height = 1000;
  const postRadius = 50;
  const antenna: VisualizationObject = {
    Name: `${accessPoint.id}-antenna`,
    Source: SOURCE_NAME,
    Type: Type.Primitive,
    Variant: Variant.Sphere,
    Position: { X: 0, Y: 0, Z: height },
    Scale: { X: postRadius * 3, Y: postRadius * 3, Z: postRadius * 3 },
    Color: { Alpha: 255, R: 11, G: 54, B: 138 },
  };
  const scanFieldData: MaterialData = {
    AutoTile: false,
    Variant: TextureVariant.ScanField,
    ScaleX: 10000.0,
    ScaleY: 10000.0,
    RotationAngle: 0.0,
    Speed: 1.0,
    BaseMap: { Alpha: 200, R: 166, G: 238, B: 93 },
  };
  const size = boxToWidthHeight(accessPoint.coverage);
  const coverageRadius = Math.sqrt(size.width * size.width + size.height * size.height) / 2;
  const coverage: VisualizationObject = {
    Name: `${accessPoint.id}-coverage`,
    Source: SOURCE_NAME,
    Type: Type.Primitive,
    Variant: Variant.Cylinder,
    Position: { X: 0, Y: 0, Z: height },
    Scale: { X: coverageRadius, Y: coverageRadius, Z: 1 },
    Data: scanFieldData,
  };

  const locationBox = {
    x: accessPoint.location.x,
    y: accessPoint.location.y,
    width: 0,
    height: 0,
  };
  const fpBox = boundingBoxToFpsBoundingBox(locationBox, workspaceBox);

  const post: VisualizationObject = {
    Name: `${accessPoint.id}-post`,
    Source: SOURCE_NAME,
    Type: Type.Primitive,
    Variant: Variant.Cylinder,
    // @ts-expect-error strictNullChecks. Pls fix me
    Position: { X: fpBox.minX, Y: fpBox.minY, Z: 0 },
    Scale: { X: postRadius, Y: postRadius, Z: height },
    Color: { Alpha: 255, R: 11, G: 54, B: 138 },
    Children: [antenna, coverage],
    Tags: ['Wifi'],
  };
  return post;
};
