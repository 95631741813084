import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback } from 'react';

import { useFileApi } from '@/modules/api/hooks';
import { useVehicleService } from '@/modules/vehicles/hooks/useVehicleService';
import { useLoading } from '@/modules/common/hooks';
import { LoadingType } from '@/modules/common/types/general';

import { buildVehicleAsset } from '../helpers/vehicle';
import {
  allVehicleAssetsSelector,
  knownVehicleDetailsSelector,
  allVehiclesState,
  enabledVehicleIdsState,
  enabledVehiclesSelector,
} from '../store';
import { isSyncAssetFileSuccessResponse } from '../types';
import {
  SyncOriginalAssetFileFailed,
  SyncOriginalAssetFileSuccess,
  UnifiedVehicle,
  UnifiedVehicleDetails,
  ValidAssetType,
  VehicleAssets,
} from '../types/internal';
import { useUvtAssetFileSyncing } from './useVehicleAssetFileSyncing';
import {
  FLOORPLAN_ASSET_FILE_CONTAINER,
  KM_MDB_ASSET_FILE_CONTAINER_DEFAULT,
  UVT_ASSET_FILE_CONTAINER_DEFAULT,
} from '../constants';
import { useFloorPlanState } from '@/modules/floorplan';

export const useVehicleDependencyManager = () => {
  const { storeAssetReference, syncOriginalAssetFileToFileStorage } = useUvtAssetFileSyncing();
  const { getFile } = useFileApi();
  const { getVehicleJSONAsset } = useVehicleService();
  const allVehicleAssets = useRecoilValue(allVehicleAssetsSelector);
  const setKnownVehicleDetails = useSetRecoilState(knownVehicleDetailsSelector);
  const { hideLoader, showLoader } = useLoading();
  const { saveFloorPlan } = useFloorPlanState();

  /** @description attempts to sync relevant assets from pim api to file api and
   * store a reference to the file exposed by the file api.
   * If not given any vehicleIds, it will default to the current enabled ones */
  const ensureVehicleAssetAvailability = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleIds?: string[], floorplanVehicleAssets?: VehicleAssets[]) => {
        const enabledVehicles = vehicleIds
          ? (await snapshot.getPromise(allVehiclesState)).filter((v) => vehicleIds.includes(v.id))
          : await snapshot.getPromise(enabledVehiclesSelector);

        // If floorplanVehicleAssets exist, prioritize them over the existing assets
        let allVehicleAssets =
          floorplanVehicleAssets || (await snapshot.getPromise(allVehicleAssetsSelector));

        const missingAssets: {
          vehicle: UnifiedVehicle;
          uvtMissing: boolean;
          kmMdbMissing: boolean;
        }[] = [];
        enabledVehicles.forEach((v) => {
          let currentAsset =
            allVehicleAssets.find((assets) => assets.vehicleVariantId === v.id) ||
            buildVehicleAsset(v.id);

          const needUvtFile =
            currentAsset.uvtReference === null ||
            !currentAsset.uvtReference?.startsWith(v.id) ||
            !currentAsset.uvtReference?.includes(UVT_ASSET_FILE_CONTAINER_DEFAULT);
          let needKmMdbFile =
            currentAsset.kmMdbReference === null ||
            !currentAsset.kmMdbReference?.startsWith(v.id) ||
            !currentAsset.kmMdbReference?.includes(KM_MDB_ASSET_FILE_CONTAINER_DEFAULT);

          if (!v.kmMdb.path) {
            needKmMdbFile = false;
          }

          if (needUvtFile || needKmMdbFile) {
            missingAssets.push({
              vehicle: v,
              uvtMissing: needUvtFile,
              kmMdbMissing: needKmMdbFile,
            });
          }
        });

        const assetSyncPromises: Promise<
          SyncOriginalAssetFileSuccess | SyncOriginalAssetFileFailed
        >[] = [];

        missingAssets.forEach((item) => {
          if (item.uvtMissing && item.kmMdbMissing) {
            assetSyncPromises.push(syncOriginalAssetFileToFileStorage(item.vehicle.id));
          } else if (item.uvtMissing) {
            assetSyncPromises.push(syncOriginalAssetFileToFileStorage(item.vehicle.id, 'uvt'));
          } else if (item.kmMdbMissing) {
            assetSyncPromises.push(syncOriginalAssetFileToFileStorage(item.vehicle.id, 'kmMdb'));
          }
        });

        const assetSyncResults = await Promise.all(assetSyncPromises);
        const failedAssetSyncAttempts = assetSyncResults.filter(
          (result) => result.success === false,
        );

        if (failedAssetSyncAttempts.length)
          console.log(
            'Something went wrong while trying to ensure that each enabled vehicle has a valid reference to an asset exposed by file api. Failed: ',
            failedAssetSyncAttempts.map((item) => ({
              vehicleId: item.vehicleId,
            })),
          );

        const successfulSyncResults = assetSyncResults.filter(isSyncAssetFileSuccessResponse);
        const newVehicleAssets: VehicleAssets[] = [];
        successfulSyncResults.forEach((result) => {
          const vehicleAsset = allVehicleAssets.find(
            (vehicle) => vehicle.vehicleVariantId === result.vehicleId,
          );
          if (vehicleAsset) {
            if (result.uvtReference) vehicleAsset.uvtReference = result.uvtReference;
            if (result.kmMdbFileRef) vehicleAsset.kmMdbReference = result.kmMdbFileRef;
          } else {
            const newVehicle: VehicleAssets = buildVehicleAsset(
              result.vehicleId,
              // @ts-expect-error strictNullChecks. Pls fix me
              result.uvtReference,
              result.kmMdbFileRef,
            );
            newVehicleAssets.push(newVehicle);
          }
        });
        const assets = [
          ...newVehicleAssets,
          ...(Array.isArray(floorplanVehicleAssets) ? floorplanVehicleAssets : []),
        ];

        assets.forEach((vehicleAsset) => {
          storeAssetReference(vehicleAsset);
        });

        return assets;
      },
    [],
  );

  /** @description attempts to sync get vehicle details from pim api.
   * If not given any vehicleIds, it will default to the current enabled ones */
  const ensureVehicleDetailsAvailability = useRecoilCallback(
    ({ snapshot }) =>
      async (
        vehicleIds?: string[],
        floorplanVehicleAssets?: VehicleAssets[],
        syncToStore?: boolean,
      ) => {
        const vehicles = vehicleIds
          ? (await snapshot.getPromise(allVehiclesState)).filter((v) => vehicleIds.includes(v.id))
          : await snapshot.getPromise(enabledVehiclesSelector);

        const knownVehicleDetails = await snapshot.getPromise(knownVehicleDetailsSelector);
        // @ts-expect-error strictNullChecks. Pls fix me
        const vehicleIdsWithKnownDetails = knownVehicleDetails.map((item) => item.id);

        const allVehicleAssets =
          floorplanVehicleAssets || (await snapshot.getPromise(allVehicleAssetsSelector));

        const missingDetailsPromises = vehicles
          .filter((item) => !vehicleIdsWithKnownDetails.includes(item.id))
          .map(
            async (
              v,
            ): Promise<
              | {
                  success: false;
                  vehicleId: string;
                }
              | {
                  success: true;
                  vehicleId: string;
                  details: UnifiedVehicleDetails;
                }
            > => {
              const currentAsset = allVehicleAssets.find(
                (assets) => assets.vehicleVariantId === v.id,
              );

              if (!currentAsset) {
                return {
                  success: false,
                  vehicleId: v.id,
                };
              }

              const uvtSpec = await getVehicleJSONAsset(currentAsset.uvtReference);
              const vehicleType = uvtSpec?.vehicleTypes.at(0);
              const physics = vehicleType?.physics;

              if (!physics || !physics.vehicleShape || !physics.positionalAccuracy) {
                return {
                  success: false,
                  vehicleId: v.id,
                };
              }

              return {
                success: true,
                vehicleId: v.id,
                details: {
                  id: v.id,
                  databaseId: v.databaseId,
                  length: Math.round(
                    (Math.abs(physics.vehicleShape.xs[0]) + Math.abs(physics.vehicleShape.xs[1])) *
                      1000,
                  ),
                  width: Math.round(
                    (Math.abs(physics.vehicleShape.ys[0]) + Math.abs(physics.vehicleShape.ys[1])) *
                      1000,
                  ),
                  safetyMargin: Math.round(physics.positionalAccuracy * 1000),
                },
              };
            },
          );

        const detailsResults = await Promise.all(missingDetailsPromises);
        const { details, missingDetailsVehicleIds } = detailsResults.reduce(
          (acc, data) => {
            if (!data.success) acc.missingDetailsVehicleIds.push(data.vehicleId);
            else acc.details.push(data.details);

            return acc;
          },
          {
            details: [],
            missingDetailsVehicleIds: [],
          } as { details: UnifiedVehicleDetails[]; missingDetailsVehicleIds: string[] },
        );

        if (missingDetailsVehicleIds.length)
          console.log(
            'Failed to retrieve vehicle details for vehicles with ids: ',
            missingDetailsVehicleIds,
          );

        setKnownVehicleDetails(details);
      },
    [setKnownVehicleDetails],
  );

  const cleanUpRedundantAssets = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const enabledVehicleIds = await snapshot.getPromise(enabledVehicleIdsState);

        set(allVehicleAssetsSelector, (current) =>
          current.filter((assets) => enabledVehicleIds.includes(assets.vehicleVariantId)),
        );
      },
    [],
  );

  const getLatestFileRef = useCallback(
    async (assetType: ValidAssetType, vehicleId: string) => {
      const vehicleAsset = allVehicleAssets.find(
        (vehicle) => vehicle.vehicleVariantId === vehicleId,
      );

      showLoader(LoadingType.TRANSPARENT);

      switch (assetType) {
        case 'uvt':
          await syncOriginalAssetFileToFileStorage(vehicleId, 'uvt').then(
            async (assetReference) => {
              if (isSyncAssetFileSuccessResponse(assetReference)) {
                const updatedVehicleAsset = {
                  ...vehicleAsset,
                  uvtReference: assetReference.uvtReference,
                  uvtReferenceOriginal: true,
                };

                // @ts-expect-error strictNullChecks. Pls fix me
                await storeAssetReference(updatedVehicleAsset);
                await saveFloorPlan();
              }
            },
          );
          break;
        case 'kmMdb':
          await syncOriginalAssetFileToFileStorage(vehicleId, 'kmMdb').then(
            async (assetReference) => {
              if (isSyncAssetFileSuccessResponse(assetReference)) {
                const updatedVehicleAsset = {
                  ...vehicleAsset,
                  kmMdbReference: assetReference.kmMdbFileRef,
                  kmMdbReferenceOriginal: true,
                };

                // @ts-expect-error strictNullChecks. Pls fix me
                await storeAssetReference(updatedVehicleAsset);
                await saveFloorPlan();
              }
            },
          );
          break;
        default: {
          const unhandledAssetType: never = assetType;
          console.error(`Unknown vehicle asset type: ${unhandledAssetType}`);
          break;
        }
      }
      hideLoader();
    },
    [
      syncOriginalAssetFileToFileStorage,
      allVehicleAssets,
      storeAssetReference,
      showLoader,
      hideLoader,
      saveFloorPlan,
    ],
  );

  const getVehicleAssetFiles = async (vehiclesAssets: VehicleAssets[]) => {
    const uvtFilesPromises = [];
    const uvtFileNames = [];
    const kmMdbFilesPromises = [];
    const kmMdbFileNames = [];

    vehiclesAssets.forEach((assets) => {
      if (assets.kmMdbReference) {
        // @ts-expect-error strictNullChecks. Pls fix me
        kmMdbFileNames.push(assets.vehicleVariantId);
        kmMdbFilesPromises.push(
          // @ts-expect-error strictNullChecks. Pls fix me
          getFile(assets.kmMdbReference, FLOORPLAN_ASSET_FILE_CONTAINER, 'arraybuffer'),
        );
      }

      if (assets.uvtReference) {
        // @ts-expect-error strictNullChecks. Pls fix me
        uvtFileNames.push(assets.vehicleVariantId);
        // @ts-expect-error strictNullChecks. Pls fix me
        uvtFilesPromises.push(getFile(assets.uvtReference, FLOORPLAN_ASSET_FILE_CONTAINER, 'json'));
      }
    });

    const [kmMdbFiles, uvtFiles] = await Promise.all([
      Promise.all(kmMdbFilesPromises),
      Promise.all(uvtFilesPromises),
    ]);

    return { uvtFiles, uvtFileNames, kmMdbFiles, kmMdbFileNames };
  };

  return {
    ensureVehicleAssetAvailability,
    ensureVehicleDetailsAvailability,
    cleanUpRedundantAssets,
    getVehicleAssetFiles,
    getLatestFileRef,
  };
};
