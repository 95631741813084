import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';

import { ProcessTwoEPShape, ProcessTwoEPShapeParameters } from '@/modules/processTwoEndPoint';
import { DTShapeParameters } from '@modules/common/types/shapes';
import shapeAtom from './atom';
import { AreaShape, AreaShapeParameters } from './types/area';

const shapeParameters = selectorFamily({
  key: 'shape/parameter',
  get:
    (id: string) =>
    ({ get }) => {
      const shape = get(shapeAtom(id));
      if (!shape) return null;
      return shape.parameters;
    },

  set:
    (id: string) =>
    ({ get, set }, newValue: DTShapeParameters) => {
      set(shapeAtom(id), {
        ...get(shapeAtom(id)),
        parameters: newValue,
      });
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default shapeParameters;

export const areaParameters = selectorFamily<AreaShapeParameters, string>({
  key: 'area/parameter',
  get:
    (id) =>
    // @ts-expect-error strictNullChecks. Pls fix me
    ({ get }) => {
      const shape = get(shapeAtom(id));
      if (!shape) return null;
      return shape.parameters as AreaShapeParameters;
    },

  set:
    (id) =>
    ({ set }, newValue: AreaShapeParameters) => {
      set(
        shapeAtom(id),
        (current: AreaShape): AreaShape => ({
          ...current,
          parameters: newValue,
        }),
      );
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processParameters = selectorFamily<ProcessTwoEPShapeParameters, string>({
  key: 'process/parameter',
  get:
    (id) =>
    // @ts-expect-error strictNullChecks. Pls fix me
    ({ get }) => {
      const shape = get(shapeAtom(id));
      if (!shape) return null;
      return shape.parameters as ProcessTwoEPShapeParameters;
    },

  set:
    (id) =>
    ({ set }, newValue: ProcessTwoEPShapeParameters) => {
      set(
        shapeAtom(id),
        (current: ProcessTwoEPShape): ProcessTwoEPShape => ({
          ...current,
          parameters: newValue,
        }),
      );
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
