import { atom, atomFamily, selectorFamily } from 'recoil';
import { LoadCarrierType } from '@modules/common/types/floorPlan';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { DEFAULT_ENABLED_LOAD_TYPE_IDS } from '@modules/common/constants/loadTypes';

import { availableLoadCarrierTypesState } from '../availableLoadCarrierTypes';
import { HistoryManager } from '../../history';

export const enabledLoadCarrierTypeAtomFamily = atomFamily<LoadCarrierType, string>({
  key: 'loadCarrierType',

  // @ts-expect-error strictNullChecks. Pls fix me
  default: selectorFamily({
    key: 'loadCarrierType/default',
    get:
      (id) =>
      ({ get }) =>
        get(availableLoadCarrierTypesState).find((item) => item.id === id),
    cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
  }),
});

export const enabledLoadCarrierTypesIdsState = atom<string[]>({
  key: 'loadCarrierTypes/ids',
  default: DEFAULT_ENABLED_LOAD_TYPE_IDS,
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue: string[], oldValue: string[]) => {
        const newLengthDiff = newValue.length - oldValue.length;

        if (
          newLengthDiff > 0 ||
          newLengthDiff < 0 ||
          (newLengthDiff === 0 &&
            // if length is same, check if the array contents are also the same
            !newValue.every((newValueElement, i) => newValueElement === oldValue[i]))
        ) {
          HistoryManager.track('Load carriers changed', newValue, oldValue, setSelf);
        }
      });
    },
  ],
});
