import { useBaseProjectApi } from '@/modules/api/hooks';
import { useCallback } from 'react';
import { Vector3 } from 'three';
import { useRecoilCallback } from 'recoil';
import { idSelector as projectIdSelector } from '@modules/floorplan/store/project';
import { idSelector as groupIdSelector } from '@modules/floorplan/store/group';
import { displayVersionSelector } from '@modules/floorplan/store/floorPlan';
import { useFloorPlanService } from '@modules/floorplan';
import { convertDataUrlToFile, convertFileToDataURL } from '@modules/common/helpers/browser';

import { CommissioningLayout, LocalizationMap, LocalizationMapType } from '../helpers/types';

const createBaseUrl = (projectId: string, groupId: string, floorPlanId: string) =>
  `/v2/${projectId}/floorPlanGroup/${groupId}/floorPlan/${floorPlanId}/commissioning`;

export const useApi = () => {
  const projectApi = useBaseProjectApi();
  const { fetchAllVersions } = useFloorPlanService();

  const getBaseUrl = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const [projectId, groupId] = await Promise.all([
          await snapshot.getPromise(projectIdSelector),
          await snapshot.getPromise(groupIdSelector),
        ]);

        const versions = await fetchAllVersions(projectId, groupId);
        const version = (await snapshot.getPromise(displayVersionSelector)) || 1;
        const floorPlan = versions.find((item) => item.version === version);

        // @ts-expect-error strictNullChecks. Pls fix me
        return createBaseUrl(projectId, groupId, floorPlan.id);
      },
    [],
  );

  const create = useCallback(
    async (data: CommissioningLayout): Promise<CommissioningLayout> => {
      const url = `${await getBaseUrl()}`;
      return mapToLayout((await projectApi.post(url, mapToPayload(data))).data);
    },
    [getBaseUrl, projectApi],
  );

  const fetch = useCallback(async () => {
    try {
      const url = `${await getBaseUrl()}/latest`;
      return mapToLayout((await projectApi.get(url)).data);
    } catch (error) {
      if (error.response?.status === 404) {
        return null;
      }

      throw error;
    }
  }, [getBaseUrl, projectApi]);

  const update = useCallback(
    async (data: CommissioningLayout): Promise<boolean> => {
      const url = `${await getBaseUrl()}/latest`;
      return await projectApi.put(url, mapToPayload(data));
    },
    [getBaseUrl, projectApi],
  );

  const fetchLocalizationFile = useCallback(
    async (localization: LocalizationMap) => {
      try {
        const { data } = await projectApi.get(localization.timeLimitedUrl);
        return convertDataUrlToFile(data, localization.fileName);
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    [projectApi],
  );

  const fetchLocalizationMapFiles = useCallback(
    async (localizationMaps: LocalizationMap[]) => {
      const kollmorgen = localizationMaps.find(
        (item) => item.type === LocalizationMapType.Kollmorgen,
      );
      const sevenSense = localizationMaps.find(
        (item) => item.type === LocalizationMapType.SevenSense,
      );
      const navitech = localizationMaps.find((item) => item.type === LocalizationMapType.Navitech);

      const [kollmorgenFile, sevensSenseFile, navitechFile] = await Promise.all([
        kollmorgen ? fetchLocalizationFile(kollmorgen) : null,
        sevenSense ? fetchLocalizationFile(sevenSense) : null,
        navitech ? fetchLocalizationFile(navitech) : null,
      ]);

      return {
        kollmorgen: kollmorgenFile,
        sevenSense: sevensSenseFile,
        navitech: navitechFile,
      };
    },
    [fetchLocalizationFile],
  );

  const deleteLocalization = useCallback(
    async (type: LocalizationMapType) => {
      const url = `${await getBaseUrl()}/latest/localizationMap/${mapToType(type)}`;
      await projectApi.delete(url);
    },
    [getBaseUrl, projectApi],
  );

  const uploadLocalizationMapFile = useCallback(
    async (type: LocalizationMapType, file: File) => {
      const url = `${await getBaseUrl()}/latest/localizationMap/${mapToType(type)}/file`;
      const dataUrlFile = new File([await convertFileToDataURL(file)], file.name);
      const formData = new FormData();
      formData.append('file', dataUrlFile);

      await projectApi.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    [getBaseUrl, projectApi],
  );

  return {
    create,
    deleteLocalization,
    fetch,
    fetchLocalizationMapFiles,
    update,
    uploadLocalizationMapFile,
  };
};

const mapToLayout = (response: any): CommissioningLayout => ({
  layoutDelta: {
    delta: new Vector3(response.layoutDelta.x, response.layoutDelta.y, response.layoutDelta.z),
    deltaAngle: response.layoutDelta.angle,
  },
  gates:
    response.gates?.map((item: any) => ({
      id: item.id,
      delta: new Vector3(item.delta.x, item.delta.y, item.delta.z),
      deltaAngle: item.delta.angle,
    })) ?? [],
  splines: response.splines,
  localizationMaps: response.localizationMaps.map(mapLocalizationMapResponse),
});

const mapLocalizationMapResponse = (response: any): LocalizationMap => ({
  ...response,
  type: mapLocalizationMapTypeResponseType(response.type),
});

const mapLocalizationMapTypeResponseType = (response: any) => {
  switch (response) {
    case 'kollmorgen':
      return LocalizationMapType.Kollmorgen;

    case 'sevenSense':
      return LocalizationMapType.SevenSense;

    case 'navitech':
      return LocalizationMapType.Navitech;

    default:
      throw new Error('Unsupported localization type');
  }
};

const mapToPayload = (data: CommissioningLayout) => ({
  layoutDelta: {
    x: data.layoutDelta.delta.x,
    y: data.layoutDelta.delta.y,
    z: data.layoutDelta.delta.z,
    angle: data.layoutDelta.deltaAngle,
  },
  gates: data.gates.map((item) => ({
    id: item.id,
    delta: {
      x: item.delta.x,
      y: item.delta.y,
      z: item.delta.z,
      angle: item.deltaAngle,
    },
  })),
  splines: [],
});

const mapToType = (type: LocalizationMapType) => {
  switch (type) {
    case LocalizationMapType.Kollmorgen:
      return 'kollmorgen';

    case LocalizationMapType.SevenSense:
      return 'sevenSense';

    case LocalizationMapType.Navitech:
      return 'navitech';

    default:
      throw new Error('Unsupported localization type');
  }
};
