import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import workspaceAtom, { WorkspaceAtom } from './atom';

export const placingIdSelector = selector<string>({
  key: 'placingIdSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: ({ get }) => get<WorkspaceAtom>(workspaceAtom).placingId,
  set: ({ get, set }, newValue) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    set(workspaceAtom, { ...get(workspaceAtom), placingId: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
