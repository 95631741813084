import { UserPreferenceName } from '@/modules/userPreferences';
import { useUserPreference } from '@/modules/userPreferences/hooks';
import {
  konvaVectorToThreeVector,
  threeVectorToKonvaVector,
} from '@/modules/workspace/helpers/konva';
import shapeAtom from '@/store/recoil/shape/atom';
import { scaleSelector } from '@/store/recoil/workspace';
import { getBubbleRadius } from '@modules/common/helpers/bubble';
import { FLOW_LINE_COLOR, FLOW_LINE_SELECTED_OPACITY } from '@modules/flows/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Arrow } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { useFlowRendering } from '../hooks/useFlowRendering';
import { flowInfoIdSelector } from '../store/flowInfoId';
import { highlightFlowSelector } from '../store/layout/selector';
import { FlowAttachShapeIds, FlowScope } from '../types';

type Props = {
  flowId: string;
  flowScope: FlowScope;
};

export function FlowLine({ flowId, flowScope }: Props) {
  const { getAttachShapeIds, getAttachPoints } = useFlowRendering();
  const scale = useRecoilValue(scaleSelector);
  const bubbleRadius = useMemo(() => getBubbleRadius(scale), [scale]);
  // @ts-expect-error strictNullChecks. Pls fix me
  const [attachShapeIds, setAttachShapeIds] = useState<FlowAttachShapeIds>(null);
  const [points, setPoints] = useState<number[]>([]);
  const fromShape = useRecoilValue(shapeAtom(attachShapeIds?.fromShapeId));
  const toShape = useRecoilValue(shapeAtom(attachShapeIds?.toShapeId));
  const relatedShapeSelected = useRecoilValue(highlightFlowSelector(flowId));
  const flowInfoId = useRecoilValue(flowInfoIdSelector);
  const [opacity, setOpacity] = useState(FLOW_LINE_SELECTED_OPACITY);
  const opacityFlow = useUserPreference(UserPreferenceName.LAYER_FLOWS_OPACITY);

  useEffect(() => {
    if (relatedShapeSelected || flowInfoId == flowId) {
      setOpacity(FLOW_LINE_SELECTED_OPACITY);
    } else {
      setOpacity(opacityFlow);
    }
  }, [relatedShapeSelected, flowInfoId, flowId, opacityFlow]);

  useEffect(() => {
    findAttachShape();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId]);

  const findAttachShape = useCallback(async () => {
    setAttachShapeIds(await getAttachShapeIds(flowId));
  }, [flowId, getAttachShapeIds]);

  const updateAttachPoint = useCallback(async () => {
    if (!attachShapeIds) return;
    const { from, to } = await getAttachPoints(
      attachShapeIds.fromShapeId,
      attachShapeIds.toShapeId,
    );
    const p1 = konvaVectorToThreeVector(from);
    const p2 = konvaVectorToThreeVector(to);
    const v = p2.clone().sub(p1).normalize();
    const distance = p2.clone().sub(p1).length() - 2 * bubbleRadius;
    const p1new = p1.clone().add(v.clone().multiplyScalar(bubbleRadius));
    const p1konva = threeVectorToKonvaVector(p1new);
    const p2new = p1new.clone().add(v.clone().multiplyScalar(distance));
    const p2konva = threeVectorToKonvaVector(p2new);
    setPoints([p1konva.x, p1konva.y, p2konva.x, p2konva.y]);
  }, [attachShapeIds, bubbleRadius, getAttachPoints]);

  // TODO refactor. updateAttachPoint is not reevaluated
  useEffect(() => {
    updateAttachPoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromShape, toShape]);

  if (points.length === 0) return null;

  return (
    <>
      <Arrow
        id={flowId}
        points={points}
        strokeWidth={2 / scale}
        stroke={FLOW_LINE_COLOR}
        fill={FLOW_LINE_COLOR}
        opacity={opacity / 100}
        pointerLength={10 / scale}
        pointerWidth={10 / scale}
        listening={false}
      />
    </>
  );
}
