import { boundingBoxToPolygon, doPolygonsIntersect } from '@/modules/common/helpers/polygon';

export const linkCutOutsToEndPointGenerators = (endPointGenerationSettings, cutOutReferences) => {
  endPointGenerationSettings.forEach((ep) => {
    const cutOutsInBounds = [];

    cutOutReferences.forEach((co) => {
      if (doPolygonsIntersect(boundingBoxToPolygon(ep.properties), boundingBoxToPolygon(co.properties))) {
        // @ts-expect-error strictNullChecks. Pls fix me
        cutOutsInBounds.push({ name: co.name });
      }
    });

    ep.data.epCutOutReferences = cutOutsInBounds;
    return ep;
  });
};
