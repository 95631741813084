import { AddIcon, FitToScreenIcon, RemoveIcon } from '@/assets/icons';
import { StyledTooltip } from '@/helpers/styles';
import { zoomTheme } from '@/modules/common/components/theme';
import { Button, IconButton, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

type ZoomProps = {
  zoom?: number;
  zoomIn?: () => void;
  zoomOut?: () => void;
  zoomFit?: () => void;
  zoomReset?: () => void;
  disableZoomButtons?: boolean;
};

const ZoomButtonComponent = ({ zoomIn, zoomOut, zoomFit, zoomReset, zoom, disableZoomButtons = false }: ZoomProps) => {
  const { t } = useTranslation('interface');

  return (
    <ThemeProvider theme={zoomTheme}>
      <Paper
        elevation={1}
        sx={{
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: zoom ? '170px' : 'auto',
          height: '40px',
          p: 1,
        }}
      >
        <StyledTooltip offset={0} title={t('zoom_to_fit')} placement='left'>
          <IconButton className='zoom-to-fit-btn' onClick={zoomFit}>
            <FitToScreenIcon />
          </IconButton>
        </StyledTooltip>
        {(zoom !== undefined || zoomIn || zoomOut) && (
          <div
            style={{
              borderLeft: `2px solid ${zoomTheme.palette.neutral.lighter}`,
              height: '16px',
              marginLeft: '8px',
            }}
          />
        )}
        <div
          style={{
            width: zoom ? '178px' : 'auto',
            marginLeft: '4px',
            display: zoom ? 'block' : 'flex',
          }}
        >
          {zoomOut && (
            <IconButton
              className='zoom-in-icon-btn'
              onClick={() => zoomOut()}
              disabled={disableZoomButtons}
            >
              <RemoveIcon />
            </IconButton>
          )}

          {zoom !== undefined && (
            <Button
              className='reset-zoom-btn'
              // @ts-expect-error strictNullChecks. Pls fix me
              onClick={() => zoomReset()}
            >
              {Math.floor(zoom * 100)}%
            </Button>
          )}

          {zoomIn && (
            <IconButton
              className='zoom-out-icon-btn'
              onClick={() => zoomIn()}
              disabled={disableZoomButtons}
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
      </Paper>
    </ThemeProvider>
  );
};

export const ZoomButton = memo(ZoomButtonComponent);
