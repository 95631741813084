import { MouseEvent } from 'react';
import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';

import { LoadCarrierType } from '@/modules/common/types/floorPlan';
import { UnifiedVehicle } from '@/modules/vehicles';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import { LoadCarrierOption, VehicleOption, ModelMapEntry } from '@modules/visualization/types';

const containerSx = {
  height: '50px',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 3,
  padding: 1,
  border: '1px solid transparent',
};

type ChangePanelProps = {
  options: VehicleOption[] | LoadCarrierOption[];
  handleCloseItem: () => void;
  handleClickItem: (event: MouseEvent<HTMLButtonElement>, itemId: string) => void;
  changeModel: (newModel: VehicleOption) => void;
  itemMap:
    | Map<string, ModelMapEntry<VehicleOption>>
    | Map<string, ModelMapEntry<LoadCarrierOption>>;
  selectedItem: UnifiedVehicle | LoadCarrierType;
  open: boolean;
  anchor: HTMLElement;
};

export function ChangePanel({
  options,
  changeModel,
  itemMap,
  handleClickItem,
  handleCloseItem,
  selectedItem,
  open,
  anchor,
}: ChangePanelProps) {
  return (
    <>
      {[...itemMap].map(([itemId, data]) => (
        <Stack key={itemId} justifyContent='space-between' direction='row'>
          <Stack sx={containerSx}>
            <img
              // @ts-expect-error strictNullChecks. Pls fix me
              src={data.image ? data.image.url : null}
              alt=''
              width={32}
              height={32}
            />
            <Typography fontSize={14} fontWeight={500}>
              {data.option.name}
            </Typography>
          </Stack>
          <IconButton onClick={(e) => handleClickItem(e, itemId)} id='model-menu'>
            <SyncAltOutlinedIcon />
          </IconButton>
          <Menu
            id='model-menu'
            anchorEl={anchor}
            open={open}
            onClose={handleCloseItem}
            MenuListProps={{
              'aria-labelledby': 'model-menu',
            }}
          >
            {options.map((model) => (
              <MenuItem key={model.name} onClick={handleCloseItem} sx={{ p: 0 }}>
                <Stack
                  direction='row'
                  spacing={2}
                  onClick={() => changeModel(model)}
                  sx={{
                    width: '100%',
                    height: '100%',
                    px: 2,
                    py: 1,
                  }}
                >
                  <Box sx={{ width: '40px' }}>
                    {selectedItem &&
                      // @ts-expect-error strictNullChecks. Pls fix me
                      itemMap.get(selectedItem.id).option.fleetTrackerId ===
                        model.fleetTrackerId && <CheckOutlinedIcon />}
                  </Box>
                  {model.name}
                </Stack>
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      ))}
    </>
  );
}
