import { useFileTypeValidator } from '@/modules/common/hooks/useFileValidator';

import { useZipFileProcessor } from '@/modules/vehicles/hooks/useZipFileProcessor';
import settingsAtom from '@/store/recoil/settings/atom';
import { useFloorPlanFile } from '@modules/floorplan';
import { useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useLoading } from '@modules/common/hooks';
import { useSetRecoilState } from 'recoil';

export function ExperimentSettings() {
  const setSettings = useSetRecoilState(settingsAtom);
  const hiddenFileInput = useRef(null);
  const { hideLoader, showLoader } = useLoading();
  const { loadFile } = useFloorPlanFile();
  const { runPreValidation } = useRunPreValidation();
  const { handleZipUpload } = useZipFileProcessor();
  const { validateFileType } = useFileTypeValidator();

  const handleClick = () => {
    // @ts-expect-error strictNullChecks. Pls fix me
    hiddenFileInput.current.click();
  };

  const handleFileSelect = useCallback(
    async (file) => {
      const fileType = file?.type;

      if (!validateFileType(file, ['json', 'zip'])) {
        setSettings(false);
        return;
      }
      showLoader();

      if (fileType === 'application/zip' || fileType === 'application/x-zip-compressed') {
        const floorplanFile = await handleZipUpload(file);
        // @ts-expect-error strictNullChecks. Pls fix me
        await loadFile(floorplanFile);
        runPreValidation();
      }

      if (fileType === 'application/json') {
        await loadFile(file);
      }

      hideLoader();
      setSettings(false);

      runPreValidation();
    },
    [
      loadFile,
      runPreValidation,
      setSettings,
      handleZipUpload,
      validateFileType,
      showLoader,
      hideLoader,
    ],
  );

  const changeHandler = useCallback(
    (e) => {
      handleFileSelect(e.currentTarget.files[0]);
    },
    [handleFileSelect],
  );

  return (
    <Stack spacing={2}>
      <Typography variant='h6'>Experimental Options</Typography>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Button onClick={handleClick} sx={{ textTransform: 'none' }}>
          Import Floorplan
        </Button>
        <input type='file' ref={hiddenFileInput} onChange={changeHandler} hidden />
      </Stack>
    </Stack>
  );
}
