import { Unit } from '../types/general';
import { defaultUnitDecimalPlacesMap } from '../constants/unit';

export const convertToMMFromUnit = (fromUnit: Unit, value: number) => {
  if (fromUnit === Unit.CENTIMETERS) {
    return value * 10;
  }
  if (fromUnit === Unit.METERS) {
    return value * 1000;
  }

  return value;
};

export const convertToUnitFromMM = (toUnit: Unit, value: number) => {
  if (toUnit === Unit.CENTIMETERS) {
    return value / 10;
  }
  if (toUnit === Unit.METERS) {
    return value / 1000;
  }

  return value;
};

export const convertMetricUnit = (
  value: number,
  sourceUnit: Unit,
  targetUnit: Unit,
  decimalPlaces: number = defaultUnitDecimalPlacesMap[targetUnit] || 0,
  limitPrecision = true,
): number => {
  let valueInMm: number;
  let convertedValue: number;

  valueInMm = convertToMMFromUnit(sourceUnit, value);
  convertedValue = convertToUnitFromMM(targetUnit, valueInMm);

  return limitPrecision ? Number(convertedValue.toFixed(decimalPlaces)) : convertedValue;
};
