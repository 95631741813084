import { useRecoilCallback } from 'recoil';
import { primitiveArraysHaveEqualContents } from '../../../common/helpers/array';
import { useDebouncedCallback } from '../../../common/hooks';
import { PreValidationAspect, PrevalidationDebounceMs } from '../constants';
import { prevalidationState } from '../store';
import { ValidationState } from '../types';
import { useConnectionLackingRoadIdsValidator } from './useConnectionLackingRoadIdsValidator';
import { useDisconnectedAreaIdsValidator } from './useDisconnectedAreaIdsValidator';
import { useDisconnectedFlowStopIdsValidator } from './useDisconnectedFlowStopIdsValidator';
import { useFlowlessAreasValidator } from './useFlowlessAreasValidator';
import { useIncorrectlyConnectedShapesToRoadIdsValidator } from './useIncorrectlyConnectedShapesToRoadIdsValidator';
import { useRequiredElementsValidator } from './useRequiredElementsValidator';

// make sure to execute a scheduled prevalidation even if the calling component unmounts
const ABORT_WHEN_CALLER_UNMOUNTS = false;

export const useRunPreValidation = (debounceMs = PrevalidationDebounceMs.DEFAULT) => {
  const { validateConnectionLackingRoadIds } = useConnectionLackingRoadIdsValidator();
  const { validateDisconnectedAreaIds } = useDisconnectedAreaIdsValidator();
  const { validateDisconnectedFlowStopIds } = useDisconnectedFlowStopIdsValidator();
  const { validateFlowlessAreas } = useFlowlessAreasValidator();
  const { validateIncorrectlyConnectedShapeToRoadIds } =
    useIncorrectlyConnectedShapesToRoadIdsValidator();
  const { validateMissingRequiredElements } = useRequiredElementsValidator();

  const preValidator = useRecoilCallback(
    ({ set }) =>
      async (skipValidatorOptions?: PreValidationAspect[]) => {
        const newDisconnectedAreaIds = skipValidatorOptions?.includes(
          PreValidationAspect.DISCONNECTED_AREA_IDS,
        )
          ? null
          : await validateDisconnectedAreaIds(false);
        const newConnectionLackingRoadIds = skipValidatorOptions?.includes(
          PreValidationAspect.CONNECTION_LACKING_ROADS,
        )
          ? null
          : await validateConnectionLackingRoadIds(false);
        const newMissingRequiredElements = skipValidatorOptions?.includes(
          PreValidationAspect.REQUIRED_ELEMENTS,
        )
          ? null
          : await validateMissingRequiredElements(false);
        const newFlowlessAreas = skipValidatorOptions?.includes(PreValidationAspect.FLOWLESS_AREAS)
          ? null
          : await validateFlowlessAreas(false);
        const newDisconnectedFlowStopIds = skipValidatorOptions?.includes(
          PreValidationAspect.DISCONNECTED_FLOW_STOPS,
        )
          ? null
          : await validateDisconnectedFlowStopIds(false);
        const newIncorrectlyConnectedShapeToRoadIds = skipValidatorOptions?.includes(
          PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
        )
          ? null
          : await validateIncorrectlyConnectedShapeToRoadIds(false);

        let validationResults: ValidationState;

        set(prevalidationState, (current) => {
          validationResults = {
            disconnectedAreaIds:
              newDisconnectedAreaIds !== null
                ? getWashedArray(current.disconnectedAreaIds, newDisconnectedAreaIds)
                : current.disconnectedAreaIds,
            connectionLackingRoadIds:
              newConnectionLackingRoadIds !== null
                ? getWashedArray(current.connectionLackingRoadIds, newConnectionLackingRoadIds)
                : current.connectionLackingRoadIds,
            missingRequiredElements:
              newMissingRequiredElements !== null
                ? getWashedArray(current.missingRequiredElements, newMissingRequiredElements)
                : current.missingRequiredElements,
            flowlessAreas: newFlowlessAreas !== null ? newFlowlessAreas : current.flowlessAreas,
            disconnectedFlowStopIds:
              newDisconnectedFlowStopIds !== null
                ? getWashedArray(current.disconnectedFlowStopIds, newDisconnectedFlowStopIds)
                : current.disconnectedFlowStopIds,
            incorrectlyConnectedShapeToRoadIds:
              newIncorrectlyConnectedShapeToRoadIds !== null
                ? getWashedArray(
                    current.incorrectlyConnectedShapeToRoadIds,
                    newIncorrectlyConnectedShapeToRoadIds,
                  )
                : current.incorrectlyConnectedShapeToRoadIds,
          };

          return validationResults;
        });

        // @ts-expect-error strictNullChecks. Pls fix me
        return validationResults;
      },
    [
      validateConnectionLackingRoadIds,
      validateDisconnectedAreaIds,
      validateDisconnectedFlowStopIds,
      validateFlowlessAreas,
      validateIncorrectlyConnectedShapeToRoadIds,
      validateMissingRequiredElements,
    ],
  );

  const runPreValidation = useDebouncedCallback(
    (skipValidatorOptions?: PreValidationAspect[]) => {
      preValidator(skipValidatorOptions);
    },
    debounceMs,
    ABORT_WHEN_CALLER_UNMOUNTS,
  );

  return { runPreValidation };
};

const getWashedArray = (arr1: any[], arr2: any[]) =>
  primitiveArraysHaveEqualContents(arr1, arr2) ? arr1 : arr2;
