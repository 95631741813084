import { Position } from '@helpers/types';
import { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import {
  calculateBoundingBox,
  getModelViewportSize,
  isViewportPositionOverModel,
  setCameraPositionFromScreenPosition,
  setCameraZoomFromScale,
} from '../helpers/camera';
import { initializedViewer, initializeGlobals, waitForViewerLoaded } from '../helpers/viewer';
import { OnViewerLoadedEventHandler, ViewerRef } from '../types';
import { useAuth } from './useAuth';
import { loadAutodeskScripts } from '../helpers/scripts';

export const useViewerInit = (
  urn: string,
  x: number,
  y: number,
  offsetX: number,
  offsetY: number,
  scale: number,
  width: number,
  height: number,
  ref: ForwardedRef<ViewerRef>,
  gui: boolean,
  onViewerLoaded?: OnViewerLoadedEventHandler,
) => {
  const viewerEl = useRef<HTMLDivElement>(null);
  const [viewer, setViewer] = useState<Autodesk.Viewing.Viewer3D>();
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [areScriptsLoaded, setAreScriptsLoaded] = useState(false);
  const [currentWidth, setCurrentWidth] = useState<number>();
  const [boundingBox, setBoundingBox] = useState<THREE.Box3>();
  const { getToken } = useAuth();

  useEffect(() => {
    loadAutodeskScripts().then(() => setAreScriptsLoaded(true));
  }, []);

  useEffect(() => {
    if (!areScriptsLoaded) {
      return;
    }

    initializeGlobals(() => getToken()).then(() => {
      // @ts-expect-error strictNullChecks. Pls fix me
      setViewer(initializedViewer(viewerEl.current, gui));
    });

    return () => {
      viewer?.finish();
      Autodesk.Viewing.shutdown();
      // @ts-expect-error strictNullChecks. Pls fix me
      setViewer(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areScriptsLoaded]);

  useEffect(() => {
    if (!viewer) {
      return;
    }

    viewer.finish();
    // @ts-expect-error strictNullChecks. Pls fix me
    setViewer(initializedViewer(viewerEl.current, gui));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gui]);

  useImperativeHandle(
    ref,
    () => ({
      isViewportPositionOverModel: (position: Position) =>
        // @ts-expect-error strictNullChecks. Pls fix me
        isViewportPositionOverModel(position, viewer.navigation, viewer.model),
    }),
    [viewer],
  );

  useEffect(() => {
    if (!viewer || !urn) {
      return;
    }

    setIsModelLoaded(false);

    Autodesk.Viewing.Document.load(
      `urn:${urn}`,
      async (viewerDocument) => {
        const defaultModel = viewerDocument.getRoot().getDefaultGeometry(true);
        await viewer.loadDocumentNode(viewerDocument, defaultModel, {
          applyScaling: 'meters',
        });
      },
      () => {
        console.error('Failed fetching Forge manifest');
      },
    );
  }, [viewer, urn]);

  useEffect(() => {
    if (!viewer) {
      return;
    }

    const onLoaded = async () => {
      // @ts-expect-error strictNullChecks. Pls fix me
      viewer.setLayerVisible(null, true);
      const bb = await calculateBoundingBox(viewer);

      setBoundingBox(bb);
      // Normalize scale
      const initialSize = getModelViewportSize(bb, viewer.navigation);
      setCameraZoomFromScale(2000 / initialSize.width, viewer.navigation);
      (viewer.navigation as any).updateCamera();

      const normalizedSize = getModelViewportSize(bb, viewer.navigation);

      // Match changed scale
      if (width) {
        setCameraZoomFromScale(width / normalizedSize.width, viewer.navigation);
        (viewer.navigation as any).updateCamera();
        setCurrentWidth(width);
      } else {
        setCurrentWidth(normalizedSize.width);
      }

      // Match workspace scale and offset
      setCameraZoomFromScale(scale * 10, viewer.navigation);
      (viewer.navigation as any).updateCamera();
      setCameraPositionFromScreenPosition(
        bb,
        { x, y },
        {
          x: offsetX,
          y: offsetY,
        },
        viewer.navigation,
        scale,
      );

      onViewerLoaded?.({
        viewportBoundingBox: {
          ...normalizedSize,
          x: 0,
          y: 0,
        },
      });
      setIsModelLoaded(true);
    };
    const cleanup = waitForViewerLoaded(viewer, onLoaded);

    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer, isModelLoaded, scale, x, y, offsetY, offsetX, height, width]);

  useEffect(() => {
    if (isModelLoaded && width && width !== currentWidth) {
      // @ts-expect-error strictNullChecks. Pls fix me
      const widthRatio = width / currentWidth;

      setCurrentWidth(width);
      // @ts-expect-error strictNullChecks. Pls fix me
      setCameraZoomFromScale(widthRatio, viewer.navigation);
      // @ts-expect-error strictNullChecks. Pls fix me
      (viewer.navigation as any).updateCamera();
      setCameraPositionFromScreenPosition(
        // @ts-expect-error strictNullChecks. Pls fix me
        boundingBox,
        { x, y },
        {
          x: offsetX,
          y: offsetY,
        },
        // @ts-expect-error strictNullChecks. Pls fix me
        viewer.navigation,
        scale,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, scale]);

  return { boundingBox, isLoaded: isModelLoaded, viewerEl, viewer };
};
