import { useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { toMap } from '@modules/common/helpers/array';
import { Crossing } from '@modules/common/types/connections';
import { ShapeType } from '@modules/common/types/shapes';
import { filterRelatedConnections } from '@modules/connections/common/helpers';
import { CopyIdMapping } from '@modules/connections/common/types';
import { allCrossingSelector, updateCrossingsSelector } from '@modules/connections/crossings/store';
import { HighwayShape } from '@recoil/shape';
import { shapesSelector } from '@recoil/shapes';
import { findCrossingsPositions } from '../helpers/crossings';
import { useUpdateCrossings } from './useUpdateCrossings';

export const useCopyCrossings = () => {
  const { updateCrossings } = useUpdateCrossings();

  const copyCrossings = useRecoilCallback(
    ({ set, snapshot }) =>
      async (idMapping: CopyIdMapping) => {
        const oldShapeIds = idMapping.map((item) => item.oldShapeId);
        const newShapeIds = idMapping.map((item) => item.newShapeId);
        const [allCrossings, newShapes] = await Promise.all([
          snapshot.getPromise(allCrossingSelector),
          snapshot.getPromise(shapesSelector(newShapeIds)),
        ]);

        const highways = newShapes.filter(
          (item) => item.type === ShapeType.HIGHWAY,
        ) as HighwayShape[];
        const highwayDict = toMap(highways, (item) => item.id);
        const crossingsToAdd: Crossing[] = [];

        filterRelatedConnections(allCrossings, oldShapeIds).forEach((crossing) => {
          const fromMapping = idMapping.find((item) => item.oldShapeId === crossing.from);
          const toMapping = idMapping.find((item) => item.oldShapeId === crossing.to);

          if (!fromMapping || !toMapping) {
            return;
          }

          const fromShape = highwayDict.get(fromMapping.newShapeId);
          const toShape = highwayDict.get(toMapping.newShapeId);
          // @ts-expect-error strictNullChecks. Pls fix me
          const position = findCrossingsPositions(fromShape, [toShape]).at(0);

          const newCrossings = {
            id: uuid(),
            // @ts-expect-error strictNullChecks. Pls fix me
            from: position.from,
            // @ts-expect-error strictNullChecks. Pls fix me
            to: position.to,
            // @ts-expect-error strictNullChecks. Pls fix me
            position: position.position,
          };
          crossingsToAdd.push(newCrossings);
        });

        set(updateCrossingsSelector, {
          crossings: crossingsToAdd,
        });

        await updateCrossings(newShapeIds);
      },
    [updateCrossings],
  );

  return {
    copyCrossings,
  };
};
