import { atom, atomFamily } from 'recoil';
import { Version } from '../../helpers/types';

/**
 * List of floor plan version ids in descending order by version number
 */
export const versionIdsAtom = atom<string[]>({
  key: 'versioning/versions/idsAtom',
  default: [],
});

/**
 * Floor plan version
 */
export const versionAtom = atomFamily<Version, string>({
  key: 'versioning/versions/atom',
  // @ts-expect-error strictNullChecks. Pls fix me
  default: null,
});
