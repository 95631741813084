import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { convertToMMFromUnit } from '@/modules/common/helpers/unit';
import { unitSelector } from '@recoil/workspace';

export const useUnitSync = () => {
  const unit = useRecoilValue(unitSelector);
  const toBaseUnit = useCallback((value: number) => convertToMMFromUnit(unit, value), [unit]);

  return {
    toBaseUnit,
  };
};
