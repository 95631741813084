import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { domReferencesAtom, WorkspaceDomReferences } from '../atom';

export const workspaceContainerRefSelector = selector<HTMLDivElement>({
  key: 'workspaceContainerRefSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: ({ get }) => get<WorkspaceDomReferences>(domReferencesAtom).container,
  set: ({ get, set }, newValue: HTMLDivElement) => {
    set(domReferencesAtom, { ...get(domReferencesAtom), container: newValue });
  },
  dangerouslyAllowMutability: true,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
