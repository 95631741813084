import { Stack } from '@mui/material';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { capitalizeFirstLetter } from '../../../../helpers/string';
import { shapesSelector } from '../../../../store/recoil/shapes';
import {
  connectionLackingRoadIdsSelector,
  disconnectedAreaIdsSelector,
  flowlessAreasSelector,
  incorrectlyConnectedShapeToRoadIdsSelector,
  missingRequiredElementsSelector,
  unbalancedProcessFlowsSelector,
  unequalProcessFlowsSelector,
} from '../store';
import MessageBox from './MessageBox';
import ValidationGroup from './ValidationGroup';

const PreValidationContentComponent = () => {
  const { t } = useTranslation('interface');
  const disconnectedAreaIds = useRecoilValue(disconnectedAreaIdsSelector);
  const disconnectedAreas = useRecoilValue(shapesSelector(disconnectedAreaIds));
  const incorrectlyConnectedShapeToRoadIds = useRecoilValue(
    incorrectlyConnectedShapeToRoadIdsSelector,
  );
  const incorrectlyConnectedShapes = useRecoilValue(
    shapesSelector(incorrectlyConnectedShapeToRoadIds),
  );
  const connectionLackingRoadIds = useRecoilValue(connectionLackingRoadIdsSelector);
  const connectionLackingRoads = useRecoilValue(shapesSelector(connectionLackingRoadIds));
  const flowlessAreas = useRecoilValue(flowlessAreasSelector);
  const missingRequiredElements = useRecoilValue(missingRequiredElementsSelector);
  const unequalProcessIds = useRecoilValue(unequalProcessFlowsSelector);
  const unequalProcessAreas = useRecoilValue(shapesSelector(unequalProcessIds));
  const unbalancedProcessIds = useRecoilValue(unbalancedProcessFlowsSelector);
  const unbalancedProcessAreas = useRecoilValue(shapesSelector(unbalancedProcessIds));
  const connectionWarnings = [...disconnectedAreas, ...incorrectlyConnectedShapes];

  const translateMissingElements = useCallback(
    (elements) => {
      const out = [];
      elements.forEach((element) => {
        // @ts-expect-error strictNullChecks. Pls fix me
        out.push({
          name: `${t(
            `prevalidation.elements.${element.element.toLowerCase()}`,
            capitalizeFirstLetter(element.element.toLowerCase()),
          )} (${element.vehicle})`,
          id: null,
        });
      });

      return out;
    },
    [t],
  );

  return (
    <>
      <Stack direction='column' alignItems='stretch' spacing={0} sx={{ mb: 4, px: 2 }}>
        <MessageBox />
        <Stack spacing={1} height='calc(100% - 80px)'>
          {missingRequiredElements.length > 0 && (
            <ValidationGroup
              label={t('prevalidation.warning_labels.missing_elements', 'Missing elements')}
              objects={translateMissingElements(missingRequiredElements)}
            />
          )}
          {connectionWarnings.length > 0 && (
            <ValidationGroup
              label={t('prevalidation.warning_labels.unconnected_areas', 'Unconnected areas')}
              objects={connectionWarnings.map((area) => ({ name: area.name, id: area.id }))}
            />
          )}
          {flowlessAreas.length > 0 && (
            <ValidationGroup
              label={t('prevalidation.warning_labels.missing_flows', 'Missing flows')}
              objects={flowlessAreas.map((area) => ({
                name: area.name,
                id: area.id,
              }))}
            />
          )}
          {connectionLackingRoadIds.length > 0 && (
            <ValidationGroup
              label={t('prevalidation.warning_labels.unconnected_roads', 'Unconnected roads')}
              objects={connectionLackingRoads.map((road) => ({ name: road.name, id: road.id }))}
            />
          )}
          {unequalProcessIds.length > 0 && (
            <ValidationGroup
              label={t(
                'prevalidation.warning_labels.incorrect_process_flows',
                'Incorrect process flows',
              )}
              objects={unequalProcessAreas.map((item) => ({ name: item.name, id: item.id }))}
            />
          )}
          {unbalancedProcessIds.length > 0 && (
            <ValidationGroup
              label={t(
                'prevalidation.warning_labels.unbalanced_process_flows',
                'Unbalanced process flows',
              )}
              objects={unbalancedProcessAreas.map((item) => ({ name: item.name, id: item.id }))}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export const PreValidationContent = memo(PreValidationContentComponent);
