import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useCallback } from 'react';

import { LaneLeftIcon, LaneLeftRightIcon, LaneRightIcon } from '@/assets/icons';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { LaneDirection } from '@modules/common/types/shapes';
import { useAutoSave } from '@modules/floorplan';
import { areaRackProperties } from '../../store/area';
import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { RackProperty } from '@/store/recoil/shape/types/area';

function AisleDirection() {
  const mode = useRecoilValue(modeSelector);
  const [rackProperties, setRackProperties] = useRecoilState(areaRackProperties);
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const selectedShapeIds = useRecoilValue(selectedShapesIdsState);
  const { save } = useAutoSave();

  const onChange = useCallback(
    (_, value) => {
      if (value != null) {
        setRackProperties(
          (current: RackProperty): RackProperty => ({ ...current, laneDirectionInAisle: value }),
        );
        save();
        updateArtefacts(selectedShapeIds);
      }
    },
    [setRackProperties, save, updateArtefacts, selectedShapeIds],
  );

  if (!rackProperties) return null;

  return (
    <ToggleButtonGroup
      value={rackProperties.laneDirectionInAisle}
      exclusive
      onChange={onChange}
      disabled={mode !== WorkspaceMode.EDITABLE}
    >
      <ToggleButton value={LaneDirection.LEFT_RIGHT} aria-label='Left right'>
        <LaneLeftRightIcon />
      </ToggleButton>
      <ToggleButton value={LaneDirection.LEFT} aria-label='Left'>
        <LaneLeftIcon />
      </ToggleButton>
      <ToggleButton value={LaneDirection.RIGHT} aria-label='Right'>
        <LaneRightIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default AisleDirection;
