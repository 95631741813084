import { memo, useEffect, useState } from 'react';
import { Line } from 'react-konva';

import { ControlPoint, ShapeType } from '@/modules/common/types/shapes';
import { getShapeColor } from '@/modules/workspace/helpers/colors';
import { SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';
import { getFlatPointsArrayFromControlPoints } from '@/modules/shapes/controlPointsShapes/helpers';

export type WallRenderProps = {
  points: ControlPoint[];
  width: number;
};

const WallRenderComponent: React.FC<WallRenderProps> = ({ points, width }) => {
  // @ts-expect-error strictNullChecks. Pls fix me
  const [canvasPoints, setCanvasPoints] = useState<number[]>(null);

  useEffect(() => {
    setCanvasPoints(
      getFlatPointsArrayFromControlPoints(points).map((num) => num * SHAPE_TO_CANVAS_SCALE),
    );
  }, [points]);

  return (
    <Line
      points={canvasPoints}
      stroke={getShapeColor(ShapeType.WALL, true)}
      strokeWidth={width}
      lineCap='butt'
      lineJoin='round'
      listening={false}
    />
  );
};

export const WallRender = memo(WallRenderComponent);
