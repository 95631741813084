import { Group as GroupType } from 'konva/lib/Group';
import { KonvaEventObject, Node } from 'konva/lib/Node';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Label, Line, Rect, Tag, Text } from 'react-konva';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Vector2 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';
import useImage from 'use-image';

import { BoundingBox } from '@/helpers/types';
import { theme } from '@/modules/common/components/theme';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { highwayLaneDirection } from '@components/PropertiesPanel/store/highway';
import { ARROW_ID_PREFIX } from '@modules/angledHighways/constants';
import { ControlPoint, LaneDirection } from '@modules/common/types/shapes';
import {
  ElementName,
  PROXY_STROKE_WIDTH,
  SHAPE_TO_CANVAS_SCALE,
} from '@modules/workspace/helpers/konva';
import { useZoom } from '@modules/workspace/hooks/useZoom';
import objectUnderMouseIdSelector from '@recoil/input/objectUnderMouseIdSelector';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { draggableShapeSelector } from '../../../store/recoil/workspace/draggableShapesSelector';
import { useShapeHover } from '../../common/hooks/useShapeHover';
import { angleBetweenVectors } from '../../workspace/helpers/shape';
import { accurateAngledHighwayBoundingBox, getFlatPointsArrayFromControlPoints } from '@/modules/shapes/controlPointsShapes/helpers';

export type AngledHighwayProxyProps = {
  id: string;
  points: ControlPoint[];
  width: number;
  laneDirection: LaneDirection;
  saveFloorPlan: () => Promise<void>;
};

export const AngledHighwayProxy = ({
  id,
  points,
  width,
  laneDirection,
  saveFloorPlan,
}: AngledHighwayProxyProps) => {
  const [arrowRight] = useImage('/chevron-double-right.svg');
  const [arrowBoth] = useImage('/chevron-double-both.svg');

  const { onMouseEnter, onMouseLeave } = useShapeHover(id);
  const { zoomifyValue } = useZoom();

  const draggable = useRecoilValue(draggableShapeSelector(id));
  const selectedShapesIds = useRecoilValue(selectedShapesIdsState);
  const setObjectUnderMouseId = useSetRecoilState(objectUnderMouseIdSelector);
  const setLineDirection = useSetRecoilState(highwayLaneDirection);
  const highwayInEditModeId = useWorkspaceStore((state) => state.shapeInEditModeIdState);
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);
  const { t } = useTranslation(['interface']);

  const [arrows, setArrows] = useState([]);
  const [hover, setHover] = useState(null);
  // @ts-expect-error strictNullChecks. Pls fix me
  const [canvasPoints, setCanvasPoints] = useState<number[]>(null);
  // @ts-expect-error strictNullChecks. Pls fix me
  const [proxyBox, setProxyBox] = useState<BoundingBox>(null);
  const mousingDown = useRef<boolean>(false);

  const showArrows = useMemo(() => highwayInEditModeId !== id, [highwayInEditModeId, id]);

  const selected = useMemo(() => selectedShapesIds.includes(id), [id, selectedShapesIds]);

  const handleArrowClick = useCallback(() => {
    if (selected) {
      if (laneDirection === LaneDirection.LEFT_RIGHT) {
        setLineDirection(LaneDirection.LEFT);
      } else if (laneDirection === LaneDirection.LEFT) {
        setLineDirection(LaneDirection.RIGHT);
      } else {
        setLineDirection(LaneDirection.LEFT_RIGHT);
      }
      saveFloorPlan();
    } else {
      setSelectedShapesIds([id]);
    }
  }, [saveFloorPlan, id, laneDirection, selected, setLineDirection, setSelectedShapesIds]);

  useEffect(() => {
    setCanvasPoints(
      getFlatPointsArrayFromControlPoints(points).map((num) => num * SHAPE_TO_CANVAS_SCALE),
    );
  }, [points]);

  useEffect(() => {
    const controlPoints = points.map((p) => p.position);
    const bb = accurateAngledHighwayBoundingBox(controlPoints, width);
    setProxyBox(bb);
  }, [points, width]);

  useEffect(() => {
    if (!arrowBoth || !arrowRight) return;

    const outArrow = [];

    // building arrows
    for (let i = 0; i < points.length - 1; i++) {
      // arrow position
      const position = new Vector2()
        .lerpVectors(points[i].position, points[i + 1].position, 0.5)
        .multiplyScalar(SHAPE_TO_CANVAS_SCALE);

      // arrow rotation
      const segmentVector = new Vector2().subVectors(points[i].position, points[i + 1].position);
      let angle = angleBetweenVectors(segmentVector, new Vector2(1, 0)) * RAD2DEG;
      if (laneDirection !== LaneDirection.LEFT_RIGHT)
        angle += laneDirection === LaneDirection.LEFT ? 180 : 0;

      const arrow = {
        index: i,
        x: position.x,
        y: position.y,
        rotation: angle,
        image: laneDirection === LaneDirection.LEFT_RIGHT ? arrowBoth : arrowRight,
      };
      // @ts-expect-error strictNullChecks. Pls fix me
      outArrow.push(arrow);
    }

    setArrows(outArrow);
  }, [arrowBoth, arrowRight, laneDirection, points, setLineDirection]);

  const handleArrowEnter = (e: KonvaEventObject<MouseEvent>) => {
    setObjectUnderMouseId(e.target.id());
    // @ts-expect-error strictNullChecks. Pls fix me
    if (selected) setHover(e.target);
  };

  const handleArrowLeave = (e: KonvaEventObject<MouseEvent>) => {
    if (hover === e.target) setHover(null);
  };

  const onDragStart = useCallback((e: KonvaEventObject<DragEvent>) => {
    const angledHighwayProxyGroup =
      e.target.getType() === 'Group' && e.target.name() === ElementName.POINTS_SHAPE_PROXY_GROUP
        ? e.target
        : e.target.getParent();

    const bbSubstitute = angledHighwayProxyGroup.children.find(
      (item) => item.name() === ElementName.POINTS_SHAPE_PROXY_BOX,
    ) as Node;

    // if drag is initiated by this shape, mark bb substitute node as drag leader
    bbSubstitute.setAttr('isDragLeader', mousingDown.current);

    if (e.target.name() === ElementName.POINTS_SHAPE_PROXY_GROUP) bbSubstitute.fire('dragstart', e);
  }, []);

  const onDragMove = useCallback((e: KonvaEventObject<DragEvent>) => {
    if (e.target.name() !== ElementName.POINTS_SHAPE_PROXY_GROUP) return;

    // @ts-expect-error strictNullChecks. Pls fix me
    const bbSubstitute = (e.target as GroupType).children.find(
      (item) => item.name() === ElementName.POINTS_SHAPE_PROXY_BOX,
    );

    // @ts-expect-error strictNullChecks. Pls fix me
    bbSubstitute.fire('dragmove', e);
  }, []);

  const isSelected = useMemo(() => selectedShapesIds.includes(id), [id, selectedShapesIds]);

  return (
    <>
      {proxyBox && (
        <Group
          draggable={draggable}
          name={ElementName.POINTS_SHAPE_PROXY_GROUP}
          onDragStart={onDragStart}
          onDragMove={onDragMove}
          onDragEnd={() => {}}
          onMouseDown={() => {
            mousingDown.current = true;
          }}
          onMouseUp={() => {
            mousingDown.current = false;
          }}
        >
          <Rect
            id={id}
            name={ElementName.POINTS_SHAPE_PROXY_BOX}
            x={proxyBox.x * SHAPE_TO_CANVAS_SCALE + PROXY_STROKE_WIDTH / 2}
            y={proxyBox.y * SHAPE_TO_CANVAS_SCALE + PROXY_STROKE_WIDTH / 2}
            width={proxyBox.width * SHAPE_TO_CANVAS_SCALE - PROXY_STROKE_WIDTH}
            height={proxyBox.height * SHAPE_TO_CANVAS_SCALE - PROXY_STROKE_WIDTH}
            listening={isSelected}
            fillEnabled={false}
            stroke='transparent'
            strokeWidth={PROXY_STROKE_WIDTH}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
          <Line
            id={id}
            name={ElementName.POINTS_SHAPE_PROXY_LINE}
            points={canvasPoints}
            stroke='#0000'
            strokeWidth={width * SHAPE_TO_CANVAS_SCALE}
            lineCap='butt'
            lineJoin='round'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </Group>
      )}

      {/* lane direction arrow  */}
      {showArrows &&
        arrows &&
        arrows.map((arrow) => (
          <Rect
            // @ts-expect-error strictNullChecks. Pls fix me
            id={`${ARROW_ID_PREFIX}${arrow.index}`}
            name={ElementName.HIGHWAY_ARROW}
            // @ts-expect-error strictNullChecks. Pls fix me
            key={arrow.index}
            // @ts-expect-error strictNullChecks. Pls fix me
            x={arrow.x}
            // @ts-expect-error strictNullChecks. Pls fix me
            y={arrow.y}
            // @ts-expect-error strictNullChecks. Pls fix me
            width={arrow.image.width}
            // @ts-expect-error strictNullChecks. Pls fix me
            height={arrow.image.height}
            // @ts-expect-error strictNullChecks. Pls fix me
            fillPatternImage={arrow.image}
            // @ts-expect-error strictNullChecks. Pls fix me
            rotation={arrow.rotation}
            // @ts-expect-error strictNullChecks. Pls fix me
            offsetX={arrow.image.width / 2}
            // @ts-expect-error strictNullChecks. Pls fix me
            offsetY={arrow.image.height / 2}
            scaleX={3}
            scaleY={3}
            onClick={handleArrowClick}
            onMouseEnter={handleArrowEnter}
            onMouseLeave={handleArrowLeave}
          />
        ))}

      {/* direction arrow tooltip */}
      {selected && hover && (
        // @ts-expect-error strictNullChecks. Pls fix me
        <Label x={hover.x()} y={hover.y() + hover.height() + 10}>
          <Tag
            fill={theme.palette.primary.light}
            pointerDirection='up'
            pointerWidth={zoomifyValue(10)}
            pointerHeight={zoomifyValue(10)}
            lineJoin='round'
          />
          <Text
            text={t('properties.highway.tooltips.lane_direction', 'Change lane direction')}
            fontFamily='Azeret Mono'
            fontSize={zoomifyValue(18)}
            padding={10}
            fill='black'
          />
        </Label>
      )}
    </>
  );
};
