import { AreaShape, HighwayShape, ObstacleShape } from '@recoil/shape';
import { CircleElement, Color, Element, RectElement } from '@thive/canvas';
import { Vector3 } from 'three';

import { groupByGate } from '@/modules/artefacts';
import { END_POINT_ID_SUFFIX } from '@modules/commissioning/helpers/constants';
import { DTShape, ShapeType } from '@modules/common/types/shapes';
import {
  GeneratedFloorPlanArtefacts,
  GeneratedLoadPosition,
  GeneratedLocation,
  Rectangle,
} from '@modules/floorplanService';
import { CHECKPOINT_COLORS, GATE_COLORS, LOAD_COLORS } from '../../commissioning/mappers/areas/colors';
import { createShapeElement } from './shape';

export const createAreaElements = (
  shape: AreaShape | HighwayShape | ObstacleShape,
  artifacts: GeneratedFloorPlanArtefacts,
  areaSelectable: boolean,
  gatesSelectable: boolean
) => [createShapeElement(shape, !areaSelectable), ...createGateElements(shape, artifacts, !gatesSelectable)];

const createGateElements = (shape: DTShape, artifacts: GeneratedFloorPlanArtefacts, disabled = false) => {
  const groupedArtifacts = groupByGate(artifacts);
  const elements: Element[] = [];

  groupedArtifacts.forEach((value) => {
    elements.push(
      createLocationElement(shape.type, value.station, shape.id, disabled),
      createEndPointElement(shape.type, value.point, value.station.locationName),
    );

    if (value.loads) {
      elements.push(
        ...createLoadPositionElements(shape.type, value.loads, value.station.locationName),
      );
    }
  });

  return elements;
};

const createLocationElement = (
  shapeType: ShapeType,
  location: GeneratedLocation,
  parentId: string,
  disabled = false,
): RectElement => ({
  id: location.locationName,
  type: 'Rect',
  name: location.locationName,
  // @ts-expect-error strictNullChecks. Pls fix me
  position: new Vector3(location.rectangle.centerX, location.rectangle.centerY, 1),
  parentId,
  // @ts-expect-error strictNullChecks. Pls fix me
  rotation: new Vector3(0, 0, location.rectangle.angleInDegrees),
  // @ts-expect-error strictNullChecks. Pls fix me
  size: createSizeVector(location.rectangle),
  fill: Color.fromHex(GATE_COLORS[shapeType]),
  interactivity: {
    selectable: !disabled,
    rotatable: false,
    draggable: false,
  },
});

const createEndPointElement = (
  shapeType: ShapeType,
  location: GeneratedLocation,
  parentId: string,
): CircleElement => ({
  id: `${location.locationName}${END_POINT_ID_SUFFIX}`,
  name: `${location.locationName}${END_POINT_ID_SUFFIX}`,
  type: 'Circle',
  parentId,
  center: new Vector3(location.x, location.y, 3),
  radius: 750 / 2,
  fill: Color.fromHex(CHECKPOINT_COLORS[shapeType]),
  interactivity: {
    selectable: false,
    rotatable: false,
    draggable: false,
  },
});

const createLoadPositionElements = (
  shapeType: ShapeType,
  loadPositions: GeneratedLoadPosition[],
  parentId: string,
): RectElement[] =>
  loadPositions.map((item) => createLoadPositionElement(shapeType, item, parentId));

const createLoadPositionElement = (
  shapeType: ShapeType,
  loadPosition: GeneratedLoadPosition,
  parentId: string,
): RectElement => ({
  id: loadPosition.loadPositionName,
  type: 'Rect',
  name: loadPosition.loadPositionName,
  position: new Vector3(loadPosition.rectangle.centerX, loadPosition.rectangle.centerY, 2),
  parentId,
  rotation: new Vector3(0, 0, loadPosition.rectangle.angleInDegrees),
  size: createSizeVector(loadPosition.rectangle),
  fill: Color.fromHex(LOAD_COLORS[shapeType]),
  interactivity: {
    selectable: false,
    rotatable: false,
    draggable: false,
  },
});

const createSizeVector = ({ width, length }: Rectangle) =>
  length > width ? new Vector3(length, width) : new Vector3(width, length);
