import { useCanvasStore } from '@/modules/canvas';
import { ElementSelectedEventArgs } from '@thive/canvas';
import { useCallback, useEffect } from 'react';
import { ConnectionShape, UnitShapeId } from '../helpers/types';
import { use3TE25LayoutStore } from '../store';
import { useSelectionStore } from '../store/useSelectionStore';
import { useConnectionUpdate } from './useConnectionUpdate';
import { useHighlightUnitId } from './useHighlighUnitId';

enum ConnectionOperation {
  Delete,
  Keep,
}

export const useInteraction = () => {
  const { instance } = useCanvasStore();
  const { deleteConnections, keepConnections } = useConnectionUpdate();
  useHighlightUnitId();

  const selectHandler = useCallback((e: ElementSelectedEventArgs) => {
    const layout = use3TE25LayoutStore.getState();
    const selection = useSelectionStore.getState();

    const selectedElements = e.elements;
    const connections: ConnectionShape[] = [];
    const unitIds: UnitShapeId[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const element of selectedElements) {
      if (layout.connectionShapes.has(element.id))
        // @ts-expect-error strictNullChecks. Pls fix me
        connections.push(layout.connectionShapes.get(element.id));
      else if (layout.unitShapes.has(element.id)) unitIds.push(element.id);
    }
    selection.setConnections(connections);
    selection.setUnitIds(unitIds);
  }, []);

  const toggleSelectedConnections = useCallback(
    (operation: ConnectionOperation) => {
      const selection = useSelectionStore.getState();
      const ids = selection.connections.map((shape) => shape.id);

      if (operation === ConnectionOperation.Delete) {
        deleteConnections(ids);
      } else if (operation === ConnectionOperation.Keep) {
        keepConnections(ids);
      }
    },
    [deleteConnections, keepConnections],
  );

  const keyboardHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === '1') toggleSelectedConnections(ConnectionOperation.Keep);
      else if (e.key === '2') toggleSelectedConnections(ConnectionOperation.Delete);
    },
    [toggleSelectedConnections],
  );

  useEffect(() => {
    if (!instance) return;

    instance.on('ElementEvents.Select', selectHandler);
    window.addEventListener('keydown', keyboardHandler);

    return () => {
      window.removeEventListener('keydown', keyboardHandler);
      instance.off('ElementEvents.Select', selectHandler);
    };
  }, [instance, keyboardHandler, selectHandler]);
};
