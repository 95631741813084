import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { simulationAtom } from './simulationAtom';

/**
 * Whether to show an input or a range slider
 */
export const isVehicleRangeSelector = selector<boolean>({
  key: 'simulations/draft/isVehicleRange',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: ({ get }) => get(simulationAtom).vehicleTypes.at(0).range.length > 1,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
