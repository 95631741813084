import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Image, Rect } from 'react-konva';
import { useRecoilValue } from 'recoil';
import useImage from 'use-image';

import {
  AUTOSCALE_DEFAULT,
  AUTOSCALE_MAX,
  AUTOSCALE_MIN,
  AUTOSCALE_NAME,
} from '@/modules/workspace/helpers/konva';
import { getHighwayDirection } from '@/modules/workspace/helpers/shape';
import { useColors } from '@/modules/workspace/hooks';
import { useAutoScale } from '@/modules/workspace/hooks/useAutoScale';
import { AreaError, HighwayDirection, LaneDirection } from '@modules/common/types/shapes';
import { InvalidShape } from '@modules/workspace/components';
import { supportedVehiclesWidthSelector, enabledVehicleIdsState } from '@/modules/vehicles';
import { AREA_RENDER_ERROR } from '@/modules/workspace/types/errors';
import { theme } from '@modules/common/components/theme';

export type HighwayRenderProps = {
  shapeId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  r: number;
  margin: number;
  gap: number;
  laneDirection: LaneDirection;
  supportedVehicleIds: string[];
};

const customAttr = {
  [AUTOSCALE_MAX]: 5,
  [AUTOSCALE_MIN]: 0.5,
  [AUTOSCALE_DEFAULT]: 0.5,
};

const ARROW_GAP = 15;

const HighwayRenderComponent: React.FC<HighwayRenderProps> = ({
  shapeId,
  x: shapeX,
  y: shapeY,
  width: shapeWidth,
  height: shapeHeight,
  r: shapeR,
  margin,
  gap,
  laneDirection,
  supportedVehicleIds
}) => {
  // recoil state
  const vehicleIds = useRecoilValue(enabledVehicleIdsState);
  const vehicleWidth = useRecoilValue(
    supportedVehiclesWidthSelector(!supportedVehicleIds || supportedVehicleIds.length === 0 ?vehicleIds : supportedVehicleIds),
  );
  useAutoScale();

  // local state
  const [laneBoxes, setLaneBoxes] = useState([]);
  const [arrows, setArrows] = useState([]);
  const [error, setError] = useState<AreaError | null>(null);

  const [arrowImage] = useImage('/chevron-double-right.svg');
  const { shapeColor } = useColors(shapeId);
  const { t } = useTranslation(['errors']);

  useEffect(() => {
    if (!vehicleWidth) return;

    const outLane = [];
    const outArrow = [];

    const direction = getHighwayDirection(shapeWidth, shapeHeight);

    // space that the lanes can take up
    let length = 0;
    if (direction === HighwayDirection.LEFT_RIGHT) {
      length = shapeHeight;
    } else {
      length = shapeWidth;
    }

    let amount = 0;

    while (amount * vehicleWidth + (amount - 1) * gap + 2 * margin <= length) {
      amount += 1;
    }
    amount -= 1;

    if (amount == 0 || gap < 0 || margin < 0) {
      setError(AreaError.AreaToSmall);
      setLaneBoxes([]);
      setArrows([]);
      return;
    }

    const laneSpace = amount * vehicleWidth + (amount - 1) * gap;

    // set parameters for the lanes
    let hSize = 0;
    let vSize = 0;
    let hOffset = 0;
    let vOffset = 0;
    let hStepSize = 0;
    let vStepSize = 0;
    if (direction === HighwayDirection.LEFT_RIGHT) {
      hSize = shapeWidth;
      vSize = vehicleWidth;
      vOffset = shapeHeight / 2 - laneSpace / 2;
      vStepSize = vehicleWidth + gap;
    } else {
      hSize = vehicleWidth;
      vSize = shapeHeight;
      hOffset = shapeWidth / 2 - laneSpace / 2;
      hStepSize = vehicleWidth + gap;
    }

    // building lane boxes
    for (let i = 0; i < amount; ++i) {
      const vehicle = {
        id: `lane_${i}`,
        x: hOffset + i * hStepSize,
        y: vOffset + i * vStepSize,
        w: hSize,
        h: vSize,
      };
      // @ts-expect-error strictNullChecks. Pls fix me
      outLane.push(vehicle);
    }
    setLaneBoxes(outLane);

    // number of arrows
    let numberOfArrows = 1;
    if (laneDirection === LaneDirection.LEFT_RIGHT) {
      numberOfArrows = 2;
    }

    // rotation of arrows
    let rotationOffset = 90;
    if (direction !== HighwayDirection.LEFT_RIGHT) {
      rotationOffset = 0;
    }
    if (laneDirection === LaneDirection.RIGHT) {
      rotationOffset += 180;
    }

    // arrow offset
    let arrowOffsetX = 0;
    let arrowOffsetY = 0;
    if (direction === HighwayDirection.LEFT_RIGHT) {
      arrowOffsetX = ARROW_GAP;
    } else {
      arrowOffsetY = -ARROW_GAP;
    }

    // building arrows
    if (numberOfArrows === 2) {
      const arrow1 = {
        id: `arrow_1`,
        x: -arrowOffsetX,
        y: -arrowOffsetY,
        rotation: 90 + rotationOffset,
      };
      const arrow2 = {
        id: `arrow_2`,
        x: arrowOffsetX,
        y: arrowOffsetY,
        rotation: 270 + rotationOffset,
      };
      // @ts-expect-error strictNullChecks. Pls fix me
      outArrow.push(arrow1, arrow2);
    } else {
      const arrow = {
        id: `arrow_1`,
        x: 0,
        y: 0,
        rotation: 90 + rotationOffset,
      };
      // @ts-expect-error strictNullChecks. Pls fix me
      outArrow.push(arrow);
    }
    setArrows(outArrow);
    setError(null);
  }, [margin, gap, shapeHeight, shapeWidth, vehicleWidth, laneDirection]);

  return (
    <Group x={shapeX} y={shapeY} rotation={shapeR}>
      {!error ? (
        <Rect
          width={shapeWidth}
          height={shapeHeight}
          fill={shapeColor}
          listening={false}
          strokeEnabled={false}
        />
      ) : shapeWidth !== 0 || shapeHeight !== 0 ? (
        <InvalidShape
          width={shapeWidth}
          height={shapeHeight}
          message={t(AREA_RENDER_ERROR[error].i18nMessageKey)}
        />
      ) : null}

      {laneBoxes &&
        laneBoxes.map((v) => (
          <Rect
            // @ts-expect-error strictNullChecks. Pls fix me
            key={v.id}
            // @ts-expect-error strictNullChecks. Pls fix me
            x={v.x}
            // @ts-expect-error strictNullChecks. Pls fix me
            y={v.y}
            // @ts-expect-error strictNullChecks. Pls fix me
            width={v.w}
            // @ts-expect-error strictNullChecks. Pls fix me
            height={v.h}
            fill={theme.palette.areas.laneColor}
            listening={false}
            strokeEnabled={false}
          />
        ))}
      {arrows && arrowImage && (
        <Group x={shapeWidth / 2} y={shapeHeight / 2} name={AUTOSCALE_NAME} {...customAttr}>
          {arrows.map((arrow) => (
            <Image
              image={arrowImage}
              // @ts-expect-error strictNullChecks. Pls fix me
              key={arrow.id}
              // @ts-expect-error strictNullChecks. Pls fix me
              rotation={arrow.rotation}
              // @ts-expect-error strictNullChecks. Pls fix me
              x={arrow.x}
              // @ts-expect-error strictNullChecks. Pls fix me
              y={arrow.y}
              offsetX={arrowImage.width / 2}
              offsetY={arrowImage.height / 2}
              listening={false}
              strokeEnabled={false}
            />
          ))}
        </Group>
      )}
    </Group>
  );
};

export const HighwayRender = memo(HighwayRenderComponent);
