import { selector } from 'recoil';
import { TemplateType } from '@/modules/common/types/templating';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import workspaceAtom, { WorkspaceAtom } from './atom';

const placingTypeSelector = selector<TemplateType>({
  key: 'placingType',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: ({ get }) => get<WorkspaceAtom>(workspaceAtom).placingType,
  set: ({ get, set }, newValue) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    set(workspaceAtom, { ...get(workspaceAtom), placingType: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default placingTypeSelector;
