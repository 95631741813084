import { Stage as StageElement } from 'konva/lib/Stage';
import { selector } from 'recoil';
import { domReferencesAtom, WorkspaceDomReferences } from '../atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';

export const stageRefSelector = selector<StageElement>({
  key: 'stageRefSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: ({ get }) => get<WorkspaceDomReferences>(domReferencesAtom).stage,
  set: ({ get, set }, newValue: StageElement) => {
    set(domReferencesAtom, { ...get(domReferencesAtom), stage: newValue });
  },
  dangerouslyAllowMutability: true,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
