import { createProcessRelatedSimFlowsMap } from '@/modules/common/helpers/shapes';
import { ProcessAreaOneEp } from '@/modules/common/types/shapes';
import { LayoutFlow } from '@/modules/flows/types';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { FloorPlan } from '@modules/common/types/floorPlan';
import { DependentFlow, Flow, OrdersPerHour, Simulation } from './types';

/**
 * Merges simulations with floor plan versions
 */
export const mergeVersions = (simulations: Simulation[], versions: FloorPlan[]) =>
  simulations.map((simulation) => ({
    ...simulation,
    details: {
      ...simulation.details,
      floorPlanVersion: versions.find((item) => item.id === simulation.floorPlanId)?.version,
    },
  }));

/**
 * Randomize update time
 */
export const randomizeTimeout = () => {
  const from = 1000;
  const to = 3000;

  return from + Math.floor((to - from) * Math.random());
};

export const convertSimulationFlowToDraftFlow = (hours: number, simulationFlows: LayoutFlow[]): Flow[] =>
  // @ts-expect-error strictNullChecks. Pls fix me
  simulationFlows
    .map((flow) => {
      const ordersPerHours: OrdersPerHour[] = [];
      if (flow.loadProfile) {
        populateOrdersPerHoursFromGivenLoadProfile(ordersPerHours, hours, flow.loadProfile);
        zeroFillUnspecifiedHours(
          ordersPerHours,
          hours,
          flow.loadProfile[flow.loadProfile.length - 1] ?? 30,
        );
      } else {
        flatFillOrdersPerHours(ordersPerHours, hours, flow.totalNumLoads);
      }

      return {
        id: flow.id,
        name: flow.name,
        deliveryName: flow.targetName,
        intakeName: flow.sourceName,
        ordersPerHours,
        loadsCount: flow.totalNumLoads, // should be removed (obsolete) when new Simulation.Api support orderprofile
        vehicleLimit: flow.vehicleLimit,
      };
    })
    .filter((item) => {
      if (item.ordersPerHours?.length > 0) {
        return item.ordersPerHours.reduce((acc, order) => acc + order.loadsCount, 0) > 0;
      }
      return item.loadsCount > 0;
    });

/**
 * @description populates ordersPerHour in place, with specified loadProfile
 */
const populateOrdersPerHoursFromGivenLoadProfile = (
  ordersPerHours: OrdersPerHour[],
  maxHours: number,
  loadProfile: number[],
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (let hour = 1; hour <= Math.min(maxHours, loadProfile.length); hour += 1) {
    ordersPerHours.push({
      hour,
      loadsCount: loadProfile[hour - 1],
    });
  }
};

/**
 * @description populates ordersPerHours in place, up to maxHours, using same loadsPerHour for each added hour
 */
const flatFillOrdersPerHours = (
  ordersPerHours: OrdersPerHour[],
  maxHours: number,
  loadsPerHour: number,
) => {
  for (let hour = 1; hour <= maxHours; ++hour) {
    ordersPerHours.push({
      hour,
      loadsCount: loadsPerHour,
    });
  }
};

/**
 * @description populates missing ordersPerHours in place, up to maxHours, with 0
 */
const zeroFillUnspecifiedHours = (
  ordersPerHours: OrdersPerHour[],
  maxHours: number,
  loadsPerHour: number,
) => {
  for (let hour = ordersPerHours.length + 1; hour <= maxHours; ++hour) {
    ordersPerHours.push({
      hour,
      loadsCount: loadsPerHour,
    });
  }
};

/**
 * @description creates dependent flows between processing inbound and outbound flows
 */
export const createDependentFlows = (
  processAreas: ProcessAreaOneEp[],
  processTwoEndPointAreas: ProcessTwoEPShape[],
  flows: LayoutFlow[],
): DependentFlow[] => {
  const processRelatedFlowMap = createProcessRelatedSimFlowsMap(processAreas, processTwoEndPointAreas, flows);

  // map each inbound to each outbound flow
  const dependentFlows: DependentFlow[] = [];
  processRelatedFlowMap.forEach(({ inbound, outbound, processingTime }) => {
    inbound.forEach((flow) => {
      const flowFromName = flow;
      const outboundFlowsAmount = outbound.length;
      for (let i = 0; i < outboundFlowsAmount; i++) {
        const flowToName = outbound[i];
        dependentFlows.push({
          name: `dep-flow-${flowFromName}-${flowToName}`,
          flowFromName,
          flowToName,
          processingTime,
        });
      }
    });
  });

  return dependentFlows;
};
