import { Vector2 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';

import { BoundingBox } from '@/helpers/types';
import { MaterialType } from '@/modules/common/types/shapes';
import { getShapeColor, hexToRgb } from '@/modules/workspace/helpers/colors';
import { angleBetweenVectors } from '@/modules/workspace/helpers/shape';
import {
  WallShape
} from '@/store/recoil/shape';
import { SOURCE_NAME } from '../consts';
import { Type, Variant } from '../enum';
import { VisualizationObject } from '../types';
import { getMaterialTexture, mapPositionTypeToAreaType } from './floorPlan';

export function createWallShape(
  shape: WallShape,
  workspaceBox: BoundingBox,
): VisualizationObject[] {
  const segments: VisualizationObject[] = []

  const data: any = {
    AutoTile: true,
    Variant: getMaterialTexture(shape.parameters.material as MaterialType),
    ScaleX: 2,
    ScaleY: 2,
  }; 

  const hexColor = getShapeColor(shape.type, true);
  const stdColor = hexToRgb(hexColor);
  // @ts-expect-error strictNullChecks. Pls fix me
  const color = { Alpha: stdColor.a, R: stdColor.r, G: stdColor.g, B: stdColor.b };

  for (let i = 0; i < shape.properties.controlPoints.length - 1; i++) {
    const startControlPoint = shape.properties.controlPoints[i].position
    const startPoint = new Vector2(startControlPoint.x - workspaceBox.x, workspaceBox.height - (startControlPoint.y - workspaceBox.y))
    const endControlPointendPos = shape.properties.controlPoints[i + 1].position
    const endPoint = new Vector2(endControlPointendPos.x - workspaceBox.x, workspaceBox.height - (endControlPointendPos.y - workspaceBox.y))
    const vector = new Vector2().subVectors(endPoint, startPoint)
    const angle = angleBetweenVectors(vector, new Vector2(0,1)) * RAD2DEG
    
    segments.push({
      Type: Type.Primitive,
      Variant: Variant.Rectangle,
      Position: { X: startPoint.x, Y: startPoint.y, Z: 0 },
      Scale: { X: shape.parameters.width, Y: vector.length(), Z: shape.parameters.height },
      Rotation: { X: 0, Y: 0, Z: angle },
      Source: SOURCE_NAME,
      Name: `${shape.name}-${i}`,
      Color: color,
      Tags: [mapPositionTypeToAreaType(shape.type)],
      Data: data,
    })
  }

  return segments;
}
