import { IconButton, Stack, Typography } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { AddIcon, RemoveIcon } from '@/assets/icons';
import { setupPropertyPanelTheme, theme } from '@/modules/common/components/theme';
import { StyledTooltip } from '@modules/common/components/StyledTooltip';
import { modeSelector } from '@modules/common/store/workspace';
import { LoadingType, WorkspaceMode } from '@modules/common/types/general';
import { PreValidationAspect, useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { useVehicleTypes } from '@modules/setup/hooks';
import { ThemeProvider } from '@mui/system';
import {
  enabledVehicleIdsState,
  enabledVehiclesSelector,
  useVehicleDependencyManager,
} from '@/modules/vehicles';
import { VehicleTypeSelectDialog } from './VehicleTypeSelectDialog';
import { OverflowTypography } from '@/modules/common/components/OverflowTypography';
import { AssetPanel } from '@/modules/vehicles/components/AssetPanel';
import GridIcon from '@/assets/icons/GridIcon';

import { useFloorPlanState } from '@/modules/floorplan';
import { useLoading } from '@/modules/common/hooks';
import { useTrackFloorplanMetric } from '@/modules/insight/hooks/useTrackFloorplanMetric';

const preValidationSkipOptions = [
  PreValidationAspect.DISCONNECTED_AREA_IDS,
  PreValidationAspect.CONNECTION_LACKING_ROADS,
  PreValidationAspect.FLOWLESS_AREAS,
  PreValidationAspect.DISCONNECTED_FLOW_STOPS,
  PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
];

export const VehicleTypes: React.FC = () => {
  const enabledVehicleIds = useRecoilValue(enabledVehicleIdsState);
  const enabledVehicles = useRecoilValue(enabledVehiclesSelector);
  const { t } = useTranslation(['interface']);
  const { setVehicleIds, deleteVehicleIds } = useVehicleTypes();
  const { hideLoader, showLoader } = useLoading();
  const {
    ensureVehicleAssetAvailability,
    cleanUpRedundantAssets,
    ensureVehicleDetailsAvailability,
  } = useVehicleDependencyManager();
  const [open, setOpen] = useState(false);
  const mode = useRecoilValue(modeSelector);
  const { runPreValidation } = useRunPreValidation();
  const [assetsVehicleId, setAssetsVehicleId] = useState('');
  const panelRef = useRef<HTMLDivElement>();
  const { saveFloorPlan } = useFloorPlanState();
  const trackFloorplanMetric = useTrackFloorplanMetric();

  const onSelect = useCallback(
    async (newEnabledVehicleIds: string[]) => {
      const startTime = performance.now();
      setOpen(false);
      showLoader(LoadingType.TRANSPARENT);

      await ensureVehicleAssetAvailability(newEnabledVehicleIds);
      await ensureVehicleDetailsAvailability(newEnabledVehicleIds);
      await setVehicleIds(newEnabledVehicleIds);
      await cleanUpRedundantAssets();
      await saveFloorPlan();
      hideLoader();
      trackFloorplanMetric('SELECT_VEHICLE', startTime);
      runPreValidation(preValidationSkipOptions);
    },
    [
      runPreValidation,
      setVehicleIds,
      ensureVehicleAssetAvailability,
      ensureVehicleDetailsAvailability,
      cleanUpRedundantAssets,
      hideLoader,
      showLoader,
      saveFloorPlan,
      trackFloorplanMetric,
    ],
  );

  const onDeleteClick = useCallback(
    async (id: string) => {
      await deleteVehicleIds([id]);
      runPreValidation(preValidationSkipOptions);
    },
    [deleteVehicleIds, runPreValidation],
  );

  return (
    <>
      {assetsVehicleId && (
        <AssetPanel
          // @ts-expect-error strictNullChecks. Pls fix me
          anchor={panelRef}
          vehicleId={assetsVehicleId}
          close={() => setAssetsVehicleId('')}
        />
      )}
      <ThemeProvider theme={setupPropertyPanelTheme}>
        {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
        <Stack spacing={1} sx={{ px: 2 }} ref={panelRef}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography
              align='center'
              sx={{
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {t('setup.floorPlan.vehicle_types.vehicles_title', 'Vehicle models', {
                count: enabledVehicles.length,
              })}
            </Typography>
            <StyledTooltip title={t('setup.floorPlan.vehicle_types.add_button.title')}>
              <IconButton
                disabled={mode !== WorkspaceMode.EDITABLE}
                onClick={() => setOpen(true)}
                sx={{ padding: 0, border: 'none' }}
              >
                <AddIcon data-testid='add-icon' />
              </IconButton>
            </StyledTooltip>
          </Stack>
          <Stack gap={1}>
            {enabledVehicles.map((item) => (
              <FloorPlanEnabledVehicleItem
                key={item.id}
                name={item.name}
                vehicleId={item.id}
                imageUrl={item.image.url}
                deletable={enabledVehicles.length > 1}
                deleteBtnTooltipText={t('setup.floorPlan.vehicle_types.delete_button.title')}
                onDeleteClick={() => onDeleteClick(item.id)}
                assetsVehicleId={assetsVehicleId}
                onFileInteractionClick={setAssetsVehicleId}
              />
            ))}
          </Stack>
        </Stack>
      </ThemeProvider>
      <VehicleTypeSelectDialog
        open={open}
        selectedVehiclesIds={enabledVehicleIds}
        onSelect={onSelect}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

type Props = {
  name: string;
  vehicleId: string;
  imageUrl: string;
  deleteBtnTooltipText: string;
  onDeleteClick: () => void;
  deletable: boolean;
  onFileInteractionClick: (id: string) => void;
  assetsVehicleId: string;
};

const containerSx = {
  height: '48px',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: 0,
  border: '1px solid transparent',
};

const containerInteractiveSx = {
  '&:hover': {
    borderColor: 'divider',
  },
};

const advancedSettingsIconBtnStyle = {
  border: 0,
  height: '10px',
  p: '0px',
  '&:hover': {
    color: theme.palette.neutral.darker,
  },
  '&:active:hover': {
    backgroundColor: theme.palette.shades.light,
    color: theme.palette.neutral.darker,
    borderColor: 'transparent',
  },
};

export const FloorPlanEnabledVehicleItem = ({
  name,
  vehicleId,
  imageUrl,
  onDeleteClick,
  deleteBtnTooltipText,
  deletable,
  onFileInteractionClick,
  assetsVehicleId,
}: Props) => (
  <Stack sx={{ ...containerSx, ...(deletable ? containerInteractiveSx : {}) }}>
    <Stack direction='row' alignItems='center' maxWidth='80%' gap={1}>
      <img src={imageUrl} alt={name} width={32} height={32} />
      <OverflowTypography
        sx={{
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        {name}
      </OverflowTypography>
    </Stack>
    <Stack direction='row' alignItems='center' gap={1}>
      <StyledTooltip
        placement='bottom'
        title={
          <Trans
            i18nKey='setup.floorPlan.vehicle_types.asset_settings_button.title'
            ns='interface'
            components={{
              s: <span />,
            }}
          />
        }
      >
        <IconButton
          color={vehicleId === assetsVehicleId ? 'primary' : 'default'}
          onClick={() => onFileInteractionClick(vehicleId)}
          className={vehicleId === assetsVehicleId ? 'active btn-icon' : 'btn-icon'}
          sx={{
            ...advancedSettingsIconBtnStyle,
            color:
              vehicleId === assetsVehicleId
                ? theme.palette.primary.main
                : theme.palette.shades.main,
          }}
        >
          <GridIcon />
        </IconButton>
      </StyledTooltip>
      {deletable && (
        <StyledTooltip title={deleteBtnTooltipText}>
          <IconButton
            sx={{ marginLeft: 'auto', padding: 0, border: 'none' }}
            onClick={onDeleteClick}
            aria-label='delete property item'
          >
            <RemoveIcon />
          </IconButton>
        </StyledTooltip>
      )}
    </Stack>
  </Stack>
);
