import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type SnackbarStore = {
  message: string;
};

type SnackbarActions = {
  showSnackbar(message: string): void;
  resetMessage(): void;
};

const INITIAL_STATE: SnackbarStore = {
  // @ts-expect-error strictNullChecks. Pls fix me
  message: null,
};

export const useSnackbarStore = create<SnackbarStore & SnackbarActions>()(
  devtools(
    (set) => ({
      ...INITIAL_STATE,

      showSnackbar: (message: string) => {
        set(
          {
            message,
          },
          undefined,
          {
            type: 'showSnackbar',
          },
        );
      },

      resetMessage: () => {
        set(
          {
            // @ts-expect-error strictNullChecks. Pls fix me
            message: null,
          },
          undefined,
          {
            type: 'resetMessage',
          },
        );
      },
    }),
    { store: 'snackbarStore', ...DEVTOOLS_OPTIONS },
  ),
);
