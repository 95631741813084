import { Button, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { MutableRefObject, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { PropertiesPopup } from '@/modules/common/components/PropertiesPopup';

const useConfirm = () => {
  const [promise, setPromise] = useState(null);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const { t } = useTranslation(['common']);
  
  const confirm = (title?: string, message?: string) => new Promise<boolean>((resolve, reject) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    setTitle(title);
    // @ts-expect-error strictNullChecks. Pls fix me
    setMessage(message);
    // @ts-expect-error strictNullChecks. Pls fix me
    setPromise({ resolve });
  });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    // @ts-expect-error strictNullChecks. Pls fix me
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    // @ts-expect-error strictNullChecks. Pls fix me
    promise?.resolve(false);
    handleClose();
  };

  type Props = {
    anchor: MutableRefObject<HTMLElement>;
    offset?: [number, number];
  };

  function Dialog({ anchor, offset }: Props) {
    return (
      <PropertiesPopup
        anchor={anchor}
        open={promise !== null}
        onClose={handleCancel}
        header={
          <PropertiesTitle
            fontSize={14}
            fontWeight={400}
            value={title}
          />
        }
        sx={{
          width: '320px',
          height: 'auto',
        }}
        offset={offset}
      >
        <Stack spacing='10px'>
          <Stack
            gap={1}
            sx={{
              maxHeight: '200px',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Typography>{message}</Typography>
            <Stack 
              direction='row'
              gap={0.5} 
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button onClick={handleConfirm}>{t('common:yes')}</Button>
              <Divider />
              <Button onClick={handleCancel} variant='secondary'>{t('common:cancel')}</Button>
            </Stack>
          </Stack>
        </Stack>
      </PropertiesPopup>
    );
  }

  return { confirm, Dialog };
};

export default useConfirm;