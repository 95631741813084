import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';

import {
  floorplanIdsBeingDeletedState,
  isDeleterOpenState,
  selectedFloorplanIdsState,
} from './store';
import { ConfirmDialog } from '../common/components/ConfirmDialog';
import {
  floorPlanGroupIdsSelector,
  floorPlanGroupSelector,
} from '@modules/floorplanAdmin/store/floorPlanGroup';
import { difference } from '@modules/common/helpers/array';
import { useFloorPlanGroupApi } from '@modules/api/hooks';
import { useSnackbarStore } from '../snackbar/store/useSnackbarStore';

export default function FloorplanDeleter() {
  const { t } = useTranslation(['interface', 'common']);
  const setDeleterIsOpen = useSetRecoilState(isDeleterOpenState);
  const selectedFloorplanIds = useRecoilValue(selectedFloorplanIdsState);
  const isDeleterOpen = useRecoilValue(isDeleterOpenState);
  const { showSnackbar } = useSnackbarStore();
  const { projectId } = useParams();
  const { remove } = useFloorPlanGroupApi();

  const handleClose = useCallback(() => {
    setDeleterIsOpen(false);
  }, [setDeleterIsOpen]);

  const handleConfirm = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        const deleteIds = await snapshot.getPromise(selectedFloorplanIdsState);

        set(floorplanIdsBeingDeletedState, new Set(deleteIds));
        set(isDeleterOpenState, false);

        await Promise.all([
          ...Array.from(deleteIds).map((id: string) =>
            // @ts-expect-error strictNullChecks. Pls fix me
            remove(projectId, id)
              .then((res) => ({ result: 'success', floorplanId: id, error: null }))
              .catch((e) => ({ result: 'failed', floorplanId: id, error: e })),
          ),
        ])
          .then((results) => {
            const failedReqs = results.filter((result) => result.result === 'failed');
            const succeededReqs = results.filter((result) => result.result === 'success');

            if (succeededReqs.length) {
              const deleteFloorPlanGroupIds = succeededReqs.map((req) => req.floorplanId);
              set(floorPlanGroupIdsSelector, (state) => difference(state, deleteFloorPlanGroupIds));
              deleteFloorPlanGroupIds.forEach((item) => reset(floorPlanGroupSelector(item)));
            }

            if (failedReqs.length) {
              const floorplansIds = failedReqs.map((req) => req.floorplanId);

              set(selectedFloorplanIdsState, new Set(floorplansIds));
              showSnackbar(t('errors:home.delete_floorplan'));

              return;
            }

            set(selectedFloorplanIdsState, new Set());

            showSnackbar(
              t('interface:home.delete_floorplan.notification.success', 'Deleted successfully'),
            );
          })
          .catch((e) => {
            console.error('Something unexpected happened. Error: ', e);
          })
          .finally(() => {
            set(floorplanIdsBeingDeletedState, new Set());
          });
      },
    [projectId, remove, showSnackbar],
  );

  return (
    <ConfirmDialog
      title={
        selectedFloorplanIds.size > 1
          ? t('interface:home.delete_floorplan.title.plural', {
              amount: selectedFloorplanIds.size,
            })
          : t('interface:home.delete_floorplan.title.single')
      }
      content={
        selectedFloorplanIds.size > 1
          ? t('interface:home.delete_floorplan.subtitle.plural', {
              amount: selectedFloorplanIds.size,
            })
          : t('interface:home.delete_floorplan.subtitle.single')
      }
      yesLabel={t('common:confirm', 'Confirm')}
      noLabel={t('common:cancel', 'Cancel')}
      open={isDeleterOpen}
      onClose={handleClose}
      onConfirm={handleConfirm}
      onDecline={handleClose}
    />
  );
}
