import { create } from 'zustand';
import { Vector3 } from 'three';
import { devtools } from 'zustand/middleware';

import { useCanvasStore } from '@modules/canvas';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';
import { calculateZoomInRange } from '@/modules/canvas/helpers';

import { map } from '../mappers/kollmorgen';
import { FLAT_REFLECTOR_SIZE, ROUND_REFLECTOR_SIZE } from '../helpers/constants';
import { deserialize } from '../converters/kollmorgen';
import { Reflector, ReflectorType } from '../helpers/types';

type KollmorgenState = {
  fileName: string;
  reflectors: Reflector[];
  roundIds: string[];
  flatIds: string[];
};

type KollmorgenActions = {
  deleteFile(): void;
  initialize(file?: File): Promise<void>;
  loadReflectors(reflectors: readonly Reflector[], fileName: string): void;
  setFile(file?: File): Promise<void>;
  scale(zoom: number): void;
  reset(): void;
};

const INITIAL_STATE: KollmorgenState = {
  // @ts-expect-error strictNullChecks. Pls fix me
  fileName: null,
  reflectors: [],
  roundIds: [],
  flatIds: [],
};

export const useKollmorgenStore = create<KollmorgenState & KollmorgenActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      deleteFile() {
        // @ts-expect-error strictNullChecks. Pls fix me
        useCanvasStore.getState().instance.removeElements(get().reflectors.map((item) => item.id));

        set(
          {
            // @ts-expect-error strictNullChecks. Pls fix me
            fileName: null,
            flatIds: [],
            reflectors: [],
            roundIds: [],
          },
          undefined,
          {
            type: 'deleteFile',
          },
        );
      },

      async initialize(file) {
        await get().setFile(file);
      },

      loadReflectors(reflectors: Reflector[], fileName: string) {
        const { instance } = useCanvasStore.getState();

        set(
          {
            fileName,
            reflectors,
            roundIds: reflectors
              .filter((item) => item.type === ReflectorType.Round)
              .map((item) => item.id),
            flatIds: reflectors
              .filter((item) => item.type !== ReflectorType.Round)
              .map((item) => item.id),
          },
          undefined,
          {
            type: 'loadReflectors',
          },
        );

        // @ts-expect-error strictNullChecks. Pls fix me
        instance.updateElements(map(reflectors));
        // @ts-expect-error strictNullChecks. Pls fix me
        get().scale(instance.getZoom());
      },

      reset() {
        set(INITIAL_STATE, undefined, {
          type: 'reset',
        });
      },

      scale(zoom: number) {
        const flatSize = calculateZoomInRange(zoom, 0, 200, FLAT_REFLECTOR_SIZE, 2000);
        const roundSize = calculateZoomInRange(zoom, 0, 200, ROUND_REFLECTOR_SIZE, 2000);

        // @ts-expect-error strictNullChecks. Pls fix me
        useCanvasStore
          .getState()
          .instance.updateTransformation(get().flatIds, {
            size: new Vector3(flatSize, flatSize, 1),
          })
          .updateTransformation(get().roundIds, {
            size: new Vector3(roundSize, roundSize, 1),
          });
      },

      async setFile(file?: File) {
        if (!file) {
          return;
        }

        const reflector = deserialize(await file.text());
        get().deleteFile();
        get().loadReflectors(reflector, file.name);
      },
    }),
    { store: 'commissioning/kollmorgenStore', ...DEVTOOLS_OPTIONS },
  ),
);
