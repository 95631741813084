import { AreaShapeParameters, DTShape } from '@/store/recoil/shape';
import { GateArtifacts } from './group';
import { isArea } from '@/modules/common/helpers/shapes';
import { StorageType } from '@/modules/common/types/storage';

/**
 * Filters out load positions on top of each other to avoid rendering uneccessary shapes
 */
export const filterRackLoadPositions = (
  shapes: DTShape[],
  artefacts: Map<string, GateArtifacts>,
) => {
  const shapeDict = new Map<string, DTShape>(shapes.map((item) => [item.id, item]));

  artefacts.forEach((item) => {
    if (item.loads) {
      const shape = shapeDict.get(item.shapeId);
      // @ts-expect-error strictNullChecks. Pls fix me
      const parameters = shape.parameters as AreaShapeParameters;
      // @ts-expect-error strictNullChecks. Pls fix me
      const minZ = Math.min(...item.loads.map((item) => item.rectangle.minZ));

      // @ts-expect-error strictNullChecks. Pls fix me
      if (isArea(shape.type) && parameters.storageType === StorageType.RACK) {
        item.loads = item.loads.filter((item) => item.rectangle.minZ === minZ);
      }
    }
  });

  return artefacts;
};
