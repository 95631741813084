import { selector } from 'recoil';
import shapeAtom from '../../shape/atom';
import { obstacleIdsAtom } from './obstacleIdsAtom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { setAtomFamilyWithIdsAtom } from '../../common/helper';
import { ObstacleShape } from '../../shape';

export const allObstaclesSelector = selector<ObstacleShape[]>({
  key: 'allObstaclesSelector',
  get: ({ get }) => {
    const output = [];
    get(obstacleIdsAtom).forEach((id) => {
      const obstacle = get(shapeAtom(id));
      // @ts-expect-error strictNullChecks. Pls fix me
      output.push(obstacle);
    });
    return output;
  },
  set: (getSetResetRecoilState, newObstacles: ObstacleShape[]) => {
    setAtomFamilyWithIdsAtom(getSetResetRecoilState, shapeAtom, obstacleIdsAtom, newObstacles);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
