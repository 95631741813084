import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';

import { DeadZone, SnapCollection, SnappingSide } from '@/modules/snapping/types';
import { allWallsSelector } from '@/store/recoil/shapes/wall';
import { BoundingBox } from '@helpers/types';
import { allAreasSelector } from '@recoil/shapes/area';
import { allHighwaysSelector } from '@recoil/shapes/highway';
import { allObstaclesSelector } from '@recoil/shapes/obstacle';
import { allPositionsState } from '@recoil/shapes/positions';
import { stageSelector, viewportSelector } from '@recoil/workspace';
import {
  calculateGuideLines,
  createSnapShape,
  findSnapPosition,
  getMultipleDrawingSides,
  getSingleDrawingSide,
} from '../helpers';

export const useSnappingCallbacks = () => {
  const getSnapShape = useCallback((boundingBox: BoundingBox, snapCollections: SnapCollection[]) => {
    if (snapCollections === null) {
      return null;
    }

    const newPosition = findSnapPosition(boundingBox, snapCollections, ['all']) ?? null;

    if (newPosition === null) {
      return null;
    }

    const snapShape = {
      x: newPosition.x,
      y: newPosition.y,
      width: boundingBox.width,
      height: boundingBox.height,
      r: 0,
      offsetX: newPosition.x - boundingBox.x,
      offsetY: newPosition.y - boundingBox.y,
      lines: newPosition.lines,
    };

    return snapShape;
  }, []);

  const getScalingSnapShape = useCallback(
    (
      boundingBox: BoundingBox,
      previousBoundingBox: BoundingBox,
      lineGuideStops: SnapCollection[],
      rotation = 0,
      multipleSidesMaching = false,
      // @ts-expect-error strictNullChecks. Pls fix me
      forceSides: SnappingSide[] = null,
      // @ts-expect-error strictNullChecks. Pls fix me
      deadzone: DeadZone = undefined,
    ) => {
      let sides =
        forceSides ||
        (multipleSidesMaching
          ? getMultipleDrawingSides(boundingBox, previousBoundingBox)
          : [getSingleDrawingSide(boundingBox, previousBoundingBox, -rotation)]);

      const newPosition =
        findSnapPosition(boundingBox, lineGuideStops, sides, deadzone, rotation) ?? null;

      if (newPosition === null) {
        return null;
      }

      return createSnapShape(sides, newPosition, boundingBox, -rotation);
    },
    [],
  );

  const getGuideLinesAsync = useRecoilCallback(
    ({ snapshot }) =>
      async (ids: string[], includeWalls = false) => {
        const [stageProps, viewport, areas, positions, highways, obstacles, walls] =
          await Promise.all([
            snapshot.getPromise(stageSelector),
            snapshot.getPromise(viewportSelector),
            snapshot.getPromise(allAreasSelector),
            snapshot.getPromise(allPositionsState),
            snapshot.getPromise(allHighwaysSelector),
            snapshot.getPromise(allObstaclesSelector),
            snapshot.getPromise(allWallsSelector),
          ]);

        if (!includeWalls)
          return calculateGuideLines(ids, [...areas, ...positions,...highways, ...obstacles], stageProps, viewport);

        return calculateGuideLines(
          ids,
          [...areas, ...positions, ...highways, ...obstacles, ...walls],
          stageProps,
          viewport,
        );
      },
    [],
  );

  return {
    getSnapShape,
    getScalingSnapShape,
    getGuideLinesAsync,
  };
};
