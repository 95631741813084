import { useRecoilCallback } from 'recoil';

import { useArtefacts } from '@/modules/artefacts';
import { supportsVehicleTypes } from '@modules/common/helpers/shapes';
import { isAreaShape, isPositionShape, isProcessAreaTwoEp } from '@modules/common/types/guards';
import { useAutoSave } from '@modules/floorplan';
import { allShapesSelector } from '@recoil/shapes';
import { enabledVehicleIdsState } from '@/modules/vehicles';

export const useVehicleTypes = () => {
  const { save } = useAutoSave();
  const { update: updateArtefacts } = useArtefacts();

  const setVehicleIds = useRecoilCallback(
    ({ set, snapshot }) =>
      async (ids: string[]) => {
        const shapes = await snapshot.getPromise(allShapesSelector);
        set(enabledVehicleIdsState, ids);
        const shapeIdsWithNewSupportedVehicles: string[] = [];

        const newShapes = shapes.map((shape) => {
          if (isAreaShape(shape) || isPositionShape(shape)) {
            const currentVehicleIds: string[] = shape.parameters.supportedVehicleIds ?? [];
            const shapeAlreadyHasSupportedTypes = currentVehicleIds.some((item) =>
              ids.includes(item),
            );
            if (shapeAlreadyHasSupportedTypes) {
              return shape;
            }

            shapeIdsWithNewSupportedVehicles.push(shape.id);

            return {
              ...shape,
              parameters: {
                ...shape.parameters,
                supportedVehicleIds: [ids.at(0)],
              },
            };
          } 
          if (isProcessAreaTwoEp(shape)) {
            const currentDeliveryVehicleIds: string[] = 
              shape.parameters.deliveryParameters.supportedVehicleIds ?? [];
            const currentIntakeVehicleIds: string[] = 
              shape.parameters.intakeParameters.supportedVehicleIds ?? [];

            const deliveryAlreadyHasSupportedTypes = currentDeliveryVehicleIds.some((item) =>
              ids.includes(item),
            )
            const intakeAlreadyHasSupportedTypes = currentIntakeVehicleIds.some((item) =>
              ids.includes(item),
            )
            if (deliveryAlreadyHasSupportedTypes && intakeAlreadyHasSupportedTypes) {
              return shape;
            }

            shapeIdsWithNewSupportedVehicles.push(shape.id);

            const newDeliveryVehicles = deliveryAlreadyHasSupportedTypes ? currentDeliveryVehicleIds : [ids.at(0)];
            const newIntakeVehicles = intakeAlreadyHasSupportedTypes ? currentIntakeVehicleIds : [ids.at(0)];

            return {
              ...shape,
              parameters: {
                ...shape.parameters,
                deliveryParameters: {
                  ...shape.parameters.deliveryParameters,
                  supportedVehicleIds: newDeliveryVehicles,
                },
                intakeParameters: {
                  ...shape.parameters.intakeParameters,
                  supportedVehicleIds: newIntakeVehicles,
                },
              },
            };
          }

          return shape;
        });

        set(allShapesSelector, newShapes);
        updateArtefacts(shapeIdsWithNewSupportedVehicles);
        await save();
      },
    [save, updateArtefacts],
  );

  const deleteVehicleIds = useRecoilCallback(
    ({ set, snapshot }) =>
      async (ids: string[]) => {
        const enabledIds = await snapshot.getPromise(enabledVehicleIdsState);
        const newEnabledIds = enabledIds.filter((id) => !ids.includes(id));
        set(enabledVehicleIdsState, newEnabledIds);
        const shapeIdsWithNewSupportedVehicles = [];

        const shapes = await snapshot.getPromise(allShapesSelector);
        const newShapes = shapes.map((shape) => {
          if (!supportsVehicleTypes(shape.type)) {
            return shape;
          }
          if (isAreaShape(shape) || isPositionShape(shape)) {
            const currentSupportedVehicleIds: string[] = shape.parameters.supportedVehicleIds ?? [];
            const shapeAlreadyHasSupportedTypes = currentSupportedVehicleIds.some((item) =>
              newEnabledIds.includes(item),
            );
            if (shapeAlreadyHasSupportedTypes) {
              return shape;
            }

            // @ts-expect-error strictNullChecks. Pls fix me
            shapeIdsWithNewSupportedVehicles.push(shape.id);

            return {
              ...shape,
              parameters: {
                ...shape.parameters,
                supportedVehicleIds: [newEnabledIds.at(0)],
              },
            };
          }
          if (isProcessAreaTwoEp(shape)) {
            const currentDeliveryVehicleIds: string[] = 
              shape.parameters.deliveryParameters.supportedVehicleIds ?? [];
            const currentIntakeVehicleIds: string[] = 
              shape.parameters.intakeParameters.supportedVehicleIds ?? [];

            const deliveryAlreadyHasSupportedTypes = currentDeliveryVehicleIds.some((item) =>
              newEnabledIds.includes(item),
            )
            const intakeAlreadyHasSupportedTypes = currentIntakeVehicleIds.some((item) =>
              newEnabledIds.includes(item),
            )
            if (deliveryAlreadyHasSupportedTypes && intakeAlreadyHasSupportedTypes) {
              return shape;
            }

            // @ts-expect-error strictNullChecks. Pls fix me
            shapeIdsWithNewSupportedVehicles.push(shape.id);

            const newDeliveryVehicles = deliveryAlreadyHasSupportedTypes ? currentDeliveryVehicleIds : [newEnabledIds.at(0)];
            const newIntakeVehicles = intakeAlreadyHasSupportedTypes ? currentIntakeVehicleIds : [newEnabledIds.at(0)];

            return {
              ...shape,
              parameters: {
                ...shape.parameters,
                deliveryParameters: {
                  ...shape.parameters.deliveryParameters,
                  supportedVehicleIds: newDeliveryVehicles,
                },
                intakeParameters: {
                  ...shape.parameters.intakeParameters,
                  supportedVehicleIds: newIntakeVehicles,
                },
              },
            };
          }

          return shape;
        });

        set(allShapesSelector, newShapes);
        updateArtefacts(shapeIdsWithNewSupportedVehicles);
        await save();
      },
    [save, updateArtefacts],
  );

  return {
    setVehicleIds,
    deleteVehicleIds,
  };
};
