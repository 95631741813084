import { ConnectionShapes, JunctionShapesIds, UnitId } from './types';

type UnitToJunctionShapesIdsMap = Map<UnitId, JunctionShapesIds>;

export const unitIdToJunctionShapeIds = (shapes: ConnectionShapes): UnitToJunctionShapesIdsMap => {
  const map: UnitToJunctionShapesIdsMap = new Map();
  shapes.forEach((connection, shapeId) => {
    const { from, to } = connection;

    // from
    if (!map.has(from)) {
      map.set(from, { inConnectionShapeIds: [], outConnectionShapeIds: [] });
    }
    const junction1 = map.get(from);
    // @ts-expect-error strictNullChecks. Pls fix me
    junction1.outConnectionShapeIds.push(shapeId);

    // to
    if (!map.has(to)) {
      map.set(to, { inConnectionShapeIds: [], outConnectionShapeIds: [] });
    }
    const junction2 = map.get(to);
    // @ts-expect-error strictNullChecks. Pls fix me
    junction2.inConnectionShapeIds.push(shapeId);
  });
  return map;
};
