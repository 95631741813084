import { Button } from '@mui/material';
import { downloadUnitData } from '../../helpers/downloadUnitData';
import { Connections, UnitData } from '../../helpers/types';
import { use3TE25LayoutStore } from '../../store';

export function ExportButton() {
  const exportUnitData = () => {
    const { layoutPoints, connectionShapes, deleteConnectionIds } = use3TE25LayoutStore.getState();

    const newUnitData: UnitData = new Map();
    const connectedUnits = new Set<number>();
    // eslint-disable-next-line no-restricted-syntax
    for (const [shapeId, shape] of connectionShapes) {
      const fromId = shape.from;
      connectedUnits.add(fromId);
      const toId = shape.to;
      if (!newUnitData.has(fromId)) {
        newUnitData.set(fromId, {
          from: fromId,
          to: new Set(),
        });
      }
      if (deleteConnectionIds.has(shapeId)) continue;
      const c = newUnitData.get(fromId);
      // @ts-expect-error strictNullChecks. Pls fix me
      c.to.add(toId);
    }

    // add back unitData that has no connection
    // eslint-disable-next-line no-restricted-syntax
    for (const [unitId] of layoutPoints) {
      if (connectedUnits.has(unitId)) continue;
      const connections: Connections = {
        from: unitId,
        to: new Set(),
      };
      newUnitData.set(unitId, connections);
    }

    downloadUnitData(newUnitData);
  };

  return <Button onClick={exportUnitData}>Export</Button>;
}
