import { atom, atomFamily } from 'recoil';

import { Simulation } from '../../helpers/types';
import { FloorPlan } from '@modules/common/types/floorPlan';

/**
 * Ids of all simulations
 */
export const simulationIdsAtom = atom<string[]>({
  key: 'simulations/simulations/idsAtom',
  default: [],
});

/**
 * A simulation
 */
export const simulationAtom = atomFamily<Simulation, string>({
  key: 'simulations/simulations/atom',
  // @ts-expect-error strictNullChecks. Pls fix me
  default: null,
});

/**
 * All floor plan versions
 */
export const floorPlanVersionsAtom = atom<FloorPlan[]>({
  key: 'simulations/floorPlanVersions/atom',
  // @ts-expect-error strictNullChecks. Pls fix me
  default: null,
});
