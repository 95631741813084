import { Color } from '@thive/canvas';
import cloneDeep from 'clone-deep';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { Vector3 } from 'three';
import { MathUtils } from 'three/src/math/MathUtils';

import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { useCanvasStore } from '@/modules/canvas';
import { COLORS } from '@/modules/commissioning/mappers/areas/colors';
import { convertV2ToV3 } from '@/modules/common/helpers/math';
import { getOrderedControlPoints } from '@/modules/common/helpers/shapes';
import { ControlPoint } from '@/modules/common/types/shapes';
import { useAutoSave } from '@/modules/floorplan';
import { useStopDrawingOnToolSwitch } from '@/modules/salesWorkspace/hooks/points/useStopDrawingOnToolSwitch';
import { useDeleteShape } from '@/modules/workspace/hooks';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { keyboardState, KEYCODE } from '@/store/recoil/input';
import shapeAtom, { WallShape } from '@/store/recoil/shape';
import { drawingIdSelector } from '@/store/recoil/workspace';

export const useActivePointsDrawing = () => {
  const { save } = useAutoSave();
  const pressedKey = useRecoilValue(keyboardState);
  const { deleteMultipleShapes } = useDeleteShape();
  const { id, interimEndPointPos, controlPoints, interimStartControlPoint, type, width } =
    useWorkspaceStore((state) => state.activePointsDrawingState);

  useStopDrawingOnToolSwitch();

  const removeLastControlPoint = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const drawingId = await snapshot.getPromise(drawingIdSelector);
        const currentControlPoints = (
          (await snapshot.getPromise(shapeAtom(drawingId))) as WallShape | AngledHighwayShape
        ).properties.controlPoints;

        // delete shape if it only has two control points
        if (currentControlPoints.length < 3) {
          deleteMultipleShapes([drawingId]);
          useWorkspaceStore.getState().reset();
          return;
        }

        const newCps: ControlPoint[] = [];
        const orderedCps = getOrderedControlPoints(currentControlPoints);
        const cpsLength = orderedCps.length;
        orderedCps.forEach((cp, index) => {
          // all but second to last and last
          if (index < cpsLength - 2) {
            newCps.push(cloneDeep(cp));
            return;
          }

          // second to last
          if (index === cpsLength - 2) {
            const newCp = cloneDeep(cp);
            newCp.next = null;
            newCps.push(newCp);
          }
        });

        set(
          shapeAtom(drawingId),
          (current: WallShape | AngledHighwayShape): WallShape | AngledHighwayShape => ({
            ...current,
            properties: {
              ...current.properties,
              controlPoints: newCps,
            },
          }),
        );
        useWorkspaceStore.getState().setControlPoints(newCps);

        save();

        // Remove last element from canvas
        const canvas = useCanvasStore.getState().instance;
        // @ts-expect-error strictNullChecks. Pls fix me
        canvas.removeElement(`${id}_${currentControlPoints.length - 2}`);
        // @ts-expect-error strictNullChecks. Pls fix me
        canvas.removeElement(`${id}_${currentControlPoints.length - 1}`);
      },
    [deleteMultipleShapes, id, save],
  );

  useEffect(() => {
    if (pressedKey === KEYCODE.BACKSPACE || pressedKey === KEYCODE.DELETE) {
      removeLastControlPoint();
    }
  }, [pressedKey, removeLastControlPoint]);

  // @ts-expect-error strictNullChecks. Pls fix me
  useEffect(() => {
    if (!id || !interimEndPointPos) return null;

    const canvas = useCanvasStore.getState().instance;
    const elementId = `${id}_${controlPoints.length - 1}`;
    const start = convertV2ToV3(controlPoints[controlPoints.length - 1].position);
    const end = convertV2ToV3(interimEndPointPos);
    const segmentVector = new Vector3().subVectors(end, start);
    const angleInDegrees = MathUtils.radToDeg(Math.atan2(segmentVector.y, segmentVector.x));
    // @ts-expect-error strictNullChecks. Pls fix me
    canvas.updateElement({
      type: 'Rect',
      name: elementId,
      id: elementId,
      position: new Vector3().lerpVectors(start, end, 0.5),
      rotation: new Vector3(0, 0, angleInDegrees),
      size: new Vector3(start.distanceTo(end), width),
      fill: Color.fromHex(COLORS[type]),
      interactivity: {
        selectable: false,
      },
    });
  }, [id, interimEndPointPos, controlPoints, interimStartControlPoint, type, width]);
};
