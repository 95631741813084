import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import modalAtom from './atom';

export const closeModalSelector = selector({
  key: 'modal/close',
  get: ({ get }) => get(modalAtom).queue,
  set: ({ get, set }, id) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    set(modalAtom, { queue: get(modalAtom).queue.filter((n) => n.id !== id) });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
