import { useRecoilCallback } from 'recoil';
import {
  disableShapesOfTypesExclusivelySelector,
  enableShapesOfTypesExclusivelySelector,
} from '@recoil/shapes';
import { allAreasSelector } from '@recoil/shapes/area';

export const useShapeDisabling = () => {
  const enableOnlyShapesOfTypes = useRecoilCallback(
    ({ set }) =>
      (types: string[]) => {
        set(enableShapesOfTypesExclusivelySelector, types);
      },
    [],
  );

  const enableOnlyShapesOfTypesAndVehicleTypes = useRecoilCallback(
    ({ set }) =>
      (types: string[], vehicleTypes: string[]) => {
        set(allAreasSelector, (shapes) =>
          shapes.map((shape) => {
            const shouldBeDisabled =
              !types.includes(shape.type) ||
              // @ts-expect-error strictNullChecks. Pls fix me
              !vehicleTypes.includes(shape.parameters.supportedVehicleIds.at(0));

            return {
              ...shape,
              disabled: shouldBeDisabled,
            };
          }),
        );
      },
    [],
  );

  const enableAllShapes = useRecoilCallback(
    ({ set }) =>
      () => {
        set(disableShapesOfTypesExclusivelySelector, []);
      },
    [],
  );

  return {
    enableOnlyShapesOfTypesAndVehicleTypes,
    enableOnlyShapesOfTypes,
    enableAllShapes,
  };
};
