import { Module } from '@/modules/common/types/navigation';
import {
  referenceCrop,
  referenceDimensions,
  referenceMode,
  referenceSelected,
} from '@/modules/referenceImage/store';
import { toolButtonState } from '@/store/recoil/tool';
import { ToolState } from '@modules/common/types/tools';
import navAtom from '@recoil/nav';
import { Group } from 'konva/lib/Group';
import { KonvaEventObject } from 'konva/lib/Node';
import { Image as KonvaImageClass } from 'konva/lib/shapes/Image';
import { Rect } from 'konva/lib/shapes/Rect';
import { Transformer } from 'konva/lib/shapes/Transformer';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Group as GroupComponent,
  Image as KonvaImage,
  Rect as RectComponent,
  Transformer as TransformerComponent,
} from 'react-konva';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useWorkspaceClamp } from '../../common/hooks';
import { Mode } from '../../types';
import { ScalePoints } from './ScalePoints';
import { useImageRef } from './useImageRef';
import objectUnderMouseIdSelector from '../../../../store/recoil/input/objectUnderMouseIdSelector';
import { theme } from '@/modules/common/components/theme';

export function ReferenceImageRenderer({ referenceImage, saveReferenceImage }) {
  const [cropEnabled, setCropEnabled] = useState(false);
  const [dwgInteractive, setDwgInteractive] = useState(true);
  const [modeState, setModeState] = useRecoilState(referenceMode);
  const [cropState, setCropState] = useRecoilState(referenceCrop);
  const [dimensionState, setDimensionState] = useRecoilState(referenceDimensions);
  const [tool, setTool] = useRecoilState(toolButtonState);
  const [isDwgSelected, setIsDwgSelected] = useRecoilState(referenceSelected);
  const setObjectUnderMouseId = useSetRecoilState(objectUnderMouseIdSelector);
  const { clamp } = useWorkspaceClamp();

  const { imgRef } = useImageRef(referenceImage.file);

  const groupRef = useRef<Group>();
  const trRef = useRef<Transformer>();
  const cropRef = useRef<Rect>();
  const imageRef = useRef<KonvaImageClass>();

  // Hack to enable / disable access
  const nav = useRecoilValue(navAtom);

  useEffect(() => {
    setCropEnabled(modeState === Mode.CROP);
    if (modeState === Mode.CROP) {
      setTool(ToolState.CROP);
      // @ts-expect-error strictNullChecks. Pls fix me
      trRef.current.nodes([cropRef.current]);
    } else if (modeState === Mode.SCALE) {
      setTool('scale');
      // @ts-expect-error strictNullChecks. Pls fix me
      trRef.current.nodes([groupRef.current]);
    } else {
      // @ts-expect-error strictNullChecks. Pls fix me
      setTool(null);
      enableTransform();
      // @ts-expect-error strictNullChecks. Pls fix me
      trRef.current.nodes([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeState]);

  useEffect(() => {
    setDwgInteractive(nav === Module.SETUP);
    // @ts-expect-error strictNullChecks. Pls fix me
    if (nav !== Module.SETUP) trRef.current.nodes([]);
  }, [nav]);

  const enableTransform = useCallback(() => {
    if (modeState !== Mode.SCALE) {
      setCropEnabled(false);
      setModeState(Mode.MOVE);
    }
  }, [modeState, setModeState]);

  const cropRectTransformEnd = useCallback(() => {
    const node = cropRef.current;
    // @ts-expect-error strictNullChecks. Pls fix me
    const scaleX = node.scaleX();
    // @ts-expect-error strictNullChecks. Pls fix me
    const scaleY = node.scaleY();

    // @ts-expect-error strictNullChecks. Pls fix me
    node.scaleX(1);
    // @ts-expect-error strictNullChecks. Pls fix me
    node.scaleY(1);
    setCropState({
      ...cropState,
      // @ts-expect-error strictNullChecks. Pls fix me
      x: Math.round(node.x() * 10),
      // @ts-expect-error strictNullChecks. Pls fix me
      y: Math.round(node.y() * 10),
      // set minimal value
      // @ts-expect-error strictNullChecks. Pls fix me
      width: Math.max(5, Math.round(node.width() * 10 * scaleX)),
      // @ts-expect-error strictNullChecks. Pls fix me
      height: Math.max(5, Math.round(node.height() * 10 * scaleY)),
    });
  }, [cropState, setCropState]);

  const cropOffset = useRef({ x: 0, y: 0, width: 0, height: 0 });

  const groupTransformStart = useCallback(() => {
    cropOffset.current = {
      // @ts-expect-error strictNullChecks. Pls fix me
      x: Math.round(Math.round(imageRef.current.x() * 10)),
      // @ts-expect-error strictNullChecks. Pls fix me
      y: Math.round(Math.round(imageRef.current.y() * 10)),
      // @ts-expect-error strictNullChecks. Pls fix me
      width: Math.round(Math.round(imageRef.current.width() * 10)),
      // @ts-expect-error strictNullChecks. Pls fix me
      height: Math.round(Math.round(imageRef.current.height() * 10)),
    };
  }, []);

  const groupTransformEnd = useCallback(() => {
    const node = groupRef.current;
    // @ts-expect-error strictNullChecks. Pls fix me
    const scaleX = node.scaleX();
    // @ts-expect-error strictNullChecks. Pls fix me
    const scaleY = node.scaleY();

    setDimensionState({
      ...dimensionState,
      // @ts-expect-error strictNullChecks. Pls fix me
      x: Math.round(node.x() * 10),
      // @ts-expect-error strictNullChecks. Pls fix me
      y: Math.round(node.y() * 10),
      width: Math.max(5, Math.round(referenceImage.original.width * scaleX)),
      height: Math.max(5, Math.round(referenceImage.original.height * scaleY)),
    });
  }, [
    dimensionState,
    referenceImage.original.height,
    referenceImage.original.width,
    setDimensionState,
  ]);

  const onGroupClick = useCallback(() => {
    enableTransform();
    setIsDwgSelected(true);
  }, [enableTransform, setIsDwgSelected]);

  const isDraggable = tool === 'cursor' && isDwgSelected;

  const onDragMove = useRecoilCallback(
    ({ set }) =>
      async (e: KonvaEventObject<DragEvent>) => {
        const clampedPositionInMm = await clamp(
          Math.round(e.target.x() * 10),
          Math.round(e.target.y() * 10),
        );

        e.target.position({
          x: clampedPositionInMm.x / 10, // convert mm back to cm for konva dimension
          y: clampedPositionInMm.y / 10, // convert mm back to cm for konva dimension
        });
        set(referenceDimensions, (state) => ({
          ...state,
          ...clampedPositionInMm,
        }));
      },
    [clamp],
  );

  const onDragEnd = useRecoilCallback(
    ({ set }) =>
      async (e: KonvaEventObject<DragEvent>) => {
        const clampedPositionInMm = await clamp(
          Math.round(e.target.x() * 10),
          Math.round(e.target.y() * 10),
        );

        e.target.position({
          x: clampedPositionInMm.x / 10,
          y: clampedPositionInMm.y / 10,
        });
        set(referenceDimensions, (state) => ({
          ...state,
          ...clampedPositionInMm,
        }));
        saveReferenceImage();
      },
    [clamp, saveReferenceImage],
  );

  return (
    <>
      {/* // Reference image */}
      {cropEnabled && (
        <KonvaImage
          x={referenceImage.x / 10}
          y={referenceImage.y / 10}
          width={dimensionState.width / 10} // /10 to show in canvas size
          height={dimensionState.height / 10} // /10 to show in canvas size
          image={imgRef}
          opacity={0.2}
        />
      )}

      <GroupComponent
        listening={dwgInteractive}
        x={referenceImage.x / 10}
        y={referenceImage.y / 10}
        // @ts-expect-error strictNullChecks. Pls fix me
        ref={groupRef}
        draggable={isDraggable}
        onDragStart={groupTransformStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onClick={onGroupClick}
        onTransformStart={groupTransformStart}
        onTransformEnd={groupTransformEnd}
        clip={{
          x: cropState.x / 10,
          y: cropState.y / 10,
          height: cropState.height / 10, // /10 to render in konva size (mm => cm)
          width: cropState.width / 10, // /10 to render in konva size (mm => cm)
        }}
      >
        {imgRef !== undefined && (
          <KonvaImage
            // @ts-expect-error strictNullChecks. Pls fix me
            ref={imageRef}
            x={0}
            y={0}
            width={dimensionState.width / 10} // /10 to render in konva size (mm => cm)
            height={dimensionState.height / 10} // /10 to render in konva size (mm => cm)
            image={imgRef}
            opacity={cropEnabled ? 1 : referenceImage.opacity / 100}
          />
        )}
        <RectComponent
          visible={cropEnabled}
          x={cropState.x / 10}
          y={cropState.y / 10}
          width={cropState.width / 10} // /10 to render in konva size (mm => cm)
          height={cropState.height / 10} // /10 to render in konva size (mm => cm)
          fill='transparent'
          strokeScaleEnabled={false}
          // @ts-expect-error strictNullChecks. Pls fix me
          ref={cropRef}
          onTransformEnd={cropRectTransformEnd}
        />
      </GroupComponent>
      <ScalePoints
        referenceImage={referenceImage}
        // @ts-expect-error strictNullChecks. Pls fix me
        group={groupRef.current}
      />
      <TransformerComponent
        // @ts-expect-error strictNullChecks. Pls fix me
        ref={trRef}
        borderStrokeWidth={3}
        anchorStroke={theme.palette.primary.main}
        borderStroke={theme.palette.primary.main}
        ignoreStroke
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
        rotateEnabled={false}
        enabledAnchors={
          modeState === Mode.SCALE
            ? []
            : cropEnabled
            ? ['top-center', 'middle-right', 'bottom-center', 'middle-left']
            : []
        }
        onMouseEnter={(e) => {
          const { name } = e.target.attrs;
          if (name.includes('_anchor')) {
            setObjectUnderMouseId(name);
          }
        }}
        onMouseLeave={() => {
          setObjectUnderMouseId(null);
        }}
      />
    </>
  );
}
