import { PlusIcon } from '@/assets/icons';
import { FlowDirection } from '@/modules/flows/components';
import { StyledTooltip } from '@helpers/styles';
import { layoutFlowSelector } from '@modules/flows/store/layout';
import { IconButton, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useSimulationFlows } from '../../hooks';

type Props = {
  id: string;
};

export function ExcludedFlow({ id }: Props) {
  const { t } = useTranslation('interface');
  const flow = useRecoilValue(layoutFlowSelector(id));
  const { name, sourceName, targetName } = flow;
  const { copyLayoutFlowToSimulationFlow } = useSimulationFlows();

  const onAddClick = useCallback(() => {
    copyLayoutFlowToSimulationFlow(id);
  }, [copyLayoutFlowToSimulationFlow, id]);

  return (
    <Stack direction='row' marginBottom={2}>
      <Stack
        sx={{
          alignItems: 'start',
          color: 'text.primary',
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          textTransform: 'inherit',
          padding: 0,
        }}
      >
        <Typography fontSize={14} fontWeight={600} marginBottom={1} textAlign='left'>
          {name}
        </Typography>
        {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
        <FlowDirection sourceName={sourceName} targetName={targetName} />
      </Stack>

      <StyledTooltip
        title={t('simulation.simulation_edit.flows.excluded_flows.include_flow.tooltip')}
      >
        <IconButton onClick={onAddClick} sx={{ pr: 0 }}>
          <PlusIcon />
        </IconButton>
      </StyledTooltip>
    </Stack>
  );
}
