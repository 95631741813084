import { FloatingPanel } from '@/modules/common/components/FloatingPanel';
import { useRecoilState } from 'recoil';
import { wifiPanelAtom } from '../store/wifiPanelAtom';
import { WifiProperties } from './WifiProperties';

export function WifiSimulationPanel() {
  const [open, setOpen] = useRecoilState(wifiPanelAtom);

  return (
    // @ts-expect-error strictNullChecks. Pls fix me
    <FloatingPanel open={open} onClose={() => setOpen(false)} title='Wifi simulation (POC)'>
      <WifiProperties />
    </FloatingPanel>
  );
}
