import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { LoadCarrierType } from '@modules/common/types/floorPlan';
import { LOAD_OPTIONS, VEHICLE_OPTIONS } from '@modules/visualization/consts';
import { EventNames, useEventBus } from '@modules/visualization/hooks';
import { LoadCarrierOption, ModelMapEntry, VehicleOption } from '@modules/visualization/types';
import { enabledLoadCarrierTypesSelector } from '@recoil/loadCarrierTypes';
import { ChangePanel } from './ChangePanel';
import {
  allVehiclesState,
  enabledVehiclesSelector,
  VehicleImage,
  UnifiedVehicle,
} from '@/modules/vehicles';
import { ChevronDownIcon } from '@/assets/icons';

const GetFleetTrackerId = (vehicleId: string) => {
  const option = VEHICLE_OPTIONS.find((x) => x.vehicleIds.includes(vehicleId));
  if (option) return option.fleetTrackerId;

  return 'rae';
};

export function Change3dModel() {
  const { t } = useTranslation(['interface']);
  const allVehicles = useRecoilValue(allVehiclesState);
  const enabledVehicles = useRecoilValue(enabledVehiclesSelector);
  const enabledLoadCarriers = useRecoilValue(enabledLoadCarrierTypesSelector);
  // @ts-expect-error strictNullChecks. Pls fix me
  const [selectedVehicle, setSelectedVehicle] = useState<UnifiedVehicle>(null);
  // @ts-expect-error strictNullChecks. Pls fix me
  const [selectedLoadCarrier, setSelectedLoadCarrier] = useState<LoadCarrierType>(null);
  const [vehicleMap] = useState(new Map<string, ModelMapEntry<VehicleOption>>());
  const [loadCarrierMap] = useState(new Map<string, ModelMapEntry<LoadCarrierOption>>());
  const { emit } = useEventBus();
  const [anchorElVehicle, setAnchorElVehicle] = useState<null | HTMLElement>(null);
  const [anchorElCarrier, setAnchorElCarrier] = useState<null | HTMLElement>(null);
  const openVehicle = Boolean(anchorElVehicle);
  const openCarrier = Boolean(anchorElCarrier);

  useEffect(() => {
    const uniqueVehicles = enabledVehicles.filter(
      (obj, index) =>
        index ===
        enabledVehicles.findIndex((o) => GetFleetTrackerId(obj.id) === GetFleetTrackerId(o.id)),
    );

    uniqueVehicles.forEach((vehicle) => {
      vehicleMap.set(vehicle.id, {
        option: {
          name: vehicle.name,
          vehicleIds: [vehicle.id],
          fleetTrackerId: GetFleetTrackerId(vehicle.id),
        },
        image: vehicle.image,
      });
    });

    enabledLoadCarriers.forEach((loadCarrier) => {
      loadCarrierMap.set(loadCarrier.id, {
        option: {
          name: 'Pallet with Load',
          fleetTrackerId: 'load-pallet-test',
        },
        image: loadCarrier.image,
      });
    });
  }, [enabledVehicles, enabledLoadCarriers, vehicleMap, loadCarrierMap]);

  const GetVehicleImage = useCallback(
    (vehicleIds: string[]): VehicleImage => {
      const vehicle = allVehicles.find((x) => vehicleIds.includes(x.id));
      if (vehicle) return vehicle.image;

      // @ts-expect-error strictNullChecks. Pls fix me
      return null;
    },
    [allVehicles],
  );

  const handleClickVehicle = (event: MouseEvent<HTMLButtonElement>, vehicleId: string) => {
    setAnchorElVehicle(event.currentTarget);
    // @ts-expect-error strictNullChecks. Pls fix me
    setSelectedVehicle(enabledVehicles.find((item) => item.id === vehicleId) || null);
  };
  const handleCloseVehicle = () => {
    setAnchorElVehicle(null);
    // @ts-expect-error strictNullChecks. Pls fix me
    setSelectedVehicle(null);
  };

  const handleClickCarrier = (event: MouseEvent<HTMLButtonElement>, loadCarrierId: string) => {
    setAnchorElCarrier(event.currentTarget);
    // @ts-expect-error strictNullChecks. Pls fix me
    setSelectedLoadCarrier(enabledLoadCarriers.find((item) => item.id === loadCarrierId) || null);
  };
  const handleCloseCarrier = () => {
    setAnchorElCarrier(null);
    // @ts-expect-error strictNullChecks. Pls fix me
    setSelectedLoadCarrier(null);
  };

  const changeModel = useCallback(
    (oldModel: string, newModel: string) => {
      const payload = {
        oldModel,
        newModel,
      };
      emit(EventNames.VisualizationWebGLSend, {
        target: 'Tracker',
        action: 'ReplaceModelJson',
        payload,
      });
    },
    [emit],
  );

  const changeVehicleModel = useCallback(
    (newModel: VehicleOption) => {
      changeModel(GetFleetTrackerId(selectedVehicle.id), newModel.fleetTrackerId);
      vehicleMap.set(selectedVehicle.id, {
        option: newModel,
        image: GetVehicleImage(newModel.vehicleIds),
      });
    },
    [GetVehicleImage, changeModel, selectedVehicle, vehicleMap],
  );

  const changeLoadModel = useCallback(
    (newModel: LoadCarrierOption) => {
      changeModel('load-pallet-test', newModel.fleetTrackerId);
      loadCarrierMap.set(selectedLoadCarrier.id, {
        option: newModel,
        image: selectedLoadCarrier.image, // Temporary until multi load carriers implemented and more load carriers added to fleettracker
      });
    },
    [changeModel, loadCarrierMap, selectedLoadCarrier],
  );

  return (
    <>
      <Accordion defaultExpanded={false} elevation={1} sx={{ px: 2 }}>
        <AccordionSummary expandIcon={<ChevronDownIcon />}>
          <Typography>{t('visualization.properties.vehicle_model')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '8px 0' }}>
          <ChangePanel
            options={VEHICLE_OPTIONS}
            changeModel={changeVehicleModel}
            itemMap={vehicleMap}
            handleClickItem={handleClickVehicle}
            handleCloseItem={handleCloseVehicle}
            selectedItem={selectedVehicle}
            open={openVehicle}
            // @ts-expect-error strictNullChecks. Pls fix me
            anchor={anchorElVehicle}
          />
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion defaultExpanded={false} elevation={1} sx={{ px: 2 }}>
        <AccordionSummary expandIcon={<ChevronDownIcon />}>
          <Typography>{t('visualization.properties.load_carrier_type')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '8px 0' }}>
          <ChangePanel
            options={LOAD_OPTIONS}
            changeModel={changeLoadModel}
            itemMap={loadCarrierMap}
            handleClickItem={handleClickCarrier}
            handleCloseItem={handleCloseCarrier}
            selectedItem={selectedLoadCarrier}
            open={openCarrier}
            // @ts-expect-error strictNullChecks. Pls fix me
            anchor={anchorElCarrier}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
