import { useCanvasStore } from '@/modules/canvas';
import { ArrowElement, CircleElement, Color, Element, RectElement } from '@thive/canvas';
import { useCallback, useEffect, useRef } from 'react';
import {
  CONNECTION_COLOR,
  CONNECTION_DISABLE_ARROW_COLOR,
  CONNECTION_IN_COLOR,
  CONNECTION_OUT_COLOR,
} from '../draw/drawConnections';
import {
  POINT_COLOR,
  POINT_COLOR_SELECTED,
  POINT_RADIUS,
  POINT_RADIUS_SELECTED,
} from '../draw/drawLayoutPoints';
import { UnitShapeId } from '../helpers/types';
import { use3TE25LayoutStore } from '../store';
import { useSelectionStore } from '../store/useSelectionStore';

export const useHighlightUnitId = () => {
  const { unitIds } = useSelectionStore();
  const prevUnitIds = useRef<UnitShapeId[]>([]);

  const highlightUnitId = useCallback((ids: UnitShapeId[], selected: boolean) => {
    const canvas = useCanvasStore.getState().instance;
    const { unitShapes, getJunctionShapeIds, deleteConnectionIds } = use3TE25LayoutStore.getState();

    const elements: Element[] = [];
    ids.forEach((id) => {
      // Point Element
      // @ts-expect-error strictNullChecks. Pls fix me
      const pointIds = Array.from(unitShapes.get(id));
      // @ts-expect-error strictNullChecks. Pls fix me
      const pointElements = canvas.getElements(pointIds) as CircleElement[];
      pointElements.forEach((element) => {
        element.fill = Color.fromHex(selected ? POINT_COLOR_SELECTED : POINT_COLOR);
        element.radius = selected ? POINT_RADIUS_SELECTED : POINT_RADIUS;
      });

      // Unit Element
      // @ts-expect-error strictNullChecks. Pls fix me
      const unitElement = canvas.getElement(id) as RectElement;
      unitElement.fill = Color.fromHex(selected ? POINT_COLOR_SELECTED : POINT_COLOR);

      // Junction
      const junction = getJunctionShapeIds(parseInt(id));
      // @ts-expect-error strictNullChecks. Pls fix me
      const inElements = canvas.getElements(junction.inConnectionShapeIds) as ArrowElement[];
      // @ts-expect-error strictNullChecks. Pls fix me
      const outElements = canvas.getElements(junction.outConnectionShapeIds) as ArrowElement[];
      inElements.forEach((e) => {
        if (selected) {
          e.stroke = Color.fromHex(CONNECTION_IN_COLOR);
        } else if (deleteConnectionIds.has(e.id)) {
          e.stroke = Color.fromHex(CONNECTION_DISABLE_ARROW_COLOR);
        } else {
          e.stroke = Color.fromHex(CONNECTION_COLOR);
        }
      });
      outElements.forEach((e) => {
        if (selected) {
          e.stroke = Color.fromHex(CONNECTION_OUT_COLOR);
        } else if (deleteConnectionIds.has(e.id)) {
          e.stroke = Color.fromHex(CONNECTION_DISABLE_ARROW_COLOR);
        } else {
          e.stroke = Color.fromHex(CONNECTION_COLOR);
        }
      });

      elements.push(...pointElements, unitElement, ...inElements, ...outElements);
    });

    // @ts-expect-error strictNullChecks. Pls fix me
    canvas.updateElements([...elements]);
  }, []);

  useEffect(() => {
    highlightUnitId(unitIds, true);
    highlightUnitId(prevUnitIds.current, false);
    prevUnitIds.current = [...unitIds];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitIds]);
};
