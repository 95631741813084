import { selector } from 'recoil';

import { layoutFlowsSelector } from '@/modules/flows/store/layout';
import { LayoutFlow } from '@/modules/flows/types';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { simulationFlowAtom, simulationFlowIdsAtom } from './atom';

export const excludedSimulationFlowsSelector = selector<LayoutFlow[]>({
  key: 'flow/simulation/exclude/flow',
  get: ({ get }) => {
    const simulationFlowIds = new Set(get(simulationFlowIdsAtom));
    const allLayoutFlows = get(layoutFlowsSelector);
    const excludedFlows: LayoutFlow[] = [];

    allLayoutFlows.forEach((flow) => {
      // @ts-expect-error strictNullChecks. Pls fix me
      if (!simulationFlowIds.has(flow.id)) {
        excludedFlows.push(flow);
      }
    });
    return excludedFlows;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const simulationFlowsSelector = selector<LayoutFlow[]>({
  key: 'flow/simulation/flows',
  get: ({ get }) => get(simulationFlowIdsAtom).map((id) => get(simulationFlowAtom(id))),
  set: ({ set }, flows: any) => {
    const newFlowIds = flows.map((flow: any) => flow.id);
    // eslint-disable-next-line no-restricted-syntax
    for (const flow of flows) {
      set(simulationFlowAtom(flow.id), flow);
    }
    set(simulationFlowIdsAtom, newFlowIds);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
