import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selectorFamily } from 'recoil';
import { shapeGroupState } from '../atom';

export const shapeGroupNameSelector = selectorFamily<string, string>({
  key: 'shapeGroup/name',
  get:
    (id) =>
    // @ts-expect-error strictNullChecks. Pls fix me
    ({ get }) => {
      const shape = get(shapeGroupState(id));
      if (!shape || !shape.name) return null;

      return shape.name;
    },
  set:
    (id) =>
    ({ set }, newValue: string) => {
      set(shapeGroupState(id), (current) => ({
        ...current,
        name: newValue,
      }));
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
