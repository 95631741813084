import { rotateVector } from '@/modules/common/helpers/math';
import { ArrowElement, Element, Color } from '@thive/canvas';
import { Vector2, Vector3 } from 'three';
import { v4 as uuid } from 'uuid';
import { ConnectionShape, ConnectionShapes, LayoutPoints, UnitData } from '../helpers/types';
import { POINT_RADIUS } from './drawLayoutPoints';

const CENTERLINE_OFFSET = 200;
const ARROW_THICKNESS = 100;
export const CONNECTION_COLOR = '#1556ed50';
export const CONNECTION_IN_COLOR = '#dbeb34ff';
export const CONNECTION_OUT_COLOR = '#f269ffff';
export const CONNECTION_DISABLE_ARROW_COLOR = '#00000010';

export const drawConnections = (unitData: UnitData, layoutPoints: LayoutPoints) => {
  const elements: Element[] = [];
  const shapes: ConnectionShapes = new Map();

  // eslint-disable-next-line no-restricted-syntax
  for (const [fromId, connection] of unitData) {
    if (!layoutPoints.has(fromId)) continue;
    const fromUnit = layoutPoints.get(fromId);
    const toIds = Array.from(connection.to);
    // eslint-disable-next-line no-restricted-syntax
    for (const toId of toIds) {
      if (!layoutPoints.has(toId)) continue;

      const toUnit = layoutPoints.get(toId);
      // @ts-expect-error strictNullChecks. Pls fix me
      const line = offsetArrow(fromUnit.center, toUnit.center);
      const shape: ConnectionShape = {
        // @ts-expect-error strictNullChecks. Pls fix me
        from: fromUnit.id,
        // @ts-expect-error strictNullChecks. Pls fix me
        to: toUnit.id,
        id: uuid(),
      };
      shapes.set(shape.id, shape);

      const element: ArrowElement = {
        id: shape.id,
        name: shape.id,
        type: 'Arrow',
        pointA: new Vector3(line.from.x, line.from.y),
        pointB: new Vector3(line.to.x, line.to.y),
        stroke: Color.fromHex(CONNECTION_COLOR),
        thickness: ARROW_THICKNESS,
        interactivity: {
          selectable: true,
        },
      };
      elements.push(element);
    }
  }
  return { elements, shapes };
};

type Line = {
  from: Vector2;
  to: Vector2;
};

const offsetArrow = (from: Vector2, to: Vector2): Line => {
  const v = to.clone().sub(from);
  const v_hat = v.clone().normalize();
  const u = rotateVector(v_hat, 90);
  u.multiplyScalar(CENTERLINE_OFFSET);

  const pointOffset = v_hat.clone().multiplyScalar(POINT_RADIUS * 1.1);

  const p1 = from.clone().add(u).add(pointOffset);
  const p2 = to.clone().add(u).sub(pointOffset);
  return {
    from: p1,
    to: p2,
  };
};
