import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useCanvasStore } from '@/modules/canvas';
import { usesControlPoints } from '@/modules/common/helpers/shapes';
import { mouseKeysState } from '@/store/recoil/input';
import { pannableSelector, shapeType, toolState } from '@/store/recoil/workspace';
import { ToolState } from '@modules/common/types/tools';
import { isMouseWithinWorkspaceState } from '@recoil/mouse';
import { useAreaMouseHandler } from './useAreaMouseHandler';
import { useDefaultMouseHandler } from './useDefaultMouseHandler';
import { usePointsMouseHandler } from './usePointsMouseHandler';

export function useMouseEvents() {
  // hook
  const defaultMouseHandler = useDefaultMouseHandler();
  const areaMouseHandler = useAreaMouseHandler();
  const pointsMouseHandler = usePointsMouseHandler();

  // recoil
  const setPannable = useSetRecoilState(pannableSelector);
  const wsToolState = useRecoilValue(toolState);
  const wsShapeType = useRecoilValue(shapeType);
  const mouseKeys = useRecoilValue(mouseKeysState);
  const isMouseInWorkspace = useRecoilValue(isMouseWithinWorkspaceState);

  const mouseHandlers = useMemo(() => {
    switch (wsToolState) {
      case ToolState.DRAW:
        if (usesControlPoints(wsShapeType)) {
          return pointsMouseHandler;
        }
        return areaMouseHandler;
      case ToolState.PAN:
      default:
        return defaultMouseHandler;
    }
  }, [wsToolState, wsShapeType, areaMouseHandler, defaultMouseHandler, pointsMouseHandler]);

  const mouseDownHandler = useCallback(
    (mouseEvent) => {
      // console.log('mouseDownHandler');
      mouseHandlers.onMouseDown(mouseEvent);
    },
    [mouseHandlers],
  );

  const mouseMoveHandler = useCallback(
    (mouseEvent) => {
      // console.log('mouseMoveHandler');
      mouseHandlers.onMouseMove(mouseEvent);
    },
    [mouseHandlers],
  );

  const mouseUpHandler = useCallback(
    (mouseEvent) => {
      // console.log('mouseUpHandler');
      mouseHandlers.onMouseUp(mouseEvent);
    },
    [mouseHandlers],
  );

  useEffect(() => {
    const canvas = useCanvasStore.getState().instance;

    // @ts-expect-error strictNullChecks. Pls fix me
    canvas
      .on('MouseEvents.PointerDown', mouseDownHandler)
      .on('MouseEvents.PointerMove', mouseMoveHandler)
      .on('MouseEvents.PointerUp', mouseUpHandler);
    return () => {
      // @ts-expect-error strictNullChecks. Pls fix me
      canvas
        .off('MouseEvents.PointerDown', mouseDownHandler)
        .off('MouseEvents.PointerMove', mouseMoveHandler)
        .off('MouseEvents.PointerUp', mouseUpHandler);
    };
  }, [mouseDownHandler, mouseMoveHandler, mouseUpHandler]);

  useEffect(() => {
    if (!isMouseInWorkspace) return;
    const isPannable = mouseKeys !== 0 || wsToolState === ToolState.PAN;
    setPannable(isPannable);
  }, [isMouseInWorkspace, mouseKeys, setPannable, wsToolState]);

  return mouseHandlers;
}
