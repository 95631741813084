import { unique } from '@/modules/common/helpers/array';
import { create } from 'zustand';
import { setRecoil } from 'recoil-nexus';
import shapeAtom from '@/store/recoil/shape/atom';
import { devtools } from 'zustand/middleware';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';

type LoadingStore = {
  abortControllers: Map<string, AbortController>;
  isLoadingIds: string[];
};

type LoadingActions = {
  abortLoading(shapesIds: string[]): void;
  isLoading(id: string): boolean;
  showLoader(shapeIds: string[]): void;
  startLoading(shapeIds: string[], abortController: AbortController): void;
  stopLoading(shapesIds: string[]): void;
};

const INITIAL_STATE: LoadingStore = {
  isLoadingIds: [],
  abortControllers: new Map(),
};

export const useLoadingStore = create<LoadingStore & LoadingActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      abortLoading(shapeIds) {
        const abortControllers = shapeIds
          .map((id) => get().abortControllers.get(id))
          .filter((item) => item);

        // @ts-expect-error strictNullChecks. Pls fix me
        unique(abortControllers).forEach((item) => item.abort());

        shapeIds.forEach((id) => {
          get().abortControllers.delete(id);
        });
      },

      isLoading(id) {
        return get().isLoadingIds.includes(id);
      },

      showLoader(shapeIds) {
        let { isLoadingIds } = get();

        set(
          {
            isLoadingIds: unique([...isLoadingIds, ...shapeIds]),
          },
          undefined,
          {
            type: 'showLoader',
          },
        );

        shapeIds.forEach((id) => {
          setRecoil(shapeAtom(id), (state) => ({ ...state, isLoading: true }));
        });
      },

      startLoading(shapeIds, abortController) {
        get().showLoader(shapeIds);
        get().abortLoading(shapeIds);

        let { abortControllers } = get();

        shapeIds.forEach((id) => {
          abortControllers.set(id, abortController);
          // @ts-expect-error strictNullChecks. Pls fix me
          setRecoil(shapeAtom(id), (state) => ({ ...state, error: null }));
        });
      },

      stopLoading(shapeIds: string[]) {
        let { abortControllers, isLoadingIds } = get();

        set(
          {
            isLoadingIds: isLoadingIds.filter((id) => !shapeIds.includes(id)),
          },
          undefined,
          {
            type: 'stopLoading',
          },
        );

        shapeIds.forEach((id) => {
          abortControllers.delete(id);
          setRecoil(shapeAtom(id), (state) => ({ ...state, isLoading: false }));
        });
      },
    }),
    { store: 'artefactLoadingStore', ...DEVTOOLS_OPTIONS },
  ),
);
