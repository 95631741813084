import axios from 'axios';

export const openLink = (url: string) => {
  window.open(url, '_blank', 'noopener');
};

export const download = (content: any, fileName: string, useWindowOpen = false) => {
  if (typeof content === 'string') {
    if (useWindowOpen) {
      window.open(content);
      return;
    }
    downloadUrl(content).then((data) => {
      simulateLink(data, fileName);
    });
    return;
  }

  simulateLink(content, fileName);
};

const downloadUrl = async (url: string) => {
  const response = await axios(url, {
    responseType: 'arraybuffer',
  });

  return response.data;
};

const simulateLink = (content: any, fileName: string) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([content]));
  link.download = fileName;
  link.click();
  link.remove();
};

export enum OperatingSystem {
  Windows = 'Windows',
  Mac = 'Mac',
  Others = 'Others',
}

export const getOperatingSystem = (): OperatingSystem => {
  const platform = navigator.userAgent;
  if (platform.indexOf('Win') != -1) {
    return OperatingSystem.Windows;
  }
  if (platform.indexOf('Mac') != -1) {
    return OperatingSystem.Mac;
  }
  return OperatingSystem.Others;
};

/**
 * Converts the given File into a Data URL string
 */
export const convertFileToDataURL = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const url =
          reader.result instanceof ArrayBuffer
            ? `data:image/png;base64,${arrayBufferToBase64(reader.result)}`
            : reader.result;

        // @ts-expect-error strictNullChecks. Pls fix me
        resolve(url);
      },
      false,
    );
    reader.addEventListener('error', (e) => reject(e), false);
    reader.readAsDataURL(file);
  });

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
}

/**
 * Converts the given Data URL string to a File
 */
export const convertDataUrlToFile = async (url: string, fileName: string) => {
  const blob = await (await fetch(url)).blob();
  return new File([blob], fileName, { type: blob.type });
};

/**
 * Parses the given text into an xml document
 *
 * @throws When parsing fails
 */
export const parseXMLDocument = (text: string, mimeType: DOMParserSupportedType) => {
  const document = new DOMParser().parseFromString(text, mimeType);

  // Chrome/Edge error
  let errorNode = document.querySelector('parsererror div');

  // Firefox error
  if (!errorNode) {
    errorNode = document.querySelector('parsererror');
  }

  if (errorNode) {
    // @ts-expect-error strictNullChecks. Pls fix me
    throw new Error(errorNode.textContent);
  }

  return document;
};

/**
 * Opens file select dialog.
 *
 * @returns Promise that resolves to a FileList or null if the user cancelled the operation
 */
export const openFile = (accept: string) =>
  new Promise<FileList>((resolve, reject) => {
    const input = document.createElement('input');
    input.style.display = 'none';
    input.type = 'file';
    input.accept = accept;
    input.addEventListener('change', (e) => {
      // @ts-expect-error strictNullChecks. Pls fix me
      resolve(input.files);
    });
    input.addEventListener('cancel', (e) => {
      // @ts-expect-error strictNullChecks. Pls fix me
      resolve(null);
    });
    input.click();
  });

export const downloadFile = (data: any, mimeType: string, fileName: string) => {
  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
