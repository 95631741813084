import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { theme } from '@common/components/theme';
import CloseIcon from '@assets/icons/CloseIcon';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '@/assets/icons';
import { filterOptionStyles, clearBtnStyles } from './styles';

const CLOSE_DELAY_MS = 200;

type FilterItem = {
  label: string;
  value: string;
};

type FilterMenuProps = {
  btnText: string;
  options: FilterItem[];
  selectedValues: string[];
  setSelectedValues: Dispatch<SetStateAction<any[]>>;
};

const FilterMenuComponent = ({
  btnText,
  options,
  selectedValues,
  setSelectedValues,
}: FilterMenuProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const closeTimerRef = useRef(null);
  const onMouseLeaveHandler = useCallback(() => {
    // @ts-expect-error strictNullChecks. Pls fix me
    closeTimerRef.current = setTimeout(close, CLOSE_DELAY_MS);
  }, [close]);

  const onMouseEnterHandler = useCallback(() => {
    if (closeTimerRef?.current) clearTimeout(closeTimerRef.current);
    setOpen(true);
  }, []);

  useEffect(
    () => () => {
      if (closeTimerRef?.current) clearTimeout(closeTimerRef.current);
    },
    [],
  );

  const canBeOpen = open && Boolean(anchorEl.current);
  const id = canBeOpen ? `filter-menu` : undefined;
  const anchorRef = useRef(null);
  const onMenuBtnClick = useCallback(() => {
    setOpen(true);
  }, []);

  const toggleItemSelection = useCallback(
    (value: string) => {
      setSelectedValues((current) => {
        if (current.includes(value)) return current.filter((item) => item !== value);

        return [...current, value];
      });
    },
    [setSelectedValues],
  );

  const onClickAway = useCallback((event: Event) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  }, []);

  const onClearAll = useCallback(() => {
    setSelectedValues([]);
  }, [setSelectedValues]);

  return (
    <Stack onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
      <Button
        aria-describedby={id}
        type='button'
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='menu'
        onClick={onMenuBtnClick}
        ref={anchorRef}
        variant='text'
        sx={{
          textTransform: 'none',
          color: 'neutral.darker',
          fontSize: '10px',
          fontWeight: 400,
          padding: '4px 0px',
          lineHeight: '16px',
          '&:hover': {
            backgroundColor: 'others.lighter',
            color: 'black',
          },
        }}
      >
        <Stack direction='row' alignItems='center' gap='4px'>
          <Typography fontWeight={selectedValues.length > 0 ? 600 : 400}>{btnText}</Typography>
          <ExpandMoreIcon sx={{ fontSize: '16px', color: theme.palette.disabled.main }} />
        </Stack>
      </Button>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement='bottom-start'
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
              transform: 'translateX(-15px)',
            }}
            timeout={{ appear: 50, enter: 300, exit: 0 }}
          >
            <Paper
              sx={{
                minWidth: '180px',
                boxShadow: '0px 0px 8px 3px #00000033',
              }}
            >
              <ClickAwayListener onClickAway={onClickAway}>
                <Stack>
                  <MenuList id='split-button-menu'>
                    <Stack
                      sx={{
                        maxHeight: '300px',
                        overflowY: 'scroll',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        paddingBottom: '8px',
                      }}
                    >
                      {options.map((item) => {
                        const selected = selectedValues.indexOf(item.value) > -1;
                        const icon = selected ? (
                          <CheckboxCheckedIcon className='check' />
                        ) : (
                          <CheckboxUncheckedIcon className='check' />
                        );

                        return (
                          <MenuItem
                            onClick={() => {
                              toggleItemSelection(item.value);
                            }}
                            key={item.value}
                            sx={filterOptionStyles}
                          >
                            <ListItemText primary={item.label} />
                            {icon}
                          </MenuItem>
                        );
                      })}
                    </Stack>
                    <Divider sx={{ marginBottom: '8px' }} />
                    <MenuItem
                      onClick={onClearAll}
                      sx={clearBtnStyles}
                      disabled={selectedValues.length === 0}
                    >
                      <ListItemText
                        primary={t('common:clear', 'Clear')}
                        sx={{
                          flex: '0 1 auto',
                        }}
                      />
                      <CloseIcon />
                    </MenuItem>
                  </MenuList>
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};

export const FilterMenu = memo(FilterMenuComponent);
