import { selector } from 'recoil';

import { ShapeGroup } from '@modules/common/types/shapeGroup';
import { selectedGroupsSelector } from '@modules/shapeGroups';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { DTShape } from '@modules/common/types/shapes';
import { selectedShapesAmountSelector, selectedShapesState } from '@recoil/shapes/selected';
import { PanelDisplayMode, panelModeState } from './panel';

const TITLE_MAP = {
  [PanelDisplayMode.AREA]: 'areas',
  [PanelDisplayMode.HIGHWAYS]: 'highways',
  [PanelDisplayMode.ANGLED_HIGHWAYS]: 'highways',
  [PanelDisplayMode.WALLS]: 'walls',
  [PanelDisplayMode.ELEMENTS]: 'elements',
  [PanelDisplayMode.OBSTACLES]: 'obstacles',
  [PanelDisplayMode.POSITIONS]: 'positions',
} as const;

export const propertiesHeaderNameSelector = selector({
  key: 'layout/propertiesPanel/headerName',
  get: ({ get }) => {
    const selectedGroups = get(selectedGroupsSelector);
    const selectedGroupedShapesAmount = new Set(
      get(selectedGroupsSelector).flatMap((item) => item.children),
    );
    const selectedShapes = get(selectedShapesState);

    // shapes that are grouped but now focused within the group
    if (
      selectedGroups.length === 1 &&
      // @ts-expect-error strictNullChecks. Pls fix me
      selectedGroups.at(0).children.length > selectedShapes.length
    ) {
      const elementName = TITLE_MAP[get(panelModeState)];
      return getHeaderNamePart(selectedShapes, elementName);
    }

    // shape name part
    const individualShapes = get(selectedShapesState).filter(
      (item) => !selectedGroupedShapesAmount.has(item.id),
    );
    const elementName = TITLE_MAP[get(panelModeState)];
    const shapeName = getHeaderNamePart(individualShapes, elementName);

    // group name part
    const groups = get(selectedGroupsSelector);
    const groupName = getHeaderNamePart(groups, 'groups');

    const selectedShapesAmount = get(selectedShapesAmountSelector);
    if (selectedGroups.length === 0) return shapeName;
    if (selectedGroupedShapesAmount.size === selectedShapesAmount) return groupName;
    return `${groupName}, ${shapeName}`;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const getHeaderNamePart = (selectedItems: ShapeGroup[] | DTShape[], appendix: string) => {
  switch (selectedItems.length) {
    case 0:
      return null;
    case 1:
      return selectedItems[0].name;
    default:
      return `${selectedItems.length} ${appendix}`;
  }
};
