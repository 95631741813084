import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { NumberInput } from '@/modules/common/components/inputs';
import { FLOW_MIN } from '@/modules/simulation';
import { CONTEXT, contextState } from '@/store/recoil/input';
import { useFlowUpdaters } from '@modules/flows/common/hooks';
import { FlowScope } from '@modules/flows/types';

type Props = {
  id: string;
  flowScope: FlowScope;
  totalNumLoads: number;
  disabled: boolean;
};

export const LoadPerHourInput = ({ id, flowScope, totalNumLoads, disabled }: Props) => {
  const { t } = useTranslation();
  const setContext = useSetRecoilState(contextState);
  const { onFlowLoadsChange } = useFlowUpdaters(id, flowScope);

  return (
    <NumberInput
      value={totalNumLoads}
      disabled={disabled}
      // @ts-expect-error strictNullChecks. Pls fix me
      onChange={onFlowLoadsChange}
      onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
      onBlur={() => setContext(CONTEXT.WORKSPACE)}
      ariaLabel={t('interface:properties.flows.flow_info.total_loads.label')}
      errorType='floating'
      dataType='integer'
      sx={{ float: 'right', height: '32px' }}
      minValue={FLOW_MIN}
      minValueErrorMsg={t('common:minimum_value', { minValue: FLOW_MIN })}
    />
  );
};
