import { LayoutFlow } from '@/modules/flows/types';
import { DEFAULT_FLOW_LOADS_AMOUNT, DEFAULT_FLOW_VEHICLE_LIMIT } from '@modules/flows/store/layout/constants';
import { atom, atomFamily } from 'recoil';

export const simulationFlowAtom = atomFamily<LayoutFlow, string>({
  key: 'flow/simulation',
  default: {
    // @ts-expect-error strictNullChecks. Pls fix me
    id: null,
    name: '',
    // @ts-expect-error strictNullChecks. Pls fix me
    intakeFlowStop: null,
    // @ts-expect-error strictNullChecks. Pls fix me
    deliveryFlowStop: null,
    totalNumLoads: DEFAULT_FLOW_LOADS_AMOUNT,
    // @ts-expect-error strictNullChecks. Pls fix me
    loadProfile: null,
    vehicleLimit: DEFAULT_FLOW_VEHICLE_LIMIT,
  },
});

export const simulationFlowIdsAtom = atom({
  key: 'flow/simulation/ids',
  default: [],
});
