import { useCallback } from 'react';

import { DEFAULT_REFERENCE_IMAGE_SETTINGS } from '@modules/referenceImage';
import { ReferenceImageSettings } from '@modules/referenceImage/types';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const useImageSettings = () => {
  const createSettings = useCallback(
    (
      floorPlanId: string,
      base64string: string,
      width: number,
      height: number,
      scaledWidth?: number,
      scaledHeight?: number,
    ): ReferenceImageSettings => ({
      ...DEFAULT_REFERENCE_IMAGE_SETTINGS,
      cacheRef: floorPlanId,
      // @ts-expect-error strictNullChecks. Pls fix me
      file: base64string,
      width: scaledWidth || width,
      height: scaledHeight || height,
      opacity: getUserPreference(UserPreferenceName.REFERENCE_OPACITY),
      original: {
        width,
        height,
      },
      crop: {
        x: 0,
        y: 0,
        width: scaledWidth || width,
        height: scaledHeight || height,
      },
    }),
    [],
  );

  const createAPSSetting = useCallback(
    (urn: string, floorplanId: string): ReferenceImageSettings => ({
      ...DEFAULT_REFERENCE_IMAGE_SETTINGS,
      cacheRef: floorplanId,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      opacity: getUserPreference(UserPreferenceName.REFERENCE_OPACITY),
      original: {
        width: 0,
        height: 0,
      },
      crop: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      // @ts-expect-error strictNullChecks. Pls fix me
      apsUrn: urn,
    }),
    [],
  );

  return {
    createAPSSetting,
    createSettings,
  };
};
