import { Stack } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { currentGroupSimulationSelector } from '@modules/simulation/store/group';
import { CardContent } from './CardContent';
import { CardHeader } from './CardHeader';

type Props = {
  id: string;
};

const containerSx = {
  border: 'none',
  borderColor: 'unset',
  borderBottom: '1px solid',
  borderBottomColor: 'neutral.lighter',
  py: 2,
};

export function GroupPanelCard({ id }: Props) {
  const simulation = useRecoilValue(currentGroupSimulationSelector(id));
  const [isHover, setIsHover] = useState<boolean>();

  return (
    <Stack
      onMouseEnter={() => setIsHover(true)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      component='article'
      sx={containerSx}
    >
      <CardHeader
        simulation={simulation}
        // @ts-expect-error strictNullChecks. Pls fix me
        isHover={isHover}
      />
      <CardContent simulation={simulation} />
    </Stack>
  );
}
