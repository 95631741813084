import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { DistantConnection } from '@modules/common/types/connections';
import { unique } from '@modules/common/helpers/array';

import { allDistconIds, distcon } from './atom';

export const allDistantConnectionsSelector = selector({
  key: 'connections/distant/all',
  get: ({ get }) => get(allDistconIds).map((id) => get(distcon(id))),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const removeDistantConnectionSelector = selector<string[]>({
  key: 'connections/distant/remove',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: () => null,
  set: ({ set, reset }, ids: string[]) => {
    ids.forEach((item) => reset(distcon(item)));
    set(allDistconIds, (state) => state.filter((item) => !ids.includes(item)));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

type UpdateConnectionsSelectorValue = {
  connections: DistantConnection[];
  connectionIdsToRemove?: Set<string>;
};

export const updateConnectionsSelector = selector<UpdateConnectionsSelectorValue>({
  key: 'connections/distant/update',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: () => null,
  set: ({ set, reset }, value: UpdateConnectionsSelectorValue) => {
    value.connections.forEach((connection) => set(distcon(connection.id), connection));

    if (value.connectionIdsToRemove) {
      value.connectionIdsToRemove.forEach((id) => reset(distcon(id)));
    }

    set(allDistconIds, (state) => {
      const newState = unique([...state, ...value.connections.map((item) => item.id)]);
      return value.connectionIdsToRemove
        ? // @ts-expect-error strictNullChecks. Pls fix me
          newState.filter((item) => !value.connectionIdsToRemove.has(item))
        : newState;
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const saveLoadDistantConnectionsSelector = selector({
  key: 'connections/distant/save-load',
  get: ({ get }) => get(allDistconIds).map((id) => get(distcon(id))),
  set: ({ set }, connections: DistantConnection[]) => {
    const ids = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const connection of connections) {
      // @ts-expect-error strictNullChecks. Pls fix me
      ids.push(connection.id);
      set(distcon(connection.id), connection);
    }
    set(allDistconIds, ids);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
