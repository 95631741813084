import { useEffect, useState } from 'react';
import {
  findRootOf,
  mapEventDataToTagTree,
  ObjectTreeItem,
  ObjectTreeVisibilityChangeEvent,
} from '@thive/fleet-tracker.ui.react';
import { useTranslation } from 'react-i18next';

import { useEventBus, EventNames } from '../../../hooks';
import { SOURCE_NAME } from '@/modules/visualization/consts';

export const useTagTree = () => {
  const [tagTree, setTagTree] = useState<ObjectTreeItem[]>();
  const { t } = useTranslation('interface');
  const { on, off, emit } = useEventBus();

  useEffect(() => {
    const onEvent = (e) => {
      if (e.action === 'onTagTreeUpdate') {
        e.payload.forEach((item: any) => {
          if (item.label === 'VikingVisualEvents')
            item.label = t('visualization.trees.VikingVisualEvents');
          else if (item.label === SOURCE_NAME)
            item.label = t('visualization.trees.DigitalTwin-Layout');
        });
        setTagTree(mapEventDataToTagTree(e.payload));
      }
    };

    on(EventNames.VisualizationWebGLReceive, onEvent);

    return () => {
      off(EventNames.VisualizationWebGLReceive, onEvent);
    };
  }, [on, off, t]);

  return {
    tagTree,
    toggleTagVisibility: (e: ObjectTreeVisibilityChangeEvent) => {
      // @ts-expect-error strictNullChecks. Pls fix me
      const source = findRootOf(e.id, tagTree);

      emit(EventNames.VisualizationWebGLSend, {
        target: 'Tracker',
        action: 'SetTagVisibility',
        payload: {
          name: e.id,
          source,
          isVisible: e.visible,
        },
      });
    },
  };
};
