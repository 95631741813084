import { Status } from '@modules/floorplanService/enum';
import { atom } from 'recoil';
import { FpsAtom, IdGenerator } from '../types';

export const fpsAtom = atom<FpsAtom>({
  key: 'fpsAtom',
  default: {
    // @ts-expect-error strictNullChecks. Pls fix me
    files: null,
    // @ts-expect-error strictNullChecks. Pls fix me
    status: null,
    warnings: [],
  },
});

export const validationStatus = atom<Status>({
  key: 'validationStatus',
  default: Status.Idle,
});

export const customIdGeneratorsState = atom<IdGenerator[]>({
  key: 'idGenerators/custom',
  default: [],
});
