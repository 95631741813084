import { UnifiedVehicle, VehicleAssets } from '@/modules/vehicles';
import { FLOORPLAN_ASSET_FILE_CONTAINER } from '@/modules/vehicles/constants';
import { buildAssetFileDownloadUrl } from '@/modules/vehicles/helpers/general';

export const genTStackExportProperties = (
  isTStackExport: boolean,
  vehicles: UnifiedVehicle[],
  vehiclesAssets: VehicleAssets[],
  fileUrl: string,
): {} | { kmTemplateMdbDownloadUrl: string } => {
  if (!isTStackExport) return {};

  let properties = {};

  if (isTStackExport && vehicles.length === 1) {
    const vId = vehicles[0].id;
    const kmTemplateMdbDownloadUrl = buildAssetFileDownloadUrl(
      fileUrl,
      FLOORPLAN_ASSET_FILE_CONTAINER,
      // @ts-expect-error strictNullChecks. Pls fix me
      vehiclesAssets.find((assets) => assets.vehicleVariantId === vId)?.kmMdbReference,
    );

    if (!kmTemplateMdbDownloadUrl)
      console.log('Could not provide kmTemplateMdbDownloadUrl to FPS. VehicleId: ', vId);

    properties = {
      ...properties,
      kmTemplateMdbDownloadUrl,
    };
  }

  return properties;
};
