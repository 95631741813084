import { useCallback } from 'react';
import { projectIdSelector, useFloorPlanState } from '@modules/floorplan';
import { useRecoilValue } from 'recoil';
import { useZoomButton } from '@modules/workspace/components/ZoomButton/useZoomButton';

export const useHome = () => {
  const { saveFloorPlan } = useFloorPlanState();
  const projectId = useRecoilValue(projectIdSelector);
  const { zoomFit } = useZoomButton();

  const goHome = useCallback(() => {
    let debounce = null;
    const debounceDelay = 100;

    zoomFit(false);
    saveFloorPlan();

    if (debounce) clearTimeout(debounce);
    // @ts-expect-error strictNullChecks. Pls fix me
    debounce = setTimeout(async () => {
      window.location.href = `/${projectId}`;
    }, debounceDelay);
  }, [projectId, saveFloorPlan, zoomFit]);

  return {
    goHome,
  };
};
