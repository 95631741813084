import { useRecoilCallback, useRecoilValue } from 'recoil';

import { OrientedBoundingBox } from '@/helpers/types';
import { calculateBoundingBox, calculateOrientedBoundingBox, calculatePointsShapeBoundingBox } from '@modules/common/helpers/boundingBox';
import { isPointsShape } from '@modules/common/types/guards';
import { useGetReferenceProperties } from '@modules/referenceImage';
import { clampZoomScale } from '@modules/workspace/helpers/clampZoomScale';
import { shapePropertyCanvas } from '@recoil/shape';
import { allShapesSelector } from '@recoil/shapes';
import {
  MAX_ZOOM_SCALE,
  MAX_ZOOM_SCALE_CLAMPED,
  ZOOM_MULTIPLIER,
  ZOOM_SPACE,
  scaleSelector,
  viewportSelector,
  zoomSelector,
} from '@recoil/workspace';

export const useZoomButton = () => {
  const zoom = useRecoilValue(scaleSelector);
  const { getReferenceProperties } = useGetReferenceProperties();

  const zoomFitReference = useRecoilCallback(
    ({ snapshot, set }) =>
      async (isZoom = true) => {
        const limitZoomIn = false;
        const [viewport, referenceProperties] = await Promise.all([
          snapshot.getPromise(viewportSelector),
          getReferenceProperties(),
        ]);

        if (!referenceProperties) return null;

        let referenceShapePropertiesInMm:
          | { properties: { x: number; y: number; height: number; width: number } }
          | undefined;
        let boxInMm = { width: viewport.w * 10, height: viewport.h * 10, x: 0, y: 0 };

        if (referenceProperties) {
          referenceShapePropertiesInMm = {
            properties: {
              x: referenceProperties.x,
              y: referenceProperties.y,
              width: referenceProperties.width,
              height: referenceProperties.height,
            },
          };

          boxInMm = calculateBoundingBox([referenceShapePropertiesInMm.properties]);
        }

        let newScale =
          Math.min(viewport.w / (boxInMm.width / 10), viewport.h / (boxInMm.height / 10)) /
          (1 + ZOOM_SPACE);
        // Infinity or NaN
        if (!Number.isFinite(newScale)) newScale = 1;

        if (limitZoomIn) {
          newScale = clampZoomScale({
            scale: newScale,
            clampMaxScale: limitZoomIn,
            clampMinScale: true,
            maxScale: limitZoomIn ? MAX_ZOOM_SCALE_CLAMPED : MAX_ZOOM_SCALE,
          });
        }

        const centerInCm = {
          x:
            -(boxInMm.x / 10) * newScale + (viewport.w / 2 - ((boxInMm.width / 10) * newScale) / 2),
          y:
            -(boxInMm.y / 10) * newScale +
            (viewport.h / 2 - ((boxInMm.height / 10) * newScale) / 2),
        };

        if (isZoom || referenceShapePropertiesInMm) {
          set(zoomSelector, { newScale, forceCenter: centerInCm, limitZoomIn });
        }
      },
    [],
  );

  const zoomFit = useRecoilCallback(
    ({ snapshot, set }) =>
      async (isZoom = true) => {
        const limitZoomIn = true;
        const [viewport, shapes, referenceProperties] = await Promise.all([
          snapshot.getPromise(viewportSelector),
          snapshot.getPromise(allShapesSelector),
          getReferenceProperties(),
        ]);
        // @ts-expect-error strictNullChecks. Pls fix me
        const onlyReferenceImageIsPresent: boolean = referenceProperties && shapes.length < 1;

        if (onlyReferenceImageIsPresent) {
          await zoomFitReference();
          return;
        }

        const allShapes: OrientedBoundingBox[] = shapes.map((item) => {
          if (isPointsShape(item)) {
            return {
              ...calculatePointsShapeBoundingBox(
                item.properties.controlPoints.map((cp) => cp.position),
                item.parameters.width,
              ),
              r: 0,
            };
          }

          return item.properties;
        });

        if (referenceProperties) {
          allShapes.push({
            x: referenceProperties.x,
            y: referenceProperties.y,
            width: referenceProperties.width,
            height: referenceProperties.height,
            r: 0,
          });
        }

        const boxInMm = allShapes.length
          ? calculateOrientedBoundingBox(allShapes)
          : { width: viewport.w * 10, height: viewport.h * 10, x: 0, y: 0 };

        let newScale =
          Math.min(viewport.w / (boxInMm.width / 10), viewport.h / (boxInMm.height / 10)) /
          (1 + ZOOM_SPACE);
        // Infinity or NaN
        if (!Number.isFinite(newScale)) newScale = 1;

        if (limitZoomIn) {
          newScale = clampZoomScale({
            scale: newScale,
            clampMaxScale: limitZoomIn,
            clampMinScale: true,
            maxScale: limitZoomIn ? MAX_ZOOM_SCALE_CLAMPED : MAX_ZOOM_SCALE,
          });
        }

        const centerInCm = {
          x: (-boxInMm.x * newScale) / 10 + (viewport.w / 2 - (boxInMm.width * newScale) / 20),
          y: (-boxInMm.y * newScale) / 10 + (viewport.h / 2 - (boxInMm.height * newScale) / 20),
        };

        if (isZoom || allShapes.length > 0) {
          set(zoomSelector, { newScale, forceCenter: centerInCm, limitZoomIn });
        }
      },
    [],
  );

  const zoomFitShape = useRecoilCallback(
    ({ snapshot, set }) =>
      async (id: string) => {
        const properties = await snapshot.getPromise(shapePropertyCanvas(id));
        const viewport = await snapshot.getPromise(viewportSelector);

        let boxInCm = {
          width: properties.width,
          height: properties.height,
          x: properties.x,
          y: properties.y,
        };

        let newScale = Math.min(viewport.w / boxInCm.width, viewport.h / boxInCm.height) / (1 + 1);
        // Infinity or NaN
        if (!Number.isFinite(newScale)) newScale = 1;

        const centerInCm = {
          x: -boxInCm.x * newScale + (viewport.w / 2 - (boxInCm.width * newScale) / 2),
          y: -boxInCm.y * newScale + (viewport.h / 2 - (boxInCm.height * newScale) / 2),
        };

        set(zoomSelector, { newScale, forceCenter: centerInCm, limitZoomIn: true });
      },
    [],
  );

  const zoomIn = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const zoom = await snapshot.getPromise(scaleSelector);
        set(zoomSelector, { newScale: zoom * ZOOM_MULTIPLIER });
      },
    [],
  );

  const zoomOut = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const zoom = await snapshot.getPromise(scaleSelector);
        set(zoomSelector, { newScale: zoom / ZOOM_MULTIPLIER });
      },
    [],
  );

  const zoomReset = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(zoomSelector, { newScale: 1 });
      },
    [],
  );

  return {
    zoom,
    zoomOut,
    zoomIn,
    zoomFit,
    zoomReset,
    zoomFitShape,
    zoomFitReference,
  };
};
