import { useRecoilCallback } from 'recoil';
import { allShapesSelector } from '@recoil/shapes';
import { DistantConnection } from '@modules/common/types/connections';
import { toMap } from '@modules/common/helpers/array';

import { allDistantConnectionsSelector, updateConnectionsSelector } from '../store';
import { updateConnectionPositioning } from '../helpers/connections';
import { getShapeId } from '../../common/connectionId';

export const useUpdateDistantConnection = () => {
  /**
   * Update distant connections for the given shapes
   */
  const updateDistantConnections = useRecoilCallback(
    ({ set, snapshot }) =>
      async (shapeIds: string[]) => {
        const [connections, shapes] = await Promise.all([
          snapshot.getPromise(allDistantConnectionsSelector),
          snapshot.getPromise(allShapesSelector),
        ]);

        const shapeDict = toMap(shapes, (item) => item.id);
        const connectionsToUpdate: DistantConnection[] = [];

        shapes
          .filter((shape) => shapeIds.includes(shape.id))
          .forEach((shape) => {
            const fromConnections = connections.filter(
              (connection) => getShapeId(connection.from) === shape.id,
            );
            const toConnections = connections.filter(
              (connection) => getShapeId(connection.to) === shape.id,
            );

            fromConnections.forEach((connection) => {
              connectionsToUpdate.push(
                updateConnectionPositioning(
                  connection,
                  shape,
                  // @ts-expect-error strictNullChecks. Pls fix me
                  shapeDict.get(getShapeId(connection.to)),
                ),
              );
            });

            toConnections.forEach((connection) => {
              connectionsToUpdate.push(
                updateConnectionPositioning(
                  connection,
                  // @ts-expect-error strictNullChecks. Pls fix me
                  shapeDict.get(getShapeId(connection.from)),
                  shape,
                ),
              );
            });
          });

        set(updateConnectionsSelector, {
          connections: connectionsToUpdate,
        });
      },
    [],
  );

  return {
    updateDistantConnections,
  };
};
