import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { enabledVehicleIdsState } from './atom';
import { UnifiedVehicleDetails } from '../../types';
import { vehicleDetailsState } from '../vehicleDetailsState';

export const enabledVehiclesDetailsSelector = selector<UnifiedVehicleDetails[]>({
  key: 'vehicles/enabled/details',
  get: ({ get }) => {
    const out = [];
    const vehicleIds = get(enabledVehicleIdsState);
    vehicleIds.forEach((id) => {
      const vehicleDetails = get(vehicleDetailsState(id));
      if (vehicleDetails) {
        // @ts-expect-error strictNullChecks. Pls fix me
        out.push(vehicleDetails);
      }
    });
    return out;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
