import { useRecoilCallback } from 'recoil';

import { MAX_ORDER_PROFILE_HOURS } from '@/modules/common/constants/orderProfile';
import { groupIdSelector, projectIdSelector, useFloorPlanService } from '@/modules/floorplan';
import {
  ordersPerHourToLoadProfile,
  prepareSimulationFlow,
  prepareSimulationFlows,
  removeZeroOrderFlows,
} from '@/modules/orderProfile/helpers';
import { orderProfileAtom } from '@/modules/orderProfile/store/orderProfileAtom';
import { SimulationDraft } from '@/modules/simulation/helpers/types';
import { layoutFlowSelector, layoutFlowsSelector } from '@modules/flows/store/layout';
import { toolButtonState } from '@recoil/tool';
import { simulationFlowAtom, simulationFlowIdsAtom } from '../../store/simulation/atom';
import { simulationFlowsSelector } from '../../store/simulation/selector';
import { LayoutFlow } from '../../types';

export const useSimulationFlows = () => {
  const { fetchVersion } = useFloorPlanService();

  const addSimulationFlow = useRecoilCallback(
    ({ set }) =>
      async (flow: LayoutFlow) => {
        set(simulationFlowAtom(flow.id), flow);
        // @ts-expect-error strictNullChecks. Pls fix me
        set(simulationFlowIdsAtom, (currentValue) => [...currentValue, flow.id]);
      },
    [],
  );

  const removeSimulationFlow = useRecoilCallback(
    ({ set, reset }) =>
      async (flowId: string) => {
        reset(simulationFlowAtom(flowId));
        set(simulationFlowIdsAtom, (currentIds) => {
          const newIds = currentIds.filter((id) => id !== flowId);
          return newIds;
        });
      },
    [],
  );

  const copyLayoutFlowToSimulationFlow = useRecoilCallback(
    ({ snapshot }) =>
      async (flowId: string) => {
        let flow = await snapshot.getPromise(layoutFlowSelector(flowId));
        const orderProfile = await snapshot.getPromise(orderProfileAtom);
        if (orderProfile) {
          flow = prepareSimulationFlow(flow, MAX_ORDER_PROFILE_HOURS);
        }

        await addSimulationFlow(flow);
      },
    [],
  );

  const copyAllLayoutFlowsToSimulationFlows = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        let flows = await snapshot.getPromise(layoutFlowsSelector);

        const orderProfile = await snapshot.getPromise(orderProfileAtom);
        if (orderProfile) {
          flows = prepareSimulationFlows(flows, MAX_ORDER_PROFILE_HOURS);
          flows = removeZeroOrderFlows(flows);
        }

        set(simulationFlowsSelector, flows);
      },
    [],
  );

  const updateSimulationFlowsWithSimulationDraft = useRecoilCallback(
    ({ snapshot, set }) =>
      async (draft: SimulationDraft) => {
        const { flows } = draft;
        draft.flows = [];
        const projectId = await snapshot.getPromise(projectIdSelector);
        const groupId = await snapshot.getPromise(groupIdSelector);
        const floorPlan = await fetchVersion(projectId, groupId, draft.floorPlanId);
        const simulationFlows: LayoutFlow[] = [];
        flows.forEach((flow) => {
          // find matching flow id in the floorplan
          const matchFlow = floorPlan.additionalData.flows.find(
            (f) => f.sourceName === flow.intakeName && f.targetName === flow.deliveryName,
          );
          if (matchFlow) {
            const newFlow = { ...matchFlow };
            if (flow.ordersPerHours)
              newFlow.loadProfile = ordersPerHourToLoadProfile(flow.ordersPerHours);
            if (flow.loadsCount) newFlow.totalNumLoads = flow.loadsCount; // should be removed (obsolete) when new Simulation.Api support orderprofile
            if (flow.vehicleLimit >= 0) newFlow.vehicleLimit = flow.vehicleLimit;
            simulationFlows.push(newFlow);
          }
        });
        set(simulationFlowsSelector, simulationFlows);
      },
    [],
  );

  const selectFlowTool = useRecoilCallback(
    ({ set }) =>
      async () =>
        set(toolButtonState, 'flow'),
    [],
  );

  return {
    selectFlowTool,
    copyLayoutFlowToSimulationFlow,
    copyAllLayoutFlowsToSimulationFlows,
    updateSimulationFlowsWithSimulationDraft,
    removeSimulationFlow,
  };
};
