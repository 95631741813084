import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Vector2 } from 'three';
import { Group } from 'konva/lib/Group';

import {
  referenceCrop,
  referenceDimensions,
  referenceMode,
  referenceScale,
} from '@modules/referenceImage/store';
import { ReferenceImageSettings } from '@modules/referenceImage/types';
import { toolButtonState } from '@recoil/tool';
import { scaleSelector } from '@recoil/workspace';

export const useScalePoints = (referenceImage: ReferenceImageSettings, group: Group) => {
  const [scale, setScale] = useRecoilState(referenceScale);
  const zoom = useRecoilValue(scaleSelector);
  const [dimensionState, setDimensionState] = useRecoilState(referenceDimensions);
  const [cropState, setCropState] = useRecoilState(referenceCrop);
  const setModeState = useSetRecoilState(referenceMode);
  const setTool = useSetRecoilState(toolButtonState);

  useEffect(() => {
    if (scale.set === true && scale.pointA && scale.pointB) {
      const currentRatio = referenceImage.original.width / referenceImage.width;

      // Get longer side of the scale line
      const distanceOnImageInCm = Math.max(
        Math.abs(scale.pointB.x - scale.pointA.x),
        Math.abs(scale.pointB.y - scale.pointA.y),
      );

      const distanceInCm = referenceImage.dist / 10; // convert from mm (user input) to cm (canvas)
      const newRatio = distanceInCm / distanceOnImageInCm / currentRatio;
      const node = group;

      node.scaleX(1);
      node.scaleY(1);

      const newWidth = Math.max(5, Math.round(referenceImage.original.width * newRatio));
      const newHeight = Math.max(5, Math.round(referenceImage.original.height * newRatio));

      const deltaRatio = newWidth / dimensionState.width;
      const scaledPointA = new Vector2(scale.pointA.x, scale.pointA.y).multiplyScalar(deltaRatio);
      const scaledPointB = new Vector2(scale.pointB.x, scale.pointB.y).multiplyScalar(deltaRatio);

      setDimensionState({
        ...dimensionState,
        x: Math.round(node.x() * 10),
        y: Math.round(node.y() * 10),
        width: newWidth,
        height: newHeight,
      });

      // RESET CROP AFTER SCALE
      setCropState({
        ...cropState,
        x: 0,
        y: 0,
        // set minimal value
        width: newWidth,
        height: newHeight,
      });

      setScale({
        dist: 0,
        set: false,
        free: true,
        pointA: null,
        pointB: null,
        setPointA: scaledPointA,
        setPointB: scaledPointB,
        setDist: scale.dist,
      });
      // @ts-expect-error strictNullChecks. Pls fix me
      setTool(null);
      // @ts-expect-error strictNullChecks. Pls fix me
      setModeState(null);

      // // Find out by how much position and size of the shapes should be adjusted
      // const shapesRatio = newWidth / width;

      // const scaledShapes = allShapes.map((shape) => {
      //   let { x, y, width, height } = shape.properties;

      //   x *= shapesRatio;
      //   y *= shapesRatio;

      //   const isWall = shape.type === SHAPE_TYPE.WALL;
      //   width *= isWall && height > width ? 1 : shapesRatio;
      //   height *= isWall && width > height ? 1 : shapesRatio;

      //   return { ...shape, properties: { ...shape.properties, x, y, width, height } };
      // });

      // setAllShapes(scaledShapes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale.set]);

  return {
    zoom,
    scale,
  };
};
