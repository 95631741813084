import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { shapeGroupState } from '@/modules/shapeGroups';
import { AreaShape } from '@/store/recoil/shape';
import shapeAtom from '@/store/recoil/shape/atom';
import { getShapeAttachPointArea, getShapeAttachPointProcess } from '@modules/common/helpers/shapes';
import { useRecoilCallback } from 'recoil';
import { layoutFlowSelector } from '../store/layout';
import { FlowAttachPoints, FlowAttachShapeIds, FlowStopType } from '../types';
import { isAreaShape } from '@/modules/common/types/guards';
import { ShapeType } from '@/modules/common/types/shapes';

export const useFlowRendering = () => {
  const getAttachShapeIds = useRecoilCallback(
    ({ snapshot }) =>
      async (flowId: string): Promise<FlowAttachShapeIds> => {
        const flow = await snapshot.getPromise(layoutFlowSelector(flowId));
        // @ts-expect-error strictNullChecks. Pls fix me
        if (!flow || !flow.intakeFlowStop || !flow.deliveryFlowStop) return null;

        // from position
        // @ts-expect-error strictNullChecks. Pls fix me
        let fromShapeId: string = null;
        if (flow.intakeFlowStop.type === FlowStopType.AREA) {
          fromShapeId = flow.intakeFlowStop.id;
        } else if (flow.intakeFlowStop.type === FlowStopType.PROCESS) {
          fromShapeId = flow.intakeFlowStop.id;
        } else {
          const fromGroup = await snapshot.getPromise(shapeGroupState(flow.intakeFlowStop.id));
          const [firstId] = fromGroup.children;
          fromShapeId = firstId;
        }

        // to position
        // @ts-expect-error strictNullChecks. Pls fix me
        let toShapeId: string = null;
        if (flow.deliveryFlowStop.type === FlowStopType.AREA) {
          toShapeId = flow.deliveryFlowStop.id;
        } else if (flow.deliveryFlowStop.type === FlowStopType.PROCESS) {
          toShapeId = flow.deliveryFlowStop.id;
        } else {
          const toGroup = await snapshot.getPromise(shapeGroupState(flow.deliveryFlowStop.id));
          const [firstId] = toGroup.children;
          toShapeId = firstId;
        }

        const attachIds: FlowAttachShapeIds = {
          fromShapeId,
          toShapeId,
        };
        return attachIds;
      },
    [],
  );

  const getAttachPoints = useRecoilCallback(
    ({ snapshot }) =>
      async (fromShapeId: string, toShapeId: string): Promise<FlowAttachPoints> => {
        const fromShape = await snapshot.getPromise(shapeAtom(fromShapeId));
        const fromPos = isAreaShape(fromShape) 
          ? getShapeAttachPointArea(fromShape) 
          : getShapeAttachPointProcess(fromShape as ProcessTwoEPShape, ShapeType.INTAKE)

        const toShape = await snapshot.getPromise(shapeAtom(toShapeId));
        const toPos = isAreaShape(toShape) 
          ? getShapeAttachPointArea(toShape) 
          : getShapeAttachPointProcess(toShape as ProcessTwoEPShape, ShapeType.DELIVERY)

        const attachPoints: FlowAttachPoints = {
          from: fromPos,
          to: toPos,
        };

        return attachPoints;
      },
    [],
  );

  return { getAttachShapeIds, getAttachPoints };
};
