import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { simulationAtom } from './simulationAtom';

/**
 * Range of vehicles selected for simulation
 */
export const vehicleTypesCountSelector = selector<string>({
  key: 'simulations/draft/vehicleTypesCount',
  get: ({ get }) => {
    const { vehicleTypes } = get(simulationAtom);
    // @ts-expect-error strictNullChecks. Pls fix me
    const { range } = vehicleTypes.at(0);

    return range.length === 1
      ? // @ts-expect-error strictNullChecks. Pls fix me
        vehicleTypes.reduce((acc, item) => acc + item.range.at(0), 0).toString()
      : [range.at(0), range.at(-1)].join(', ');
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
