import ExcelJS, { Borders, Fill } from 'exceljs';
import { t } from 'i18next';
import { validate as validateUuid } from 'uuid';

const EXCEL_HEADER_FILL: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFF0F0F0' },
};

const EXCEL_HEADER_BORDER: Borders = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' },
  // @ts-expect-error strictNullChecks. Pls fix me
  diagonal: undefined,
};

const EXCEL_MODIFIABLE_FILL: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFDFE5FC' },
};

export const SUPPORTED_FILE_TYPES = '.xls,.xlsx';

export const writeInfoSection = (
  sheet: ExcelJS.Worksheet,
  projectName: string,
  floorPlanName: string,
  floorPlanVersion: number,
) => {
  let rowIndex = 1;
  sheet.getRow(rowIndex++).values = ['Project name', projectName];
  sheet.getRow(rowIndex++).values = ['Floorplan name', floorPlanName];
  sheet.getRow(rowIndex++).values = ['Version', floorPlanVersion.toString()];
  sheet.getRow(rowIndex++).values = ['Remarks', 'Only modify cells in this color'];
  sheet.getCell(`B${rowIndex - 1}`).fill = EXCEL_MODIFIABLE_FILL;
  for (let i = 1; i < rowIndex; ++i) {
    sheet.getCell(`A${i}`).font = {
      bold: true,
    };
    sheet.getCell(`A${i}`).fill = EXCEL_HEADER_FILL;
    sheet.getCell(`A${i}`).border = EXCEL_HEADER_BORDER;
  }
  return rowIndex;
};

export const writeHeaderRow = (sheet: ExcelJS.Worksheet, rowIndex: number, values: string[]) => {
  sheet.getRow(rowIndex).values = values;
  sheet.getRow(rowIndex).font = {
    bold: true,
  };
  sheet.getRow(rowIndex).alignment = {
    horizontal: 'center',
  };
  sheet.getRow(rowIndex).fill = EXCEL_HEADER_FILL;
  sheet.getRow(rowIndex).border = EXCEL_HEADER_BORDER;
};

export const markCellModifiable = (cell: ExcelJS.Cell) => {
  cell.fill = EXCEL_MODIFIABLE_FILL;
};

// TODO error localizations
export const parseGuidCell = (cell: ExcelJS.Cell) => {
  if (cell.value == null) {
    throw new Error(t('errors:excel.cell.uuid_null', { address: cell.address }), {
      cause: {
        cellAddress: cell.address,
      },
    });
  }

  if (!validateUuid(cell.value.toString())) {
    throw new Error(t('errors:excel.cell.uuid_not_uuid', { address: cell.address }), {
      cause: {
        cellAddress: cell.address,
      },
    });
  }

  return cell.value.toString();
};

export const parseTextCell = (cell: ExcelJS.Cell) => {
  if (cell.value == null) {
    throw new Error(t('errors:excel.cell.text_null', { address: cell.address }), {
      cause: {
        cellAddress: cell.address,
      },
    });
  }

  return cell.value.toString();
};

export const parseNumberCell = (cell: ExcelJS.Cell) => {
  if (cell.value == null) {
    throw new Error(t('errors:excel.cell.number_null', { address: cell.address }), {
      cause: {
        cellAddress: cell.address,
      },
    });
  }

  const { value } = cell;
  const number =
    typeof value === 'number' || (typeof value === 'string' && value !== '') ? Number(value) : NaN;

  if (Number.isNaN(number)) {
    throw new Error(t('errors:excel.cell.number_not_number', { address: cell.address }), {
      cause: {
        cellAddress: cell.address,
      },
    });
  }

  return number;
};
