import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { versionAtom } from './versionsAtom';

export const selectedSelector = selectorFamily<boolean, string>({
  key: 'versioning/versions/selected',
  // @ts-expect-error strictNullChecks. Pls fix me
  get:
    (id: string) =>
    ({ get }) =>
      get(versionAtom(id)).isSelected,
  set: (id: string) => ({ set }, isSelected: boolean) =>
      set(versionAtom(id), (state) => ({
        ...state,
        isSelected,
      })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT
});
