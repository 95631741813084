import { selector } from 'recoil';

import floorplanAtom from '@/store/recoil/floorplan';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

/**
 * Id of the currently loaded floor plan
 */
export const idSelector = selector<string>({
  key: 'floorplan/id',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: ({ get }) => get(floorplanAtom).id,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
