import { supportsLoadCarriers } from '@/modules/common/helpers/shapes';
import { isAreaShape, isHighwayShape } from '@/modules/common/types/guards';
import { isVertical } from '@/modules/workspace/helpers/shape';
import { DTShape, ShapeType } from '@modules/common/types/shapes';

export const AREA_MINIMUM_SIZE = 1000;
export const OBSTACLE_MINIMUM_SIZE = 50;
export const WALL_MINIMUM_LENGTH = 50;

export function getMinimumSizeByShapeType(type: ShapeType) {
  const minSize =
    type === ShapeType.WALL
      ? WALL_MINIMUM_LENGTH
      : type === ShapeType.OBSTACLE
      ? OBSTACLE_MINIMUM_SIZE
      : AREA_MINIMUM_SIZE;

  return minSize;
}

/**
 * returns the minimum size for a shape, taking into account the provided max dimensions
 *
 * @param shape
 * @param vehiclesMaxDimensions represents the largest width, and largest length of a series of vehicle dimensions.
 * @param loadCarriersMaxDimensions represents the largest width, and largest length of a series of load carrier dimensions.
 */
export function getMinimumSize(
  shape: DTShape,
  vehiclesMaxDimensions?: { width: number; length: number },
  loadCarriersMaxDimensions?: { width: number; length: number },
): {
  width: number;
  height: number;
} {
  let minWidth: number;
  let minHeight: number;
  const minSize = getMinimumSizeByShapeType(shape.type);

  if (isHighwayShape(shape)) {
    const isHorizontal = shape.properties.width >= shape.properties.height;
    // @ts-expect-error strictNullChecks. Pls fix me
    const minWidthHighway = vehiclesMaxDimensions.width + 2 * shape.parameters.margin;
    const minHeightHighway =
      // @ts-expect-error strictNullChecks. Pls fix me
      vehiclesMaxDimensions.length + 2 * shape.parameters.routingPointMarginToCrossing;

    minWidth = isHorizontal ? minHeightHighway : minWidthHighway;
    minHeight = isHorizontal ? minWidthHighway : minHeightHighway;
  } else if (isAreaShape(shape)) {
    const { direction } = shape.parameters;

    if (supportsLoadCarriers(shape.type)) {
      // @ts-expect-error strictNullChecks. Pls fix me
      const minWidthAreasWithLoad = loadCarriersMaxDimensions.width;
      // @ts-expect-error strictNullChecks. Pls fix me
      const minHeightAreasWithLoad = loadCarriersMaxDimensions.length;

      minWidth = isVertical(direction) ? minHeightAreasWithLoad : minWidthAreasWithLoad;
      minHeight = isVertical(direction) ? minWidthAreasWithLoad : minHeightAreasWithLoad;
    } else {
      // @ts-expect-error strictNullChecks. Pls fix me
      const minWidthAreasNoLoad = vehiclesMaxDimensions.width + 2 * shape.parameters.margin;
      // @ts-expect-error strictNullChecks. Pls fix me
      const minHeightAreasNoLoad = vehiclesMaxDimensions.length + 2 * shape.parameters.margin;

      minWidth = isVertical(direction) ? minHeightAreasNoLoad : minWidthAreasNoLoad;
      minHeight = isVertical(direction) ? minWidthAreasNoLoad : minHeightAreasNoLoad;
    }
  } else {
    minWidth = minSize;
    minHeight = minSize;
  }

  return {
    width: minWidth,
    height: minHeight,
  };
}
