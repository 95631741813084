import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';

import { moduleAtom } from './module';

/**
 * Error message to show
 */
export const errorMessageSelector = selector({
  key: 'simulation/module/errorMessage',
  get: ({ get }) => get(moduleAtom).errorMessage,
  set: ({ set }, errorMessage: string) =>
    // @ts-expect-error strictNullChecks. Pls fix me
    set(moduleAtom, (state) => ({
      ...state,
      errorMessage,
    })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
