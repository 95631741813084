import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { v4 as uuid } from 'uuid';
import { persist, devtools } from 'zustand/middleware';
import { enableMapSet } from 'immer';

import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';
import { ErrorNotification, Notification, NotificationType } from '../helpers/types';

export type NotificationStore = {
  notifications: Map<string, Notification | ErrorNotification>;
  hasUnreadNotifications: boolean;
};

type NotificationActions = {
  addNotification(
    title: string,
    description: string,
    type: Exclude<NotificationType, NotificationType.ERROR>,
  ): void;
  addErrorNotification(notification: ErrorNotification): void;
  removeNotification(id: string): void;
  clearNotifications(): void;
  markMessagesAsRead(): void;
};

const INITIAL_STATE: NotificationStore = {
  notifications: new Map(),
  hasUnreadNotifications: false,
};

enableMapSet();

export const useNotificationStore = create<NotificationStore & NotificationActions>()(
  devtools(
    persist(
      immer((set) => ({
        ...INITIAL_STATE,

        addNotification: (title, description, type) => {
          const id = uuid();
          const newNotification: Notification = {
            title,
            id,
            type,
            description,
            timestamp: new Date(),
            read: false,
          };

          set(
            (state) => {
              const newNotifications = new Map([[id, newNotification], ...state.notifications]);
              state.notifications = newNotifications;
              state.hasUnreadNotifications = true;
            },
            undefined,
            {
              type: 'addNotification',
            },
          );
        },

        addErrorNotification: (notification: ErrorNotification) =>
          set(
            (state) => {
              const newNotifications = new Map([
                [notification.id, notification],
                ...state.notifications,
              ]);
              state.notifications = newNotifications;
              state.hasUnreadNotifications = true;
            },
            undefined,
            {
              type: 'addErrorNotification',
            },
          ),

        removeNotification: (id) =>
          set(
            (state) => {
              const notification = state.notifications.get(id);
              if (notification) {
                state.notifications.delete(id);
                state.hasUnreadNotifications = false;
              }
            },
            undefined,
            {
              type: 'removeNotification',
            },
          ),

        markMessagesAsRead: () =>
          set(
            (state) => {
              state.notifications.forEach((notification) => {
                notification.read = true;
              });
              state.hasUnreadNotifications = false;
            },
            undefined,
            {
              type: 'markMessagesAsRead',
            },
          ),

        clearNotifications: () =>
          set(
            (state) => {
              state.notifications = new Map();
              state.hasUnreadNotifications = false;
            },
            undefined,
            {
              type: 'clearNotifications',
            },
          ),
      })),
      {
        name: 'notifications-store',
        storage: {
          getItem: (name) => {
            const str = sessionStorage.getItem(name);
            return {
              state: {
                // @ts-expect-error strictNullChecks. Pls fix me
                ...JSON.parse(str).state,
                // @ts-expect-error strictNullChecks. Pls fix me
                notifications: new Map(JSON.parse(str).state.notifications),
              },
            };
          },
          setItem: (name, newValue) => {
            const str = JSON.stringify({
              state: {
                ...newValue.state,
                notifications: Array.from(newValue.state.notifications.entries()),
              },
            });
            sessionStorage.setItem(name, str);
          },
          removeItem: (name) => sessionStorage.removeItem(name),
        },
      },
    ),
    { store: 'notificationStore', ...DEVTOOLS_OPTIONS },
  ),
);
