import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../../store/recoil/common';
import { processTwoEndPointIdsState } from '../state';

export const deleteProcessTwoEndPointdsSelector = selector<string[]>({
  key: 'allProcessTwoEndPoint/ids/delete',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: () => null,
  set: ({ get, set }, deleteIds: string[]) => {
    const ids = new Set(get(processTwoEndPointIdsState));
    deleteIds.forEach((id) => ids.delete(id));
    set(processTwoEndPointIdsState, Array.from(ids));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
