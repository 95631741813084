import { Typography } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { PropertiesTitle } from '@common/components/PropertiesTitle';
import { CONTEXT, contextState } from '@recoil/input';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { SIMULATION_EDIT_ERROR } from '../helpers/constants';

type Props = {
  children: string;
  subTitle?: ReactNode;
  editable?: boolean;
  onChange?: (name: string) => void;
};

export function HeaderTitle({ children, subTitle, editable, onChange }: Props) {
  const setContext = useSetRecoilState(contextState);
  const { t } = useTranslation('interface');

  const onChangeInner = useCallback(
    (value: string) => {
      if (!value.length) {
        return {
          error: t(SIMULATION_EDIT_ERROR.NAME_TOO_SHORT.i18nMessageKey),
        };
      }
      // @ts-expect-error strictNullChecks. Pls fix me
      onChange(value);
    },
    [t, onChange],
  );

  return (
    <>
      <PropertiesTitle
        editable={editable}
        multiline={false}
        fontSize={18}
        value={children}
        onChange={onChangeInner}
        onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
        onBlur={() => setContext(CONTEXT.WORKSPACE)}
      />
      {!!subTitle && (
        <Typography
          sx={{
            fontSize: '10px',
            textAlign: 'left',
            flexGrow: 1,
            marginBottom: '4px',
            color: 'neutral.main',
          }}
        >
          {subTitle}
        </Typography>
      )}
    </>
  );
}
