import { IconButton, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CloseIcon } from '@/assets/icons';
import { WorkspaceMode } from '@/modules/common/types/general';
import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { useEnterBlur } from '@modules/common/components/inputs/hooks/useEnterBlur';
import { modeSelector } from '@modules/common/store/workspace';
import { CONTEXT, contextState } from '@recoil/input';

type Props = {
  vehicleLimit: number;
  onChange: (value: number) => void;
  disabled?: boolean;
};

function VehicleLimit({ vehicleLimit, onChange, disabled }: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const { saveFloorPlan } = useFloorPlanState();
  const [inputType, setInputType] = useState('text');
  const [displayText, setDisplayText] = useState('All');
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);

  const onVehicleLimitChange = useCallback(
    (e) => {
      if (Number.isNaN(e.target.value) || Number(e.target.value) < 0) {
        return;
      }

      if (inputType === 'text') {
        setInputType('number');
      }
      onChange(Number(e.target.value));
      saveFloorPlan();
    },
    [inputType, onChange, saveFloorPlan],
  );

  const onBlur = useCallback(() => {
    setContext(CONTEXT.WORKSPACE);
    if (vehicleLimit < 0) {
      setInputType('text');
      setDisplayText('All');
    }
  }, [setContext, vehicleLimit]);

  const onClear = useCallback(() => {
    onChange(-1);
    setInputType('text');
    setDisplayText('All');
    saveFloorPlan();
  }, [onChange, saveFloorPlan]);

  const onFocus = useCallback(() => {
    setContext(CONTEXT.PROPERTYPANEL);
    if (inputType === 'text') setDisplayText('');
  }, [inputType, setContext]);

  useEffect(() => {
    if (vehicleLimit >= 0) setInputType('number');
    else setInputType('text');
  }, [inputType, vehicleLimit]);

  // @ts-expect-error strictNullChecks. Pls fix me
  const { onKeyUp } = useEnterBlur(ref);

  return (
    <>
      <TextField
        inputRef={ref}
        value={inputType === 'text' ? displayText : Number(vehicleLimit).toString()}
        type={inputType}
        sx={{ width: '100%', float: 'right' }}
        disabled={disabled || mode !== WorkspaceMode.EDITABLE}
        onFocus={onFocus}
        onBlur={onBlur}
        variant='filled'
        onChange={onVehicleLimitChange}
        InputProps={{ disableUnderline: true }}
        onKeyUp={onKeyUp}
      />
      <IconButton
        onClick={onClear}
        disabled={disabled}
        sx={{
          position: 'absolute',
          right: 0,
          mt: '1px',
          mr: '18px',
          height: '34px',
          p: 0,
          pt: 0.5,
          display: inputType === 'text' ? 'none' : 'inline',
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
}

export default VehicleLimit;
