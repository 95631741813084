import { Vector2 } from 'three';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { ActivePointsDrawingState, ControlPoint } from '@/modules/common/types/shapes';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';

type WorkspaceStore = {
  activePointsDrawingState: ActivePointsDrawingState;
  shapeInEditModeIdState: string;
};

type WorkspaceActions = {
  setActivePointsDrawingState(value: ActivePointsDrawingState): void;
  setActivePointsDrawingIdState(id: string): void;
  setControlPoints(controlPoints: ControlPoint[]): void;
  setInterimEndPointPos(interimEndPointPos: Vector2): void;
  setShapeInEditModeIdState(id: string): void;
  reset(): void;
};

const INITIAL_STATE: WorkspaceStore = {
  activePointsDrawingState: {
    id: null,
    // @ts-expect-error strictNullChecks. Pls fix me
    type: null,
    controlPoints: [],
    interimStartControlPoint: null,
    interimEndPointPos: null,
    // @ts-expect-error strictNullChecks. Pls fix me
    width: null,
  },
  // @ts-expect-error strictNullChecks. Pls fix me
  shapeInEditModeIdState: null,
};

export const useWorkspaceStore = create<WorkspaceStore & WorkspaceActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      setActivePointsDrawingState(value) {
        set(
          {
            activePointsDrawingState: value,
          },
          undefined,
          {
            type: 'setActivePointsDrawingState',
          },
        );
      },

      setActivePointsDrawingIdState(id) {
        const { activePointsDrawingState } = get();
        set(
          {
            activePointsDrawingState: { ...activePointsDrawingState, id },
          },
          undefined,
          {
            type: 'setActivePointsDrawingIdState',
          },
        );
      },

      setControlPoints(controlPoints) {
        const { activePointsDrawingState } = get();
        set(
          {
            activePointsDrawingState: { ...activePointsDrawingState, controlPoints },
          },
          undefined,
          {
            type: 'setControlPoints',
          },
        );
      },

      setInterimEndPointPos(interimEndPointPos) {
        const { activePointsDrawingState } = get();
        set(
          {
            activePointsDrawingState: { ...activePointsDrawingState, interimEndPointPos },
          },
          undefined,
          {
            type: 'setInterimEndPointPos',
          },
        );
      },

      setShapeInEditModeIdState(id) {
        set({ shapeInEditModeIdState: id }, undefined, {
          type: 'setShapeInEditModeIdState',
        });
      },

      reset() {
        set(INITIAL_STATE, undefined, {
          type: 'reset',
        });
      },
    }),
    { store: 'workspaceStore', ...DEVTOOLS_OPTIONS },
  ),
);
