import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { unitSelector } from '@/store/recoil/workspace';
import { Accordion } from '@common/components/Accordion';
import { Input } from '@common/components/Input';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { AreaEndpointDirection } from '@/modules/common/types/shapes';
import { convertToMMFromUnit, convertToUnitFromMM } from '@/modules/common/helpers/unit';
import { areaStorageProperty } from '@components/PropertiesPanel/store/area';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import { CONTEXT, contextState } from '@recoil/input';
import { EndpointDirection } from './EndpointDirection';
import { useCallback } from 'react';
import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import {
  isSideLoadingStorageProperty,
  SideLoadingProperty,
} from '@/store/recoil/shape/types/area';

function SideLoadingProperties() {
  const [storageProperty, setStorageProperty] = useRecoilState(areaStorageProperty);

  if (!storageProperty || !isSideLoadingStorageProperty(storageProperty)) return null;

  return <SideLoadingPropertiesContent value={storageProperty} setValue={setStorageProperty} />;
}

function SideLoadingPropertiesContent({
  value,
  setValue,
}: {
  value: SideLoadingProperty;
  // TODO: fix type any. tied to return type of findParameterValue used in areaStorageProperty selector
  setValue: SetterOrUpdater<any>;
}) {
  const { saveFloorPlan } = useFloorPlanState();
  const displayUnit = useRecoilValue(unitSelector);
  const deltaXDisplayValue = convertToUnitFromMM(displayUnit, value.sideLoadingDeltaX);
  const deltaYDisplayValue = convertToUnitFromMM(displayUnit, value.sideLoadingDeltaY);
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation('interface');
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const ids = useRecoilValue(selectedShapesIdsState);

  const onDeltaXChange = useCallback(
    async (value: number) => {
      if (value != null) {
        const valueInMillimeter = convertToMMFromUnit(displayUnit, value as number);

        setValue((current) => {
          if (!isSideLoadingStorageProperty(current)) return current;

          return {
            ...current,
            sideLoadingDeltaX: valueInMillimeter,
          };
        });
        saveFloorPlan();
        updateArtefacts(ids);
      }
    },
    [setValue, saveFloorPlan, updateArtefacts, ids, displayUnit],
  );

  const onDeltaYChange = useCallback(
    (value: number) => {
      if (value != null) {
        const valueInMillimeter = convertToMMFromUnit(displayUnit, value as number);

        setValue((current) => {
          if (!isSideLoadingStorageProperty(current)) return current;

          return {
            ...current,
            sideLoadingDeltaY: valueInMillimeter,
          };
        });
        saveFloorPlan();
        updateArtefacts(ids);
      }
    },
    [setValue, saveFloorPlan, updateArtefacts, ids, displayUnit],
  );

  const onEndpointDirectionChange = useCallback(
    (value: AreaEndpointDirection) => {
      if (value != null) {
        setValue((current) => {
          if (!isSideLoadingStorageProperty(current)) return current;

          return {
            ...current,
            endpointDirection: value,
          };
        });
        saveFloorPlan();
        updateArtefacts(ids);
      }
    },
    [setValue, saveFloorPlan, updateArtefacts, ids],
  );

  const onFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const onBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);

  return (
    <Accordion
      title={t('interface:properties.area.sideloading.label', 'Side Loading Offset Settings')}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        <PropertiesLabel i18nkey='interface:properties.area.sideloading.endpoint_direction.label'>
          <EndpointDirection value={value.endpointDirection} setValue={onEndpointDirectionChange} />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.area.sideloading.deltaX'>
          <Input
            value={deltaXDisplayValue}
            unit={t(`interface:settings.units.${unit}`, unit)}
            disabled={mode !== WorkspaceMode.EDITABLE}
            onChange={onDeltaXChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.area.sideloading.deltaY'>
          <Input
            value={deltaYDisplayValue}
            unit={t(`interface:settings.units.${unit}`, unit)}
            disabled={mode !== WorkspaceMode.EDITABLE}
            onChange={onDeltaYChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </PropertiesLabel>
      </Stack>
    </Accordion>
  );
}

export default SideLoadingProperties;
