import { useState, useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigation } from '@/modules/common/hooks';
import { positionState } from '../contextMenu';
import { useLongPress } from 'use-long-press';
import {
  isFloorplanSelected,
  isFloorplanBeingDeleted,
  selectedFloorplanIdsState,
  floorplanIdBeingDuplicatedState,
  isDuplicatorOpenState,
} from '../store';
import { floorPlanGroupSelector } from '../store/floorPlanGroup';

export const useFloorplanGridItem = (floorplanId: string) => {
  const { goToBuilder } = useNavigation();

  const [thumb, setThumb] = useState<string | null>(null);
  const [itemHover, setItemHover] = useState(false);

  const floorPlanGroup = useRecoilValue(floorPlanGroupSelector(floorplanId));

  // readers
  const isSelected = useRecoilValue(isFloorplanSelected(floorplanId));
  const isBeingDeleted = useRecoilValue(isFloorplanBeingDeleted(floorplanId));

  // setters
  const setSelectedFloorplanIds = useSetRecoilState(selectedFloorplanIdsState);
  const setContextMenuPosition = useSetRecoilState(positionState);
  const setDuplicatorIsOpen = useSetRecoilState(isDuplicatorOpenState);
  const setOriginFloorplanId = useSetRecoilState(floorplanIdBeingDuplicatedState);

  const handleFloorplanClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (e.shiftKey) {
        setSelectedFloorplanIds((current) => {
          const tempNewSet = new Set(current);

          if (!current.has(floorplanId)) {
            tempNewSet.add(floorplanId);
          } else {
            tempNewSet.delete(floorplanId);
          }

          return tempNewSet;
        });

        return;
      }

      setSelectedFloorplanIds(new Set([floorplanId]));
    },
    [floorplanId, setSelectedFloorplanIds],
  );

  const onDuplicate = useCallback(
    (e) => {
      e.stopPropagation();
      setOriginFloorplanId(floorplanId);
      setDuplicatorIsOpen(true);
    },
    [setDuplicatorIsOpen, setOriginFloorplanId, floorplanId],
  );

  useEffect(() => {
    // @ts-expect-error strictNullChecks. Pls fix me
    setThumb(floorPlanGroup.thumbnail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDoubleClick = useCallback(() => {
    goToBuilder(floorPlanGroup.id, floorPlanGroup.floorPlanId);
  }, [goToBuilder, floorPlanGroup]);

  const bindLongPress = useLongPress(() => {
    onDoubleClick();
  });

  const onMouseOver = useCallback(() => setItemHover(true), [setItemHover]);
  const onMouseLeave = useCallback(() => setItemHover(false), [setItemHover]);

  const onContextMenuHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      if (!isSelected) {
        setSelectedFloorplanIds(new Set([floorplanId]));
      }

      setContextMenuPosition({
        x: e.clientX,
        y: e.clientY,
      });
    },
    [setContextMenuPosition, isSelected, setSelectedFloorplanIds, floorplanId],
  );

  return {
    name: floorPlanGroup.name,
    itemHover,
    handleFloorplanClick,
    thumb,
    bindLongPress,
    onMouseOver,
    onMouseLeave,
    onDoubleClick,
    onContextMenuHandler,
    isSelected,
    isBeingDeleted,
    onDuplicate,
  };
};
