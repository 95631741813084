import { Transformer as TransformerShape } from 'konva/lib/shapes/Transformer';
import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { domReferencesAtom, WorkspaceDomReferences } from '../atom';

export const transformerShapeSelector = selector<TransformerShape>({
  key: 'transformerShapeSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: ({ get }) => get<WorkspaceDomReferences>(domReferencesAtom).transformerShape,
  set: ({ get, set }, newValue) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    set(domReferencesAtom, { ...get(domReferencesAtom), transformerShape: newValue });
  },
  dangerouslyAllowMutability: true,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
