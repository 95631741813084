import { atom, atomFamily } from 'recoil';
import { Connection } from '@modules/common/types/connections';

export const connectionState = atomFamily<Connection, string>({
  key: 'connection',
  default: {
    // @ts-expect-error strictNullChecks. Pls fix me
    id: null,
    // @ts-expect-error strictNullChecks. Pls fix me
    from: null,
    // @ts-expect-error strictNullChecks. Pls fix me
    to: null,
    position: {
      x: 0,
      y: 0,
    },
    rot: 0,
    inChecked: true,
    outChecked: true,
    usePivots: false,
  },
});

export const allConnectionIds = atom<string[]>({
  key: 'allConnectionIds',
  default: [],
});
