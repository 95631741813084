import cloneDeep from 'clone-deep';
import { Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import { boundingBoxToLine } from '@/modules/common/helpers/boundingBox';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import {
  getDefaultLoadPlacement,
  getDefaultOperationTime,
} from '@/modules/shapes/helpers/defaults';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import {
  APP_SPECIFIC_SHAPE_PROPERTIES_DEFAULT,
  HIGHWAY_ROUTING_POINT_GROUP_MIN_GAP_DEFAULT,
  HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_DEFAULT,
  HIGHWAY_VEHICLE_LIMIT_DEFAULT,
} from '@modules/common/constants/shapes';
import {
  DEFAULT_RACK,
  DEFAULT_SIDE_LOADING,
  MIN_RACK,
  RACK_DISTRIBUTION_OVERRIDE,
  RACK_MIN_GAP_OVERRIDE,
} from '@modules/common/constants/storage';
import { supportsLoadCarriers, supportsVehicleTypes } from '@modules/common/helpers/shapes';
import {
  AngledHighwayShapePersisted,
  AreaShapeParametersWithPotentialLegacy,
  AreaShapePersisted,
  DTShapePersisted,
  HighwayShapePersisted,
  ObstacleShapePersisted,
  PositionShapePersisted,
  ProcessTwoEPShapePersisted,
  WallShapeParametersWithPotentialLegacy,
  WallShapePersisted,
} from '@modules/common/types/floorPlan';
import {
  isAngledHighwayShape,
  isAngledHighwayShapePersisted,
  isAreaShapePersisted,
  isHighwayShapePersisted,
  isObstacleShape,
  isObstacleShapePersisted,
  isPositionShape,
  isPositionShapePersisted,
  isProcessAreaTwoEp,
  isProcessAreaTwoEpPersisted,
  isShapeProperties,
  isWallShape,
  isWallShapePersisted,
} from '@modules/common/types/guards';
import {
  AreaLoadCarrierOrientation,
  ControlPoint,
  DTShape,
  LaneDirection,
  ShapeProperties,
  ShapeType,
} from '@modules/common/types/shapes';
import { StorageType } from '@modules/common/types/storage';
import {
  AreaShapeParameters,
  HighwayShapeParameters,
  PositionShapeParameters,
  WallShape,
} from '../../../store/recoil/shape';
import { RackProperty } from '../../../store/recoil/shape/types/area';
import { VehiclePosition } from '@/modules/floorplanService/helpers/mapping/types';
import { storageLoadWidth } from '@/modules/floorplanService/helpers/mapping/utils';

/**
 * Prepares shapes state to be loaded into the app
 */
export const prepareToLoad = (
  shapes: DTShapePersisted[],
  vehicleTypes: string[],
  loadTypes: string[],
  unit: string | undefined,
): DTShape[] => {
  let tempNewShapes = shapes;

  if (unit === undefined) {
    tempNewShapes = convertCmFloorplanToMm(tempNewShapes);
  }

  tempNewShapes = filterOutInvalidShapes(tempNewShapes);
  tempNewShapes = backwardCompatibilityForWalls(tempNewShapes);
  tempNewShapes = backwardCompatibilityForHighways(tempNewShapes);
  tempNewShapes = backwardCompatibilityForAngledHighways(tempNewShapes);
  tempNewShapes = backwardsCompatibilityForVehicleLoadTypes(tempNewShapes, vehicleTypes, loadTypes);
  tempNewShapes = backwardCompatibilityForStorage(tempNewShapes);

  return tempNewShapes.map((shape) => {
    if (isAngledHighwayShapePersisted(shape)) {
      const convertedShape: AngledHighwayShape = {
        id: shape.id,
        type: shape.type,
        name: shape.name,
        parameters: shape.parameters,
        properties: {
          controlPoints: shape.properties.controlPoints.map(
            (cp): ControlPoint => ({
              ...cp,
              position: new Vector2(cp.position.x, cp.position.y).round(),
            }),
          ),
        },
        ...cloneDeep(APP_SPECIFIC_SHAPE_PROPERTIES_DEFAULT),
      };

      return convertedShape;
    }

    if (isWallShapePersisted(shape)) {
      const convertedShape: WallShape = {
        id: shape.id,
        type: shape.type,
        name: shape.name,
        parameters: shape.parameters,
        properties: {
          controlPoints: shape.properties.controlPoints.map(
            (cp): ControlPoint => ({
              ...cp,
              position: new Vector2(cp.position.x, cp.position.y).round(),
            }),
          ),
        },
        ...cloneDeep(APP_SPECIFIC_SHAPE_PROPERTIES_DEFAULT),
      };

      return convertedShape;
    }

    if (isProcessAreaTwoEpPersisted(shape)) {
      const convertedShape: ProcessTwoEPShape = {
        id: shape.id,
        type: shape.type,
        name: shape.name,
        parameters: {
          ...shape.parameters,
          operationTime: shape.parameters.operationTime || getDefaultOperationTime(shape.type),
        },
        properties: {
          controlPoints: shape.properties.controlPoints.map(
            (cp): ControlPoint => ({
              ...cp,
              position: new Vector2(cp.position.x, cp.position.y).round(),
            }),
          ),
        },
        ...cloneDeep(APP_SPECIFIC_SHAPE_PROPERTIES_DEFAULT),
      };

      return convertedShape;
    }

    return {
      id: shape.id,
      type: shape.type,
      name: shape.name,
      parameters: {
        ...shape.parameters,
        ...(isAreaShapePersisted(shape) || isPositionShapePersisted(shape)
          ? {
              operationTime: shape.parameters.operationTime || getDefaultOperationTime(shape.type),
              loadPlacement: shape.parameters.loadPlacement || getDefaultLoadPlacement(shape.type),
            }
          : {}),
      },
      properties: {
        width: shape.properties.width,
        height: shape.properties.height,
        y: shape.properties.y,
        r: shape.properties.r,
        x: shape.properties.x,
      },
      ...cloneDeep(APP_SPECIFIC_SHAPE_PROPERTIES_DEFAULT),
    } as Exclude<DTShape, AngledHighwayShape | WallShape | ProcessTwoEPShape>;
  });
};

/**
 * Prepares shapes state to be saved
 */
export const prepareToSave = (shapes: DTShape[]): DTShapePersisted[] => {
  const convertedShapes = shapes.map((shape) => {
    if (isAngledHighwayShape(shape)) {
      const convertedShape: AngledHighwayShapePersisted = {
        id: shape.id,
        type: shape.type,
        name: shape.name,
        properties: {
          controlPoints: shape.properties.controlPoints.map(
            (cp): ControlPoint => ({
              ...cp,
              position: new Vector2(cp.position.x, cp.position.y).round(),
            }),
          ),
        },
        parameters: {
          ...shape.parameters,
          width: Math.round(shape.parameters.width),
          gap: Math.round(shape.parameters.gap),
          margin: Math.round(shape.parameters.margin),
        },
      };

      return convertedShape;
    }

    if (isProcessAreaTwoEp(shape)) {
      const convertedShape: ProcessTwoEPShapePersisted = {
        id: shape.id,
        type: shape.type,
        name: shape.name,
        properties: {
          controlPoints: shape.properties.controlPoints.map(
            (cp): ControlPoint => ({
              ...cp,
              position: new Vector2(cp.position.x, cp.position.y).round(),
            }),
          ),
        },
        parameters: {
          ...shape.parameters,
          width: Math.round(shape.parameters.width),
        },
      };

      return convertedShape;
    }

    if (isWallShape(shape)) {
      const convertedShape: WallShapePersisted = {
        id: shape.id,
        type: shape.type,
        name: shape.name,
        properties: {
          controlPoints: shape.properties.controlPoints.map(
            (cp): ControlPoint => ({
              ...cp,
              position: new Vector2(cp.position.x, cp.position.y).round(),
            }),
          ),
        },
        parameters: {
          ...shape.parameters,
          width: Math.round(shape.parameters.width),
        },
      };

      return convertedShape;
    }

    if (isPositionShape(shape) || isObstacleShape(shape)) {
      return {
        id: shape.id,
        type: shape.type,
        name: shape.name,
        properties: {
          height: Math.round(shape.properties.height),
          width: Math.round(shape.properties.width),
          x: Math.round(shape.properties.x),
          y: Math.round(shape.properties.y),
          r: Math.round(shape.properties.r),
        },
        parameters: {
          ...shape.parameters,
        },
      } as Extract<
        DTShapePersisted,
        PositionShapePersisted | WallShapePersisted | ObstacleShapePersisted
      >;
    }

    return {
      id: shape.id,
      type: shape.type,
      name: shape.name,
      properties: {
        height: Math.round(shape.properties.height),
        width: Math.round(shape.properties.width),
        x: Math.round(shape.properties.x),
        y: Math.round(shape.properties.y),
        r: Math.round(shape.properties.r),
      },
      parameters: {
        ...shape.parameters,
        gap: Math.round(shape.parameters.gap),
        margin: Math.round(shape.parameters.margin),
      },
    } as Extract<DTShapePersisted, HighwayShapePersisted | AreaShapePersisted>;
  });

  return convertedShapes;
};

export const backwardsCompatibilityForVehicleLoadTypes = (
  shapes: DTShapePersisted[],
  vehicleTypes: string[],
  loadTypes: string[],
) => {
  const convertedShapes = shapes.map((shape): DTShapePersisted => {
    if (!isAreaShapePersisted(shape) && !isPositionShapePersisted(shape)) return shape;

    let tempShape = cloneDeep(shape);

    if (
      supportsVehicleTypes(shape.type) &&
      (tempShape.parameters.supportedVehicleIds === undefined ||
        tempShape.parameters.supportedVehicleIds.length === 0)
    ) {
      tempShape.parameters = {
        ...tempShape.parameters,
        // @ts-expect-error strictNullChecks. Pls fix me
        supportedVehicleIds: vehicleTypes.at(0) ? [vehicleTypes.at(0)] : [],
      };
    }

    if (
      supportsLoadCarriers(shape.type) &&
      (tempShape.parameters.supportedLoadCarriersIds === undefined ||
        tempShape.parameters.supportedLoadCarriersIds.length === 0)
    ) {
      tempShape.parameters = {
        ...tempShape.parameters,
        // @ts-expect-error strictNullChecks. Pls fix me
        supportedLoadCarriersIds: loadTypes.at(0) ? [loadTypes.at(0)] : [],
      };
    }

    if (
      supportsLoadCarriers(shape.type) &&
      (tempShape.parameters.loadCarrierOrientation === undefined ||
        !tempShape.parameters.loadCarrierOrientation)
    ) {
      tempShape.parameters = {
        ...tempShape.parameters,
        loadCarrierOrientation: AreaLoadCarrierOrientation.SHORT_SIDE,
      };
    }

    return tempShape;
  });

  return convertedShapes;
};

// fn for backward compatible with Rack/Side Loading property
const backwardCompatibilityForStorage = (shapes: DTShapePersisted[]): DTShapePersisted[] =>
  shapes.map((shape) => {
    if (!isAreaShapePersisted(shape)) return shape;

    const oldParams: AreaShapeParametersWithPotentialLegacy = shape.parameters;
    let newParams: AreaShapeParametersWithPotentialLegacy = cloneDeep(shape.parameters);

    if (shape.parameters.storageType === StorageType.SIDELOADING) {
      const { storageProperty } = oldParams;
      let endpointDirection = DEFAULT_SIDE_LOADING.END_POINT_DIRECTION;
      let sideLoadingDeltaX = DEFAULT_SIDE_LOADING.DELTA_X;
      let sideLoadingDeltaY = DEFAULT_SIDE_LOADING.DELTA_Y;

      if (storageProperty && storageProperty.endpointDirection) {
        endpointDirection = storageProperty.endpointDirection;
      } else if (oldParams.endpointDirection) {
        endpointDirection = oldParams.endpointDirection;
      }

      if (storageProperty && storageProperty.sideLoadingDeltaX) {
        sideLoadingDeltaX = storageProperty.sideLoadingDeltaX;
      } else if (oldParams.sideLoadingDeltaX !== undefined) {
        sideLoadingDeltaX =
          typeof oldParams.sideLoadingDeltaX === 'number'
            ? oldParams.sideLoadingDeltaX
            : DEFAULT_SIDE_LOADING.DELTA_X;
      }

      if (storageProperty && storageProperty.sideLoadingDeltaY) {
        sideLoadingDeltaY = storageProperty.sideLoadingDeltaY;
      } else if (oldParams.sideLoadingDeltaY !== undefined) {
        sideLoadingDeltaY =
          typeof oldParams.sideLoadingDeltaY === 'number'
            ? oldParams.sideLoadingDeltaY
            : DEFAULT_SIDE_LOADING.DELTA_Y;
      }

      newParams = {
        ...newParams,
        storageProperty: {
          endpointDirection,
          sideLoadingDeltaX,
          sideLoadingDeltaY,
        },
      };

      delete newParams.endpointDirection;
      delete newParams.sideLoadingDeltaX;
      delete newParams.sideLoadingDeltaY;
    } else if (shape.parameters.storageType === StorageType.RACK || shape.parameters.storageType === StorageType.TWOSIDEDRACK) {
      const storageProperty: RackProperty = oldParams.storageProperty as RackProperty;
      let rackLevels = DEFAULT_RACK.LEVELS;
      let framesDeep = DEFAULT_RACK.FRAMES_DEEP;
      let rackLoadsPerShelf = DEFAULT_RACK.LOADS_PER_SHELF;
      let loadHeight = DEFAULT_RACK.LOAD_HEIGHT;
      let clearanceSide = DEFAULT_RACK.CLEARANCE_SIDE;
      let clearanceTop = DEFAULT_RACK.CLEARANCE_TOP;
      let clearanceInBetween = DEFAULT_RACK.CLEARANCE_IN_BETWEEN;
      let beamHeight = DEFAULT_RACK.BEAM_HEIGHT;
      let postWidth = DEFAULT_RACK.POST_WIDTH;
      let firstShelfHeight = DEFAULT_RACK.FIRST_SHELF_HEIGHT;
      let aisleWidth = DEFAULT_RACK.AISLE_WIDTH;
      let vehiclePositionInRack = VehiclePosition.ALONGSIDE;
      let offsetDrivingInRack = 0;
      let secondLoadPositionIdGeneratorName = '';

      if (storageProperty && storageProperty.rackLevels) {
        rackLevels = storageProperty.rackLevels;
      } else if (oldParams.rackLevels !== undefined) {
        rackLevels =
          typeof oldParams.rackLevels === 'number' ? oldParams.rackLevels : DEFAULT_RACK.LEVELS;
      }

      if (storageProperty && storageProperty.rackLoadsPerShelf) {
        rackLoadsPerShelf = storageProperty.rackLoadsPerShelf;
      } else if (oldParams.rackLoadsPerShelf !== undefined) {
        rackLoadsPerShelf =
          typeof oldParams.rackLoadsPerShelf === 'number'
            ? oldParams.rackLoadsPerShelf
            : DEFAULT_RACK.LOADS_PER_SHELF;
      }

      if (storageProperty && (storageProperty.framesDeep || storageProperty.framesDeep === 0)) {
        framesDeep = Math.max(storageProperty.framesDeep, MIN_RACK.FRAMES_DEEP);
      }

      if (storageProperty && (storageProperty.loadHeight || storageProperty.loadHeight === 0)) {
        loadHeight = Math.max(storageProperty.loadHeight, MIN_RACK.LOAD_HEIGHT);
      }

      if (
        storageProperty &&
        (storageProperty.clearanceSide || storageProperty.clearanceSide === 0)
      ) {
        clearanceSide = Math.max(storageProperty.clearanceSide, MIN_RACK.CLEARANCE_SIDE);
      }

      if (storageProperty && (storageProperty.clearanceTop || storageProperty.clearanceTop === 0)) {
        clearanceTop = Math.max(storageProperty.clearanceTop, MIN_RACK.CLEARANCE_TOP);
      }

      if (
        storageProperty &&
        (storageProperty.clearanceInBetween || storageProperty.clearanceInBetween === 0)
      ) {
        clearanceInBetween = Math.max(
          storageProperty.clearanceInBetween,
          MIN_RACK.CLEARANCE_IN_BETWEEN,
        );
      }

      if (storageProperty && (storageProperty.beamHeight || storageProperty.beamHeight === 0)) {
        beamHeight = Math.max(storageProperty.beamHeight, MIN_RACK.BEAM_HEIGHT);
      }

      if (storageProperty && (storageProperty.postWidth || storageProperty.postWidth === 0)) {
        postWidth = Math.max(storageProperty.postWidth, MIN_RACK.POST_WIDTH);
      }

      if (storageProperty && (storageProperty.firstShelfHeight || storageProperty.firstShelfHeight === 0)) {
        firstShelfHeight = Math.max(storageProperty.firstShelfHeight, MIN_RACK.FIRST_SHELF_HEIGHT);
      }

      if (storageProperty && storageProperty.aisleWidth) {
        aisleWidth = storageProperty.aisleWidth;
      }
      
      if (storageProperty && storageProperty.secondLoadPositionIdGeneratorName) {
        secondLoadPositionIdGeneratorName = storageProperty.secondLoadPositionIdGeneratorName;
      }

      if (storageProperty && storageProperty.vehiclePositionInRack) {
        vehiclePositionInRack = storageProperty.vehiclePositionInRack;
      }

      if (storageProperty && storageProperty.offsetDrivingInRack) {
        offsetDrivingInRack = storageProperty.offsetDrivingInRack;
      }

      const newStorageProperty: RackProperty = {
        framesDeep,
        rackLevels,
        rackLoadsPerShelf,
        loadHeight,
        clearanceSide,
        clearanceTop,
        clearanceInBetween,
        beamHeight,
        postWidth,
        firstShelfHeight,
        aisleWidth,
        vehiclePositionInRack,
        offsetDrivingInRack,
        laneDirectionInAisle: storageProperty.laneDirectionInAisle ?? LaneDirection.LEFT_RIGHT,
        secondLoadPositionIdGeneratorName,
      };

      newParams = {
        ...newParams,
        gap: RACK_MIN_GAP_OVERRIDE,
        distribution: RACK_DISTRIBUTION_OVERRIDE,
        storageProperty: newStorageProperty,
      };
    }

    delete newParams.rackDepth;
    delete newParams.rackLevels;
    delete newParams.rackLoadsPerShelf;

    return { ...shape, parameters: newParams };
  });

export function backwardCompatibilityForHighways(shapes: DTShapePersisted[]): DTShapePersisted[] {
  return shapes.map((originShape) => {
    if ((originShape.type as string) !== 'way' && !isHighwayShapePersisted(originShape))
      return originShape;

    let newShape = cloneDeep(originShape) as HighwayShapePersisted;
    const { routingPointMarginToCrossing, routingPointGroupMinGap, vehicleLimit } =
      newShape.parameters;

    newShape = {
      ...newShape,
      parameters: {
        ...newShape.parameters,
        routingPointMarginToCrossing:
          routingPointMarginToCrossing || routingPointMarginToCrossing === 0
            ? routingPointMarginToCrossing
            : HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_DEFAULT,
        routingPointGroupMinGap:
          routingPointGroupMinGap || routingPointGroupMinGap === 0
            ? routingPointGroupMinGap
            : HIGHWAY_ROUTING_POINT_GROUP_MIN_GAP_DEFAULT,
        vehicleLimit:
          vehicleLimit || vehicleLimit === 0 || vehicleLimit === -1
            ? vehicleLimit
            : HIGHWAY_VEHICLE_LIMIT_DEFAULT,
      },
    };

    // convert ways to highways
    // Ways are removed from the DT, and will be converted to highways
    if ((newShape.type as string) === 'way') {
      newShape = {
        ...newShape,
        type: ShapeType.HIGHWAY,
        parameters: {
          laneDirection: LaneDirection.LEFT_RIGHT,
          gap: newShape.parameters.gap,
          margin: newShape.parameters.margin,
          routingPointMarginToCrossing: newShape.parameters.routingPointMarginToCrossing,
          routingPointGroupMinGap: newShape.parameters.routingPointGroupMinGap,
          vehicleLimit: newShape.parameters.vehicleLimit,
          supportedVehicleIds: [],
        },
      };
    }

    return newShape;
  });
}

export function backwardCompatibilityForAngledHighways(
  shapes: DTShapePersisted[],
): DTShapePersisted[] {
  return shapes.map((originShape) => {
    if (!isAngledHighwayShapePersisted(originShape)) {
      return originShape;
    }
    let newShape = cloneDeep(originShape) as AngledHighwayShapePersisted;
    const { routingPointMarginToCrossing, routingPointGroupMinGap, vehicleLimit } =
      newShape.parameters;

    newShape = {
      ...newShape,
      parameters: {
        ...newShape.parameters,
        routingPointMarginToCrossing:
          routingPointMarginToCrossing || routingPointMarginToCrossing === 0
            ? routingPointMarginToCrossing
            : HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_DEFAULT,
        routingPointGroupMinGap:
          routingPointGroupMinGap || routingPointGroupMinGap === 0
            ? routingPointGroupMinGap
            : HIGHWAY_ROUTING_POINT_GROUP_MIN_GAP_DEFAULT,
        vehicleLimit:
          vehicleLimit || vehicleLimit === 0 || vehicleLimit === -1
            ? vehicleLimit
            : HIGHWAY_VEHICLE_LIMIT_DEFAULT,
      },
    };

    return newShape;
  });
}

export function backwardCompatibilityForWalls(shapes: DTShapePersisted[]): DTShapePersisted[] {
  return shapes.map((originShape) => {
    if (!isWallShapePersisted(originShape) || !isShapeProperties(originShape.properties))
      return originShape;

    let newParams: WallShapeParametersWithPotentialLegacy = cloneDeep(originShape.parameters);

    const idStartPoint = uuid();
    const idEndPoint = uuid();
    const line = boundingBoxToLine(originShape.properties);
    const newProperties = {
      controlPoints: [
        {
          id: idStartPoint,
          prev: null,
          next: idEndPoint,
          position: line.points.start,
        },
        {
          id: idEndPoint,
          prev: idStartPoint,
          next: null,
          position: line.points.end,
        },
      ],
    };
    newParams = {
      ...newParams,
      // @ts-expect-error strictNullChecks. Pls fix me
      width: newParams.thickness,
    };
    delete newParams.thickness;

    return { ...originShape, properties: newProperties, parameters: newParams };
  });
}

const scaleRectangleProperties = (properties: ShapeProperties): ShapeProperties => ({
  ...properties,
  x: Math.round(properties.x * 10),
  y: Math.round(properties.y * 10),
  width: Math.round(properties.width * 10),
  height: Math.round(properties.height * 10),
});

const scaleRectangleParameters = <
  T extends AreaShapeParameters | PositionShapeParameters | HighwayShapeParameters,
>(
  parameters: T,
): T => ({
  ...parameters,
  gap: Math.round(parameters.gap * 10),
  margin: Math.round(parameters.margin * 10),
});

export const convertCmFloorplanToMm = (shapes: DTShapePersisted[]) =>
  shapes.map((shape) => {
    if (isAreaShapePersisted(shape)) {
      return {
        ...shape,
        parameters: scaleRectangleParameters(shape.parameters),
        properties: scaleRectangleProperties(shape.properties),
      };
    }
    if (isHighwayShapePersisted(shape)) {
      return {
        ...shape,
        parameters: scaleRectangleParameters(shape.parameters),
        properties: scaleRectangleProperties(shape.properties),
      };
    }
    if (isPositionShapePersisted(shape)) {
      return {
        ...shape,
        parameters: scaleRectangleParameters(shape.parameters),
        properties: scaleRectangleProperties(shape.properties),
      };
    }
    if (isObstacleShapePersisted(shape)) {
      return { ...shape, properties: scaleRectangleProperties(shape.properties) };
    }

    return shape;
  });

export const filterOutInvalidShapes = (shapes: DTShapePersisted[]) => {
  const invalidShapes: DTShapePersisted[] = []

  shapes.forEach((shape) => {
    if (
      isAreaShapePersisted(shape) || 
      isHighwayShapePersisted(shape) || 
      isObstacleShapePersisted(shape)
    ) {
      if (shape.properties.width <= 0 || shape.properties.height <= 0) {
        invalidShapes.push(shape)
      }
    }
  })

  return shapes.filter((shape) => !invalidShapes.includes(shape))
};
