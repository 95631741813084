import { useBaseProjectApi } from '@modules/api/hooks';
import { AdditionalData, FloorPlan } from '@modules/common/types/floorPlan';
import { ReferenceImageDetails } from '@modules/common/types/referenceImage';
import { useCallback } from 'react';
import { useBackwardCompatibility } from './useBackwardCompatibility';

const createReferenceBaseUrl = (projectId: string, floorplanId: string): string =>
  `/v2/${projectId}/floorplan/${floorplanId}/referenceImage`;

export const useFloorPlanService = () => {
  const projectApi = useBaseProjectApi();
  const { convert } = useBackwardCompatibility();

  const mapToAdditionalData = useCallback(
    (response: any): AdditionalData =>
      convert({
        empty: Object.keys(response).length === 0,
        connections: response.connections,
        crossings: response.crossings,
        distcons: response.distcons,
        flows: response.flows,
        groups: response.groups,
        layers: response.layers,
        loadType: response.loadType,
        loadTypes: response.loadTypes,
        orderProfile: response.orderProfile,
        shapes: response.shapes,
        thumbnail: response.thumbnail,
        unit: response.unit,
        urn: response.urn,
        vehicleType: response.vehicleType,
        vehicleTypes: response.vehicleTypes,
        vehicleAssets: response.vehicleAssets,
        workspaceSize: response.workspaceSize,
        customIdGenerators: response.customIdGenerators,
      }),
    [convert],
  );

  const mapToFloorPlan = useCallback(
    (response: any): FloorPlan => ({
      id: response.id,
      hasUnsavedChanges: response.hasUnsavedChanges ?? false,
      project: response.project,
      groupId: response.group,
      name: response.name,
      created: new Date(response.created),
      lastUpdated: new Date(response.lastUpdated),
      // @ts-expect-error strictNullChecks. Pls fix me
      additionalData: response.additionalData ? mapToAdditionalData(response.additionalData) : null,
      version: response.floorPlanVersion,
      lastUpdatedBy: response.lastUpdatedBy,
    }),
    [mapToAdditionalData],
  );

  const fetchLatestVersion = useCallback(
    async (projectId: string, groupId: string): Promise<FloorPlan> =>
      mapToFloorPlan(
        (await projectApi.get(`/v2/${projectId}/floorPlanGroup/${groupId}/latest`)).data,
      ),
    [mapToFloorPlan, projectApi],
  );

  const fetchVersion = useCallback(
    async (projectId: string, groupId: string, floorPlanId: string): Promise<FloorPlan> =>
      mapToFloorPlan(
        (
          await projectApi.get(
            `/v2/${projectId}/floorPlanGroup/${groupId}/floorPlan/${floorPlanId}`,
          )
        ).data,
      ),
    [mapToFloorPlan, projectApi],
  );

  const fetchAllVersions = useCallback(
    async (projectId: string, groupId: string): Promise<FloorPlan[]> =>
      (await projectApi.get(`/v2/${projectId}/floorPlanGroup/${groupId}/floorPlan`)).data.map(
        mapToFloorPlan,
      ),
    [mapToFloorPlan, projectApi],
  );

  const fetchReferenceDetails = useCallback(
    async (projectId: string, floorPlanId: string): Promise<ReferenceImageDetails> =>
      mapToReferenceDetails(
        (await projectApi.get(createReferenceBaseUrl(projectId, floorPlanId))).data,
      ),
    [projectApi],
  );

  const fetchReferenceImage = useCallback(
    async (url: string): Promise<string> => (await projectApi.get(url)).data,
    [projectApi],
  );

  const updateLatest = useCallback(
    async (projectId: string, groupId: string, data: any) =>
      mapToUpdateResponse(
        (await projectApi.put(`/v2/${projectId}/floorPlanGroup/${groupId}/latest`, data)).data,
      ),
    [projectApi],
  );

  const uploadReferenceImage = useCallback(
    (projectId: string, floorPlanId: string, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return projectApi.put(
        `${createReferenceBaseUrl(projectId, floorPlanId)}/file?filename=${file.name}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },
    [projectApi],
  );

  const updateReferenceDetails = useCallback(
    (projectId: string, floorPlanId: string, data: any) =>
      projectApi.put(createReferenceBaseUrl(projectId, floorPlanId), data),
    [projectApi],
  );

  const deleteReference = useCallback(
    (projectId: string, floorPlanId: string) =>
      projectApi.delete(createReferenceBaseUrl(projectId, floorPlanId)),
    [projectApi],
  );

  const bumpVersion = useCallback(
    async (projectId: string, groupId: string) =>
      mapToFloorPlan(
        (await projectApi.post(`/v2/${projectId}/floorPlanGroup/${groupId}/latest`)).data,
      ),
    [mapToFloorPlan, projectApi],
  );

  const restoreVersion = useCallback(
    async (projectId: string, groupId: string, sourceFloorPlanId: string) =>
      mapToFloorPlan(
        (
          await projectApi.post(`/v2/${projectId}/floorPlanGroup/${groupId}/restore`, {
            sourceFloorPlanId,
          })
        ).data,
      ),
    [mapToFloorPlan, projectApi],
  );

  return {
    deleteReference,
    fetchAllVersions,
    fetchReferenceDetails,
    fetchReferenceImage,
    fetchLatestVersion,
    fetchVersion,
    bumpVersion,
    restoreVersion,
    updateLatest,
    uploadReferenceImage,
    updateReferenceDetails,
  };
};

const mapToReferenceDetails = (response: any): ReferenceImageDetails => ({
  x: response.x,
  y: response.y,
  width: response.width,
  height: response.height,
  opacity: response.opacity,
  original: response.original,
  crop: response.crop,
  imageName: response.imageName,
  timeLimitedReferenceImageUrl: response.timeLimitedReferenceImageUrl,
  setPointA: response.pointA,
  setPointB: response.pointB,
  setDist: response.dist,

  // TODO are these needed?
  dist: response.dist,
  set: response.set,
  free: response.free,
});

const mapToUpdateResponse = (response: any) => ({
  hasUnsavedChanges: response.hasUnsavedChanges ?? false,
});
